import { TranslationService } from "../../../services/TranslationService";

const StaticFooter: React.FC<{ lang: string }> = (props: { lang: string }) => {
  const { translate } = TranslationService;
  return (
    <footer className="login-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-sm-2 hidden-xs hidden-sm">
            <h4 className="mb-0">{translate.Software}</h4>
            <ul className="pl-0">
              {(props.lang === "es" || props.lang === "pt") &&
                <li>
                  <a
                    target="_blank"
                    href="https://intiza.blog/wp-content/uploads/2019/06/INTIZA_-_Software_Gestion_Cobranzas.pdf"
                    rel="noreferrer"
                  >
                    {translate.SoftwareDocs}
                  </a>
                </li>}
              <li>
                <a
                  href="https://intiza.com/es/home/_terms"
                  className="ajaxmodal"
                  data-bs-target="#modal"
                >
                  {translate.Terms}
                </a>
              </li>
              <li>
                <a
                  href="https://intiza.com/es/home/_privacy"
                  className="ajaxmodal"
                  data-bs-target="#modal"
                >
                  {translate.PrivacyPolicy}
                </a>
              </li>
              <li>
                <a
                  href="https://intiza.com/es/home/_cookies"
                  className="ajaxmodal"
                  data-bs-target="#modal"
                >
                  {translate.CookiesPolicy}
                </a>
              </li>
              {(props.lang === "es" || props.lang === "pt") &&
                <li>
                  <a href={`https://www.intiza.com/${props.lang}/blog`} target="_blank" rel="noreferrer">
                    {translate.BlogFooter}
                  </a>
                </li>}

            </ul>
          </div>
          <div className="col-md-3 col-sm-2 hidden-xs hidden-sm">
            <h4 className="mb-0">{translate.OtherProducts}</h4>
            <ul className="pl-0">
              <li>
                <a
                  target="_blank"
                  href="https://proveedores.intiza.com/"
                  rel="noreferrer"
                >
                  {translate.VendorsSoftware}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-2 hidden-xs hidden-sm">
            <h4 className="mb-0">{translate.ContactFooter}</h4>
            <ul className="pl-0">
              <li>
                <a href="https://www.intiza.com/es#contacto">
                  {translate.ContactInfo}
                </a>
              </li>
              <li>
                <a
                  href="https://www.intiza.com/es#contacto"
                  className="ajaxmodal"
                >
                  {translate.ContactSupport}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row" style={{ marginTop: "0.4rem" }}>
          <div className="col-md-6 col1 order-2 order-md-1" style={{ fontSize: 12 }}>
            {translate.Copyright.replace("{year}", new Date().getFullYear().toString())} <span />
            {translate.Rights}
          </div>
        </div>
      </div>
    </footer>

  )
}

export default StaticFooter;