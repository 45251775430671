import React, { useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import InvoiceService from "../../../services/InvoiceService";
import { InvoiceHistoryListRequest } from "../../client/invoice/entities/InvoiceHistoryListRequest";
import { InvoiceHistoryListResponse } from "../../client/invoice/entities/InvoiceHistoryListResponse";

export class InvoiceHistoryTableContextValues extends TableContextValues<InvoiceHistoryListResponse, { extraFilters: string[] }> {
    max: number = 0;
    request: InvoiceHistoryListRequest = new InvoiceHistoryListRequest();
    setRequest: (request: InvoiceHistoryListRequest) => void = () => { };
}

const ReportHistoryIoListTableProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: InvoiceHistoryListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new InvoiceHistoryListRequest());
    const endpoint = useCallback(() => InvoiceService.getIoHistoryList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);

    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, filter: keyword })
    };
    const value: InvoiceHistoryTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        max: 0, //adaptar
        pageSize: request.pageSize,
        // setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportHistoryIoListTableProvider; 