import { FileUploadResponse } from "../pages/file/entities/FileUploadResponse";
import RequestService from "./RequestService";

class FileService {
    public static async download(fileId: string, fileName: string = "export.xlsx") {
        return RequestService.downloadFile("/file/download?id=" + fileId, fileName);
    }

    public static async downloadTask(fileId: string, taskId: string, fileName: string) {
        return RequestService.downloadFile(`/file/downloadtaskfile?id=${fileId}&taskid=${taskId}`, fileName);
    }

    public static async downloadEmailMessage(fileId: string, messageid: string | number, fileName: string) {
        return RequestService.downloadFile(`/file/downloadmessagefile?attachmentid=${fileId}&messageid=${messageid}`, fileName);
    }

    public static async downloadIO(fileId: string, ioid: string, fileName: string) {
        return RequestService.downloadFile(`/file/downloadiofiles?ioFileID=${fileId}&ioid=${ioid}`, fileName);
    }

    public static async downloadAction(fileId: string, actionId: string, fileName: string) {
        return RequestService.downloadFile(`/file/DownloadActionAttachment?actionAttachmentId=${fileId}&actionid=${actionId}`, fileName);
    }

    public static async downloadActionLetterTemplate(actionId: string) {
        return RequestService.downloadFile(`/file/DownloadActionTemplateFile?actionid=${actionId}`, 'template.doc');
    }

    public static async downloadReceipt(receiptid: string) {
        return RequestService.downloadFile(`/file/downloadreceipt?id=${receiptid}`);
    }

    public static async downloadImportTemplate(fileId: string, fileName: string = "template.xlsx") {
        return RequestService.downloadFile("/file/downloadimporttemplate?id=" + fileId, fileName);
    }

    public static downloadTempFile(file: File): void {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    public static async downloadReceiptTemplate() {
        return RequestService.downloadFile(`/file/downloadreceipttemplate`);
    }

    public static async downloadLastBackup() {
        return RequestService.downloadFile(`/file/downloadbackup`);
    }

    public static async deleteReceiptTemplate() {
        return RequestService.post('/receipt/deletetemplate', {})
    }

    public static uploadReceiptTemplate(fileid: string): Promise<FileUploadResponse | Error> {
        return RequestService.post("/receipt/settemplate", { fileid });
    }

    public static upload(file: File): Promise<FileUploadResponse | Error> {
        const formData = new FormData();
        formData.append("File", file, file.name);
        return RequestService.post("/file/upload", formData);
    }
}

export default FileService;