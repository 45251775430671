import { useContext } from "react";
import AdvancedFiltersContext from "../../shared/components/AdvancedFiltersContext";
import { TranslationService } from "../../../services/TranslationService";
import { FilterCollection } from "../../../services/FilterService";
import AdvancedFilters from "../../shared/components/AdvancedFilters";

export const RrsAdvancedFilters = ({ onFilterApply }: { onFilterApply: (filter: string[]) => void }) => {
    const context = useContext(AdvancedFiltersContext);
    return (<div className="card mh-100 mb-2">
        <div className="row">
            <div className="col-auto">
                <div className="d-flex input-column">
                    <button className="btn btn-tertiary me-auto" onClick={() => context.showFilters()}>
                        <i className="far fa-filter"></i>
                        {TranslationService.translate.AddFilters}
                    </button>
                </div>
            </div>
            <div className="col d-flex align-items-center">
                <AdvancedFilters page={FilterCollection.Invoice} onFilterApply={onFilterApply} />
            </div>
        </div>
    </div>);
}