import { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { TabPositionProvider } from "./TabPositionContext.tsx";
import ActionList from "../action/ActionList.tsx";
import EditAction from "../action/Edit/EditAction.tsx";
import AuthenticationContext from "./AuthenticationContext";
import Layout from "./Layout";
import GroupedList from "../grouped/List";
import Company from "../company";
import ClientList from "../client/List";
import GoalList from "../goal/List";
import SegmentEdit from "../segment/EditSegment";
import SegmentList from "../segment/List";
import TaskList from "../task/List";
import Profile from "../profile";
import CompanyConfiguration from "../company/Configuration";
import UserList from "../user/List";
import ClientDetail from "../client/ClientDetail";
import ProcessLog from "../company/Configuration/ProcessLog";
import ImportLog from "../company/Configuration/ImportLog";
import ErrorBoundary from "./ErrorBoundary";
import ReportRoutes from "./ReportRoutes";
import DashboardPanelPreview from "../company/DashboardPanelPreview";
import SegmentClientList from "../segment/SegmentClientList";
import ImportTemplateManual from "./components/ImportTemplateManual";
import CashappPage from "../cashapp/CashappPage";

const Application = () => {
    return (
        <div className="App">
            <Switch>
                <IsolatedRoute path="/:lang/company/edit" component={CompanyConfiguration} />
                <IsolatedRoute path="/:lang/edit" component={CompanyConfiguration} />
                <IsolatedRoute path="/:lang/configuration" component={CompanyConfiguration} />

                <IsolatedRoute path="/:lang/user" component={UserList} />
                <IsolatedRoute path="/:lang/company/user" component={UserList} />

                <IsolatedRoute path="/:lang/company/process" component={ProcessLog} />
                <IsolatedRoute path="/:lang/company/imports" component={ImportLog} />
                <IsolatedRoute path="/:lang/company/dashboardpanelpreview" component={DashboardPanelPreview} />

                <IsolatedRoute path="/:lang/action/editAction" component={EditAction} />

                <IsolatedRoute path="/:lang/action" component={ActionList} />
                <IsolatedRoute path="/:lang/grouped" component={GroupedList} />
                <IsolatedRoute path="/:lang/client/details" component={ClientDetail} />
                <IsolatedRoute path="/:lang/client" component={ClientList} />
                <IsolatedRoute path="/:lang/company" component={Company} />
                <IsolatedRoute path="/:lang/dashboard" component={Company} />
                <IsolatedRoute path="/:lang/goal" component={GoalList} />
                <IsolatedRoute path="/:lang/segment/edit" component={SegmentEdit} />
                <IsolatedRoute path="/:lang/segment/details" component={SegmentClientList} />
                <IsolatedRoute path="/:lang/segment" component={SegmentList} />
                <IsolatedRoute path="/:lang/task" component={TaskList} />

                <IsolatedRoute path="/:lang/account/edit" component={Profile} />
                <IsolatedRoute path="/:lang/report" component={ReportRoutes} />
                <IsolatedRoute path="/:lang/importTemplateManual" component={ImportTemplateManual} />

                <IsolatedRoute path="/:lang/cashapp" component={CashappPage} />
            </Switch>
        </div>
    )
}

const IsolatedRoute = (props) => {
    return (
        <Route path={props.path}>
            <RouteBoundary component={props.component} />
        </Route>);
}

const RouteBoundary = (props) => {
    const Component = props.component;
    const location = useLocation();
    return (
        <ErrorBoundary location={location.pathname + location.search}>
            <Component />
        </ErrorBoundary>);
}

const BaseSite = (props) => {
    const { isAuthenticated } = useContext(AuthenticationContext);
    const location = useLocation();
    const returnUrl = location.pathname + location.search;
    return (isAuthenticated ?
        <TabPositionProvider>
            <Layout />
            <Application />
            {/* <Footer></Footer> */}
        </TabPositionProvider>
        :
        <Redirect to={`/${props.lang}/logon?returnUrl=${returnUrl}`} />)
}

export default BaseSite;