import React, { useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService'
import AccountService, { Tax } from '../../../services/AccountService'

const initState = { "name": "-", "address": "-", "taxNumber": "-" }

export const InvoiceData = () => {
    const [data, setData] = useState<Tax>(initState)
    const [dataResponse, setDataResponse] = useState<Tax>(initState)
    const [changingData, setChangingData] = useState(false)

    const handleChangeData = () => setChangingData(p => !p)

    const loadData = async () => {
        const result = await AccountService.getTaxesDataUser()
        if (result instanceof Error) {
            return;
        }
        setData(result)
        setDataResponse(result)
    }

    const changeDataTaxes = async () => {
        const result = await AccountService.setTaxesDataUser(data)
        if (result instanceof Error) {
            return;
        }
        loadData()
        handleChangeData()
    }

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) =>
        setData((prevState) => ({ ...prevState, [name]: value, }));

    useEffect(() => {
        loadData()
    }, [])


    return (
        <div className="card-body">
            <div className='mb-2'>
                <h5>{TranslationService.translate.InvoicingData}</h5>
            </div>
            {changingData ?
                <>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="font-weight-normal col-form-label">{TranslationService.translate.Name}</label>
                            <input type="text" name='name' onChange={handleInputChange} className="form-control" defaultValue={dataResponse?.name} />
                        </div>
                        <div className="col mb-3">
                            <label className="font-weight-normal col-form-label">{TranslationService.translate.TaxId}</label>
                            <input type="text" name='taxNumber' onChange={handleInputChange} className="form-control" defaultValue={dataResponse?.taxNumber} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col mb-3">
                            <label className="font-weight-normal col-form-label">{TranslationService.translate.Address}</label>
                            <textarea className="form-control" onChange={(event) => setData(prev => ({ ...prev, address: event.target.value }))} defaultValue={dataResponse?.address} style={{ resize: "none" }} rows={6} />
                        </div>
                    </div>
                </> :
                <>
                    <div className='row mb-2'>
                        <label className="col-3 me-1">{TranslationService.translate.Name}:</label>
                        {dataResponse?.name}
                    </div>
                    <div className='row mb-2'>
                        <label className="col-3 me-1">{TranslationService.translate.TaxId}:</label>
                        {dataResponse?.taxNumber}
                    </div>
                    <div className='row mb-2'>
                        <label className="col-3 me-1">{TranslationService.translate.Address}:</label>
                        {dataResponse?.address}
                    </div>
                </>}

            <div className='mb-2 d-flex justify-content-end'>
                {!changingData ?
                    <button className="btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Change}</button>
                    : <div className='d-flex gap-2'>
                        <button className="col btn btn-primary mt-2" onClick={changeDataTaxes}>{TranslationService.translate.Save}</button>
                        <button className=" col btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Cancel}</button>
                    </div>}
            </div>

        </div>
    )
}
