import React, { useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import { ScheduledListResponse } from "../entities/ScheduledListResponse";
import { ScheduledListRequest } from "../entities/ScheduledListRequest";
import ScheduledService from "../../../services/ScheduledService";

export class ScheduledTableContextValues extends TableContextValues<ScheduledListResponse, { extraFilters: string[] }> {
    max: number = 0;
    request: ScheduledListRequest = new ScheduledListRequest();
    setRequest: (request: ScheduledListRequest) => void = () => { };
}

const ReportScheduledListTableProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: ScheduledListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new ScheduledListRequest());
    const endpoint = useCallback(() => ScheduledService.getScheduledTaskList(), []);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = 1;
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };
    const value: ScheduledTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        max: 0, //adaptar
        pageSize: request.pageSize,
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportScheduledListTableProvider; 