import { CSSProperties, useCallback, useEffect, useState } from "react";
import CompanyService, { Entities } from "../../../../../services/CompanyService";
import FieldConfigurationService, { AdditionalDefinitionsListResponse } from "../../../../../services/FieldConfigurationService";
import { TranslationService } from "../../../../../services/TranslationService";
import { ToastService } from "../../../../shared/bootstrap/Toast";
import ErrorMessage from "../../../../shared/components/Error";
import Loading from "../../../../shared/components/Loading";
import FloatingPanelService from "../../../../shared/FloatingPanel";
import AdditionalDefinitionsEdit from "../AdditionalDefinitionsEdit";
import ClientFieldItem from "../ClientFieldItem";
import OrderableTable from "../OrderableTable";
import { CompanyAuth } from "../../../../../entities/login/CompanyAuth";

type FieldType = CompanyAuth.ConfigItem | AdditionalDefinitionsListResponse.Item;
const FieldConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<FieldType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const requestData = async () => {
        setLoading(true);
        setError(false);
        const result = await FieldConfigurationService.getList();
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setError(true);
            setLoading(false);
            return;
        }
        const withFixedFields = [
            ...CompanyService.getConfigItems().filter(x => x.Entity === Entities.Client),
            ...result.list.filter(x => x.Entity === Entities.Client),
        ];
        const getValue = <T,>(field: FieldType, configExtractor: (x: CompanyAuth.ConfigItem) => T, additionalExtractor: (x: AdditionalDefinitionsListResponse.Item) => T) =>
            "AdditionalDefinitionID" in field ? additionalExtractor(field) : configExtractor(field);
        const sorted = CompanyService.getPersonSortedFields()
            .split(",")
            .map(x => withFixedFields.find(y => getValue(y, x => x.field, x => x.AdditionalDefinitionID).toString() === x)!)
            .filter(x => x !== undefined);
        setResponse(sorted);
        setLoading(false);
        setError(false);
    }
    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const showAdditionalDefinitionsEditModal = (AdditionalDefinitions?: AdditionalDefinitionsListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={requestDataCallback} fieldId={AdditionalDefinitions?.AdditionalDefinitionID} />,
            title: translate.AdditionalDefinitionss,
            width: 600,
            height: 800,
            position: "center",
        });
    }

    const onSort = (items: FieldType[]) => {
        const getValue = <T,>(field: FieldType, configExtractor: (x: CompanyAuth.ConfigItem) => T, additionalExtractor: (x: AdditionalDefinitionsListResponse.Item) => T) =>
            "AdditionalDefinitionID" in field ? additionalExtractor(field) : configExtractor(field);
        return CompanyService.getPersonSortedFields()
            .split(",")
            .map(x => items.find(y => getValue(y, x => x.field, x => x.AdditionalDefinitionID).toString() === x))
            .filter(x => x !== undefined);
    }

    const headers: { title: string, style?: undefined | CSSProperties }[] = [
        { title: translate.Field },
        { title: translate.Format },
        { title: translate.Identifier, style: { textAlign: "center" } },
        { title: translate.CustomerSite, style: { textAlign: "center" } },
        { title: "" }];

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }
    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.ClientFields}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showAdditionalDefinitionsEditModal(undefined)}><i className="fal fa-plus" /> {translate.CreateNewField}</button>
                </div>
            </div>
            <div className="col-lg-12">
                <OrderableTable data={response} RowComponent={ClientFieldItem} headers={headers} getKey={x => "Name" in x ? x.Name : x.field.toString()} onSort={onSort} reload={requestDataCallback} />
            </div>
        </div>
    )
}

export default FieldConfiguration;