import React from "react";

export class AdvancedFiltersContextValues {
    showFilters: () => void = () => { throw new Error("Filters not found") };
}
const AdvancedFiltersContext = React.createContext<AdvancedFiltersContextValues>(new AdvancedFiltersContextValues());
export const AdvancedFiltersProvider = (props: React.PropsWithChildren<{}>) =>
    <AdvancedFiltersContext.Provider value={{ showFilters: () => { } }}>
        {props.children}
    </AdvancedFiltersContext.Provider>;

export default AdvancedFiltersContext;    