import { useContext, useState } from "react";
import ClientService, { ClientFields, getClientDefaultFieldProperty } from "../../services/ClientService";
import CompanyService, { DataType, Entities } from "../../services/CompanyService";
import ToastContext from "../shared/bootstrap/Toast";
import { TranslationService } from "../../services/TranslationService";
import ClientFieldEdit from "./components/ClientFieldEdit";
import { Client } from "./entities/ClientGetResponse";
import { ClientEdit as ClientEditModel } from "./entities/ClientEdit";
import { ClientListRequest } from "./entities/ClientListRequest";
import { AutocompleteMultiSelect } from "../shared/components/Editors";
import { EntityField, sortEntityFields } from "../shared/entities/EntityField";
import FloatingPanelService from "../shared/FloatingPanel";
import { FloatingPanelFooter } from "../shared/components/FloatingPanelFooter";
import { DefaultModalProps } from "../shared/bootstrap/DefaultModal";
import ModalService from "../shared/bootstrap/Modal";
import { RequiredManager } from "../shared/RequieredManager";
import { Cast } from "../../utils/Utils";
import { formatDateTimeDigits } from "../../utils/FormatUtils";
import { restTimezone } from "../../utils/ParseUtils";
import { getError } from "../../utils/RequestUtils";

const ClientEdit = ({ client, onClientSave, onClientDeleted }: { client?: Client, onClientSave: () => void, onClientDeleted?: () => void }) => {
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [submiting, setSubmiting] = useState("");
    const { showDefaultModal } = ModalService;
    const requiredManager = new RequiredManager();

    const clientFields = [
        ...CompanyService.getConfigItemsFiltered(Entities.Client)
            .filter(x => ![-28, -27].includes(x.field))
            .map(x => new EntityField(x)),
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client, undefined, client ? undefined : true).map(x => new EntityField(x)),
    ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")));

    const emailIdx = clientFields.findIndex(x => x.id === -23);
    const commentaryIdx = clientFields.findIndex(x => x.id === -26);
    if (emailIdx >= 0 && commentaryIdx >= 0) {
        const email = clientFields.splice(emailIdx, 1)[0]
        const commentaryIdx = clientFields.findIndex(x => x.id === -26);
        clientFields.insert(commentaryIdx + 1, email);
    }

    const [clientEdit, setClientEdit] = useState(new ClientEditModel(client, parseInt(CompanyService.getUserid()), clientFields));

    //Agrupados - START
    const loadClientOptions = async (text: string) => {
        const request = new ClientListRequest();
        request.sortColumn = "Name";
        request.text = text;
        const result = await ClientService.getList(request);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return [];
        }
        return result.list.map(x => ({ value: x.PersonId.toString(), label: x.Name }));
    };

    const fieldsToValidate = [ClientFields.Name, CompanyService.getPersonKey()!];

    const onFieldValueChange = (field: EntityField) => (value: string | undefined) => {
        if (!clientEdit) { return; }
        if (value && field.type === DataType.Date) {
            value = new Date(value).toJSON();
        }

        if (field.isDefault()) {
            const clientProperty = getClientDefaultFieldProperty(field.id);
            setClientEdit({ ...clientEdit, [clientProperty]: value });
        }
        else {
            const newAdditionals = [...clientEdit.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }];
            setClientEdit({ ...clientEdit, additionals: newAdditionals });
        }
    };

    const saveClient = async () => {
        if (submiting !== "" || !requiredManager.validate()) {
            return;
        }
        clientEdit.groupedpersonids = clientEdit.groupedperson?.map(x => x.PersonId).join(",") ?? "";
        setSubmiting("save");
        const result = await ClientService.setClient(clientEdit, CompanyService.getAdditionalDefinitions());
        setSubmiting("");
        if (result instanceof Error) {
            const error = getError(result);
            if (error.message === "Key conflict") {
                ModalService.showDefaultModal({
                    message: TranslationService.translate.ClientCreateErrorExistingId.replace("{fieldName}",
                        CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === CompanyService.getPersonKey())?.Name ??
                        CompanyService.getConfigItems().find(x => x.field === CompanyService.getPersonKey())?.name ?? "")
                });
            } else {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
            }
            return;
        }
        showToast(translate.ClientSaved, undefined, "success");
        onClientSave && onClientSave();
        if (!client) {
            setClientEdit(new ClientEditModel(client, parseInt(CompanyService.getUserid())));
        }
        FloatingPanelService.hidePanel();
    };

    const deleteClient = () => {
        const modalProps: DefaultModalProps = {
            message: () => <> {translate.AreYouSureDeleteClient.split("\n").map(x => (
                <p key={x}>{x}</p>
            ))}</>,
            acceptButtonLabel: translate.Delete,
            acceptButtonClassName: "btn btn-danger",
            onAcceptClick: async () => {
                if (submiting !== "") {
                    return;
                }
                setSubmiting("del");
                const result = await ClientService.delete(client!.PersonId);
                setSubmiting("");
                if (result instanceof Error) {
                    showToast(translate.ErrorProcessingRequest, undefined, "danger");
                    return;
                }
                showToast(translate.ClientDeleted, undefined, "success");
                FloatingPanelService.hidePanel();
                onClientDeleted && onClientDeleted();
            }
        };
        showDefaultModal(modalProps);
    };

    return (
        <>
            <div className="floatingBody p-4">
                <div className="row">
                    {clientFields.map(field =>
                        <ClientFieldEdit key={field.id} field={field} client={Cast<Client>(clientEdit)} onChange={onFieldValueChange(field)} requiredManager={requiredManager} fieldsToValidate={fieldsToValidate} />
                    )}
                </div>
                {CompanyService.getCompanyAuthorization().includes("enablemultiplekey") &&
                    <div className="d-flex input-column mb-3">
                        <label
                            className="form-label">Agrupados</label>
                        <div className="row">
                            <div className="col">
                                <AutocompleteMultiSelect
                                    onChange={agrupados => {
                                        setClientEdit(clientEdit => ({
                                            ...clientEdit, groupedperson: agrupados?.map(x => ({
                                                PersonId: parseInt(x.value)
                                            }))
                                        }));
                                    }} loadOptions={loadClientOptions}
                                    defaultVal={client?.GroupedPerson?.map(x => ({
                                        value: x.PersonId.toString(), label: x.Name
                                    })) || []}
                                />
                            </div>
                        </div>
                    </div>}
                <div className="my-1 d-flex
                justify-content-between">
                    <small>
                        {client?.Created && <><b>{translate.CreatedDate}: </b>
                            {formatDateTimeDigits(restTimezone(new Date(client!.Created!)))}</>}
                    </small>
                    <small>
                        {client?.Created && (client.CreatorUserID || client.ImportID) && <><b className="ms-3">{translate.Creator}: </b>
                            {(client.CreatorUserID && CompanyService.getUsers()?.find(x => x.Id === client.CreatorUserID!.toString())?.Value)}&nbsp;
                            {client.ImportID && " (" + translate.ByImportProcess + ")"}</>}
                    </small>
                </div>
            </div>
            <FloatingPanelFooter showDelete={CompanyService.canDo("deleteperson") && (client?.PersonId ?? 0) > 0} onDeleteClick={deleteClient} isDeleting={submiting === "del"}>
                <button className="btn btn-primary" onClick={saveClient}>
                    {translate.Save}
                    {submiting === "save" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};



export default ClientEdit;