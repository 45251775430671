import React from 'react'
import { TranslationService } from '../../../../services/TranslationService'

type WhatsappInputSendType = {
    handleSend: () => void,
    whatsAppTemplateText: string,
    setFreeTextChanged: (data: string) => void,
    disabledInput?: boolean
}

export const WhatsappInputSend = ({
    handleSend,
    whatsAppTemplateText,
    setFreeTextChanged,
    disabledInput = true
}: WhatsappInputSendType) => {

    const handleChange = (e: any) => {
        e.target?.value && setFreeTextChanged(e.target.value.toString())
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <textarea
                    name="input"
                    className="col-1"
                    style={{ width: '90%', color: disabledInput ? 'grey' : 'black' }}
                    rows={3}
                    placeholder={disabledInput ? TranslationService.translate.SelectTemplate : TranslationService.translate.Freetext}
                    autoComplete="off"
                    disabled={disabledInput}
                    value={whatsAppTemplateText}
                    onChange={(v: any) => handleChange(v)}
                />
                <div className="conversation-compose">
                    <button className="send" onClick={handleSend}>
                        <div className="circle">
                            <i className="fas fa-send"></i>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}
