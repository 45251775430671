import { useContext, useEffect, useState } from "react";
import { TranslationService } from '../../../services/TranslationService';
import ToastContext from "../../shared/bootstrap/Toast";
import ModalService from "../../shared/bootstrap/Modal";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import DashboardService from "../../../services/DashboardService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { MultiselectEditor } from "../../shared/components/Editors";
import CompanyService from "../../../services/CompanyService";
import { ClientFields } from "../../../services/ClientService";

const DashboardEdit = ({ reload, dashboard }: { reload: () => void, dashboard: DashboardEntity }) => {

    useEffect(
        () => {
            setDashboardModel(dashboard);
        }
        , [dashboard])


    const canShowVariable = () => {
        return dashboardModel.Type === 0 || dashboardModel.Type === 1 || dashboardModel.Type === 2 || dashboardModel.Type === 7 || dashboardModel.Type === 8;
    }

    const canShowExtraData = () => {
        return dashboardModel.Type === 0 || dashboardModel.Type === 1 || dashboardModel.Type === 2 || dashboardModel.Type === 7;
    }

    const canShowGroupBy = () => {
        return dashboardModel.Type === 0 || dashboardModel.Type === 1 || dashboardModel.Type === 2 || dashboardModel.Type === 7 || dashboardModel.Type === 11;
    }

    const canShowLastDays = () => {
        return dashboardModel.Type === 10 || dashboardModel.Type === 11 || dashboardModel.Type === 12;
    }

    const canShowSegments = () => {
        return dashboardModel.GroupBy === ClientFields.Segments
    }

    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [submiting, setSubmiting] = useState("");
    const [dashboardModel, setDashboardModel] = useState({ ...dashboard });

    const grouping = DashboardService.getGrouping(dashboardModel.Type === 11);
    const variables = DashboardService.getVariables(dashboardModel.Type === 8);
    const panelTypes = DashboardService.getPanelTypes();
    const segments = CompanyService.getSegments().map(x => ({ value: x.Id, label: x.Value }));

    const dashboardTypeChanged = (type: number) => {
        dashboardModel.Type = type;
        if (canShowExtraData()) {
            if (dashboardModel.GroupBy === 0) {
                dashboardModel.GroupBy = grouping[0].Value;
            }
        }
        else {
            //dashboardModel.Title = "";
            dashboardModel.MaxItems = 0;
            //dashboardModel.Variable = 0;
            dashboardModel.GroupBy = 0;
        }
        if (canShowVariable()) {
            const variableOptions = DashboardService.getVariables(dashboardModel.Type === 8);
            if (!variableOptions.find(x => x.Value === dashboardModel.Variable)) {
                dashboardModel.Variable = variableOptions[0].Value;
            }
        }
        else {
            dashboardModel.Variable = 0;
        }
        if (!canShowLastDays()) {
            dashboardModel.DashboardPersonDays = null;
        }

        setDashboardModel({ ...dashboardModel });
    }

    const groupByChanged = (groupBy: number) => {
        setDashboardModel({ ...dashboardModel, GroupBy: groupBy });
    }

    const segmentChanged = (values: string[] | undefined) => {
        const val = values ? values.join(",") : null;
        setDashboardModel({ ...dashboardModel, Data: val });
    }

    const onSubmit = async () => {
        if (submiting !== "") {
            return;
        }
        setSubmiting("set");

        const result = await DashboardService.set(dashboardModel);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting("");
            return;
        }
        setSubmiting("");
        reload();
        FloatingPanelService.hidePanel();
        showToast(translate.DashboardSaved, undefined, "success");
    }

    const onDelete = () => {
        if (submiting !== "") {
            return;
        }
        const deleteDashboard = async () => {
            setSubmiting("del");
            const result = await DashboardService.delete(dashboardModel.DashboardID!, dashboardModel.DashboardPanelID);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting("");
                return;
            }
            setSubmiting("");
            reload();
            FloatingPanelService.hidePanel();
            showToast(translate.DashboardDeleted, undefined, "success");
        }
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: translate.AreYouSureDeleteDashboard,
            onAcceptClick: deleteDashboard,
        })
    }

    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label>{translate.Type}:</label>
                        <select className="form-control" onChange={(e) => dashboardTypeChanged(parseIntOrDefault(e.target.value, 0))} defaultValue={dashboard.Type}>
                            <option value="1">{panelTypes[1]}</option>
                            <option value="2">{panelTypes[2]}</option>
                            <option value="0">{panelTypes[0]}</option>
                            <option value="7">{panelTypes[7]}</option>
                            <option value="3">{panelTypes[3]}</option>
                            <option value="5">{panelTypes[5]}</option>
                            <option value="6">{panelTypes[6]}</option>
                            <option value="4">{panelTypes[4]}</option>
                            <option value="8">{panelTypes[8]}</option>
                            <option value="9">{panelTypes[9]}</option>
                            <option value="10">{panelTypes[10]}</option>
                            <option value="11">{panelTypes[11]}</option>
                            <option value="12">{panelTypes[12]}</option>
                            <option value="13">{panelTypes[13]}</option>
                        </select>
                    </div>

                    <div className="form-group mb-3">
                        <label>{translate.Title}</label>
                        <input type="text" className="form-control" value={dashboardModel.Title} onChange={(e) => setDashboardModel({ ...dashboardModel, Title: e.target.value })}></input>
                    </div>

                    {canShowVariable() &&
                        <div className="form-group mb-3">
                            <label>{translate.Variable}</label>
                            <select className="form-control" onChange={(e) => setDashboardModel({ ...dashboardModel, Variable: parseIntOrDefault(e.target.value, 0) })} value={dashboardModel.Variable}>
                                {variables.map(x => (
                                    <option key={x.Value} value={x.Value}>{x.Text}</option>
                                ))}
                            </select>
                        </div>
                    }
                    {canShowGroupBy() &&
                        <>
                            <div className="form-group mb-3">
                                <label>{translate.GroupedBy}</label>
                                <select className="form-control" onChange={(e) => groupByChanged(parseIntOrDefault(e.target.value, 0))} value={dashboardModel.GroupBy}>
                                    {grouping.map(x => (
                                        <option key={x.Value} value={x.Value}>{x.Text}</option>
                                    ))}
                                </select>
                            </div>
                            {canShowSegments() &&
                                <div className="form-group mb-3">
                                    <label>{translate.Segments}</label>
                                    <MultiselectEditor placeholder={translate.Select} callback={segmentChanged} value={dashboardModel.Data ? dashboardModel.Data.split(",").map(x => ({ value: x, label: segments.find(y => y.value === x)!.label })) : undefined} items={segments} />
                                </div>
                            }
                        </>
                    }
                    {canShowExtraData() &&
                        <div className="form-group mb-3">
                            <label>{translate.ItemsLimit}</label>
                            <div className="d-flex">
                                <input type="text" style={{ width: "75px" }} className="form-control" value={dashboardModel.MaxItems ?? 0} onChange={(e) => setDashboardModel({ ...dashboardModel, MaxItems: parseIntOrDefault(e.target.value, 0) })}></input>
                                <label className="font-weight-normal col-form-label ps-2">0: {translate.NoLimit}</label>
                            </div>
                        </div>
                    }

                    {canShowLastDays() &&
                        <div className="form-group mb-3">
                            <label>{translate.LastNDays}</label>
                            <div className="d-flex">
                                <input type="number" style={{ width: "75px" }} className="form-control" value={dashboardModel.Data ?? ""} onChange={(e) => setDashboardModel({ ...dashboardModel, Data: parseIntOrDefault(e.target.value, 0).toString() })}></input>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <FloatingPanelFooter>

                {dashboard.DashboardID > 0 && <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                    {translate.Delete}
                    {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>}

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )
}

export default DashboardEdit