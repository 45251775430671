import React, { useState } from 'react'
import { TranslationService } from '../../../services/TranslationService'
import AccountService from '../../../services/AccountService'
import Dropdown from '../../shared/components/Dropdown'
import CompanyService from '../../../services/CompanyService'
import Loading from '../../shared/components/Loading'

const timeZoneItems = [
    { value: -720, text: "(UTC-12:00) Línea internacional de cambio de fecha" },
    { value: -660, text: "(UTC-11:00) Hora universal coordinada-11" },
    { value: -600, text: "(UTC-10:00) Hawai" },
    { value: -600, text: "(UTC-10:00) Islas Aleutianas" },
    { value: -558, text: "(UTC-09:30) Islas Marquesas" },
    { value: -540, text: "(UTC-09:00) Alaska" },
    { value: -540, text: "(UTC-09:00) Hora universal coordinada-09" },
    { value: -480, text: "(UTC-08:00) Baja California" },
    { value: -480, text: "(UTC-08:00) Hora del Pacífico (EE.UU. y Canadá)" },
    { value: -480, text: "(UTC-08:00) Hora universal coordinada-08" },
    { value: -420, text: "(UTC-07:00) Arizona" },
    { value: -420, text: "(UTC-07:00) Hora de las Montañas Rocosas (EE.UU. y Canadá)" },
    { value: -420, text: "(UTC-07:00) La Paz, Mazatlán" },
    { value: -420, text: "(UTC-07:00) Yukon" },
    { value: -360, text: "(UTC-06:00) América Central" },
    { value: -360, text: "(UTC-06:00) Guadalajara, Ciudad de México, Monterrey" },
    { value: -360, text: "(UTC-06:00) Hora central (EE.UU. y Canadá)" },
    { value: -360, text: "(UTC-06:00) Isla de Pascua" },
    { value: -360, text: "(UTC-06:00) Saskatchewan" },
    { value: -300, text: "(UTC-05:00) Bogotá, Lima, Quito, Rio Branco" },
    { value: -300, text: "(UTC-05:00) Chetumal" },
    { value: -300, text: "(UTC-05:00) Haití" },
    { value: -300, text: "(UTC-05:00) Hora del este (EE.UU. y Canadá)" },
    { value: -300, text: "(UTC-05:00) Indiana (este)" },
    { value: -300, text: "(UTC-05:00) Islas Turcas y Caicos" },
    { value: -300, text: "(UTC-05:00) La Habana" },
    { value: -240, text: "(UTC-04:00) Asunción" },
    { value: -240, text: "(UTC-04:00) Caracas" },
    { value: -240, text: "(UTC-04:00) Cuiabá" },
    { value: -240, text: "(UTC-04:00) Georgetown, La Paz, Manaos, San Juan" },
    { value: -240, text: "(UTC-04:00) Hora del Atlántico (Canadá)" },
    { value: -240, text: "(UTC-04:00) Santiago" },
    { value: -198, text: "(UTC-03:30) Terranova" },
    { value: -180, text: "(UTC-03:00) Araguaína" },
    { value: -180, text: "(UTC-03:00) Brasilia" },
    { value: -180, text: "(UTC-03:00) Cayena, Fortaleza" },
    { value: -180, text: "(UTC-03:00) Ciudad de Buenos Aires" },
    { value: -180, text: "(UTC-03:00) Montevideo" },
    { value: -180, text: "(UTC-03:00) Punta Arenas" },
    { value: -180, text: "(UTC-03:00) Salvador" },
    { value: -180, text: "(UTC-03:00) San Pedro y Miquelón" },
    { value: -120, text: "(UTC-02:00) Atlántico central - Antiguo" },
    { value: -120, text: "(UTC-02:00) Groenlandia" },
    { value: -120, text: "(UTC-02:00) Hora universal coordinada-02" },
    { value: -60, text: "(UTC-01:00) Azores" },
    { value: -60, text: "(UTC-01:00) Cabo Verde" },
    { value: 0, text: "(UTC) Hora universal coordinada" },
    { value: 0, text: "(UTC+00:00) Dublín, Edimburgo, Lisboa, Londres" },
    { value: 0, text: "(UTC+00:00) Monrovia, Reikiavik" },
    { value: 0, text: "(UTC+00:00) Santo Tomé" },
    { value: 60, text: "(UTC+01:00) Casablanca" },
    { value: 60, text: "(UTC+01:00) Amsterdam, Berlín, Berna, Roma, Estocolmo, Viena" },
    { value: 60, text: "(UTC+01:00) Belgrado, Bratislava, Budapest, Liubliana, Praga" },
    { value: 60, text: "(UTC+01:00) Bruselas, Copenhague, Madrid, París" },
    { value: 60, text: "(UTC+01:00) Sarajevo, Skopie, Varsovia, Zagreb" },
    { value: 60, text: "(UTC+01:00) África Central Occidental" },
    { value: 120, text: "(UTC+02:00) Atenas, Bucarest" },
    { value: 120, text: "(UTC+02:00) Beirut" },
    { value: 120, text: "(UTC+02:00) Chisinau" },
    { value: 120, text: "(UTC+02:00) El Cairo" },
    { value: 120, text: "(UTC+02:00) Gaza, Hebrón" },
    { value: 120, text: "(UTC+02:00) Harare, Pretoria" },
    { value: 120, text: "(UTC+02:00) Helsinki, Kiev, Riga, Sofía, Tallin, Vilna" },
    { value: 120, text: "(UTC+02:00) Jartum" },
    { value: 120, text: "(UTC+02:00) Jerusalén" },
    { value: 120, text: "(UTC+02:00) Kaliningrado" },
    { value: 120, text: "(UTC+02:00) Trípoli" },
    { value: 120, text: "(UTC+02:00) Windhoek" },
    { value: 120, text: "(UTC+02:00) Yuba" },
    { value: 180, text: "(UTC+03:00) Ammán" },
    { value: 180, text: "(UTC+03:00) Bagdad" },
    { value: 180, text: "(UTC+03:00) Damasco" },
    { value: 180, text: "(UTC+03:00) Estambul" },
    { value: 180, text: "(UTC+03:00) Kuwait, Riad" },
    { value: 180, text: "(UTC+03:00) Minsk" },
    { value: 180, text: "(UTC+03:00) Moscú, San Petersburgo" },
    { value: 180, text: "(UTC+03:00) Nairobi" },
    { value: 180, text: "(UTC+03:00) Volgogrado" },
    { value: 198, text: "(UTC+03:30) Teherán" },
    { value: 240, text: "(UTC+04:00) Abu Dabi, Mascate" },
    { value: 240, text: "(UTC+04:00) Astrakhan, Ulyanovsk" },
    { value: 240, text: "(UTC+04:00) Bakú" },
    { value: 240, text: "(UTC+04:00) Ereván" },
    { value: 240, text: "(UTC+04:00) Izhevsk, Samara" },
    { value: 240, text: "(UTC+04:00) Port Louis" },
    { value: 240, text: "(UTC+04:00) Sarátov" },
    { value: 240, text: "(UTC+04:00) Tiflis" },
    { value: 258, text: "(UTC+04:30) Kabul" },
    { value: 300, text: "(UTC+05:00) Asjabad, Tashkent" },
    { value: 300, text: "(UTC+05:00) Ekaterimburgo" },
    { value: 300, text: "(UTC+05:00) Islamabad, Karachi" },
    { value: 300, text: "(UTC+05:00) Qyzylorda" },
    { value: 318, text: "(UTC+05:30) Chennai, Kolkata (Calcuta), Mumbai, Nueva Delhi" },
    { value: 318, text: "(UTC+05:30) Sri Jayawardenepura" },
    { value: 327, text: "(UTC+05:45) Katmandú" },
    { value: 360, text: "(UTC+06:00) Astaná" },
    { value: 360, text: "(UTC+06:00) Dacca" },
    { value: 360, text: "(UTC+06:00) Omsk" },
    { value: 378, text: "(UTC+06:30) Yangón (Rangún)" },
    { value: 420, text: "(UTC+07:00) Bangkok, Hanói, Yakarta" },
    { value: 420, text: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
    { value: 420, text: "(UTC+07:00) Hovd" },
    { value: 420, text: "(UTC+07:00) Krasnoyarsk" },
    { value: 420, text: "(UTC+07:00) Novosibirsk" },
    { value: 420, text: "(UTC+07:00) Tomsk" },
    { value: 480, text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { value: 480, text: "(UTC+08:00) Irkutsk" },
    { value: 480, text: "(UTC+08:00) Kuala Lumpur, Singapur" },
    { value: 480, text: "(UTC+08:00) Perth" },
    { value: 480, text: "(UTC+08:00) Taipéi" },
    { value: 480, text: "(UTC+08:00) Ulán Bator" },
    { value: 507, text: "(UTC+08:45) Eucla" },
    { value: 540, text: "(UTC+09:00) Chita" },
    { value: 540, text: "(UTC+09:00) Osaka, Sapporo, Tokio" },
    { value: 540, text: "(UTC+09:00) Pyongyang" },
    { value: 540, text: "(UTC+09:00) Seúl" },
    { value: 540, text: "(UTC+09:00) Yakutsk" },
    { value: 558, text: "(UTC+09:30) Adelaida" },
    { value: 558, text: "(UTC+09:30) Darwin" },
    { value: 600, text: "(UTC+10:00) Brisbane" },
    { value: 600, text: "(UTC+10:00) Canberra, Melbourne, Sídney" },
    { value: 600, text: "(UTC+10:00) Guam, Port Moresby" },
    { value: 600, text: "(UTC+10:00) Hobart" },
    { value: 600, text: "(UTC+10:00) Vladivostok" },
    { value: 618, text: "(UTC+10:30) Isla Lord Howe" },
    { value: 660, text: "(UTC+11:00) Chokurdakh" },
    { value: 660, text: "(UTC+11:00) Isla Bougainville" },
    { value: 660, text: "(UTC+11:00) Isla Norfolk" },
    { value: 660, text: "(UTC+11:00) Islas Salomón, Nueva Caledonia" },
    { value: 660, text: "(UTC+11:00) Magadán" },
    { value: 660, text: "(UTC+11:00) Sajalín" },
    { value: 720, text: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky" },
    { value: 720, text: "(UTC+12:00) Auckland, Wellington" },
    { value: 720, text: "(UTC+12:00) Fiyi" },
    { value: 720, text: "(UTC+12:00) Hora universal coordinada+12" },
    { value: 720, text: "(UTC+12:00) Petropavlovsk-Kamchatsky - antiguo" },
    { value: 747, text: "(UTC+12:45) Islas Chatham" },
    { value: 780, text: "(UTC+13:00) Hora universal coordinada+13" },
    { value: 780, text: "(UTC+13:00) Nuku'alofa" },
    { value: 780, text: "(UTC+13:00) Samoa" },
    { value: 840, text: "(UTC+14:00) Isla Kiritimati" },
];


const languagesDefault = [
    { text: 'Español', value: 'es' },
    { text: 'English', value: 'en' },
    { text: 'Portugues', value: 'pt' },
]

export const CultureData = () => {
    const { currentLanguage, setCurrentLanguage } = TranslationService
    const timeZoneDefault = CompanyService.getCompanyToken()?.timezone
    const initTimeZone = timeZoneDefault && timeZoneItems.find(el => el.value === parseInt(timeZoneDefault) * -1)?.text;

    const [changingData, setChangingData] = useState(false)
    const [dataText, setDataText] = useState<{ culture?: string, timezone?: number, timeName?: string }>();
    const [loading, setLoading] = useState(false)
    const timeZonesMapped = [{ text: TranslationService.translate.Select, value: -1 }, ...timeZoneItems.map(x => ({ text: x.text, value: x.value }))]
    const languagesMapped = [{ text: TranslationService.translate.Select, value: '' }, ...languagesDefault]

    const handleChangeData = () => {
        setChangingData(p => !p)
        setLoading(false)
    }

    const handleTimeZoneChange = async (timezone: number) => {

        setLoading(true)

        const valueSelected = timeZoneItems.find(el => el.value === timezone)
        if (valueSelected && valueSelected?.value && valueSelected?.text.length > 0) {
            setDataText(d => ({ ...d, timezone: valueSelected.value, timeName: valueSelected.text }))
        }
        await AccountService.setAccountTimeZone({ offset: timezone })
            .catch(() => setLoading(false))
            .finally(handleChangeData)
    }

    const handleCultureChange = async (culture: string) => {
        setLoading(true)
        setDataText(d => ({ ...d, culture }))
        setCurrentLanguage(culture as "es" | "en" | "pt")
        await AccountService.setAccountCulture({ culture })
            .catch(() => setLoading(false))
            .finally(() => window.location.replace(window.location.pathname.replace(currentLanguage, culture)))
    }

    return (
        <div className="card-body">
            <div className='mb-2'>
                <h5>{TranslationService.translate.CultureData}</h5>
            </div>
            {
                loading ?
                    <Loading /> :
                    <>
                        {changingData ?
                            <div className="col">
                                <div className="col mb-3">
                                    <label className="font-weight-normal col-form-label">{TranslationService.translate.TimeZone}</label>
                                    <Dropdown onChange={handleTimeZoneChange} items={timeZonesMapped} />
                                    <label className="font-weight-normal col-form-label">{TranslationService.translate.Language}</label>
                                    <Dropdown onChange={handleCultureChange} items={languagesMapped} />
                                </div>
                            </div>
                            : <div className='row mb-2'>
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.TimeZone}</label>
                                <p>{dataText?.timeName?.split(' ')[0] || initTimeZone?.split(' ')[0]}</p>


                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Language}</label>
                                <p>{dataText?.culture || languagesDefault.find(el => el.value === currentLanguage)?.text}</p>
                            </div>}
                    </>
            }




            <div className='mb-2 d-flex justify-content-end'>
                {!changingData ?
                    <button className="btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Change}</button>
                    : <div className='d-flex gap-2'>
                        <button className=" col btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Cancel}</button>
                    </div>}
            </div>
        </div>
    )
}
