import { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
// import ToastContext from "../../shared/bootstrap/Toast";
// import ModalService from "../../shared/bootstrap/Modal";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { CheckBoxEditor, TextEditor } from "../../shared/components/Editors";
import { IOStatusListResponse } from "../../../entities/company/IOStatus/IOStatusListResponse";
import IOStatusService from "../../../services/IOStatusService";
import { IOStatusDependency, IOStatusEntity } from "../../../entities/company/IOStatus/IOStatusEntity";
import CompanyService, { Entities } from "../../../services/CompanyService";
import Dropdown from "../../shared/components/Dropdown";
import ToastContext from "../../shared/bootstrap/Toast";
import ModalService from "../../shared/bootstrap/Modal";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";


const InvoiceStatus = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<IOStatusListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        setLoading(true);
        const result = await IOStatusService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        result.list.unshift({
            Exclude: false,
            IOStatusDependencies: [],
            Public: false,
            IOStatusID: -1,
            PublicValue: "",
            Value: CompanyService.getNormalIOStatusName()!
        });
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showInvoiceStatusEdit = (invoiceStatus?: IOStatusEntity) => {
        FloatingPanelService.showPanel({
            children: <InvoiceStatusEdit reload={requestDataCallback} invoiceStatus={invoiceStatus} />,
            title: invoiceStatus ? translate.EditInvoiceStatus : translate.NewInvoiceStatus,
            width: 700,
            height: 450,
            position: "center",
        });
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.InvoiceStatus}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showInvoiceStatusEdit(undefined)}><i className="fal fa-plus" /> {translate.NewInvoiceStatus}</button>
                </div>
            </div>
            <table className="table shadow-hover">
                <thead>
                    <tr>
                        <th>{translate.Value}</th>
                        <th>{translate.Exclude}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <tr key={x.IOStatusID} className="pointer show-child-on-hover" onClick={(event) => {
                            showInvoiceStatusEdit(x); event.stopPropagation();
                        }}>
                            <td>
                                {x.Value}
                            </td>
                            <td>{x.Exclude}</td>
                            <td width={200}> </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    )
}


const InvoiceStatusEdit = ({ reload, invoiceStatus }: { reload: () => void, invoiceStatus?: IOStatusEntity }) => {

    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [showAdvanceOptions, setShowAdvanceOptions] = useState(invoiceStatus && (invoiceStatus.IOStatusDependencies.length > 0 || invoiceStatus.Public || invoiceStatus.PublicValue !== ""));
    const [submiting, setSubmiting] = useState<string | null>(null);
    const [invoiceStatusModel, setInvoiceStatusModel] = useState<IOStatusEntity>({
        ...invoiceStatus ?? {
            Exclude: false,
            IOStatusID: 0,
            Public: false,
            PublicValue: "",
            Value: "",
            IOStatusDependencies: []
        }
    });

    const [value, setValue] = useState(invoiceStatus?.Value ?? "");

    const [iOStatusDependency, setIOStatusDependency] = useState<IOStatusDependency>({
        IOStatusDependencyID: 0,
        Mandatory: false,
        AdditionalDefinitionID: 0,
        Public: false
    });

    const requiredManager = new RequiredManager();
    const onValueChanged = requiredManager.makeRequired(setValue);

    const addIOStatusDependency = () => {
        if (!iOStatusDependency.AdditionalDefinitionID) {
            return;
        }
        invoiceStatusModel.IOStatusDependencies.push(iOStatusDependency);
        setIOStatusDependency({
            IOStatusDependencyID: 0,
            Mandatory: false,
            AdditionalDefinitionID: "",
            Public: false
        });
    }

    const deleteIOStatusDependency = (index: number) => {
        invoiceStatusModel.IOStatusDependencies.splice(index, 1);
        setInvoiceStatusModel({ ...invoiceStatusModel });
    }

    const changeIOStatusDependencyMandatory = (index: number, value: boolean) => {
        invoiceStatusModel.IOStatusDependencies[index].Mandatory = value;
        setInvoiceStatusModel({ ...invoiceStatusModel });
    }

    const changeIOStatusDependencyPublic = (index: number, value: boolean) => {
        invoiceStatusModel.IOStatusDependencies[index].Public = value;
        setInvoiceStatusModel({ ...invoiceStatusModel });
    }

    const onSubmit = async () => {
        if (submiting !== null) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        setSubmiting("set");
        invoiceStatusModel.Value = value;
        const result = await IOStatusService.set(invoiceStatusModel);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting(null);
            return;
        }
        setSubmiting(null);
        reload();
        FloatingPanelService.hidePanel();
        showToast(translate.InvoiceStatusSaved, undefined, "success");
    }

    const onDelete = () => {
        const deleteTransactionType = async () => {
            if (submiting !== null) {
                return;
            }
            setSubmiting("del");
            const result = await IOStatusService.delete(invoiceStatusModel?.IOStatusID!);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting(null);
                return;
            }
            setSubmiting(null);
            reload();
            FloatingPanelService.hidePanel();
            showToast(translate.InvoiceStatusDeleted, undefined, "success");
        }
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: () => <>{translate.AreYouSureDeleteInvoiceStatus.replace("{0}", CompanyService.getNormalIOStatusName()).split("\n").map(x => (
                <><span>{x}</span><br /></>
            ))}</>,
            onAcceptClick: deleteTransactionType,
        })
    }


    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="font-weight-normal col-form-label">{translate.Name}</label>
                        <TextEditor onChange={onValueChanged} defaultValue={value} />
                        {<ValidationMessage onChange={onValueChanged} defaultValue={value} />}
                    </div>

                    {invoiceStatusModel.IOStatusID > -1 && (
                        <>
                            <div className="mb-3">
                                <CheckBoxEditor onChange={(x) => setInvoiceStatusModel({ ...invoiceStatusModel, Exclude: x })} defaultValue={invoiceStatus?.Exclude} message={translate.Exclude}></CheckBoxEditor>
                            </div>
                            <div className="mb-3">
                                <button type="button" className="btn btn-link ps-0" onClick={() => setShowAdvanceOptions(x => !x)}>{showAdvanceOptions ? translate.HideAdvancedOptions : translate.ShowAdvancedOptions}</button>
                            </div>

                            {showAdvanceOptions &&
                                <>
                                    <div className="mb-3">
                                        <CheckBoxEditor onChange={(x) => setInvoiceStatusModel({ ...invoiceStatusModel, Public: x })} defaultValue={invoiceStatus?.Public} message={translate.Public}></CheckBoxEditor>
                                    </div>

                                    <div className="mb-3">
                                        <label className="font-weight-normal col-form-label">{translate.PublicName}</label>
                                        <TextEditor onChange={x => { setInvoiceStatusModel({ ...invoiceStatusModel, PublicValue: x }) }} defaultValue={invoiceStatus?.PublicValue} />
                                    </div>
                                    <table className="table mt-1 optionsList ">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {translate.AdditionalField}
                                                </th>
                                                <th className="text-center">
                                                    {translate.Mandatory}
                                                </th>
                                                <th className="text-center">
                                                    {translate.Public}
                                                </th>
                                                <th style={{ width: "50px" }}>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceStatusModel?.IOStatusDependencies.map((x, index) => {
                                                return (
                                                    <tr key={index} className="show-child-on-hover">
                                                        <td>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                {CompanyService.getAdditionalDefinitions().find(y => y.AdditionalDefinitionID === x.AdditionalDefinitionID)?.Name}
                                                            </div>
                                                        </td>
                                                        <td className="text-center">

                                                            <CheckBoxEditor onChange={(x) => { changeIOStatusDependencyMandatory(index, x); }} value={x.Mandatory} message=""></CheckBoxEditor>

                                                        </td>
                                                        <td className="text-center ">
                                                            <CheckBoxEditor onChange={(x) => { changeIOStatusDependencyPublic(index, x); }} value={x.Public} message=""></CheckBoxEditor>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-column justify-content-center align-items-center show-when-hovering-parent text-danger">
                                                                <i className="far fa-fw pointer fa-trash mx-2" onClick={(event) => {
                                                                    deleteIOStatusDependency(index); event.stopPropagation();
                                                                }}></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>
                                                    <Dropdown onChange={x => { setIOStatusDependency({ ...iOStatusDependency, AdditionalDefinitionID: x as number }) }} value={iOStatusDependency.AdditionalDefinitionID as number} optionLabel={TranslationService.translate.Select} items={CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Invoice).map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))}></Dropdown>
                                                </td>
                                                <td className="text-center">
                                                    <CheckBoxEditor onChange={(x) => setIOStatusDependency({ ...iOStatusDependency, Mandatory: x })} value={iOStatusDependency.Mandatory} message=""></CheckBoxEditor>
                                                </td>
                                                <td className="text-center ">
                                                    <CheckBoxEditor onChange={(x) => setIOStatusDependency({ ...iOStatusDependency, Public: x })} value={iOStatusDependency.Public} message=""></CheckBoxEditor>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-link" onClick={addIOStatusDependency}>{translate.Add}</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                        </>
                    )}
                </div>
            </div>

            <FloatingPanelFooter>
                {invoiceStatusModel.IOStatusID > 0 &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                        {translate.Delete}
                        {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )

}


export default InvoiceStatus