import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import DateFormat from "./Components/DateFormat";
import SeparatorSimple from "./Components/SeparatorSimple";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";
import { TextEditor, CheckBoxEditor } from "../../../shared/components/Editors";
import EditItem from "./Components/EditItem";


const ImportTemplateEditActivity = ({ data, reload }: { data?: ImportTemplateListResponse.Item, reload: () => void }) => {
const { translate } = TranslationService;
    const requiredIds = ["client", "content"];

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.ConfigureInvoicePaymentImport}</h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {translate.RequirementsOfTheFileToImportTransaction.split("\n").map((x, i) => <div className="text-muted ps-3">{x}</div>)}
            </details>
        </div>)
    }

    const AdvancedOptions = ({ data, updateModel, model }: ImportTemplateComponentProps) => {
        return (<div>
            <small className="text-muted mb-3">{translate.IfYouLeaveFormatBlank}</small>
            <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} label={translate.DueDateFormat} />
            <SeparatorSimple updateModel={updateModel} data={data} />
            <CheckBoxEditor message={TranslationService.translate.UseQuotesToContainColumnValues} onChange={updateModel("HasQuotedValues")} defaultValue={data?.HasQuotedValues} />
            <EditItem label={translate.SendResultsTo} ><TextEditor onChange={updateModel("EmailReturn")} defaultValue={data?.EmailReturn} /></EditItem>
            <CheckBoxEditor message={<>{translate.IgnoreFirstRow} <i className="fal fa-question-circle px-2" title={translate.IgnoreFirstRowTip} /></>} onChange={updateModel("IgnoreFirstRow")} defaultValue={data?.IgnoreFirstRow} />
            <UploadTemplate formatsAllowed=".xlsx,.csv" model={model} updateModel={updateModel} />
        </div>)
    };

    return (
        <ImportTemplateEditBase  AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    )
}



export default ImportTemplateEditActivity;