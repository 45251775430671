import TooltipComponent from "../../shared/TooltipComponent";

const MenuBox = (props: React.PropsWithChildren<{ title: string, content: string | undefined, tooltip?: string, className?: string }>) => {
    return ( 
        <li className="row aboutCompanyItem">
            <h5 className={"col-5 " + (props.className ?? "")}>{props.title}:</h5>
            <p className={"col pe-0" + (props.className ?? "")}>{props.content}
                {props.tooltip &&
                    <TooltipComponent title={props.tooltip} placement="right">
                        <i className="ms-2 fal fa-question-circle"></i>
                    </TooltipComponent>
                }
            </p>
        </li>
    );
}

export default MenuBox;