import { DashboardDebtResponse } from "../entities/company/Dashboard/DashboardDebtResponse";
import { DashboardEntity } from "../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../entities/Filter/FilterRequest";
import { DashboardGetResponse } from "../entities/company/Dashboard/DashboardGetResponse";
import { DashboardIOStatusResponse } from "../entities/company/Dashboard/DashboardIOStatusResponse";
import { DashboardListResponse } from "../entities/company/Dashboard/DashboardListResponse";
import { DashboardPriorityResponse } from "../entities/company/Dashboard/DashboardPriorityResponse";
import { DashboardTableResponse } from "../entities/company/Dashboard/DashboardTableResponse";
import { DashboardTaskResponse } from "../entities/company/Dashboard/DashboardTaskResponse";
import AdvancedFilterService, { Filter } from "./AdvancedFilterService";
import CompanyService, { DataType, Entities } from "./CompanyService";
import FilterService, { FilterCollection, FilterDefinition } from "./FilterService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";
import { Cast } from "../utils/Utils";
import { ClientFields } from "./ClientService";

class DashboardService {
    public static set(dashboard: DashboardEntity): Promise<{ id: number } | Error> {
        const request = {
            item: { ...dashboard }
        }
        return RequestService.post("/dashboard/set", request);
    }

    public static getList(dashboardpanelid: number): Promise<DashboardListResponse | Error> {
        return RequestService.post("/dashboard/list", { "dashboardpanelid": dashboardpanelid });
    }

    public static delete(id: number, dashboardpanelid: number): Promise<unknown | Error> {
        return RequestService.post("/dashboard/delete", { id, "requestpanelid": dashboardpanelid });
    }

    public static get(id: number): Promise<DashboardGetResponse | Error> {
        return RequestService.post("/dashboard/get", { id });
    }

    public static async calculate(id: number, filter: FilterRequest[], userId?: string) {

        const result = await RequestService.post<Promise<
            { optionextend: Highcharts.Options }
            | DashboardDebtResponse
            | DashboardPriorityResponse
            | DashboardIOStatusResponse
            | DashboardTaskResponse
            | DashboardTableResponse
            | Error>>("/dashboard/calculate", { id, filter, uid: userId, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
        if (result && "optionextend" in result) {
            const colors = Cast<any>(result.optionextend.series?.[0])?.data?.map((x: string[]) => x[4]);
            result.optionextend.colors = colors?.filter(Boolean).length > 0 ? colors : null;
        }
        return result;
    }

    private static groupings: { Text: string, Value: number }[] | null = null;
    private static groupingsClient: { Text: string, Value: number }[] | null = null;
    public static getGrouping(onlyClients: boolean) {
        if (this.groupings !== null && !onlyClients) {
            return this.groupings;
        }
        if (this.groupingsClient !== null && onlyClients) {
            return this.groupingsClient;
        }

        if (onlyClients) {
            this.groupingsClient = CompanyService.getAdditionalDefinitions().filter(x => x.entity === Entities.Client).map(x => ({
                Text: x.Name,
                Value: x.AdditionalDefinitionID
            }));
            return this.groupingsClient;
        }

        this.groupings = CompanyService.getAdditionalDefinitions().filter(x => x.entity === Entities.Client || x.entity === Entities.Invoice).map(x => ({
            Text: x.Name,
            Value: x.AdditionalDefinitionID
        }));

        this.groupings.unshift({ Text: TranslationService.translate.Segments, Value: ClientFields.Segments });
        this.groupings.unshift({ Text: TranslationService.translate.Aging, Value: ClientFields.duePeriod });
        this.groupings.unshift({ Text: TranslationService.translate.ExpirationDay, Value: ClientFields.dueAmount });
        return this.groupings;
    }

    public static getGroupName(value: number) {
        return this.getGrouping(false).find(x => x.Value === value)?.Text;
    }

    private static variables: { Text: string, Value: number }[] | null = null;
    private static variablesCard: { Text: string, Value: number }[] | null = null;
    public static getVariables(card: boolean) {
        if (this.variables !== null && !card) {
            return this.variables;
        }
        if (this.variablesCard !== null && card) {
            return this.variablesCard;
        }
        if (card) {
            this.variablesCard = [];
            this.variablesCard.push({ Text: TranslationService.translate.DaysSalesOutstanding, Value: 8 });
            this.variablesCard.push({ Text: TranslationService.translate.DebtAging, Value: 9 });
            return this.variablesCard;
        }

        this.variables = [];
        this.variables.push({ Text: TranslationService.translate.ClientQty, Value: 0 });
        this.variables.push({ Text: TranslationService.translate.PendingAmount, Value: 2 });
        this.variables.push({ Text: TranslationService.translate.DueAmount, Value: 3 });
        this.variables.push({ Text: TranslationService.translate.ClaimableAmount, Value: 7 });
        this.variables.push({ Text: TranslationService.translate.InvoiceQty, Value: 1 });
        return this.variables;
    }

    public static getVariableName(value: number) {
        let val = this.getVariables(false).find(x => x.Value === value)?.Text;
        if (!val) {
            val = this.getVariables(true).find(x => x.Value === value)?.Text;
        }
        return val;
    }

    public static getPanelTypes() {
        return [
            TranslationService.translate.Pie,
            TranslationService.translate.Bars,
            TranslationService.translate.Lines,
            TranslationService.translate.Debt,
            TranslationService.translate.InvoiceStatus,
            TranslationService.translate.Priority,
            TranslationService.translate.OverdueTasks,
            TranslationService.translate.Table,
            TranslationService.translate.Card,
            TranslationService.translate.Cashflow,
            TranslationService.translate.PersonActivity,
            TranslationService.translate.UnmanagedDebt,
            TranslationService.translate.ClientDebtCoverage,
            TranslationService.translate.Aging,
        ]
    }

    public static GetQueryFilter(dashboardFilters: FilterRequest[], filterType: FilterCollection) {
        const filter = dashboardFilters.map<Filter>(x => ({
            kind: this.GetKind(x, filterType),
            definition: { Field: (parseInt(x.field) > 0 ? "additional-" : "") + x.field } as FilterDefinition,
            value: x.value,
            operator: x.operator
        }));

        const filters = filter.map(AdvancedFilterService.filterToString);

        return FilterService.GetExtraFiltersRequestString(filters);
    }

    private static GetKind(dashboardFilters: FilterRequest, filterType: FilterCollection) {
        const fieldId = (parseInt(dashboardFilters.field) > 0 ? "additional-" : "") + dashboardFilters.field;
        const filterTypes = FilterService.GetFiltersForPage(filterType);
        return filterTypes.flatMap(x => x.definitions).find(x => x.Field === fieldId)?.Type ?? DataType.List;
    }
}



export default DashboardService;