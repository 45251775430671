import React, { useEffect, useMemo, useState } from "react";
import { TranslationService } from "../../../../services/TranslationService";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import Dropdown from "../../../shared/components/Dropdown";
import WhatsappService, { WhatsappTemplateListType } from "../../../../services/WhatsappService";
import { ToastService } from "../../../shared/bootstrap/Toast";

export const SelectWhatsappTemplate = (props: EditProps) => {
    return (
        <div className="row">
            <div className={"col-6"}>
                <ChangeWhatsAppTemplate {...props} />
            </div>
        </div>
    )
}

const ChangeWhatsAppTemplate = (props: any) => {
    const [data, setData] = useState<WhatsappTemplateListType>();

    const getWhatsappTemplateList = async () => {
        const result = await WhatsappService.getList()
        if (result instanceof Error) {
            ToastService.showToast('Error al obtener templates', undefined, "danger");
            return;
        } else {
            setData(result);
        }
    }

    const onChange = (value: string) => (value?.length > 0) && props.setData((old: any) => ({ ...old, Content: (value.split('-')[1] ?? ""), WATemplateID: value.split('-')[0], Type: 7 }))

    useEffect(() => {
        getWhatsappTemplateList()
    }, [])

    const fixedFields = useMemo(() => {
        const templateFieldOptions = data?.list.filter(f => f.FieldList).map(x => ({ value: `${x.WATemplateID}-${x.Structure}`, text: x.Name ?? '' }));
        return templateFieldOptions || [];
    }, [data?.list]);

    const insertFieldOptions = useMemo(() => {
        const itemsFields = [...fixedFields]
        return itemsFields;
    }, [fixedFields]);

    return (
        <Field colClass={'col'} title={TranslationService.translate.WhatsAppTemplate}>
            <Dropdown
                onChange={onChange}
                placeholder={TranslationService.translate.Select + ' ' + TranslationService.translate.WhatsAppTemplate}
                items={insertFieldOptions} />
        </Field>
    )
}
