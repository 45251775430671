import { useRef, useState } from "react";
import FileService from "../../../services/FileService";
import { TranslationService } from "../../../services/TranslationService";
import { ToastService } from "../../shared/bootstrap/Toast";
import CompanyService from "../../../services/CompanyService";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import AuthenticationService from "../../../services/AuthenticationService";
import { getCache } from "../../../utils/PersistenceUtils";
import { IAuthenticationData, AUTHENTICATION_DATA_STORAGE_KEY } from "../../shared/AuthenticationContext";

const DataCompanyConfiguration = () => {
  const newFile = useRef<Promise<undefined | string>>(Promise.resolve(undefined));
  const newName = useRef<string>();
  const imgRef = useRef<HTMLImageElement|null>(null);
  const [saving, setSaving] = useState(false);
  
  const onFileChange = async (fileList: FileList | null) => {
    const uploadFile = async (fileList: FileList | null) => {
      const fileUpload = async (file: File) => {
        let result = await FileService.upload(file);
        if (result instanceof Error) {
          ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
          return result;
        }
        return { id: result.id, fileName: result.fileName };
      }
      if (!(fileList?.length)) {
        return undefined;
      }
      const file = fileList[0];
      imgRef.current!.src = URL.createObjectURL(file);
      let result = await fileUpload(file);
      if (result instanceof Error) {
        ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger")
        return undefined;
      } 
      return result.id;
    }
    newFile.current = uploadFile(fileList);
  }

  const onSave = async () => {
    const fileId = await newFile.current;
    setSaving(true);
    const saveImage = fileId ? handleErrorWithToast(CompanyService.changeCompanyLogo(fileId)) : Promise.resolve();
    const saveName = newName.current ? handleErrorWithToast(CompanyService.changeCompanyName(newName.current)) : Promise.resolve();
    await saveImage;
    await saveName;
    await AuthenticationService.refreshToken({refreshToken: getCache<IAuthenticationData>(AUTHENTICATION_DATA_STORAGE_KEY)!, refreshlists: 1});
    window.location.reload();
    setSaving(false);
  }

  return (
    <div className="card px-5">
      <div className="d-flex my-4">
        <h2>{TranslationService.translate.Company}</h2>
      </div>
      <h3 className="mb-3">{TranslationService.translate.CompanyLogo}</h3>
      <div className="row">
        <div className="col-4 col-xxl-2">
          <form className="mb-3">
            <div className="mb-3">
              <label className="companyLogo">
                <div>
                  <img className="rounded-circle" ref={imgRef} src={CompanyService.getCompanyAuth()?.logoUrl} alt={TranslationService.translate.CompanyLogo} />
                  <div className="upload rounded-circle">
                    <i className="fa-regular fa-camera"></i>
                    <input type="file" className="d-none" accept=".jpg,.jpeg,.png" onChange={(e) => onFileChange(e.target.files)}></input>
                  </div>
                </div>
              </label>
            </div>
            <div className="mb-3">
              <label>{TranslationService.translate.CompanyName}</label>
              <input type="text" className="form-control" defaultValue={CompanyService.getCompanyName()} onChange={(x) => newName.current = x.target.value}></input>
            </div>
          </form>
          <button className="btn btn-primary w-75" type="button" onClick={onSave}>{TranslationService.translate.Save} {saving && <i className="fas fa-spinner-third fa-spin third ms-2"/>}</button>
        </div>
      </div>
    </div>
  );
}

export default DataCompanyConfiguration;
