import { ToastService } from "../pages/shared/bootstrap/Toast";
import { ProcessLogListResponse } from "../pages/shared/entities/ProcessLogListResponse";
import { getError } from "../utils/RequestUtils";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class ProcessService {
    public static getList(page: number, pagesize: number): Promise<ProcessLogListResponse | Error> {
        return RequestService.post("/processrequest/list", { page, pagesize });
    }
    public static async showResultToast(promise: Promise<unknown | Error>) {
        const result = await promise;
        if (result instanceof Error) {
            const {status} = getError(result);
            if (status === 409) {
                ToastService.showToast(TranslationService.translate.ThereIsProcessPending, undefined, "warning");
            } else {
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                return false;
            }
        } else {                    
            ToastService.showToast(TranslationService.translate.ProcessRunning, undefined, "success");
        }
        return true;
    }
}
export default ProcessService;