import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import TooltipComponent from "../../../shared/TooltipComponent";
import { EditProps } from "../EditAction";
import { NotifyToOptions } from "./EmailComponents";
import { EmailInput } from "./EmailInput";
import { ActionType } from "../../../../services/ActionService";

export const NotifyTo = (props: EditProps) => {
    if (props.data.Type === ActionType.ActionSendLetter &&  (props.data.Letter_Method ?? 0) !== 0) {
        return (<></>);
    }

    return (<div className="row">
        <EmailInput {...props} field="Notify" items={getEnumArray(NotifyToOptions)}
            title={
                <>
                    {TranslationService.translate.NotifyTo}
                    <TooltipComponent placement="right" title={TranslationService.translate.NotifyToTip}>
                        <i className="fal fa-question-circle ms-2" />
                    </TooltipComponent>
                </>}
        />
    </div>)

}