import CompanyService from "../../services/CompanyService";
import { TranslationService } from "../../services/TranslationService";
import TabPosition from "../shared/TabPosition";
import { CultureData } from "./components/CultureData";
import { EmailsNotificationsData } from "./components/EmailsNotificationsData";
import { InvoiceData } from "./components/InvoiceData";
import { ProfileData } from "./components/ProfileData";
import { SignData } from "./components/SignData";
import { SubscriptionData } from "./components/SubscriptionData";


const Profile: React.FC = () => {

  return (
    <TabPosition module="Profile">
      <div className="main-layout-container mx-3 pt-4">
        <div className="main-panel">

          {CompanyService.getCompanyToken()?.userid === CompanyService.getCompanyToken()?.ownerId &&
            <div className="row">
              <h2 className="m-3">{TranslationService.translate.CustomDataPlan}</h2>
              <div className="row mx-3">
                <div className="col-6">
                  <SubscriptionData />
                </div>
              </div>
            </div>}

          <div className="row">
            <div className="col">
              <h2 className="m-3">{TranslationService.translate.DataPlan}</h2>
              <div className="card" style={{ minHeight: '85%' }}>
                <div className="card-body">
                  <InvoiceData />
                </div>
              </div>
            </div>

            <div className="col">
              <h2 className="m-3">{TranslationService.translate.DataPersonal}</h2>
              <div className="card" style={{ height: '85%' }}>
                <ProfileData />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <h2 className="m-3">{TranslationService.translate.CompanySettingsProfile.replace('{1}', CompanyService.getCompanyName())}</h2>
          </div>

          <div className="row">
            <div className="col">
              <div className="card" style={{ minHeight: '85%' }}>
                <div className="card-body">
                  <SignData />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card" style={{ minHeight: '85%' }}>
                <CultureData />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <h2 className="m-3">{TranslationService.translate.EmailNotifications}</h2>
          </div>

          <div className="row mb-5">
            <div className="col">
              <div className="card" style={{ minHeight: '85%' }}>
                <div className="card-body">
                  <EmailsNotificationsData />
                </div>
              </div>
            </div>
            <div className="col" />
          </div>

        </div>
      </div>
    </TabPosition>
  );
};

export default Profile;
