import RequestService from "./RequestService";

class FieldConfigurationService {
    public static get(id: number): Promise<AdditionalDefinitionsGetResponse | Error> {
        return RequestService.post("/AdditionalDefinition/get", {id});
    }
    public static getList(): Promise<AdditionalDefinitionsListResponse | Error> {
        return RequestService.post("/AdditionalDefinition/list", {});
    }
    public static set(definition: AdditionalDefinitionsListResponse.Item): Promise<AdditionalDefinitionsGetResponse | Error> {
        return RequestService.post("/AdditionalDefinition/set", {response: "ok", item: definition});
    }
    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/AdditionalDefinition/delete", { id });
    }
}
export default FieldConfigurationService;

export interface AdditionalDefinitionsListResponse {
    Response: string
    list: AdditionalDefinitionsListResponse.Item[]
}

export interface AdditionalDefinitionsGetResponse {
    Response: string
    item: AdditionalDefinitionsListResponse.Item
}

export namespace AdditionalDefinitionsListResponse {
    export interface Item {
        IsPK: boolean
        typeName: string
        type: number
        TypeName: string
        entity: number
        AdditionalDefinitionID: number
        CreatorUserID: number
        Created: string
        Type: number
        Name: string
        Field: number
        Entity: number
        IncludeInList: boolean
        ShowToClient: boolean
        ShowAlways?: boolean
        IncludeInClientExport: boolean
        Width?: number
        ManualEdit?: boolean
        HasIODependency: boolean
        ExcludeInFilters?: boolean
        PublicName?: string
        LanguageNames?: string
        AutoPopulate?: boolean
        AdditionalDefinitionID_ref: any
        Hidden?: boolean
        Whatsapp?: boolean
        Summarize?: boolean
        Right?: boolean
        IncludeInCustomerPortal?: boolean
        IOStatusDependencies: any[]
        AdditionalDefinitionItems: AdditionalDefinitionItem[]
      }
      
      export interface AdditionalDefinitionItem {
        AdditionalDefinitionItemID: number
        AdditionalDefinitionID: number
        Value: string
        Default?: boolean
        ImportID?: number
        AdditionalDefinitionItemID_ref: any
      }
      
}
