import { useState } from "react";
import ButtonDropdown from "./ButtonDropdown";

export type MenuButtonActions = { text: string, action: () => void }[];

const MenuButton = (props: { text: string, actions: MenuButtonActions, extraClassName?: string, icon?: string, buttonClass?: string }) => {
    const [chevronClass, setChevronClass] = useState("fa-chevron-down");
    const onClick = (indexString: string) => {
        const index = parseInt(indexString);
        props.actions[index].action();
    }
    return (
        <ButtonDropdown items={props.actions.map((x, i) => ({ value: i.toString(), text: x.text }))} onCollapse={() => setChevronClass("fa-chevron-down")} onShow={() => setChevronClass("fa-chevron-up")} onChange={onClick} buttonClass={props.buttonClass}>
            <div className={"select-intiza " + (props.extraClassName ?? "")}>
                {props.icon && <i className={props.icon}></i>}
                {props.text}<i className={"fas ms-2 " + chevronClass}></i>
            </div>
        </ButtonDropdown>
    );
}

export default MenuButton;