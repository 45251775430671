import React, { useMemo, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService'
import AccountService, { DataUser, USER_ACCOUNT_RESPONSE } from '../../../services/AccountService'
import CompanyService from '../../../services/CompanyService'
import Loading from '../../shared/components/Loading'
import { UserPayload } from '../../../entities/login/UserPayload'

export const ProfileData = () => {
    const [firstName, ...lastName] = CompanyService.getUserFullName().split(' ')
    const [data, setData] = useState<DataUser>({
        firstName: firstName,
        lastName: lastName.join(' '),
        email: CompanyService.getUserEmail()
    })

    const [loading, setLoading] = useState(false)
    const [changingData, setChangingData] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [passwordChange, setPasswordChange] = useState(false)

    const fName = useMemo(() => data.firstName, [data])
    const lName = useMemo(() => data.lastName, [data])
    const cEmail = useMemo(() => data.email, [data])

    const handleChangeData = () => {
        setErrorMsg('')
        setLoading(false)
        setChangingData(!changingData)
    }

    const changeUserData = async () => {
        try {
            setErrorMsg('')
            if (passwordChange) {
                if (data.password !== data.repeatPassword) {
                    setErrorMsg(TranslationService.translate.PasswordNotMatch)
                    return
                }
            }
            setLoading(true)

            const result = await AccountService.setUserAccount(data)
            if (result instanceof Error || ![USER_ACCOUNT_RESPONSE.OK, USER_ACCOUNT_RESPONSE.Ok, USER_ACCOUNT_RESPONSE.USER_DELEGATED].some(el => el === result.Response)) {
                setErrorMsg(errorMsgSwitch(result.Response.toString()))
                setLoading(false)
                return;
            }
            setErrorMsg('')
            setLoading(false)
            setChangingData(false)
            setLoading(false)

            const userTokenPayload: UserPayload | undefined = CompanyService.getUserPayload()
            userTokenPayload && await CompanyService.setUserPayload({ ...userTokenPayload, fullname: data.firstName + ' ' + data.lastName, emailaddress: data.email ?? CompanyService.getUserEmail() });
            await window.location.reload()
        } catch (error) {
            setLoading(false)
        }
    }

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (name === 'currentPassword' || name === 'password' || name === 'repeatPassword') {
            setPasswordChange(true)
        }
        setData((prevState: any) => ({ ...prevState, [name]: value, }))
    };

    const errorMsgSwitch = (err: string) => {
        switch (err) {
            case USER_ACCOUNT_RESPONSE.INVALID_PASSWORD:
                return TranslationService.translate.InvalidCurrentPassword
            case USER_ACCOUNT_RESPONSE.FAIL_MAIL:
                return TranslationService.translate.IncorrectEmailEntered
            case USER_ACCOUNT_RESPONSE.OLD_PASSWORD:
                return TranslationService.translate.PasswordOldMsg
            case USER_ACCOUNT_RESPONSE.INVALID_TOKEN:
                return TranslationService.translate.LoginTokenIncorrect
            case USER_ACCOUNT_RESPONSE.WAIT_PASSWORD:
                return TranslationService.translate.PasswordWaitMsg
            case USER_ACCOUNT_RESPONSE.WEEK_PASSWORD:
                return TranslationService.translate.PasswordWeakMsg
            default:
                return TranslationService.translate.DataUpdatedSuccessfully
        }
    }

    return loading ?
        <div className="card-body">
            <Loading />
        </div> : (
            <div className="card-body">
                <div className='mb-2'>
                    <h5>{TranslationService.translate.PersonalData}</h5>
                </div>

                {changingData ?
                    <>
                        <div className="row">
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.LastName}</label>
                                <input type="text" name='lastName' onChange={handleInputChange} className="form-control" value={lName} defaultValue={lName} />
                            </div>
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Name}</label>
                                <input type="text" name='firstName' onChange={handleInputChange} className="form-control" value={fName} defaultValue={fName} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Email}</label>
                                <input type="text" name='email' onChange={handleInputChange} className="form-control" value={data.email} />
                            </div>
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.CurrentPassword}</label>
                                <input type="password" autoComplete='off' name='currentPassword' onChange={handleInputChange} className="form-control" placeholder='******' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.NewPasswordData}</label>
                                <input type="password" autoComplete='off' name='password' onChange={handleInputChange} className="form-control" placeholder='******' />
                            </div>
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.RepeatPassword}</label>
                                <input type="password" autoComplete='off' name='repeatPassword' onChange={handleInputChange} className="form-control" placeholder='******' />
                            </div>
                        </div>
                    </> : null}


                {!changingData ?
                    <>
                        <div className='row mb-2'>
                            <label className="col-3 me-1">{TranslationService.translate.Email}:</label>
                            {cEmail}
                        </div>
                        <div className='row mb-2'>
                            <label className="col-3 me-1">{TranslationService.translate.Name}:</label>
                            {fName + ' ' + lName}
                        </div>
                        <div className='row mb-2'>
                            <label className="col-3 me-1">{TranslationService.translate.Password}:</label>
                            *********
                        </div>
                    </> : null}

                {errorMsg && (
                    <div className="alert alert-warning">
                        {errorMsg}
                    </div>
                )}

                <div className='mb-2 d-flex justify-content-end'>
                    {!changingData ?
                        <button className="btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Change}</button>
                        : <div className='d-flex gap-2'>
                            <button className="col btn btn-primary mt-2" onClick={changeUserData}>{TranslationService.translate.Save}</button>
                            <button className=" col btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Cancel}</button>
                        </div>}
                </div>
            </div>
        )
}
