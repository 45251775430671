import { useMemo } from "react";
import { TranslationService } from "../../../services/TranslationService";
import Dropdown from "../../shared/components/Dropdown";
import { AutocompleteSelect, CheckBoxEditor, TagsDropdownEditor } from "../../shared/components/Editors";
import SwitchButton from "../../shared/components/SwitchButton";
import { EmailComposeModel, EmailTabProps } from "./EmailCompose";
import CompanyService from "../../../services/CompanyService";
import GroupService from "../../../services/GroupService";
import { CacheEntity, StorageService } from "../../../services/StorageService";

export const EmailTabAdditional = ({ model, setModel, getFeature }: EmailTabProps) => {
    const setEmail = <TKey extends keyof EmailComposeModel["item"],>(key: TKey) =>
        (value: EmailComposeModel["item"][TKey]) =>
            setModel(model => {
                const newModel = { ...model };
                if (!newModel.item) {
                    newModel.item = {} as Partial<EmailComposeModel>["item"];
                }
                if (newModel.item) {
                    newModel.item[key] = value;
                }
                return newModel;
            });


    const groupSelectOptions = async (inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (!model.item?.personid || inputValue.length < minInputLength) { return list; }
        const result = await GroupService.search(model.item!.personid.toString(), inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.data.toString(), label: x.value }));
        }
        return list;
    };
    const groupName = model.item?.groupName ?? (model.item?.groupid ? StorageService.getCache(CacheEntity.GroupName, model.item?.groupid) : undefined);
    const feature = getFeature("mailframeid");
    const emailFrameOptions = useMemo(() => CompanyService.getEmailFrames().map(x => ({ value: x.MailFrameID, text: x.Name })), []);

    return <>
        <div className="tabBody additional">
            <div className="card pb-3 mb-5">
                {Boolean(CompanyService.getTags()?.length) && <div className="row mb-1">
                    <label className="col-2 font-weight-normal col-form-label">
                        {TranslationService.translate.Tags}:
                    </label>
                    <div className="col-10">
                        <TagsDropdownEditor onChange={(x) => setEmail("Tag_Message")(x.split(", ").map(y => ({ TagID: parseInt(y) })))} defaultValue={model?.item?.Tag_Message?.map(x => x.TagID).join(", ")} />
                    </div>
                </div>}
                {CompanyService.getGroupName()?.length > 0 && <div className="row mb-1">
                    <label className="col-2 font-weight-normal col-form-label">
                        {CompanyService.getGroupName()}:
                    </label>
                    <div className="col-10">
                        <AutocompleteSelect loadOptions={groupSelectOptions} onChange={x => setEmail("groupid")(x?.value)} defaultVal={model.item?.groupid && groupName ? { value: model.item?.groupid, label: groupName } : undefined} />
                    </div>
                </div>}
                <hr></hr>
                <div className="row my-3 g-0 align-items-center justify-content-between">
                    <div className="col-3">
                        <SwitchButton label={TranslationService.translate.Design} defaultValue={feature.isOn} onChange={feature.toggleFeature} />
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col">
                                <Dropdown onChange={feature.setFeatureValue("mailframeid")} items={emailFrameOptions} optionLabel={TranslationService.translate.None} defaultValue={model.item?.mailframeid} />
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row g-0"><CheckBoxEditor onChange={setEmail("includelink")} message={<span style={{ lineHeight: "28px" }}>{TranslationService.translate.AddLinkPortal}</span>} defaultValue={Boolean(model?.item?.includelink)} /></div>
                <div className="row g-0"><CheckBoxEditor onChange={setEmail("includeattachment")} message={<span style={{ lineHeight: "28px" }}>{TranslationService.translate.IncludeAttachment}</span>} defaultValue={Boolean(model?.item?.includeattachment)} /></div>
            </div>
        </div>
    </>;
};