import { TableHeader, default as Table } from "../shared/Table";
import { useContext } from "react";
import LanguageContext from "../shared/LanguageContext";
import { Link, useLocation } from "react-router-dom";

import { ClientHistoryListRequest } from "./entities/ClientHistoryListRequest";
import ReportHistoryClientListTableProvider from "./providers/ReportHistoryClientListTableProvider";
import ClientHistoryTableItem from "../client/components/ClientHistoryTableItem";
import ClientHistoryTableTotal from "../client/components/ClientHistoryTableTotal";
import { TranslationService } from "../../services/TranslationService";
import FilterContainer from "../client/components/FilterContainer";
import moment from "moment";

const ReportHistoryClientList: React.FC = () => {
    const { translate } = useContext(LanguageContext);
    const { search } = useLocation<{ prevRequest?: ClientHistoryListRequest } | undefined>();

    let req = new ClientHistoryListRequest();
    const urlParams = new URLSearchParams(search);
    const filterQSfilter = urlParams.get("filter");
    const fromQS = urlParams.get("from")?.replaceAll('-', '');
    const toQS = urlParams.get("to")?.replaceAll('-', '');

    if (fromQS) {
        req.datefrom = fromQS;
    }
    if (toQS) {
        req.dateto = toQS;
    }
    req.filter = filterQSfilter


    const prevRequest = req;
    const dateFrom = moment(req.datefrom, 'YYYY-MM-DD').format("DD MMM YYYY");

    const tableHead: TableHeader[] = [
        new TableHeader("   ", translate("", "Client"), false, true, "w-400px"),
        new TableHeader("cant", translate("", "Invoices"), true, true, "w-150px"),
        new TableHeader("amount", translate("", "Amount"), true, true, "w-200px"),
        new TableHeader("   ", translate("", ""), false, false, "w-200px"),
    ];

    return (
        <div className="container-fluid padding">
            <div className="card">
                <h2 className="mb-3">{TranslationService.translate.DueState} {dateFrom}</h2>
                <ReportHistoryClientListTableProvider prevRequest={prevRequest} >
                    <FilterContainer
                        enableImport={false}
                        additionalAction={
                            <Link to={`/${TranslationService.currentLanguage}/report/historyiolist?${urlParams}`}>
                                {TranslationService.translate.ViewInvoicesResume}
                            </Link>
                        } />
                    <Table headers={tableHead} item={ClientHistoryTableItem}>
                        <ClientHistoryTableTotal />
                    </Table>
                </ReportHistoryClientListTableProvider>
            </div>
        </div>
    )
}

export default ReportHistoryClientList