import { ActivityTypeEntity, ActivityTypeSummary } from "../entities/company/ActivityType/ActivityTypeEntity";
import { ActivityTypeGetResponse } from "../entities/company/ActivityType/ActivityTypeGetResponse";
import { ActivityTypeListResponse } from "../entities/company/ActivityType/ActivityTypeListResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

class ActivityTypeService {

    public static get(id: number): Promise<Error | ActivityTypeGetResponse> {
        return RequestService.post("/activitytype/get", { id });
    }

    public static set(activitytype: ActivityTypeEntity): Promise<Error | unknown> {
        return RequestService.post("/activitytype/set", { item: activitytype });
    }

    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/activitytype/delete", { id });
    }

    public static getList(): Promise<Error | ActivityTypeListResponse> {
        return RequestService.post("/activitytype/list", {});
    }

    public static getSummary(request: ActivityTypeSummary): Promise<Error | ActivityTypeGetResponse> {
        return RequestService.post("/activitytype/summary", { additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }

    public static async exportActivityTypeSummary(request: ActivityTypeSummary) {
        return ExportService.requestExport("/activitytype/exportsummary", { ...request }, 100, 'activitytypeSummary');
    }
}


export default ActivityTypeService;