import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import AuthenticationContext from "../shared/AuthenticationContext";
import AuthenticationService from "../../services/AuthenticationService";
import CompanyService from "../../services/CompanyService";
import { TranslationService } from "../../services/TranslationService";
import ModalService from "../shared/bootstrap/Modal";
import { getRememberCacheValue } from "./Logon";
import { StorageService } from "../../services/StorageService";


const LogonModal = (props: { expired: boolean }) => {
    const { rememberUser, userEmailCache } = getRememberCacheValue()
    const defaultValues = {
        email: userEmailCache,
        password: "",
        remember: rememberUser
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [userCredentials, setUserCredentials] = useState(defaultValues);

    const history = useHistory();
    const { setCurrentAuthentication, doLogout } = useContext(
        AuthenticationContext
    );
    const { translate, currentLanguage } = TranslationService;

    const _classNameList = ["modal", "fade"];
    const classNameList = props.expired
        ? [..._classNameList, "show"]
        : _classNameList;
    const style = props.expired ? { display: "block" } : {};

    const formDataSchema = yup.object({
        email: yup
            .string()
            .email(translate.LoginEmailIncorrect)
            .required(translate.LoginEmailMissing),
        password: yup.string().required(translate.LoginPasswordMissing),
    });

    useEffect(() => {
        if (props.expired) {
            ModalService.hideModal();
        }
    }, [props.expired])

    async function handleLogIn(): Promise<void> {
        // Step 0: Set loading state
        setError(undefined);
        if (!userCredentials.email && !userCredentials.password) {
            setError(translate.LoginIncorrect);
        } else {
            formDataSchema
                .validate(userCredentials)
                .then(async () => {
                    setLoading(true);
                    const userData = await AuthenticationService.login(
                        userCredentials.email,
                        userCredentials.password
                    );
                    StorageService.setPreference('rememberUser', userCredentials.remember);
                    StorageService.setPreference('userEmailCache', userCredentials.remember ? userCredentials.email : "");

                    if (userData instanceof Error) {
                        setError(translate.Network);
                    } else if (userData.response === "LoginIncorrect") {
                        setError(translate.LoginIncorrect);
                    } else if (userData.response === "NextBlock") {
                        setError(translate.NextBlock);
                    } else if (userData.response === "2AF") {
                        const queryParams = new URLSearchParams();
                        queryParams.append("token", userData.twofadata);
                        queryParams.append(
                            "returnUrl",
                            history.location.pathname + history.location.search
                        );
                        if (CompanyService.getUserEmail() !== userCredentials.email) {
                            window.location.href = `/${currentLanguage}/complete-sign-in?${queryParams.toString()}`;
                        }
                        return;
                    } else if (userData.response === "OK") {
                        const companyData = await AuthenticationService.companyLogin(
                            userData,
                            userCredentials.remember
                        );
                        if (companyData instanceof Error) {
                            setError(translate.Network);
                        } else {
                            setUserCredentials({
                                email: CompanyService.getUserPayload()?.emailaddress ?? "",
                                password: "",
                                remember: userCredentials.remember,
                            });
                            setCurrentAuthentication(companyData);
                        }
                        if (CompanyService.getUserEmail() !== userCredentials.email) {
                            window.location.reload();
                        }
                    } else if (userData.response === "Blocked") {
                        setError(translate.Blocked);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err.errors[0]);
                    setLoading(false);
                });
        }
    }

    const handleLogout = () => doLogout();

    return (
        <div className={classNameList.join(" ")} role="dialog" style={style}>
            <div className="container-backdrop">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ "backgroundColor": "lightsteelblue" }}>
                            <h2 className="h5 modal-title">{translate.SessionExpired}</h2>
                        </div>
                        <div className="modal-body">
                            {error && (
                                <div className="alert alert-warning">
                                    {error} {/* Este mensaje no sera visible por defecto*/}
                                </div>
                            )}
                            <div className="mb-3 text-start">
                                <label className="form-label">{translate.Email}</label>
                                <input
                                    className="form-control input-lg text-box single-line valid"
                                    type="email"
                                    defaultValue={userCredentials.email}
                                    onChange={(x) =>
                                        setUserCredentials((userCredentials) => ({
                                            ...userCredentials,
                                            email: x.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="mb-3 text-start">
                                <label className="form-label">{translate.Password}</label>
                                <input
                                    className="form-control input-lg text-box single-line valid"
                                    type="password"
                                    value={userCredentials.password}
                                    onChange={(x) =>
                                        setUserCredentials((userCredentials) => ({
                                            ...userCredentials,
                                            password: x.target.value,
                                        }))
                                    }
                                    onKeyPress={(x) => x.key === "Enter" && handleLogIn()}
                                />
                            </div>
                            <a href={`/${currentLanguage}/recoverpassword`}>
                                {translate.PasswordForgotten}
                            </a>
                            <div className="mt-2 mb-3" style={{ textAlign: "left" }}>
                                <label
                                    className="form-check-label fw-normal"
                                    style={{ display: "inline" }}
                                    htmlFor="remember"
                                >
                                    {" "}
                                    <input
                                        style={{ marginRight: "5px" }}
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        defaultChecked={userCredentials.remember}
                                        checked={userCredentials.remember}
                                        onChange={(x) =>
                                            setUserCredentials((userCredentials) => ({
                                                ...userCredentials,
                                                remember: x.target.checked,
                                            }))
                                        }
                                    />{" "}
                                    {translate.RememberMe}
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary ms-auto"
                                data-bs-dismiss="modal"
                                disabled={loading}
                                onClick={handleLogIn}
                            >
                                {translate.Login.toUpperCase()}
                                {loading && (
                                    <i className="fas fa-spinner-third fa-spin third ms-2"></i>
                                )}
                            </button>
                        </div>
                        <div className="modal-logout">
                            <p onClick={handleLogout}>{translate.Logout}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default LogonModal;
