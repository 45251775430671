import { CompanyAuth } from "../../../entities/login/CompanyAuth";
import { getStatusList, TaskStatus } from "./TaskStatus";

export class TaskListRequest {
    page: number = 0;
    pagesize: number = 50;
    mainfav?: number;
    status?: number;
    uid?: string;
    vid?: string;
    filter: string = "";
    sortcolumn: string = "dueDate"
    descending: number = 0;
    constructor(
        userId: string | null, statusId: number | null,
        public additionaldefinitions: CompanyAuth.AdditionalDefinition[],
    ){
        const statusList = getStatusList();
        const defaultStatusFilter = statusId ?? statusList.find(x => x.status === TaskStatus.Pending)?.status;
        this.status = defaultStatusFilter;
        if (userId) {
            this.uid = userId;
        }
    };
}
