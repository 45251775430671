import Highcharts from "highcharts";
import HighchartsExport from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import { Cast, OptionalMap } from "../../../utils/Utils";
HighchartsExport(Highcharts);

Highcharts.AST.allowedAttributes.push("onclick");
Highcharts.AST.allowedAttributes.push("onmouseup");
Highcharts.setOptions({
    lang: {
        thousandsSep: window.location.href.replace(window.location.origin, "").startsWith("/en/") ? "," : ".",
        decimalPoint: window.location.href.replace(window.location.origin, "").startsWith("/en/") ? "." : ",",
    }
});

type HighchartChartType = {
    dashboard: DashboardEntity,
    options: Highcharts.Options,
    dashboardFilters: FilterRequest[],
    filterCallback: (dashboardFilterEntity: DashboardFilterEntity) => void,
    valuesEnabled: boolean
}

const HighchartChart = ({ dashboard, options, dashboardFilters, filterCallback, valuesEnabled }: HighchartChartType) => {
    const chartOptionClicked = (pointEvent: Highcharts.SeriesClickEventObject) => {
        const data: Array<any> = (highchartOptions.series![0] as any).data[pointEvent.point.index];
        const value = OptionalMap(data[3] as string, x => [...x].filter(x => x === "-").length === 2 ? x.replaceAll("-", "/") : x);
        const filter: DashboardFilterEntity = {
            dashboard: dashboard,
            filterEntity: data[2],
            value: value ?? "",
            option: data[0],
            operator: data[8]
        };
        filterCallback(filter);
    };
    if (options?.tooltip) {
        options.tooltip!.useHTML = true;
        options.tooltip!.formatter = function () {
            return formatTooltip(this);
        };
        options.plotOptions!.series!.events = {
            click: chartOptionClicked
        };
        //options.plotOptions!.column!.pointWidth = 100;}
    }
    const [highchartOptions, setHighchartOptions] = useState<Highcharts.Options>(options);
    const { currentLanguage } = TranslationService;
    const history = useHistory();

    useEffect(() => {
        Highcharts.setOptions({ lang: { decimalPoint: TranslationService.currentLanguage === "en" ? "," : "." } });
    }, []);

    /*ITS OK TO SET 2 TIMES*/
    useEffect(() => {
        setHighchartOptions(options);
        setHighchartOptions({ ...options });
    }, [options]);


    const formatTooltip = (context: Highcharts.TooltipFormatterContextObject) => {
        const percentage = (context.y ?? 0) / (context.series.data.map(x => x.y).reduce((x, y) => (x ?? 0) + (y ?? 0)) ?? 1) * 100;
        let tooltip = `<div>${dashboard.Variable === 1 ? context.y : formatCurrency(context.y ?? 0, CompanyService.getDefaultCurrencyId())} (${formatInteger(percentage)}%)</div>`;

        if (dashboard.Type !== 9) {
            tooltip += `<div><button type="button" class="btn btn-link-dashboard" onmouseup="goToClient${dashboard.DashboardID.toString().replace("-", "")}(event, ${context.point.index});">${TranslationService.translate.ViewCustomers}</a></div>`;
        }
        tooltip += `<div><button type="button" class="btn btn-link-dashboard" onmouseup="goToInvoice${dashboard.DashboardID.toString().replace("-", "")}(event, ${context.point.index});">${TranslationService.translate.ViewInvoices}</a></div>`;
        return tooltip;
    };

    Cast<Record<string, (event: MouseEvent, index: number) => void>>(window)["goToClient" + dashboard.DashboardID.toString().replace("-", "")] =
        function (event: MouseEvent, index: number) {
            goTo("/client", index, FilterCollection.Client, event.button === 1);
        };

    Cast<Record<string, (event: MouseEvent, index: number) => void>>(window)["goToInvoice" + dashboard.DashboardID.toString().replace("-", "")] =
        function (event: MouseEvent, index: number) {
            goTo("/report/iolist", index, FilterCollection.ReportInvoice, event.button === 1);
        };

    const goTo = function (url: string, index: number, filterType: FilterCollection, newTab: boolean) {
        const totalFilters = [...dashboardFilters];
        const data: Array<any> = ((highchartOptions.series![0] ?? highchartOptions.series) as any).data[index];
        const idMap: Record<string | number, string> = {
            "-29": "-7",
            "-33": "-10",
        };
        let addExtraFilters = false;
        if (data[2] === -36) {
            const val = CompanyService.GetAgeingValues()[data[3]];
            if (filterType === FilterCollection.Client) {
                totalFilters.push({
                    field: "-8",
                    operator: data[8],
                    value: val.filterVal
                });
            }
            else {
                totalFilters.push({
                    field: "-8",
                    operator: data[8],
                    value: val.filterVal
                });
                addExtraFilters = true;
            }
        }
        else {
            const value = OptionalMap(data[3] as string ?? undefined, x => [...x].filter(x => x === "-").length === 2 ? x.replaceAll("-", "/") : x) ?? "";
            totalFilters.push({
                field: idMap[data[2]] ?? data[2],
                operator: data[8],
                value,
            });
            const fixDue = totalFilters.find(x => x.field === "-10" && x.value === "1");
            if (fixDue) {
                fixDue.operator = 2;
                fixDue.value = "0";
            }
            const fixDue2 = totalFilters.find(x => x.field === Cast<string>(-8));
            const val = CompanyService.GetAgeingValues()[parseInt(data[3])];
            if (fixDue2 && val) {
                fixDue2.value = val.filterVal;
            }
        }
        if (filterType === FilterCollection.ReportInvoice && dashboard.GroupBy !== -29 && dashboard.Type !== 1) {
            addExtraFilters = true;
        }
        if (filterType === FilterCollection.ReportInvoice && dashboard.GroupBy === -29) {
            totalFilters.push({
                operator: 5,
                field: "-1012",
                value: "0"
            });
        }
        if (dashboard.Variable === 7) {
            totalFilters.push({
                operator: 0,
                field: "-1016",
                value: "1",
            });
        }
        if (dashboard.GroupBy === -33) {
            const val = CompanyService.GetAgeingValues()[data[3]];
            totalFilters.push({
                field: "-8",
                operator: data[8],
                value: val.filterVal
            });
        }
        // if (filterType === FilterCollection.ReportInvoice && dashboard.Type === 1) {
        //     totalFilters.splice(totalFilters.findIndex(x => x.field === "-1012"), 1);
        //     const value = (data[3] === "1") ?
        //         (formatIntizaDate(new Date(0)) + "-" + formatIntizaDate(moment().add(-1, "day").toDate())) :
        //         (formatIntizaDate(new Date()) + "-" + formatIntizaDate(new Date(2200, 0)));

        //     const dateOperator = 0;
        //     totalFilters.push({
        //         operator: dateOperator,
        //         field: "-1024",
        //         value: value
        //     });
        // }

        if (addExtraFilters) {
            //const ageing = CompanyService.GetAgeingValues()[data[3]];
            //if (dashboard.GroupBy === "-33" && ageing) {
            //const value = formatIntizaDate(((ageing.minDays !== undefined) ? (moment().add((ageing.minDays), "days")).toDate() : new Date(0))) + "-" +
            //formatIntizaDate((ageing.maxDays !== undefined) ? ((moment().add((ageing.maxDays - 1), "days")).toDate()) : new Date(2200, 0));
            //const dateOperator = 0;
            //totalFilters.push({
            //operator: dateOperator,
            //field: "-1024",
            //value: value
            //});
            //}

            const status = totalFilters.find(x => x.field === "-8");
            if (status) {
                totalFilters.push({
                    operator: 5,
                    field: "-1012",
                    value: "0"
                });
            }
            if (dashboard.Type === 9) {
                totalFilters.push({
                    operator: 5,
                    field: "-1012",
                    value: "0"
                });
            }
        }

        const qs = encodeURIComponent(DashboardService.GetQueryFilter(totalFilters, filterType) || "");

        if (newTab) {
            window.open(`/${currentLanguage}${url}?filter=${qs}`, "_blank");
        }
        else {
            history.push(`/${currentLanguage}${url}?filter=${qs}`);
        }
    };


    useEffect(() => {
        const toggleShowValues = (checked?: boolean) => setHighchartOptions(x => ({
            ...Object.assign(x, {
                plotOptions: {
                    pie: {
                        dataLabels: {
                            format: checked ?
                                "{point.name}: {point.percentage:.1f} %<br>{point.y:,.0f}" :
                                "{point.name}: {point.percentage:.1f} %"
                        },
                        series: {
                            animation: { duration: 0 }
                        }
                    },
                    series: {
                        dataLabels: {
                            enabled: checked && dashboard.Type === 2
                        }
                    }
                },
                yAxis: {
                    stackLabels: { enabled: checked }
                }

            })
        }));
        toggleShowValues(valuesEnabled);
    }, [valuesEnabled, dashboard.Type]);

    console.log({ highchartOptions })

    return (
        <div>
            {/*<CheckBoxEditor onChange={showValues} message={TranslationService.translate.AlwaysShowValues}></CheckBoxEditor>*/}
            <HighchartsReact highcharts={Highcharts} options={highchartOptions} />
        </div>
    );
};

export default HighchartChart;
