import { FilterCollection } from "../../../../services/FilterService";
import { TranslationService } from "../../../../services/TranslationService";
import AdvancedFilters from "../../../shared/components/AdvancedFilters";
import Label from "../../../shared/form/Label";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps } from "../EditAction";

export const InvoiceFilters = ({ propGen }: EditProps) => {
    const context = { showFilters: () => { } };
    const props = propGen.str.required("Mail_IOFilter");
    const onFilterApply = (filters: string[]) => {
        const prepared = filters
            .filter(x => x !== undefined && x.length > 0)
            .map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&");
        props.onChange(prepared);
    };
    return (
    <div className="row mb-3">
        <div className="col-10">
            <Label label={TranslationService.translate.Filters} className={""}>
                <AdvancedFilters page={FilterCollection.ActionEdit} customContext={context}
                    onFilterApply={onFilterApply} defaultValue={props.defaultValue} />
            </Label>
        </div>
        <div className="col-10">
            <button className="btn btn-link px-0 pt-0 pb-2 text-start" onClick={() => context.showFilters()}>
                + {TranslationService.translate.AddFilters}
            </button>
        </div>
        <div className="col-10">
            <ValidationMessage {...props} validationMethod={x => Boolean(x && x.length)}
                message={TranslationService.translate.IOFilter} />
        </div>
    </div>);
}