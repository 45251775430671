import React, { useCallback, useContext, useEffect, useState } from "react";
import ActivityService from "../../../services/ActivityService";
import { formatDateDigits, formatDateMonthYear, formatDateShort, formatDateTimeDigits } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import TaskStar from "../../task/components/TaskStar";
import ErrorMessage from "../../shared/components/Error";
import { ActivityListResponse } from "./entities/AcitivityListResponse";
import { ActivityPendingResponse } from "./entities/AcitivityPendingResponse";
import CompanyService from "../../../services/CompanyService";
import Paginator from "../../shared/Paginator";
import TableContext from "../../task/TableContext";
import ActivityTableProvider, { ActivityTableContextValues } from "./ActivityTableProvider";
import ModalService from "../../shared/bootstrap/Modal";
import NewComment from "./components/NewComment";
import { SMSCompose } from "../components/SMSCompose";
import NewTaskForm from "../../task/components/NewTaskForm";
import TaskDetail from "../../task/components/TaskDetail";
import { TaskStatus } from "../../task/entities/TaskStatus";
import TaskService from "../../../services/TaskSerivce";
import AdvancedFilters from "../../shared/components/AdvancedFilters";
import { FilterCollection } from "../../../services/FilterService";
import { EmailCompose } from "../components/EmailCompose";
import FloatingPanelService from "../../shared/FloatingPanel";
import Empty from "../../shared/components/Empty";
import ClientDetailContext from "../ClientDetailContext";
import { ActivityEmail } from "./components/ActivityEmailDisplay";
import { Cast, isNullOrWhitespace, Switch } from "../../../utils/Utils";
import { ToastService } from "../../shared/bootstrap/Toast";
import { SMSView } from "../components/SMSView";
import { TranslationService } from "../../../services/TranslationService";
import AdvancedFiltersContext from "../../shared/components/AdvancedFiltersContext";
import MenuButton from "../../shared/components/MenuButton";
import { ActivityHistoryType } from "../../shared/enums";
import iconComment from "../../../assets/img/iconComment.svg";
import iconSMS from "../../../assets/img/iconSMS.svg";
import iconTask from "../../../assets/img/taskIcon.svg";
import iconEmail from "../../../assets/img/iconEmail.svg";
import TooltipComponent from "../../shared/TooltipComponent";
import iconWhatsapp from "../../../assets/img/ico-whatsapp.png";

import { restTimezone } from "../../../utils/ParseUtils";
import { TaskGetMessageListResponse } from "../../task/entities/TaskGetMessageListResponse";
import Collapsable from "../../shared/bootstrap/Collapsable";
import FileService from "../../../services/FileService";
import { Client } from "../entities/ClientGetResponse";
import { WhatsappCompose } from "../components/WhatsappCompose";
import { ActivityWhatsapp } from "./components/ActivityWhatsappDisplay";
import { StatusMessage, WhatsappStatus } from "../../shared/components/whatsappChat/WhatsappChat";

export const ActivityHistory = ({ personid, person, emailAddress, groupId = undefined, setReloads = undefined }: { personid: string, person?: Client, emailAddress: string, groupId?: number, setReloads?: { activity: () => void } }) => {
    return (
        <div>
            <ActivityTableProvider personid={personid} groupId={groupId}>
                <ActivityTabFilter personid={personid} person={person} emailAddress={emailAddress} setReloads={setReloads} />
            </ActivityTableProvider>
        </div>);
};

const ActivityPending = ({ loading, error, response, reload, selected }: { loading: boolean, error: boolean, response: ActivityPendingResponse, reload: () => void, selected: number }) => {
    const { translate } = TranslationService;
    if (loading) {
        return (<Loading />);
    }
    if (error) {
        return (<ErrorMessage onRefresh={reload} message={translate.ErrorProcessingRequest} />);
    }
    if ((response.mails ? response.mails.length : 0) + (response.scheduledtasks ? response.scheduledtasks.length : 0) + (response.tasks ? response.tasks.length : 0) === 0) {
        return (<></>);
    }
    const mailProps: PedingListProps<ActivityPendingResponse.Mail> = {
        reload,
        list: response.mails,
        name: translate.ScheduledEmails,
        Children: ActivityScheduledEmail,
        keyFun: x => x.MessageID
    };
    const taskProps: PedingListProps<ActivityPendingResponse.Task> = {
        reload,
        list: response.tasks,
        name: translate.PendingTask,
        Children: ActivityPendingTask,
        keyFun: x => x.TaskID
    };
    const scheduledTasksProps: PedingListProps<ActivityPendingResponse.Scheduledtask> = {
        reload,
        list: response.scheduledtasks,
        name: translate.ScheduledTasks,
        Children: ActivityScheduledTask,
        keyFun: x => x.ScheduledTaskID
    };
    return (<>
        <div className="listResults">
            {(selected === 0 || selected === 1) && <PendingList {...mailProps} />}
            {(selected === 0 || selected === 3) && <PendingList {...taskProps} />}
            {(selected === 0 || selected === 3) && <PendingList {...scheduledTasksProps} />}
        </div>
    </>);
};

type PedingListProps<T> = {
    reload: () => void,
    list: Array<T>,
    name: string,
    Children: React.FC<{ data: T, reload: () => void }>,
    keyFun: (item: T) => React.Key
}

function PendingList<T>({ reload, list, name, Children, keyFun }: PedingListProps<T>) {
    const [minimize, setMinimize] = useState(false);

    if (!list || list.length <= 0) {
        return (<></>);
    }
    return (
        <>
            <h5 className="h5 pointer" onClick={() => setMinimize(x => !x)}>{name} <i className={"fa-solid fa-fw " + (minimize ? "fa-chevron-right" : "fa-chevron-down")} /></h5>
            <Collapsable show={!minimize}>
                <div className="listResults">
                    {list.map(x => <Children key={keyFun(x)} data={x} reload={reload} />)}
                </div>
            </Collapsable>
        </>
    );
}

const ActivityList = ({ reload }: { reload: () => void }) => {
    const { translate } = TranslationService;
    const { error, response, loading } = useContext(TableContext) as ActivityTableContextValues;
    const groupedByMonth = response?.list.reduce((prev, curr) => {
        const index = formatDateMonthYear(restTimezone(new Date(curr.date)));
        const array = prev[index] ?? [];
        array.push(curr);
        prev[index] = array;
        return prev;
    }, {} as { [index: string]: ActivityListResponse.Item[] });

    const users = CompanyService.getUsers().concat(response?.oldusers ?? []);

    return (
        <>
            {loading ? <Loading /> :
                ((error || response === undefined || groupedByMonth === undefined) ? <ErrorMessage onRefresh={reload} message={translate.ErrorProcessingRequest} /> :
                    response.list.length === 0 ?
                        <Empty /> : (
                            <>{Object.keys(groupedByMonth).map((month) =>
                                <div className="listResults" key={month}>
                                    <h5 className="h5">{month}</h5>
                                    {groupedByMonth[month].map(x => {
                                        const params = {
                                            key: x.LogId,
                                            data: x,
                                            reload: reload,
                                            users: users
                                        };
                                        switch (x.typeName) {
                                            case "Comment":
                                                return <ActivityComment {...params} />;
                                            case "Email":
                                                return <ActivityEmail {...params} />;
                                            case "SMS":
                                                return <ActivitySms {...params} />;
                                            case "Task":
                                                return <ActivityTask {...params} />;
                                            case "Whatsapp":
                                                return <ActivityWhatsapp {...params} />;
                                            default:
                                                throw new Error("Missing element for activity with type: " + x.typeName);
                                        }
                                    })}
                                </div>)}</>
                        ))}
            <Paginator />
        </>
    );
};

export const ActivityItemTemplate = ({ data, type, date, creator, to, subject, content, actions, contentBeforeTitle }: {
    data: ActivityListResponse.Item | ActivityPendingResponse.Task | ActivityPendingResponse.Mail | ActivityPendingResponse.Scheduledtask, type: ActivityHistoryType, date?: Date | string, creator: string, to?: string, subject?: string, content: JSX.Element | string, actions: { icon: string, classes?: string, onClick: () => void, tooltip?: string }[], contentBeforeTitle?: JSX.Element | string
}) => {
    const { translate } = TranslationService;

    let imgSrc = "";
    switch (type) {
        case ActivityHistoryType.comment:
            imgSrc = iconComment;
            break;
        case ActivityHistoryType.email:
            imgSrc = iconEmail;
            break;
        case ActivityHistoryType.pendingTask:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            imgSrc = iconTask;
            break;
        case ActivityHistoryType.sms:
            imgSrc = iconSMS;
            break;
        case ActivityHistoryType.whatsapp:
            imgSrc = iconWhatsapp;
            break;
    }

    const [comment, setComment] = useState<TaskGetMessageListResponse>();
    const [loadingComment, setLoadingComment] = useState(false);

    const hasComments = ("hasComments" in data && data.hasComments) || ("HasComments" in data && data.HasComments);

    const doShowComment = async () => {
        setLoadingComment(true);
        const id = "ID" in data ? data.ID : ("TaskID" in data ? data.TaskID : 0);
        if (id === 0) {
            return;
        }
        const result = await TaskService.getComments(id);
        setLoadingComment(false);
        if (result instanceof Error) {
            setComment(undefined);
            return;
        }
        console.log({ result })
        setComment(result);

    };

    return (
        <div className="historyCard">
            <div className="row">
                <div className="col-auto pe-0">
                    <div className="icon">
                        <img className="img-fluid" src={imgSrc} alt="icon comment" />
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center gap-2">
                            {contentBeforeTitle && <div>{contentBeforeTitle}</div>}
                            <div className="type">{DisplayActivityType(data)}
                                <span className="creator"> {translate.By}: {creator}
                                    {to && <> | {translate.To}:  {to} </>}
                                </span>
                            </div>
                        </div>
                        {date && <div className="date">{typeof date === "string" ? date : (date.getHours() === 0 && date.getMinutes() === 0) ? formatDateDigits(date) : formatDateTimeDigits(restTimezone(date))}</div>}
                    </div>
                    <div className="row" >
                        <div className="col">
                            {subject && <small><em><strong>{TranslationService.translate.Subject}: {subject}</strong></em></small>}
                            <p style={{ wordBreak: "break-all" }}>
                                {content}
                            </p>
                            {DisplayFileList(data, type)}
                            {DisplayTagList(data)}
                            {DisplayActivityName(data)}
                            {DisplayGroupName(data)}

                            <div>
                                {hasComments && comment === undefined &&
                                    <button className="btn btn-intiza ps-0 mt-1" onClick={doShowComment}>
                                        {TranslationService.translate.ViewComments}
                                        {loadingComment && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                                    </button>}
                                {comment !== undefined &&
                                    <>
                                        <h6>{TranslationService.translate.Comments}</h6>
                                        <table className="ms-3">
                                            <tbody>
                                                {comment.map(x => (<tr key={x.MessageID}>
                                                    <td className="pe-3">{formatDateShort(new Date(x.Created))}</td>
                                                    <td>
                                                        <strong>{x.Username}: </strong>
                                                        {x.Content}
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </>}
                            </div>
                        </div>
                        <div className="actions col-auto align-items-start d-flex gap-3 ms-3">
                            {actions.map((action, index) =>
                                <TooltipComponent title={action.tooltip ?? false} key={index}>
                                    <div key={index} >
                                        <button>
                                            <i className={"far " + action.icon + " " + action.classes} onClick={action.onClick} />
                                        </button>
                                    </div>
                                </TooltipComponent>
                            )}
                            {DisplayStatusWhatsapp(data)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ActivityItemLeft = ({ icon, date, name = undefined, onClick = undefined }: { icon: string, date: Date, name?: string, onClick?: () => void }) => {
    return (
        <>
            <i className={"far fa-fw me-2 " + icon + (onclick ? " pointer" : "")} onClick={onClick} />
            {formatDateShort(date)}
            {name !== undefined && <div className="text-secondary">{name}</div>}
        </>);
};

export const deleteActivityAction = (
    id: number,
    title: string,
    message: string,
    reload: () => void,
    onError?: () => void
) => {
    return {
        icon: "fa-trash",
        classes: "text-danger",
        onClick: () => {
            ModalService.showDefaultModal({
                title: title,
                message: message,
                acceptButtonLabel: TranslationService.translate.Delete,
                acceptButtonClassName: "btn btn-danger",
                onAcceptClick: async () => {
                    const result = await ActivityService.delete(id.toString());
                    if (result instanceof Error) {
                        onError && onError();
                        return;
                    }
                    reload && reload();
                }
            });
        },
        tooltip: TranslationService.translate.Delete,
    };
};

export const deleteTaskAction = (
    id: number,
    reload: () => void,
    onError?: () => void
): { icon: string; classes: string; onClick: () => void; } => {
    return {
        icon: "fa-trash",
        classes: "text-danger",
        onClick: () => {
            ModalService.showDefaultModal({
                title: TranslationService.translate.DeleteActivity,
                message: TranslationService.translate.AreYouSureDeleteActivity,
                acceptButtonLabel: TranslationService.translate.Delete,
                onAcceptClick: async () => {
                    const result = await TaskService.deleteSingle(id);
                    if (result instanceof Error) {
                        onError && onError();
                        return;
                    }
                    reload && reload();
                }
            });
        }
    };
};

const ActivityScheduledEmail = ({ data, reload }: { data: ActivityPendingResponse.Mail, reload: () => void }) => {
    const { translate } = TranslationService;
    const onReadMoreClicked = async (id: number) => {

        const result = await ActivityService.get(data.MessageID);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ModalService.showDefaultModal({
            message: () => <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: result.item.Content }}></div>,
            title: translate.Email,
            size: "modal-xl",
        });
    };
    const openEmailModal = async () => {
        const emailRequest = await ActivityService.get(data.MessageID);
        if (emailRequest instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children:
                <EmailCompose
                    personId={data.PersonID.toString()}
                    emailBase={emailRequest.item}
                    onSubmit={reload} />,
            width: 900,
            height: 669,
        });
    };

    const replyAction = () => ({
        icon: "fa-pen",
        onClick: openEmailModal,
        tooltip: translate.Edit
    });

    const actions = [
        replyAction(),
        deleteActivityAction(data.MessageID, translate.ScheduledEmailDeleteTitle, translate.ScheduledEmailDeleteMessage, reload, () => ToastService.showToast(translate.ErrorProcessingRequest)),
    ];

    const content = <div>
        <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Content }}></div>
        {data.HasMoreContent && <button className="btn btn-intiza ps-0 mt-1" onClick={() => onReadMoreClicked(data.MessageID)}>{translate.ReadMore}</button>}
    </div>;
    return (
        <ActivityItemTemplate creator={data.From} data={data} type={ActivityHistoryType.email} subject={data.Subject} date={data.Draft ? TranslationService.translate.Draft : new Date(data.ScheduledDate)} to={data.To} content={content} actions={CompanyService.canDo("editmail") ? actions : []} />
    );
};

const ActivityPendingTask = ({ data, reload }: { data: ActivityPendingResponse.Task, reload: () => void }) => {
    const { translate } = TranslationService;
    const onTaskClick = async () => { await TaskService.toggle(data.TaskID, TaskStatus.Fulfilled); reload(); };
    const openEditModal = () =>
        FloatingPanelService.showPanel({
            children: <TaskDetail taskId={data.TaskID} clientId={data.PersonID} creatorUserId={data.CreatorUserID} dateCreated={data.Created} reload={reload} />,
            title: "",
            height: 800,
            width: 750,
        });
    const actions = [{ icon: "fa-pen", onClick: openEditModal, tooltip: translate.Edit }, deleteTaskAction(data.TaskID, reload, () => ToastService.showToast(translate.ErrorProcessingRequest))];
    const creator = CompanyService.getUsers()?.find(x => x.Id === data.ResponsibleUserID?.toString())?.Value ?? translate.NotAssigned;
    const content = <div className="form-check">
        {(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) &&
            (CompanyService.canDo("edittask") || CompanyService.canDo("checkotherstask") || (data.ResponsibleUserID?.toString() === CompanyService.getUserid())) &&
            <input className="form-check-input" type="checkbox" onClick={(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) ? onTaskClick : undefined} />
        }
        <div className="form-check-label">
            <TaskStar {...{ Fav: data.Fav, TaskID: data.TaskID }} className={"me-1 align-text-top"} />
            {data.Description}
            {/* {DisplayTagList(data)} */}
        </div>
    </div>;

    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.pendingTask} date={formatDateDigits(new Date(data.dueDate))} creator={creator} content={content} actions={CompanyService.canDo("edittask") ? actions : []} />
    );
};

const ActivityScheduledTask = ({ data, reload }: { data: ActivityPendingResponse.Scheduledtask, reload: () => void }) => {
    const { translate } = TranslationService;
    const actions = [deleteTaskAction(data.ScheduledTaskID, reload, () => ToastService.showToast(translate.ErrorProcessingRequest))];
    const creator = CompanyService.getUsers()?.find(x => x.Id === data.ResponsibleUserID?.toString())?.Value ?? translate.NotAssigned;
    return (
        <ActivityItemTemplate data={{ ...data, typeName: "Task" }} type={ActivityHistoryType.scheduledTask} date={getScheduledTime(data)} creator={creator} content={data.Description} actions={CompanyService.canDo("edittask") ? actions : []} />
    );
};

const getScheduledTime = (data: ActivityPendingResponse.Scheduledtask) => {
    const { translate } = TranslationService;
    const days = [translate.Sunday, translate.Monday, translate.Tuesday, translate.Wednesday, translate.Thursday, translate.Friday, translate.Saturday,];
    switch (data.RepeatPeriod) {
        case 0:
            return translate.Daily;
        case 1:
            return `${translate.Weekly} ${translate.On.toLowerCase()} ${days[data.RepeatFrequency].toLowerCase()}`;
        case 2:
            return `${translate.Monthly} ${translate.On.toLowerCase()} ${data.RepeatFrequency}`;
    }
};

const DisplayActivityType = (data: {} | { typeName?: string }) => {
    if ("typeName" in data && data.typeName !== undefined) {
        return <>{TranslationService.getTranslation(data.typeName)}</>;
    }
    return (<></>);
};


const DisplayFileList = (data: ActivityListResponse.Item | ActivityPendingResponse.Task | ActivityPendingResponse.Mail | ActivityPendingResponse.Scheduledtask, type: ActivityHistoryType) => {
    if ("item" in data) {
        data = Cast<{ item: ActivityListResponse.Item }>(data).item;
    }
    const download = (fileId: string, fileName: string) => {
        switch (type) {
            // [FALLTHROUGH] Comment uses the same endpoint as email
            case ActivityHistoryType.comment:
            case ActivityHistoryType.email:
                FileService.downloadEmailMessage(fileId, (data as ActivityListResponse.Item).ID ?? (data as ActivityPendingResponse.Mail).MessageID, fileName);
                break;
            case ActivityHistoryType.scheduledTask:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Scheduledtask).ScheduledTaskID.toString(), fileName);
                break;
            case ActivityHistoryType.pendingTask:
            case ActivityHistoryType.task:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Task).TaskID.toString(), fileName);
                break;
            case ActivityHistoryType.sms:
                break;
        }
    };

    const files: { fileName: string, id: string }[] = [];
    if ("Attachments" in data) {
        files.push(...data.Attachments.map(x => ({ fileName: x.Filename, id: x.AttachmentID.toString() })));
    }
    if ("files" in data) {
        files.push(...data.files);
    }

    if (files.length > 0) {
        return (
            <div className="d-flex align-items-baseline flex-wrap">
                <i className="me-2 mt-2 far fa-fw fa-paperclip"></i>
                {files.map((x, index) => (
                    <>
                        {index > 0 ? <span>,</span> : <></>}
                        <button className="btn btn-link p-0 m-0 ms-2" onClick={() => download(x.id, x.fileName)}>
                            {x.fileName}
                        </button>
                    </>
                ))}
            </div>
        );
    }

};

const DisplayTagList = (data: { tagList: string } | { tags: string } | { Tags: string } | {}) => {
    let tags = "";
    if ("tagList" in data) {
        tags = data.tagList;
    }
    else if ("tags" in data) {
        tags = data.tags;
    }

    if (tags === null && "Tags" in data) {
        tags = data.Tags;
    }

    if (!tags || tags.length === 0) {
        return <></>;
    }
    return <div>
        <i className={"me-2 mt-2 far fa-fw fa-tag"} />
        {tags}
    </div>;
};

const DisplayGroupName = (data: { groupName?: string } | object) => {
    if ("groupName" in data && data.groupName && data.groupName.length > 0) {
        return <div><strong>{CompanyService.getGroupName()}</strong>: <em>{data.groupName}</em></div>;
    }
    return <></>;
};

const DisplayActivityName = (data: { ActivityType?: { ActivityTypeName: string } } | object) => {
    if ("ActivityType" in data && data.ActivityType?.ActivityTypeName !== undefined) {
        return <em>{data.ActivityType.ActivityTypeName}</em>;
    }
    return <></>;
};

const DisplayStatusWhatsapp = (data: { Status?: WhatsappStatus } | object) => {
    if ("Status" in data && data?.Status && data.Status > 0) {
        return <StatusMessage status={data?.Status} />;
    }
    return <></>;
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DisplayInvoices = (data: { hasIOs: boolean }) => {
    if (data.hasIOs !== true) {
        return <></>;
    }
    return (
        <i className="fal fa-copy fa-fw" title={TranslationService.translate.ManagementAssociatedWithInvoices}></i>);
};

const ActivitySms = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;
    const openSms = () => {
        FloatingPanelService.showPanel({
            children: <SMSView data={data} />,
            title: "SMS",
            width: 600,
            height: 400,
        });
    };
    const actions = [{ icon: "fa-search", onClick: openSms }];
    if (CompanyService.canDo("deletecomment") && (CompanyService.getIsAdmin() || (data.Creator ?? 0) === parseInt(CompanyService.getUserid()))) {
        actions.push(deleteActivityAction(data.ID, translate.SMSDeleteTitle, translate.SMSDeleteMessage, reload));
    }

    const content = <>
        {data.Content}
    </>;
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.sms} date={new Date(data.date)} creator={data.smsphone ?? ""} content={content} actions={actions} />
    );
};

const ActivityComment = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;

    const openEditCommentModal = () =>
        FloatingPanelService.showPanel({
            children: <NewComment clientid={data.PersonId.toString()} data={data} reload={reload} />,
            title: translate.EditComment,
            height: 800,
            width: 750,
        });
    const actions = [];
    if (CompanyService.canDo("editcomment")) {
        actions.push({ icon: "fa-pen", onClick: openEditCommentModal, tooltip: translate.Edit });
    }
    if (CompanyService.canDo("deletecomment")) {
        actions.push(deleteActivityAction(data.ID, translate.CommentDeleteTitle, translate.CommentDeleteMessage, reload));
    }

    const contentBeforeTitle = data.hasIOs ? <TooltipComponent title={TranslationService.translate.ManagementAssociatedWithInvoices} placement="right"><i className="far fa-notes me-2"></i></TooltipComponent> : undefined;

    const content =
        <>
            <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Content }}></div>
        </>;
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.comment} date={new Date(data.date)} creator={data.Username} content={content} actions={actions} contentBeforeTitle={contentBeforeTitle} />
    );
};

const ActivityTask = ({ data, reload, users }: { data: ActivityListResponse.Item, reload: () => void, users: { Id: string, Value: string }[] }) => {
    const username = Switch(
        [() => data.Status === TaskStatus.Validated && data.ValidatorUserID !== undefined, users.find(x => x.Id === data.ValidatorUserID?.toString())?.Value],
        [() => data.Status === TaskStatus.Fulfilled, data.ResponsibleUsername],
        [() => isNullOrWhitespace(data.Username), data.CreatorUsername],
        [true, data.Username],
    ) ?? data.Username ?? data.CreatorUsername;

    const onTaskClick = (CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) ? async () => { await TaskService.toggle(data.ID, TaskStatus.Pending); reload(); } : undefined;
    const openEditCommentModal = () =>
        FloatingPanelService.showPanel({
            children: <TaskDetail taskId={data.ID} clientId={data.PersonId} creatorUserId={data.CreatorUserID} dateCreated={data.date} reload={reload} />,
            title: "",
            height: 800,
            width: 750,
        });
    const content = <>
        <i className={"pe-2 fa " + (data.Status === TaskStatus.Validated ? "fa-check-double" : "fa-check") + (onTaskClick ? " pointer" : "")} onClick={onTaskClick} />
        {data.Content}

    </>;

    const actions = [{ icon: "fa-pen", onClick: openEditCommentModal, tooltip: TranslationService.translate.Edit }, deleteTaskAction(data.ID, reload)];
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.task} date={new Date(data.date)} creator={username} content={content} actions={CompanyService.canDo("edittask") ? actions : []} />
    );
};

const ActionButtons = ({ personaId, person, selectedTabId, emailAddress, reload }: { personaId: string, person?: Client, emailAddress: string, selectedTabId: number, reload: () => void }) => {
    const clientDetailContextValues = useContext(ClientDetailContext);
    const { translate } = TranslationService;

    const openNewCommentModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewComment,
            children: <NewComment clientid={personaId} reload={reload} />,
            width: 800,
            height: 649,
        });
    };

    const openNewTaskModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewTask,
            children: <NewTaskForm personId={personaId} onNewTask={reload} personName={person?.Name ?? ""} />,
            width: 800,
            height: 750,
        });
    };

    const openNewSMSModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewSMS,
            children: <SMSCompose person={person!} onSubmit={reload} />,
            width: 800,
            height: 396,
        });
    };

    const openNewWhatsappModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewWhatsApp,
            children: <WhatsappCompose person={person!} personId={personaId} onSubmit={reload} />,
            width: 800,
            height: 650,
            position: 'bottom-right'
        });
    };

    const openNewEmailModal = () => {
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children: <EmailCompose personId={personaId} defaultTo={emailAddress} clientDetailContext={clientDetailContextValues} onSubmit={reload} />,
            width: 900,
            height: 669,
        });
    };

    const buttons = [undefined,
        !CompanyService.canDo("newmail") ? undefined : { title: translate.Email, onClick: openNewEmailModal },
        !CompanyService.canDo("newcomment") ? undefined : { title: translate.Comment, onClick: openNewCommentModal },
        !CompanyService.canDo("newtask") ? undefined : { title: translate.Task, onClick: openNewTaskModal },
        !CompanyService.canDo("newsms") ? undefined : { title: translate.SMS, onClick: openNewSMSModal },
        undefined,
        (!CompanyService.canDo("newwhatsapp") || !CompanyService.canShowWhatsApp()) ? undefined : { title: translate.WhatsApp, onClick: openNewWhatsappModal },
    ];


    const filteredButtons = buttons.filterFalsey();

    if (filteredButtons.length === 0) {
        return <></>;
    }

    switch (selectedTabId) {
        case 0:
            return (<MenuButton text={translate.New} actions={filteredButtons.map(x => ({ action: x!.onClick, text: x!.title }))}></MenuButton>);
        default:
            return (<>{buttons[selectedTabId] && <button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{buttons[selectedTabId]?.title}</button>}</>);
    }
};

const ActivityTabFilter = ({ personid, person, emailAddress, setReloads = undefined }: { personid: string, person?: Client, emailAddress: string, setReloads?: { activity: () => void } }) => {
    const filtersContext = useContext(AdvancedFiltersContext);
    const { applySearch, reload: reloadTable } = useContext(TableContext);
    const [selected, setSelected] = useState(0);
    const { translate } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<ActivityPendingResponse>();

    const reloadPending = useCallback(() => {
        const getData = async () => {
            const result = await ActivityService.getPending(personid);
            if (result instanceof Error) {
                setError(true);
            } else {
                setResponse(result);
            }
            setLoading(false);
        };
        getData();
    }, [personid]);

    useEffect(() => {
        setLoading(true);
        reloadPending();
    }, [reloadPending]);

    const onTabClick = (selection: number) => {
        setSelected(selection);
        if (selection < 7) {
            applySearch(selection.toString());
        }
    };

    const reload = () => {
        (reloadTable as ((skipReloading: boolean) => void))(true); reloadPending();
    };

    if (setReloads) {
        setReloads.activity = reload;
    }

    return (
        <div className="historyManagement">
            <div className="historyManagementActions">
                <ul className="d-flex flex-row buttonTabs">
                    <li>
                        <button className={"btn history" + (selected === 0 ? " active" : "")} type="button" onClick={() => onTabClick(0)}>
                            {translate.AllActivities}
                        </button>
                    </li>
                    <li>
                        <button className={"btn history" + (selected === 1 ? " active" : "")} type="button" onClick={() => onTabClick(1)}>
                            {translate.Email}
                        </button>
                    </li>
                    <li>
                        <button className={"btn history" + (selected === 2 ? " active" : "")} type="button" onClick={() => onTabClick(2)}>
                            {translate.Comments}
                        </button>
                    </li>
                    <li>
                        <button className={"btn history" + (selected === 3 ? " active" : "")} type="button" onClick={() => onTabClick(3)}>
                            {translate.Tasks}
                        </button>
                    </li>
                    <li>
                        <button className={"btn history" + (selected === 4 ? " active" : "")} type="button" onClick={() => onTabClick(4)}>
                            SMS
                        </button>
                    </li>
                    {CompanyService.canShowWhatsApp() && <li>
                        <button className={"btn history" + (selected === 6 ? " active" : "")} type="button" onClick={() => onTabClick(6)}>
                            {translate.WhatsApp}
                        </button>
                    </li>}
                </ul>
                <div className="d-flex flex-row align-items-center gap-3">
                    <button className="btn btn-tertiary" type="button" onClick={() => filtersContext.showFilters()}><i className="fa-regular fa-filter"></i> {translate.MoreFilters}</button>
                    <ActionButtons personaId={personid} person={person} selectedTabId={selected} emailAddress={emailAddress} reload={reload}></ActionButtons>
                </div>
            </div>
            <AdvancedFilters page={FilterCollection.Activity} />
            <ActivityPending {...{ loading, error, response: response!, reload, selected }} />
            <ActivityList reload={reload} />
        </div>
    );
};

export default ActivityHistory;
