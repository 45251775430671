import { useContext, useState } from "react";
import InvoiceService, { InvoiceFields } from "../../../services/InvoiceService";
import Collapsable from "../../shared/bootstrap/Collapsable";
import ModalService, { ModalParams } from "../../shared/bootstrap/Modal";
import ToastContext, { ToastService } from "../../shared/bootstrap/Toast";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import { InvoiceListResponse } from "./entities/InvoiceListResponse";
import { InvoiceMarkAsPayed } from "./InvoiceMarkAsPayed";
import { InvoiceChangeStatusForm } from "./InvoiceChangeStatusForm";
import { InvoiceMassiveEditFields } from "./InvoiceMassiveEditFields";
import { formatCurrency } from "../../../utils/FormatUtils";
import { InvoiceTableContextValues } from "./InvoiceTableProvider";
import ClientDetailContext, { ClientDetailContextValues } from "../ClientDetailContext";
import TooltipComponent from "../../shared/TooltipComponent";
import FloatingPanelService from "../../shared/FloatingPanel";
import CompanyService from "../../../services/CompanyService";
import CurrencyService from "../../../services/CurrencyService";

const InvoiceMassiveChanges = ({ personId }: { personId: number }) => {
    const { translate } = TranslationService;
    const clientDetailContext = useContext(ClientDetailContext);
    const { invoiceAll, invoiceIds, setInvoiceAll, setInvoiceIds } = clientDetailContext;
    const { showModal, hideModal } = ModalService;
    const { showToast } = useContext(ToastContext);

    const { response, reload, getFilters } = useContext(TableContext) as InvoiceTableContextValues;
    const selectedCount = invoiceAll ? (response as InvoiceListResponse).itemCount : invoiceIds.length;
    const getCurrencyTotals = () => {
        return (invoiceAll ? getAllCurrencyTotals() : getSelectedCurrencyTotals()).map(x => formatCurrency(x.pendingAmount, x.currencyId));
    };
    const getSelectedCurrencyTotals = () => {
        const pendingAmounts: { currencyId: number, pendingAmount: number }[] = [];
        const invoiceList = (response as InvoiceListResponse)?.list ?? [];
        const defaultCurrencyId = CompanyService.getDefaultCurrencyId();
        invoiceIds.forEach(id => {
            const invoice = invoiceList.find(x => x.IOID === id);
            if (!invoice) { return; }
            const pendingAmount = invoice.pending;
            const currencyId = invoice.CurrencyID;
            if (CompanyService.getSetting("groupediototals")) {
                const currencySum = pendingAmounts.find(x => x.currencyId === invoice.CurrencyID);
                if (currencySum) {
                    currencySum.pendingAmount += pendingAmount;
                }
                else {
                    pendingAmounts.push({ currencyId, pendingAmount });
                }
            } else {
                const currencySum = pendingAmounts.find(x => x.currencyId === CompanyService.getDefaultCurrencyId());
                const toSum = CurrencyService.convert(pendingAmount, invoice.CurrencyID, defaultCurrencyId);
                if (currencySum) {
                    currencySum.pendingAmount += toSum;
                }
                else {
                    pendingAmounts.push({ currencyId: defaultCurrencyId, pendingAmount: toSum });
                }
            }
        });

        return pendingAmounts;
    };
    const getAllCurrencyTotals = () => {
        if (!CompanyService.getSetting("groupediototals")) {
            return [{ currencyId: CompanyService.getDefaultCurrencyId(), pendingAmount: (response as InvoiceListResponse).total }];
        }
        (response as InvoiceListResponse).list.filter(x => invoiceIds.includes(x.IOID));
        const responseTotals = response?.currencyTotals;
        if (!responseTotals) { return []; }
        return responseTotals.flatMap(x => x).filter(x => x.id === InvoiceFields.pending).map(x => ({ pendingAmount: x.value, currencyId: x.currencyid }));
    };

    //No pay modal
    const onModalSubmit = () => {
        hideModal();
        reload();
    };

    const noPayModal = new ModalParams();
    noPayModal.children = <ConfirmNoPay personId={personId} onSubmit={onModalSubmit} selectedCount={selectedCount} clientDetailContext={clientDetailContext} filters={getFilters().filter} />;
    noPayModal.closeClickingOutside = true;
    noPayModal.title = translate.MarkAsNotPaid;

    //Edit multiple modal
    const editMultipleInvoices = new ModalParams();
    editMultipleInvoices.children = <InvoiceMassiveEditFields onSubmit={onModalSubmit} personId={personId.toString()} getFilters={getFilters} clientDetailContext={clientDetailContext} />;
    editMultipleInvoices.closeClickingOutside = true;
    editMultipleInvoices.title = translate.MultiInvoiceEdit;
    editMultipleInvoices.size = "modal-lg";

    const showMarkAsPaid = () => {
        FloatingPanelService.showPanel({
            title: translate.MarkAsPaid,
            children: <InvoiceMarkAsPayed personId={personId} onSubmit={onModalSubmit} selectedInvoiceCount={selectedCount} filters={getFilters()} clientDetailContext={clientDetailContext} />,
            width: 800,
            height: 669,
        });
    };

    //Change status modal
    const onStatusSubmit = async (statusEdit: InvoiceEditStatus) => {
        const result = await InvoiceService.statusChange({
            invoiceIds: invoiceIds,
            all: invoiceAll,
            filters: getFilters().filter,
            personId: personId.toString(),
            statusEdit
        });
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            showToast(translate.SavedChanges, undefined, "success");
            if (result.refreshactivity) {
                clientDetailContext.reloadExtraData();
            }
            setInvoiceAll(false);
            setInvoiceIds([]);
        }
        hideModal();
        reload();
    };

    const TotalsCurrency = getCurrencyTotals().map(currency => <span key={currency}>{currency}</span>);
    const changeStatusModal = new ModalParams();
    changeStatusModal.children = <InvoiceChangeStatusForm onSubmit={onStatusSubmit} personId={personId.toString()} />;
    changeStatusModal.closeClickingOutside = true;
    changeStatusModal.title = translate.ChangeStatus;

    return (
        <Collapsable show={selectedCount > 0}>
            <div data-bs-toggle="collapse" className="selectedCollapse mb-3">
                <div className="quantity me-2">
                    <p>{translate.NumberSelectedInvoices.replace("{invoiceCount}", selectedCount.toString())}</p>
                    {TotalsCurrency}
                </div>
                {!CompanyService.getSetting("hideeditio") && <>
                    <button className="btn btn-primary" onClick={() => showModal(changeStatusModal)}> {translate.ChangeStatus}</button>
                    <div className="actions">
                        <TooltipComponent title={translate.EditValues}><button onClick={() => showModal(editMultipleInvoices)}><i className="far fa-edit"></i></button></TooltipComponent>
                        {!CompanyService.getSetting("hideeditpayment") && <>
                            <TooltipComponent title={translate.MarkAsPaid}><button onClick={() => showMarkAsPaid()}><i className="far fa-check-circle"></i></button></TooltipComponent>
                            <TooltipComponent title={translate.MarkAsNotPaid}><button onClick={() => showModal(noPayModal)}><i className="far fa-times-circle"></i></button></TooltipComponent>
                        </>}
                    </div>
                </>}
                {/* <FilterDropdownButton items={items} title={translate.MassiveActions} /> */}
            </div>
        </Collapsable>
    );
};

const ConfirmNoPay = ({ personId, onSubmit, selectedCount, clientDetailContext, filters }:
    { personId: number, onSubmit: () => void, selectedCount: number, clientDetailContext: ClientDetailContextValues, filters: string | undefined }) => {
    const { translate } = TranslationService;
    const { invoiceAll, invoiceIds, setInvoiceAll, setInvoiceIds } = clientDetailContext;
    const [isSaving, setIsSaving] = useState(false);

    const confirmNoPay = async () => {
        if (isSaving) { return; }
        setIsSaving(true);
        const result = await InvoiceService.togglePay({
            pay: false,
            invoiceIds: invoiceIds,
            all: invoiceAll,
            filters: invoiceAll ? filters : undefined,
            personId: personId.toString(),
            additional: [],
            receipt: false
        });
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            ToastService.showToast(translate.SavedChanges, undefined, "success");
            setInvoiceAll(false);
            setInvoiceIds([]);
            onSubmit && onSubmit();
        }
        setIsSaving(false);
    };

    return (
        <>
            <div className="modal-body">
                <div className="d-flex input-column mb-3">
                    <p>{(selectedCount === 1 ? translate.MassiveActionConfirmNoPay : translate.MassiveActionConfirmNoPayMultiple).replaceAll("{0}", selectedCount.toString())}</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => ModalService.hideModal()}>{translate.Close}</button>
                <button className="btn btn-primary" onClick={confirmNoPay}>
                    {translate.Confirm}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </div>
        </>
    );
};

export default InvoiceMassiveChanges;