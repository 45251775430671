import React, { useEffect, useState } from "react";
import ActionService from "../../../services/ActionService";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { useEndpointData } from "../../../utils/TableUtils";
import { ActionListResponse } from "../entities/ActionListResponse";

export class ActionTableContextValues extends TableContextValues<ActionListResponse> {
    paused: boolean = false;
    setPaused = (val: boolean) => {};
}

const ActionTableProvider = (props: React.PropsWithChildren<{}>) => {
    const value = useEndpointData(ActionService.list) as ActionTableContextValues;
    const [paused, setPaused] = useState(value.response instanceof Error ? false : Boolean(value.response?.disabled));
    value.paused = paused;
    value.setPaused = setPaused;
    useEffect(() => {
        setPaused(x => value.response instanceof Error ? x : Boolean(value.response?.disabled));
    }, [value.response]);
    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default ActionTableProvider;

