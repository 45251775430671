import { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from "react";
import ActivityService from "../../../../services/ActivityService";
import CompanyService, { DataType } from "../../../../services/CompanyService";
import { FilterCollection } from "../../../../services/FilterService";
import InvoiceService from "../../../../services/InvoiceService";
import { formatDateDigits, formatDateShort } from "../../../../utils/FormatUtils";
import ModalService from "../../../shared/bootstrap/Modal";
import ToastContext, { ToastService } from "../../../shared/bootstrap/Toast";
import AdvancedFilters from "../../../shared/components/AdvancedFilters";
import Dropdown from "../../../shared/components/Dropdown";
import { AutocompleteClient, AutocompleteGroup, DatePickerEditor, FileApi, FileListEditor, MultiselectEditor, TagsDropdownEditor, TextAreaBigEditor } from "../../../shared/components/Editors";
import { SortDirection } from "../../../shared/entities/Sort";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { RequiredManager, ValidationMessage, ValidationMessageProps } from "../../../shared/RequieredManager";
import Table, { TableHeader } from "../../../shared/Table";
import TableContext, { TableContextValues } from "../../../task/TableContext";
import ClientDetailContext, { ClientDetailProvider } from "../../ClientDetailContext";
import InvoiceTableItem from "../../invoice/InvoiceTableItem";
import InvoiceTableProvider from "../../invoice/InvoiceTableProvider";
import { ActivityGetResponse } from "../entities/AcitivityGetResponse";
import { ActivityListResponse } from "../entities/AcitivityListResponse";
import { ActivitySetComment } from "../entities/ActivitySetComment";
import AdvancedFiltersContext, { AdvancedFiltersProvider } from "../../../shared/components/AdvancedFiltersContext";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import TagService from "../../../../services/TagService";
import { MapIfHasHours as MapIfHasTime, addTimezone, restTimezone } from "../../../../utils/ParseUtils";
import { addIf, Cast, OptionalMap } from "../../../../utils/Utils";
import FileService from "../../../../services/FileService";

export const NewComment = ({ reload, clientid, data: original = undefined }: { reload: () => void, clientid: string, data?: ActivityListResponse.Item }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [invoiceIds, setInvoiceIds] = useState<number[]>([]);

    const [isSaving, setIsSaving] = useState(false);
    const requiredManager = useRef(new RequiredManager()).current;
    const [filters, setFilters] = useState<string[]>([]);
    const [fixedIos, setFixedIos] = useState<ActivityGetResponse["ios"]>();

    const createCommentModel = (original?: ActivityListResponse.Item): ActivitySetComment => {
        if (original === undefined) {
            return {
                item: {
                    PersonID: parseInt(clientid),
                    Content: "",
                    files: [],
                    ActivityTypeID: CompanyService.getActivityTypes().find(x => x.Comment && x.CommentDefault)?.ActivityTypeID,
                },
                filter: "",
                ids: "",
                quickfilter: undefined,
            };
        }
        const item = { ...original, PersonID: original.PersonId };
        for (const key of Object.keys(item).filter(x => !["ID", "Content", "PersonID", "Sent", "GroupID", "TaskID", "ActivityTypeID", "NotifyTo", "Tag_Message", "files", "MessageID", "tagList "].includes(x))) {
            delete item[key as keyof typeof item];
        }
        return Cast<ActivitySetComment>({
            item: { ...item, Sent: original.date, ActivityTypeID: original.ActivityTypeID, Tag_Message: original.Tags?.map(x => ({ TagID: x.TagID })) },
        });
    };
    const [comment, setComment] = useState<ActivitySetComment>(createCommentModel(original));
    const [files, setFiles] = useState<{ id: string; name: string; }[]>(original?.files.map(x => ({ id: x.id, name: x.fileName })) ?? []);
    useEffect(() => {
        const requestData = async () => {
            if (original === undefined) {
                return;
            }
            const result = await ActivityService.get(original?.ID);
            if (result instanceof Error) {
                return;
            }
            setComment(comment => ({ ...comment, item: { ...comment.item, MessageID: result.item.MessageID } }));
            if (result.ios !== undefined && result.ios.length > 0) {
                setFixedIos(result.ios);
            }
        };
        requestData();
    }, [original]);
    const fields: {
        title: string;
        onChange: (value: string) => void;
        type: DataType;
        items: { value: string; text: string; }[],
        defaultValue?: (current: ActivitySetComment) => string | undefined;
        className: string
    }[] = useMemo(() => [
        {
            title: translate.Date,
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Sent: value } })),
            type: CompanyService.getSetting("canselectactivitydate") ? DataType.Date : DataType.Readonly,
            items: [],
            defaultValue: () => CompanyService.getSetting("canselectactivitydate") ? original?.date : formatDateShort(OptionalMap(original?.date, x => new Date(x)) ?? new Date()),
            className: "col-6",
        },
        {
            title: translate.ActivityType2,
            onChange: requiredManager.makeRequiredIf(
                CompanyService.getSetting("mandatorycommenttype"),
                (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, ActivityTypeID: value ? parseInt(value) : undefined } })),
                "activitytype"),
            type: DataType.List,
            items: [{ value: "", text: translate.None },
            ...CompanyService.getActivityTypes().filter(x => x.Comment).map(x => ({ value: x.ActivityTypeID.toString(), text: x.ActivityTypeName }))],
            defaultValue: (current) => current.item?.ActivityTypeID?.toString(),
            className: "col-6"
        },
        ...addIf(CompanyService.getGroupName()?.length > 0, {
            title: CompanyService.getGroupName(),
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, GroupID: parseInt(value) } })),
            type: DataType.Group,
            items: [{ value: "0", text: clientid }],
            defaultValue: () => original?.groupId,
            className: "col-12"
        }),
        {
            title: translate.Comment,
            onChange: requiredManager.makeRequiredWithId((value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Content: value } })), "content"),
            type: DataType.Text,
            items: [],
            defaultValue: (current) => current?.item.Content,
            className: "col-12"
        },
        {
            title: translate.NotifyTo,
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, NotifyTo: value } })),
            type: DataType.Multiselect,
            items: CompanyService.getUsers().map(x => ({ value: x.Id, text: x.Value })),
            defaultValue: (current) => undefined,
            className: "col-6"
        },
        ...(CompanyService.getTags()?.length ? [{
            title: translate.Tags,
            onChange: requiredManager.makeRequiredIf(CompanyService.getSetting("mandatorytag"), (value: string) => {
                setComment(comment => ({ ...comment, item: { ...comment.item, Tag_Message: value.split(", ").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) })) } }));
            }, "comment"),
            type: DataType.Tags,
            items: CompanyService.getTags().map(x => ({ value: x.Id, text: x.Value })),
            defaultValue: (current: ActivitySetComment) => current.item?.Tag_Message?.map(x => x.TagID).join(","),
            className: "col-6"
        }] : []),
    ], [clientid, original, requiredManager, translate.ActivityType2, translate.Comment, translate.Date, translate.None, translate.NotifyTo, translate.Tags]);

    const saveComment = async () => {
        if (isSaving) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        if (CompanyService.getSetting("mandatoryeffectiveactivityfile") && !comment.item.files?.length) {
            ToastService.showToast(TranslationService.translate.YouHaveToAttachAtLeastOneFileInAnEffectiveActivity, undefined, "warning");
            return;
        }
        setIsSaving(true);
        {
            const result = await TagService.createMissingTags(comment.item.Tag_Message?.map(x => x.TagID).join(", "));
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSaving(false);
                return;
            }
            const val = result?.split(",").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) }));
            setComment(comment => ({ ...comment, item: { ...comment.item, Tag_Message: val } }));
            comment.item.Tag_Message = val;
        }

        if (comment.quickfilter === 3) {
            comment.filter = filters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&");
        }

        if (comment.quickfilter === 6) {
            comment.ids = invoiceIds.join(",");
        }
        const result = await ActivityService.setComment(comment);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        FloatingPanelService.hidePanel();
        reload();
    };

    const onFileChange = (files: FileApi[]) => {
        setComment(comment => ({ ...comment, item: { ...comment.item, files: files.map(x => ({ fileName: x.name, response: null, id: x.id })) } }));
        setFiles([...files]);
    };

    const onFilterChange = (value: string) => setComment(comment => ({ ...comment, filter: value }));
    const tableContext = new TableContextValues();
    tableContext.applyFilters = onFilterChange;

    const tableHeaders: TableHeader[] = [
        ...InvoiceService.getTableHeaders(),
    ];

    const tableValues = new TableContextValues();
    tableValues.error = false;
    tableValues.response = { list: fixedIos };
    tableValues.loading = false;
    tableValues.sort = { sortColumn: "", sortDirection: SortDirection.Ascending };
    tableValues.setSortColumn = () => Boolean(4);

    const changeSelection = () => {
        ModalService.showDefaultModal({
            acceptButtonLabel: "Ok",
            message: translate.ChangeSelectionMessage,
            onAcceptClick: () => { setFixedIos(undefined); setComment(x => ({ ...x, quickfilter: Cast<number>(0) })); },
            title: translate.ChangeSelection
        });
    };

    return (
        <>
            <div className="floatingBody p-4">
                <div className="row">
                    {fields.map(x =>
                        <CommentFieldEdit key={x.title?.replaceAll(" ", "")} {...x} defaultValue={x.defaultValue && x.defaultValue(comment)} />
                    )}
                    {fixedIos !== undefined && CompanyService.getSetting("messageiolink") && <div>
                        <div className="row mb-3">
                            <div className="col">
                                <h6 className="mt-1">{translate.LinkedInvoices}</h6>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-intiza" onClick={changeSelection}>{translate.ChangeSelection}</button>
                            </div>
                        </div>
                        <TableContext.Provider value={tableValues}>
                            <Table headers={tableHeaders} item={InvoiceTableItem(undefined)} />
                        </TableContext.Provider>
                    </div>}
                    {fixedIos === undefined && CompanyService.getSetting("messageiolink") && <>
                        <CommentFieldEdit key={translate.LinkCommentWith.replaceAll(" ", "")} {...
                            {
                                title: translate.LinkCommentWith,
                                onChange: requiredManager.makeRequiredIf(CompanyService.getSetting(Cast<"allowIOUpdateByReferenceNumber">("mandatoryiolistincomment")), (value: string) => setComment(comment => ({ ...comment, quickfilter: value === "" ? null : parseInt(value) })), "iocomment"),
                                type: DataType.List,
                                items: [ //due = 0, pending = 1, all = 2, filtered = 3, (Hay que incluir filter) claimable = 4, dueclaimable = 5, selected = 6 (Hay que incluir ids)
                                    { text: translate.NoInvoices, value: "" },
                                    { text: translate.PendingInvoices, value: "1" },
                                    { text: translate.AllPendingClaimable, value: "4" },
                                    { text: translate.DuesClaimable, value: "5" },
                                    { text: translate.DueInvoices, value: "0" },
                                    { text: translate.OtherFilters, value: "3" },
                                    { text: translate.SelectFromAList, value: "6" },
                                ],
                                defaultValue: comment.quickfilter?.toString(),
                                className: "col-6"
                            }} />
                        {comment.quickfilter === 3 &&
                            <AdvancedFiltersProvider>
                                <AdvancedFiltersContext.Consumer>{(context) =>
                                    <>
                                        <div className="col-6">
                                            <div className="d-flex input-column mb-3">
                                                <label className="form-label">&nbsp;</label>
                                                <button className="btn btn-tertiary me-auto" onClick={() => context.showFilters()}>
                                                    <i className="far fa-filter"></i>
                                                    {translate.AddFilters}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <AdvancedFilters page={FilterCollection.Invoice} onFilterApply={setFilters} />
                                        </div>
                                    </>
                                }
                                </AdvancedFiltersContext.Consumer>
                            </AdvancedFiltersProvider>
                        }
                        {comment.quickfilter === 6 &&
                            <ClientDetailProvider>
                                <AdvancedFiltersProvider>
                                    <InvoiceList personId={parseInt(clientid)} setIds={setInvoiceIds} />
                                </AdvancedFiltersProvider>
                            </ClientDetailProvider>
                        }
                    </>}
                    <div className="mt-4">
                        <FileListEditor onFilesChange={onFileChange} canEdit={true} files={files} downloadFile={(id, name) => FileService.downloadEmailMessage(id, original?.ID.toString() ?? "", name)} />
                    </div>
                </div>
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary" onClick={saveComment}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

const CommentFieldEdit = ({ type, title, onChange, className, items, defaultValue = undefined }: { onChange: (value: string) => void, type: DataType, title: string, className: string, items: { value: string, text: string }[], defaultValue?: string | undefined }) => {
    let editor = undefined;
    let validationMethod: ValidationMessageProps<string>["validationMethod"] = undefined;
    switch (type) {
        case DataType.Client:
            editor = <AutocompleteClient onChange={onChange} defaultValue={defaultValue} />;
            break;
        case DataType.Group:
            editor = items.length === 1 ? <AutocompleteGroup onChange={(value) => onChange(value!.value)} clientId={items[0].text} /> : <input type="text" readOnly className="form-control-plaintext"></input>;
            break;
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={onChange} items={items} defaultValue={defaultValue} />;
            break;
        case DataType.Date:
            editor = <DatePickerEditor onChange={(x) => onChange(formatDateDigits(MapIfHasTime(new Date(x), addTimezone), "en"))} defaultValue={defaultValue ? MapIfHasTime(new Date(defaultValue), restTimezone)!.toString() : defaultValue} />;
            break;
        case DataType.Number:
        case DataType.Currency:
            editor = <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" />;
            break;
        case DataType.CurrencyWithCurrencyType:
            editor = <div className="row">
                <div className="col-4">
                    <Dropdown onChange={(value: string | number) => onChange("currencyId:" + value)}
                        items={CompanyService.getCurrencies().map(x => ({ value: x.CurrencyId, text: x.Symbol }))} />
                </div>
                <div className="col-8">
                    <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" />
                </div>
            </div>;
            break;
        case DataType.Text:
        case DataType.Phone:
        case DataType.Link:
            editor = <TextAreaBigEditor onChange={onChange} defaultValue={defaultValue} />;
            validationMethod = (value: string | undefined) => value !== undefined && value.length > 0;
            break;
        case DataType.Multiselect:
            {
                const selectItems = items.map(x => ({ value: x.value.toString(), label: x.text }));
                const def = defaultValue?.split(",").map(x => selectItems.find(y => y.value === x)!).filter(x => x !== undefined);
                editor = <MultiselectEditor items={selectItems} callback={x => onChange(x ? x.join(",") : "")} defaultValue={def} />;
            }
            break;
        case DataType.Tags:
            editor = <TagsDropdownEditor onChange={(value) => {
                onChange(value);
            }} defaultValue={defaultValue} />;
            break;
        case DataType.Readonly:
            editor = <>{defaultValue}</>;
            break;
        default:
            throw new Error("Missing editor: " + DataType[type]);
    }
    return (
        <div className={className}>
            <div className="d-flex input-column mb-3">
                <label className="form-label">{title}</label>
                {editor}
                <ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} />
            </div>
        </div>
    );
};

const InvoiceList = ({ personId, style, setIds }: { personId: number, style?: CSSProperties, setIds: (ids: number[]) => void }) => {
    const { invoiceIds, setInvoiceIds } = useContext(ClientDetailContext);
    const filtersContext = useContext(AdvancedFiltersContext);

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        let newIds: number[] = [];
        if (checked) {
            newIds = [...invoiceIds, invoiceId];
        }
        else {
            newIds = invoiceIds.filter(x => x !== invoiceId);
        }
        setInvoiceIds(newIds);
        setIds(newIds);
    };

    const tableHeaders: TableHeader[] = [
        new TableHeader("Checkbox", () => <></>, false, false),
        ...InvoiceService.getTableHeaders()
    ];

    return (
        <InvoiceTableProvider clientId={personId}>
            <div className="col-6">
                <div className="d-flex input-column mb-3">
                    <label className="form-label">&nbsp;</label>
                    <button className="btn btn-tertiary me-auto" onClick={() => filtersContext.showFilters()}>
                        <i className="far fa-filter"></i>
                        {TranslationService.translate.AddFilters}
                    </button>
                </div>
            </div>
            <div className="col-12">
                <AdvancedFilters page={FilterCollection.Invoice} />
            </div>
            <Table headers={tableHeaders} item={InvoiceTableItem(toggleOneCheckbox)} />
        </InvoiceTableProvider>
    );
};

export default NewComment;