import React from "react";
import { TranslationService } from "../../../../services/TranslationService";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";

export const WhatsAppTemplateContent = (propsEdit: EditProps) => {
    const { propGen: props, data } = propsEdit;
    return (
        <div className="row">
            <Field title={TranslationService.translate.template} colClass="col-8">
                <textarea
                    disabled
                    className="form-control"
                    value={data.Content}
                    onChange={e => props.str.required("Content").onChange(e.target.value)}
                    style={{ resize: "none" }}
                    rows={6} />
                <ValidationMessage {...props.str.required("Content")}
                    message={TranslationService.translate.EnterBodyWhatsApp} />
            </Field>
        </div>)
}