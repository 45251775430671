import { useEffect, useState } from "react";
import Loading from "./Loading";

const TableLoader = ({ children, loading }: { children: React.ReactNode, loading: boolean }) => {
    const [firstLoad, setfirstLoad] = useState(true);
    useEffect(() => {
        setfirstLoad(x => x && loading);
    }, [loading])

    if (loading && firstLoad) {
        return <Loading height={'60vh'} />
    }
    return (<div className="table-loader-container">
        <div className={"table-loader-loading-fade" + (loading ? " background-fade" : "")}>
            {loading && <i className="fas fa-spinner fa-pulse fa-8x"></i>}
        </div>
        {children}
    </div>)
}

export default TableLoader;