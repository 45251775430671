import React, { useState } from 'react'
import { ScheduledRequestItem } from '../entities/ScheduledListResponse';
import { TranslationService } from '../../../services/TranslationService';
import CompanyService from '../../../services/CompanyService';
import { CheckBoxEditor } from '../../shared/components/Editors';
import { Field } from '../../action/Edit/components/FieldHelpers';
import ScheduledService from '../../../services/ScheduledService';
import { ToastService } from '../../shared/bootstrap/Toast';
import FloatingPanelService from '../../shared/FloatingPanel';
import Loading from '../../shared/components/Loading';
import Dropdown from "../../shared/components/Dropdown";

function formatTime(hour: number) {
    return hour.toString().padStart(2, '0') + ':00';
}

function createHourObjectsArray() {
    const hoursArray = [];
    for (let i = 0; i <= 23; i++) {
        hoursArray.push({
            text: formatTime(i),
            value: i
        });
    }
    return hoursArray;
}

const itemsHoras = createHourObjectsArray();

const initialStateScheduleForm = {
    frequency: 0,
    period: 0,
    hour: 0,
    day0: false,
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
    day6: false,
    reportType: 3,
}

export
    const ScheduleModal = () => {
        const [loading, setLoading] = useState<boolean>(false)
        const [formShcedule, setFormShcedule] = useState<ScheduledRequestItem>(initialStateScheduleForm);

        const items = [
            { text: TranslationService.translate.Default, value: 0 },
            ...CompanyService.getReportExports()
                .filter(x => x.Report === 3)
                .map(x => ({ text: x.Name ?? TranslationService.translate.Default, value: x.ReportExportID })),
        ];

        const daysOfWeek = [TranslationService.translate.Sunday, TranslationService.translate.Monday, TranslationService.translate.Tuesday, TranslationService.translate.Wednesday, TranslationService.translate.Thursday, TranslationService.translate.Friday, TranslationService.translate.Saturday,];

        const periodByFrequency = (freq: number) => {
            switch (freq) {
                case 2:
                    return (
                        <div className="d-flex align-items-center justify-content-between mb-2" style={{ gap: 10 }}>
                            {TranslationService.translate.SpecificDays}:
                            {daysOfWeek.map((x, i) => <CheckBoxEditor message={x} onChange={(value) => handleSelect(value, `day${i}`)} />)}
                        </div>
                    )
                case 3:
                    return (

                        <div className="row align-items-center mb-3 mx-2">
                            {TranslationService.translate.ExactDate}
                            <Field colClass="col-2">
                                <input name="period" className="form-control" onChange={handleInputChange}></input>
                            </Field>
                            {TranslationService.translate.FromExactMonth}
                        </div>
                    )
            }
        }

        const handleInputChange = ({ target: { name, value, checked, type } }: React.ChangeEvent<HTMLInputElement>) =>
            setFormShcedule((prevState: any) => ({
                ...prevState,
                [name]: type === "checkbox" ? checked : value,
            }));

        const onFilterChange = (value: number, name: string) => {
            setFormShcedule((prevState: any) => ({
                ...prevState,
                [name]: value,
            }));
        };

        const handleSelect = (valueUpdated: any, name: string) => {
            setFormShcedule((prevState: any) => ({ ...prevState, [name]: valueUpdated }));
        }

        const handleScheduleChange = async () => {
            await requestData()
        }

        const requestData = async () => {
            setLoading(true);
            const result = await ScheduledService.createScheduledSet({ item: formShcedule });
            if (result instanceof Error) {
                setLoading(false);
                ToastService.showToast(TranslationService.translate.ScheduleReportError, undefined, "danger");
                return;
            }
            setLoading(false);
            setFormShcedule(initialStateScheduleForm);
            FloatingPanelService.hidePanel();
            ToastService.showToast(TranslationService.translate.ScheduleReport, undefined, "success");
        }

        return (
            <div className="floatingBody p-4">
                <h5>{TranslationService.translate.Type}: {TranslationService.translate.ReportInvociesActivities}</h5>
                {loading ?
                    <Loading />
                    : <>
                        <div className="row my-4">
                            <div className="col-12 ">
                                <div className="row">
                                    <Field title={TranslationService.translate.Name} colClass="col-8">
                                        <input name="name" className="form-control w-100" onChange={handleInputChange}></input>
                                    </Field>
                                    <Field title={TranslationService.translate.Frequency} colClass="col-4">
                                        <Dropdown
                                            onChange={(value) => onFilterChange(value ?? 0, 'period')}
                                            items={[TranslationService.translate.Manually, TranslationService.translate.DailyShort, TranslationService.translate.Weekly, TranslationService.translate.Monthly].map((x, i) => ({ text: x, value: i }))} />
                                    </Field>
                                </div>

                                {periodByFrequency(formShcedule.period || 0)}
                                {(formShcedule?.frequency && formShcedule.frequency > 0) ? <div className="row mb-3">
                                    <Field title={TranslationService.translate.Hour} colClass="col-4">
                                        <Dropdown
                                            onChange={(value) => onFilterChange(value ?? 0, 'hour')}
                                            items={itemsHoras} />
                                    </Field>
                                </div> : null}

                                <div className="row mb-3">
                                    <Field title={TranslationService.translate.Destination} colClass="col-4">
                                        <Dropdown
                                            onChange={(value) => onFilterChange(value ?? 0, 'destination')}
                                            items={[{ text: "Email", value: 0 }, { text: "FTP", value: 1 }]} />
                                    </Field>
                                    <Field title={Boolean(formShcedule.destination) ? 'FTP' : 'Email'} colClass="col-8">
                                        <input name={Boolean(formShcedule.destination) ? 'destinationftp' : 'destinationemail'} className="form-control w-100" onChange={handleInputChange}></input>
                                    </Field>
                                </div>

                                <div className="row mb-3">
                                    <Field title={TranslationService.translate.FileName} colClass="col-6">
                                        <input name="fileNaming" className="form-control w-100" onChange={handleInputChange}></input>
                                    </Field>
                                    <Field title={TranslationService.translate.Template} colClass="col-6">
                                        <Dropdown
                                            onChange={(value) => onFilterChange(value ?? 0, 'reportexportid')}
                                            items={items} />
                                    </Field>
                                </div>

                                <div className="mb-3">
                                    <CheckBoxEditor message={TranslationService.translate.CompressFile} onChange={(value) => handleSelect(value, 'zipfile')} />
                                </div>
                                <div className="mb-3">
                                    <CheckBoxEditor message={TranslationService.translate.AddDateFile} onChange={(value) => handleSelect(value, 'addsufix')} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-2 mb-3 flex-end" >
                            <button className="btn btn-primary" onClick={handleScheduleChange}>
                                {TranslationService.translate.Save}
                            </button>
                            <button className="btn btn-tertiary" onClick={() => FloatingPanelService.hidePanel()}>
                                {TranslationService.translate.Cancel}
                            </button>
                        </div>
                    </>}
            </div>
        )
    }