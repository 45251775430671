import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";

export const DashboardSales = (props: { noDue: number | undefined, overdue: number | undefined, totalAmount: number | undefined, currencyId?: number }) => {
    const { translate } = TranslationService;
    const { noDue, overdue, totalAmount, currencyId = CompanyService.getDefaultCurrencyId() } = props;
    return (
        <div className="dashboard__sales row">
            <div className="col-4 pe-0">
                <div className="dashboard__total card p-3">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.TotalReceivables}</p>
                            <span></span>
                        </div>
                        <h2>{formatCurrency(totalAmount ?? 0, currencyId, formatInteger)}</h2>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="dashboard__expired card p-3">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.Dues}</p>
                            <span>{formatInteger((overdue ?? 0) / (totalAmount ? (totalAmount === 0 ? 1: totalAmount) : 1) * 100)}%</span>
                        </div>
                        <h2>{formatCurrency(overdue ?? 0, currencyId, formatInteger)}</h2>
                    </div>
                </div>
            </div>
            <div className="col-4 ps-0">
                <div className="dashboard__notExpired card p-3">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.NoDues}</p>
                            <span>{formatInteger((noDue ?? 0) / (totalAmount ? (totalAmount === 0 ? 1: totalAmount) : 1) * 100)}%</span>
                        </div>
                        <h2>{formatCurrency(noDue ?? 0, currencyId, formatInteger)}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardSales;