import { useContext } from "react";
import CompanyService, { DataType } from "../../services/CompanyService";
import { FilterCollection } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { InvoiceTableContextValues } from "../client/invoice/InvoiceTableProvider";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import Table, { TableHeader, TableHeaderWithFieldId } from "../shared/Table";
import { useHistory, useLocation } from "react-router-dom";
import TableContext from "../task/TableContext";
import ClientService from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";
import AdvancedFiltersContext from "../shared/components/AdvancedFiltersContext";
import ReportCustomerAnalysisProvider from "./providers/ReportCustomerAnalysisProvider";
import { CustomerAnalysisListEntities } from "./entities/CustomerAnalysisListResponse";
import { formatCurrency, formatDateShort, formatInteger, formatNumber } from "../../utils/FormatUtils";
import { Cast } from "../../utils/Utils";
import { CustomerAnalysisListRequest } from "./entities/CustomerAnalysisListRequest";
import { ToastService } from "../shared/bootstrap/Toast";

const ReportCustomerAnalysis = () => {
    const { search } = useLocation<{ prevRequest?: CustomerAnalysisListRequest } | undefined>();
    const tableHeaders = getTableHeaders();
    const filtersContext = useContext(AdvancedFiltersContext);
    const urlParams = new URLSearchParams(search);
    const filterQS = urlParams.get("filter") ? [...urlParams.entries()].map((x, i) => "filter" + i.toString() + "=" + x[1].replace("filter0=", "")).join("&") : "";

    const exportData = async () => {
        const objCustomerAnalysisList = new CustomerAnalysisListRequest()
        const response = await ClientService.getCustomerAnalysisList(objCustomerAnalysisList)
        if (response instanceof Error) {
            return console.error(response)
        }
        await ClientService.exportCustomerAnalysisList({
            filter: objCustomerAnalysisList.filter,
            expectedRows: response.itemCount
        })
        ToastService.showToast(TranslationService.translate.FileExporting);
    }

    return (
        <div className="container-fluid padding">
            <div className="card mh-100">
                <div className="d-flex justify-content-between">
                    <h2 className="mb-3">{TranslationService.translate.CustomerAnalysis}</h2>
                </div>
                <div className="genericHeader">
                    <div className="searcherFilterHeader">
                        <button className="btn btn-tertiary" type="button" onClick={() => filtersContext.showFilters()}>
                            <i className="far fa-filter" />{TranslationService.translate.Filters}
                        </button>
                    </div>
                    {CompanyService.canDo("export") &&
                        <button type="button" className="btn btn btn-underline" onClick={exportData}>
                            <i className="far fa-arrow-to-bottom me-2"></i> {TranslationService.translate.ExportClients}
                        </button>}
                </div>
                <ReportCustomerAnalysisProvider>
                    <AdvancedFilters page={FilterCollection.Client} defaultValue={filterQS} />
                    <Table headers={tableHeaders} item={ReportCustomerAnalysisItem} stickyHeader={true} />
                </ReportCustomerAnalysisProvider>
            </div>
        </div>
    );
}

export const ExportButton = ({ ReportTypeId = 1 }: { ReportTypeId?: number }) => {
    const { getFilters, response } = useContext(TableContext) as InvoiceTableContextValues;
    const exportInvoices = (reportId: number | null) => {
        InvoiceService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === ReportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader">
        {CompanyService.canDo("export") && <MenuButton text={TranslationService.translate.ExportToXls} actions={exportItems} extraClassName="select-export" icon="far fa-arrow-to-bottom pe-1" />}
    </div>)
}

const ReportCustomerAnalysisItem = ({ data }: { data: any }) => {
    const { currentLanguage } = TranslationService;
    const tableHeaders = getTableHeaders();
    const history = useHistory()

    const handleNavigate = () => history.push(`/${currentLanguage}/client/details?id=${data.PersonId}`)

    return (
        <tr key={data.PersonId} style={{ cursor: 'pointer' }} onClick={handleNavigate}>
            {tableHeaders.map(header => <ClientColumn key={header.fieldId} data={data} header={header} />)}
        </tr>
    );
}

const ClientColumn = ({ data, header }: { data: CustomerAnalysisListEntities.ListItem, header: TableHeaderWithFieldId }) => {
    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.additionals.find(x => x.Id.toString() === header.fieldId)?.Value ??
                Cast<{ transactionadditionals?: typeof data.additionals }>(data).transactionadditionals?.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data[header.fieldId as keyof CustomerAnalysisListEntities.ListItem];
    };

    const negativeValueClass = CompanyService.getSetting("iosnegativeamountinred") ? "text-danger" : "";
    const value = getValue();

    switch (header.fieldId) {
        case "Name":
            return (
                <td>
                    {data.Name}
                </td>
            )
        case "dueDays":
        case "averageAgingDays":
        case "averagePaymentDays":
        case "count":
            return <td className={"text-end"}>{formatInteger(value)}</td>
    }

    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }

    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end " + (parseFloat(value) < 0 ? negativeValueClass : "")}>{formatCurrency(parseFloat(value), CompanyService.getDefaultCurrencyId(), formatNumber)}</td>;
        case DataType.Number:
            return <td className={"text-end"} > {formatInteger(value)}</td >;
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
        case DataType.List:
            {
                const additionalId = data.additionals.find(x => x.Id.toString() === header.fieldId)?.Id;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId?.toString());
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value)
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
}

function getTableHeaders() {
    const tableHeaders = ClientService.getCustomerAnalysisHeaders();
    tableHeaders.push({
        ...new TableHeader("amount", TranslationService.translate.ReportAmount, true, false, "w-150px"),
        fieldId: "amount",
        type: DataType.Currency
    })
    tableHeaders.push({
        ...new TableHeader("overdueAmount", TranslationService.translate.ReportOverdueAmount, true, false, "w-150px"),
        fieldId: "overdueAmount",
        type: DataType.Currency
    })
    tableHeaders.push({
        ...new TableHeader("percentDue", TranslationService.translate.ReportPercentDue, true, false, "w-150px"),
        fieldId: "percentDue",
        type: DataType.Percentage
    })
    tableHeaders.push({
        ...new TableHeader("count", TranslationService.translate.ReportPendingInvoices, true, false, "w-200px"),
        fieldId: "count",
        type: DataType.Number
    })
    tableHeaders.push({
        ...new TableHeader("averagePaymentDays", TranslationService.translate.ReportAveragePaymentDays, true, false, "w-200px"),
        fieldId: "averagePaymentDays",
        type: DataType.Number
    })
    tableHeaders.push({
        ...new TableHeader("averageAgingDays", TranslationService.translate.ReportAverageAgingDays, true, false, "w-200px"),
        fieldId: "averageAgingDays",
        type: DataType.Number
    })
    tableHeaders.push({
        ...new TableHeader("dueDays", TranslationService.translate.ReportDueDays, true, false),
        fieldId: "dueDays",
        type: DataType.Number
    })
    return tableHeaders;
}

export default ReportCustomerAnalysis;