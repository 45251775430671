import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import TabPosition from "../shared/TabPosition";
import ClientService, { ClientFields, getClientFieldDisplayValue } from "../../services/ClientService";
import ToastContext from "../shared/bootstrap/Toast";
import CompanyService, { DataType, Entities } from "../../services/CompanyService";
import { Client } from "./entities/ClientGetResponse";
import { formatCurrency, formatDateDigits, formatInteger, formatNumber } from "../../utils/FormatUtils";
import whatsappIco from "../../assets/img/ico-whatsapp.png";
import Loading from "../shared/components/Loading";
import InvoiceList from "./invoice/InvoiceList";
import { EntityField, sortEntityFields } from "../shared/entities/EntityField";
import { ClientDetailProvider } from "./ClientDetailContext";
import { ClientDetailLeftMenuButtons } from "./components/ClientDetailLeftMenuButtons";
import { AutocompleteClient, AutocompleteGroup } from "../shared/components/Editors";
import TableContext, { TableContextValues } from "../task/TableContext";
import Table, { TableHeader } from "../shared/Table";
import GroupService from "../../services/GroupService";
import { GroupListResponse } from "../group/entities/GroupListResponse";
import ModalService from "../shared/bootstrap/Modal";
import MenuBox from "./components/MenuBox";
import DashboardSales from "./components/DashboardSales";
import CashAppDetail from "./components/CashAppDetail";
import TooltipComponent from "../shared/TooltipComponent";
import ClientEdit from "./ClientEdit";
import FloatingPanelService from "../shared/FloatingPanel";
import PriorityService from "../../services/PriorityService";
import BreadcrumbHistory from "../shared/BreadcrumbHistory";
import { ClientIterator } from "./ClientIterator";
import Dropdown from "../shared/components/Dropdown";
import { InvoiceGroups } from "./invoice/InvoiceGroups";
import { CacheEntity, StorageService } from "../../services/StorageService";
import ActivityHistory from "./activity/ActivityHistory";
import CurrencyService from "../../services/CurrencyService";
import RequestService from "../../services/RequestService";
import { InvoiceListResponse } from "./invoice/entities/InvoiceListResponse";
import { BadlySpelled2, Choose, isNullOrWhitespace } from "../../utils/Utils";
import CashappService from "../../services/CashappService";
import TableLoader from "../shared/components/TableLoader";

export const getClientFieldDisplayValueHtml = (field: EntityField, client: Client, copyText?: (text: string) => void, currencyId: number = CompanyService.getDefaultCurrencyId()) => {
    const displayValue = getClientFieldDisplayValue(field, client);
    if (!displayValue) { return undefined; }

    // if (field.id === ClientFields.Email) {
    //     return <>
    //         {displayValue}
    //         {copyText &&
    //             <TooltipComponent title={TranslationService.translate.CopyToClipboard} placement="right">
    //                 <i className="fal fa-copy is-link px-2" onClick={() => { copyText(displayValue) }}></i>
    //             </TooltipComponent>
    //         }
    //     </>
    // }

    switch (field.type) {
        case DataType.Date:
            return formatDateDigits(new Date(displayValue));
        case DataType.Link:
            return <a target="_blank" rel="noopener noreferrer" href={displayValue} title={displayValue}>
                {displayValue}
                <i className="far fa-external-link ms-2"></i>
            </a>;
        case DataType.Number:
            return <>{formatNumber(displayValue)}</>;
        case DataType.Currency:
            return <>{formatCurrency(displayValue, currencyId)}</>;
        case DataType.Phone:
            return <>
                <a href={"tel:" + CompanyService.getPhoneLink(displayValue)}>
                    {displayValue}</a>
                {field.Whatsapp &&
                    <a target="_blank" className="ms-2" rel="noopener noreferrer" href={CompanyService.getWhatsappUrl(displayValue)}>
                        <img src={whatsappIco} width="20" height="20" alt="whatsapp icon" />
                    </a>
                }
            </>;
    }
    return displayValue;
};

const ClientFieldDisplayByContent = ({ title, value, link, isMail, tooltip, copyText }: { title: string, value: string | JSX.Element, link?: string, isMail?: boolean, tooltip?: string, copyText?: (text: string) => void }) => {
    return (
        <li className="row aboutCompanyItem">
            <h5 className="col-5">{title}: </h5>
            <div className="col pe-0" style={{ verticalAlign: "middle" }}>
                {link && <a className="ellipsis-oneline d-inline" href={(isMail ? "mailto:" : "") + link} rel="noreferrer" target="_blank">{value}<i className="far fa-external-link ms-2"></i></a>}
                {link && copyText && <TooltipComponent title={TranslationService.translate.CopyToClipboard} placement="right"><i className="fal fa-copy is-link px-2" onClick={() => { copyText(link); }} /></TooltipComponent>}
                {!link && <label>{value}</label>}
                {/* {tooltip && <TooltipComponent title={tooltip} placement="right"><i className=" fal fa-question-circle"></i></TooltipComponent>} */}
            </div>
        </li >
    );
};

const ClientFieldDisplay = ({ field, client, copyText }: { field: EntityField, client: Client, copyText: (text: string) => void }) => {
    const title = field.title;
    const displayValue = getClientFieldDisplayValueHtml(field, client, copyText);
    if (!displayValue) {
        return (<></>);
    }
    return (
        <li className="row aboutCompanyItem">
            <h5 className="col-5">{title}: </h5>
            <div className="col pe-0">
                <p className="ellipsis-oneline d-inline">{displayValue}</p>
                {/* {field.id === ClientFields.Email &&
                    <TooltipComponent title={TranslationService.translate.CopyToClipboard} placement="right">
                        <i className="fal fa-copy is-link px-2" onClick={() => { copyText(displayValue); }}></i>
                    </TooltipComponent>
                } */}
            </div>
        </li>
    );
};

const ClientFieldDisplayRight = ({ field, client, copyText }: { field: EntityField, client: Client, copyText: (text: string) => void }) => {
    const title = field.title;
    const displayValue = getClientFieldDisplayValueHtml(field, client, copyText);
    if (!displayValue) {
        return (<></>);
    }
    return (
        <div className="col-2 mt-3">
            <div className="creditLimitBoxes">
                <div className="creditLimitBox">
                    <h5>{title}</h5>
                    <p>{displayValue}</p>
                </div>
            </div>
        </div>
    );
};
export const ClientDetail = () => {
    const { translate, currentLanguage } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [client, setClient] = useState<Client | null | undefined>();
    const [agrupadoId, setAgrupadoId] = useState<number>();
    const [loadingAgrupado, setLoadingAgrupado] = useState<boolean>(false);
    const [agrupado, setAgrupado] = useState<Client | null | undefined>();
    const groupRef = useRef<{ setValue: (state: { value: number, label: string }) => void }>();
    //const [pendingReceivables, setPendingReceivables] = useState(0);
    const [segments, setSegments] = useState<string>();
    const [extraValues, setExtraValues] = useState<Exclude<Awaited<ReturnType<typeof ClientService.getExtraValues>>, Error>>();
    const [groupId, setGroupId] = useState<number>();
    const needClientCurrency = CompanyService.getSetting(BadlySpelled2("showtotalincustomercurrency", "showtotalintcustomercurrency"));
    const [displayCurrencyId, setDisplayCurrencyId] = useState<number>(needClientCurrency ? 0 : CompanyService.getDefaultCurrencyId());
    const [cashappClientId, setCashappClientId] = useState<string | null>(null);

    useEffect(() => {
        if (needClientCurrency && !displayCurrencyId) {
            RequestService.intercept("/io/list", (result: InvoiceListResponse) => setDisplayCurrencyId(result.currencyId));
        }
    }, [displayCurrencyId, needClientCurrency]);

    useEffect(() => {
        if (!client) {
            setCashappClientId(null);
            return;
        }
        const getData = async () => {
            const personKey = CompanyService.getCompanyAuth()?.PersonKey!;
            const clientCode = Choose(
                () => client.additionals.find(x => x.id === personKey)?.value,
                () => ClientService.getClientHeaders().find(x => x.key === personKey.toString())?.fieldId,
            );
            if (clientCode === undefined) {
                setCashappClientId(null);
            } else {
                const result = await CashappService.getClientId(clientCode);
                setCashappClientId(result instanceof Error ? null : result.clientId);
            }
        }
        getData();
    }, [client]);

    const [filtersSetter, setFiltersSetter] = useState<string[]>([]);
    const ioTreeFilters = useMemo(() => filtersSetter.join(), [filtersSetter]);

    const { state, search } = useLocation<{ clientIterator: ClientIterator, prevRequest: unknown }>();
    const clientId = parseInt(new URLSearchParams(search).get("id") ?? "");
    const history = useHistory();
    const [showMenu, setShowMenu] = useState(StorageService.getPreferences().clientDetailsCollapsed);
    const leftColumnFields = [
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client, false).map(x => new EntityField(x)),
        ...CompanyService.getConfigItemsFiltered(Entities.Client, true, [ClientFields.Name, ClientFields.Contact, ClientFields.Email, ClientFields.Phone, ClientFields.Address, ClientFields.Comments]).map(x => new EntityField(x)),
    ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")));

    const rightColumnFields = [
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client, true).map(x => new EntityField(x)),
    ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")));
    const invoiceGroups = CompanyService.getIOGroup();

    const toggleMenu = () => {
        setShowMenu(x => {
            StorageService.setPreference("clientDetailsCollapsed", !x);
            return !x;
        });
    };

    const collGet = (x: null | HTMLDivElement) => {
        if (x === null) {
            return;
        }
        if (StorageService.getPreferences().clientDetailsCollapsed) {
            x.classList.add("show");
        }
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        showToast(translate.CopiedToClipboard, undefined, "success");
    };

    const loadClient = useCallback(() => {
        const loadClient = async () => {
            if (agrupadoId !== undefined) {
                loadAgrupado();
            }
            const result = await ClientService.get(clientId);
            if (result instanceof Error) {
                // showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setClient(null);
                !agrupadoId && setAgrupado(null);
                return;
            }
            // setPendingReceivables(result.pendingReceivables);
            !agrupadoId && setSegments(result.segments?.map(x => x.Name).join(", "));
            setClient(result.item);
            !agrupadoId && setAgrupado(result.item);
        };
        const loadAgrupado = async () => {
            setLoadingAgrupado(true);
            const result = await ClientService.get(agrupadoId!);
            if (result instanceof Error) {
                // showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setAgrupado(null);
                setLoadingAgrupado(false);
                return;
            }
            //setPendingReceivables(result.pendingReceivables);
            setSegments(result.segments?.map(x => x.Name).join(", "));
            setAgrupado(result.item);
            setLoadingAgrupado(false);
        };
        const loadExtraValues = async () => {
            const result = await ClientService.getExtraValues(clientId);
            setExtraValues(result instanceof Error ? undefined : result);
        };
        loadExtraValues();
        loadClient();
    }, [agrupadoId, clientId]);

    useEffect(() => {
        loadClient();
    }, [loadClient]);

    const convertClientCurrency = (amount: number | undefined) => displayCurrencyId ? CurrencyService.convert(amount ?? 0, CompanyService.getDefaultCurrencyId(), displayCurrencyId) : 0;

    if (client === null || agrupado === null) {
        ModalService.showDefaultModal({
            message: translate.ClientNotFound.replace("{company}", CompanyService.getCompanyName()!),
        });
        return (<Redirect to={"/" + currentLanguage + "/client"} />);
    }

    const reloads = { invoice: () => undefined, activity: () => undefined, client: loadClient };
    const reload = () => {
        reloads.invoice();
        reloads.activity();
        reloads.client();
    };

    const setGroupFilter = (name: string, groupId: number) => {
        groupRef.current!.setValue({ value: groupId, label: name });
    };

    const openEditClientModal = () => {
        FloatingPanelService.showPanel({
            title: translate.EditClient,
            children: <ClientEdit client={client} onClientSave={() => reload()} onClientDeleted={() => { history.push(`/${currentLanguage}/client`); }} />,
            width: 800,
            height: 669,
        });
    };

    const creditLimit = client?.CreditLimit ?? 0;
    const creditAvailable = creditLimit - (client?.amount ?? 0);
    const creditAvailablePerc = Math.abs(creditAvailable * 100 / (creditLimit === 0 ? 1 : creditLimit));
    const rank = PriorityService.getRankingIndex(client?.CalculatedRank);

    const showCashapp = [CompanyService.getCompanyToken()?.cashappid, cashappClientId]
        .reduce<boolean>((prev, curr) => prev && !isNullOrWhitespace(curr), true);
    return (
        <ClientDetailProvider reloadExtraData={() => { loadClient(); reloads.activity(); }}>
            <TabPosition module="client">
                <section className="debts" key={clientId}>
                    <div>
                        {client === undefined || agrupado === undefined ?
                            <TableLoader loading={true}>
                                <Loading />
                            </TableLoader>
                            :
                            <div className="row flex-nowrap g-0 app-height">
                                <div className="col-auto p-0 debtsMenu h-100">
                                    <div className="collapse collapse-horizontal h-100" id="collapseMenu" ref={collGet}>
                                        <div className="d-flex flex-column h-100">
                                            <div className="resumeCompany">
                                                {client?.GroupedPerson && client.GroupedPerson.length > 0 &&
                                                    <div className="viewData row align-items-center">
                                                        <div className="col-5">
                                                            <label>{<>{translate.ViewDataFrom}:{loadingAgrupado && <i className="fas fa-spinner-third fa-spin third ms-2" />}</>}
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            <Dropdown items={client.GroupedPerson.map(x => ({ text: x.Name, value: x.PersonId }))} onChange={setAgrupadoId} optionLabel={translate.MainCompany} selectClasses="col" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="toggleMenu">
                                                    <button className="" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-expanded="false" aria-controls="collapseMenu" onClick={() => toggleMenu()} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="96" viewBox="0 0 20 96" fill="none">
                                                            <g clipPath="url(#clip0_268_7814)">
                                                                <path d="M1 0H2C2 20 14 12 14 32V64C14 84 2 76 2 96H1V0Z" fill="white" />
                                                                <path d="M1.5 0C1.5 20 13.5 12 13.5 32V64C13.5 84 1.5 76 1.5 96" fill="white" />
                                                                <rect x="-1" width="3" height="96" fill="white" />
                                                                <path d="M1.5 0H2.5C2.5 20 14.5 12 14.5 32V64C14.5 84 2.5 76 2.5 96L1.5 96V0Z" fill="#D7DEEC" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.5 0H2.5C2.5 5.18298 3.3059 8.48553 4.5 10.8823C5.60979 13.1098 7.05489 14.5549 8.5 16C11.5 19 14.5 22 14.5 32V64C14.5 74 11.5 77 8.5 80C7.05489 81.4451 5.60979 82.8902 4.5 85.1177C3.3059 87.5145 2.5 90.817 2.5 96L1.5 96V0ZM4.5 16.1588V79.8412C5.12007 79.1369 5.73629 78.5209 6.304 77.9533L6.37868 77.8787C7.88433 76.373 9.03486 75.1963 9.91379 73.3407C10.7932 71.4843 11.5 68.7067 11.5 64V32C11.5 27.2933 10.7932 24.5157 9.91379 22.6593C9.03486 20.8037 7.88433 19.627 6.37868 18.1213C6.35389 18.0965 6.329 18.0717 6.30402 18.0467C5.7363 17.4791 5.12007 16.8631 4.5 16.1588Z" fill="#D7DEEC" />
                                                                <path d="M1.5 0C1.5 20 13.5 12 13.5 32V64C13.5 84 1.5 74.5 1.5 96" fill="white" />
                                                            </g>
                                                            <path d="M0 0H1L2 7C2 7 1.5 90.6309 1.5 91C1.5 91.3691 1.5 96 1 96H0V0Z" fill="white" />
                                                            <defs>
                                                                <clipPath id="clip0_268_7814">
                                                                    <rect width="14" height="96" fill="white" transform="translate(1)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <div>
                                                            <span>
                                                                <i className={"fa-solid " + (showMenu ? "fa-caret-left" : "fa-caret-right")} />
                                                            </span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="actions column">
                                                    <ClientDetailLeftMenuButtons client={client} clientEmail={client?.Email} reload={reload} direction={"column"} />
                                                </div>
                                                <div className="actions">
                                                    <ClientDetailLeftMenuButtons client={client} clientEmail={client?.Email} reload={reload} />
                                                </div>
                                            </div>
                                            <div className="aboutCompany">
                                                {extraValues &&
                                                    <ul className="spacing-boxes" >
                                                        <MenuBox title={translate.DaysSalesOutstanding} tooltip={translate.AvaragePaymentsTip} content={formatInteger(extraValues.averagePeriodPerson) + " " + translate.Days + " (" + formatInteger(extraValues.averagePeriod) + ")"}></MenuBox>
                                                        <MenuBox title={translate.DebtAging} tooltip={translate.DebtAgingTip} content={formatInteger(extraValues.averageAgingPerson) + " " + translate.Days + " (" + formatInteger(extraValues.averageAging) + ")"}></MenuBox>
                                                        {(client.CreditLimit ?? 0) > 0 && <>
                                                            {(displayCurrencyId !== undefined && displayCurrencyId !== 0) ? <>
                                                                <MenuBox title={translate.CreditLimit} content={formatCurrency(convertClientCurrency(client.CreditLimit ?? 0), displayCurrencyId)}></MenuBox>
                                                                <MenuBox title={creditAvailable < 0 ? translate.CreditExceeded : translate.CreditAvailable}
                                                                    content={formatCurrency(convertClientCurrency(Math.abs(creditAvailable)), displayCurrencyId) + " (" + formatInteger(creditAvailablePerc) + "%)"}
                                                                    className={creditAvailable < 0 ? "text-danger" : ""}
                                                                ></MenuBox>
                                                            </> : <Loading />}
                                                        </>}
                                                    </ul>
                                                }
                                                <ul>
                                                    {leftColumnFields.map(field =>
                                                        <ClientFieldDisplay key={field.id} field={field} client={agrupado} copyText={copyText} />
                                                    )}
                                                    {segments && <ClientFieldDisplayByContent title={translate.Segments} value={segments}></ClientFieldDisplayByContent>}
                                                    {rank && !(CompanyService.getCompanyAuth()?.HidePriority) &&
                                                        <li className="row aboutCompanyItem">
                                                            <h5 className="col-5">{translate.Priority}: </h5>
                                                            <div className="col pe-0" style={{ verticalAlign: "middle" }}>
                                                                <span className={"priority priority-" + rank}>{agrupado.CalculatedRank.toString()}</span>
                                                                <TooltipComponent title={translate.PriorityTip} placement="right" className="align-middle"><i className=" fal fa-question-circle"></i></TooltipComponent>
                                                            </div>
                                                        </li >}
                                                    {agrupado.followUpMail && <ClientFieldDisplayByContent title={translate.FollowUpMail} value={agrupado.followUpMail} tooltip={translate.FollowUpMailTip} link={agrupado.followUpMail} isMail={true} copyText={copyText}></ClientFieldDisplayByContent>}
                                                    {CompanyService.getSetting("customerportalenabled") && agrupado.customerSite && <ClientFieldDisplayByContent title={translate.CustomerSite} value={translate.Open} link={agrupado.customerSite} copyText={copyText}></ClientFieldDisplayByContent>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col debtsDetail h-100 overflow-auto">
                                    <div className="ps-3 mb-0 pt-4 position-relative z-index-10">
                                        <BreadcrumbHistory defaultPrevious={{
                                            link: () => {
                                                history.push(`/${TranslationService.currentLanguage}/client`, { prevRequest: state.prevRequest })
                                            }, name: (state?.clientIterator?.items?.length > 0) ? TranslationService.translate.Clients + ` (${TranslationService.translate.Filtered})` : translate.Clients
                                        }}
                                            current={{ name: client.Name, link: `client/details?id=${client.PersonId}` }} >
                                            <span className="breadcrumb-controls ms-auto d-flex align-items-center flex-row mb-0">
                                                {state?.clientIterator?.hasPrev && <>
                                                    {state.clientIterator.hasPrev() && <button className="btn btn-link" onClick={() => { setClient(undefined); state.clientIterator.goPrev(history); }}>{TranslationService.translate.Previous}</button>}
                                                    {state.clientIterator.hasPrev() && state.clientIterator.hasNext() && <>|</>}
                                                    {state.clientIterator.hasNext() && <button className="btn btn-link" onClick={async () => { await state.clientIterator.goNext(history); setClient(undefined); }}>{TranslationService.translate.Next}</button>}
                                                </>}
                                                {TranslationService.translate.GoTo}
                                                <span className="ms-2 mb-1" style={{ width: 200 }}>
                                                    <AutocompleteClient onChange={(x) => {
                                                        if (x) {
                                                            setClient(undefined); history.push(`/${currentLanguage}/client/details?id=${x}`);
                                                        }
                                                    }} />
                                                </span>
                                            </span>
                                        </BreadcrumbHistory>
                                    </div>
                                    <div className="ps-3 mb-1 sticky-clientname">
                                        {(CompanyService.canDo("editperson") && !CompanyService.getSetting("hideeditperson")) ?
                                            <div className={"nameCompany mb-3 canEdit"}>
                                                <h2 onClick={openEditClientModal}>
                                                    {agrupado.Name}<i className="far fa-pen"></i>
                                                </h2>
                                            </div>
                                            :
                                            <div className={"nameCompany mb-3"}>
                                                <h2>{agrupado.Name}</h2>
                                            </div>
                                        }
                                    </div>
                                    {showCashapp && <ul className="nav nav-pills client-detail" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-collections-tab" data-bs-toggle="pill" data-bs-target="#pills-collections" type="button" role="tab" aria-controls="pills-collections" aria-selected="true">
                                                {translate.Collections}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-reconciliations-tab" data-bs-toggle="pill" data-bs-target="#pills-reconciliations" type="button" role="tab" aria-controls="pills-reconciliations" aria-selected="false">
                                                {translate.Reconciliations}
                                            </button>
                                        </li>
                                    </ul>}
                                    <div className="tab-content p-0" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-collections" role="tabpanel" aria-labelledby="pills-collections-tab">
                                            <div className="pt-4">
                                                {(displayCurrencyId > 0) ?
                                                    <DashboardSales noDue={convertClientCurrency(client.noDueAmount)}
                                                        overdue={convertClientCurrency(client.overdueAmount)}
                                                        totalAmount={convertClientCurrency(client.amount)}
                                                        currencyId={displayCurrencyId} />
                                                    : <Loading />}
                                                {rightColumnFields && <div className="row creditLimit">
                                                    {rightColumnFields.map(field =>
                                                        <ClientFieldDisplayRight key={field.id} field={field} client={agrupado} copyText={copyText} />
                                                    )}
                                                </div>}
                                            </div>
                                            {invoiceGroups?.length > 0 &&
                                                <div className="card mt-4 p-0">
                                                    <section className="clientsTabs">
                                                        <div className="tab-content">
                                                            <div className="tab-pane fade show active">
                                                                <InvoiceGroups
                                                                    personid={client.PersonId}
                                                                    groups={invoiceGroups}
                                                                    filtersSetter={filtersSetter}
                                                                    setFiltersSetter={setFiltersSetter}
                                                                />
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>}
                                            {<>
                                                <div className="card mt-4 p-0">
                                                    <section className="clientsTabs">
                                                        {CompanyService.getSetting("showgrouptable") &&
                                                            <GroupTable personId={clientId} setGroupFilter={setGroupFilter} actions={<div className="col-3 group-filter"><AutocompleteGroup clientId={clientId.toString()} onChange={x => setGroupId(x && parseInt(x.value))} ref={groupRef} /></div>} />}
                                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#invoices-tab-pane" type="button" role="tab" ><p><i className="far fa-file me-2"></i> {translate.Invoices}</p><span className="rounded"></span></button>
                                                            </li>
                                                            {showCashapp && <li className="nav-item" role="presentation">
                                                                <button className="nav-link" data-bs-toggle="pill" data-bs-target="#payments-tab-pane" type="button" role="tab" ><p><i className="far fa-cash-register me-2"></i> {translate.Payments}</p><span className="rounded"></span></button>
                                                            </li>}
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div className="tab-pane fade show active" id="invoices-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                                <InvoiceList client={client} groupId={groupId} setReloads={reloads} ioTreeFilters={ioTreeFilters} />
                                                            </div>
                                                            {showCashapp && <div className="tab-pane fade" id="payments-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                                <CashAppDetail iframeStyle={{ width: "100%", height: "300px" }} url={CashappService.getBaseUrl() + "/Payment/GetClientPendingPaymentList?clientId=" + cashappClientId} />
                                                            </div>}
                                                        </div>
                                                    </section>
                                                </div>
                                                <div className="card mt-4 p-0">
                                                    <section className="clientsTabs">
                                                        <ul className="nav nav-pills" id="activity-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#activity-tab-pane" type="button" role="tab"><p><i className="far fa-history me-2"></i> {translate.ActivityHistory}</p><span className="rounded"></span></button>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div className="tab-pane fade show active" id="activity-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                                                                <ActivityHistory personid={clientId.toString()} person={client} emailAddress={client.Email} groupId={groupId} setReloads={reloads} />
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </>
                                            }</div>
                                        <div className="tab-pane fade" id="pills-reconciliations" role="tabpanel" aria-labelledby="pills-reconciliations-tab">
                                            {showCashapp && <CashAppDetail url={CashappService.getBaseUrl() + "/Client/Details/" + cashappClientId + "?hideName=true"} iframeStyle={{ width: "100%", height: "65vh" }} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </TabPosition>
        </ClientDetailProvider>
    );
};

const GroupTable = ({ personId, actions, setGroupFilter }: { personId: number, actions?: JSX.Element, setGroupFilter: (groupName: string, groupId: number) => void }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [group, setGroup] = useState<GroupListResponse.Item[]>([]);
    useEffect(() => {
        const getData = async () => {
            const result = await GroupService.list(personId);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            result.list.map(x => StorageService.setCache(CacheEntity.GroupName, x.GroupID, x.Name));
            setGroup(result.list);
        };
        getData();
    }, [personId, showToast, translate]);
    if (group.length === 0) {
        return <></>;
    }
    const headers = [
        new TableHeader("group", CompanyService.getGroupName(), false, false),
        new TableHeader("io", translate.Invoices, true, false),
        new TableHeader("amount", translate.Amount, true, false),
        new TableHeader("amountDue", translate.Due, true, false),
        new TableHeader("amountNotDue", translate.NotDue, true, false),
        new TableHeader("due", translate.DueNoDue, false, false),
    ];

    const tableValues = new TableContextValues();
    tableValues.response = { list: group };
    tableValues.loading = false;
    tableValues.error = false;
    const max = group.map(x => x.amount).reduce((prev, curr) => prev > curr ? prev : curr, 1);

    return (
        <>
            <TableContext.Provider value={tableValues}>
                <div className="table-no-border group-table">
                    {/* <Table title={CompanyService.getGroupName()} headers={headers} actions={actions} item={({ data }: { data: GroupListResponse.Item }) => ( */}
                    <Table headers={headers} item={({ data }: { data: GroupListResponse.Item }) => {
                        return (
                            <tr onClick={() => setGroupFilter(data.Name, data.GroupID)} className="pointer">
                                <td>{data.Name}</td>
                                <td width={80} className={"text-end"}>{data.count}</td>
                                <td width={100} className={"text-end w-sm"}>{formatCurrency(data.amount, CompanyService.getDefaultCurrencyId())}</td>
                                <td width={100} className={"text-end w-sm text-danger"}>{formatCurrency(data.overdueAmount, CompanyService.getDefaultCurrencyId())}</td>
                                <td width={100} className={"text-end w-sm"}>{formatCurrency(data.amount - data.overdueAmount, CompanyService.getDefaultCurrencyId())}</td>
                                <td className="bar-progress">
                                    <div className="bar">
                                        <div className="expired" style={{ width: ((data.overdueAmount ?? 0) * 100 / max) + "%" }}></div>
                                        <div className="not-expired" style={{ width: (((data.amount - data.overdueAmount) ?? 0) * 100 / max) + "%" }}></div>
                                    </div>
                                </td>
                            </tr>);
                    }}
                    ></Table>
                    {actions && <div className="mt-2">{actions}</div>}
                </div>
                <hr className="mt-4"></hr>
            </TableContext.Provider>
        </>);
};

export default ClientDetail;
