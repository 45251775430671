import { CollapsableTr } from '../../../segment/List';

type ConfigurationItemType = {
    openAction?: boolean;
    component: JSX.Element | null;
}

export const ConfigurationItem = ({ openAction = true, component }: ConfigurationItemType) => {
    return (
        <CollapsableTr show={openAction} className={"pointer show-child-on-hover"} onClick={() => { }}>
            {component}
        </CollapsableTr>
    )
}
