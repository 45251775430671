import { useState, useMemo, useContext, useEffect, useCallback } from "react";
import { ActionType } from "../../../../services/ActionService";
import CompanyService from "../../../../services/CompanyService";
import { translateInvoiceSetFields } from "../../../../services/InvoiceService";
import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import Dropdown from "../../../shared/components/Dropdown";
import { FileListEditor } from "../../../shared/components/Editors";
import TooltipComponent from "../../../shared/TooltipComponent";
import { EditProps } from "../EditAction";
import { Checkbox, Field } from "./FieldHelpers";
import Label from "../../../shared/form/Label";
import AdvancedFilters from "../../../shared/components/AdvancedFilters";
import FilterService, { FilterCollection } from "../../../../services/FilterService";
import AdvancedFiltersContext from "../../../shared/components/AdvancedFiltersContext";
import FileService from "../../../../services/FileService";

enum InvoiceListModeOptions {
    EmailOnBody,
    EmailAsAttachment,
}

enum InvoiceListTypeOptions {
    AllPending,
    OnlyDues,
    OnlyClaimable,
    OnlyDuesClaimable,
    OtherFilters,
}

export const EmailAditionalContent = ({ setField, data }: EditProps) => {
    const sortBy = useMemo(() => {
        return (data.Mail_OrderBy ? data.Mail_OrderBy : CompanyService.getCompanyAuth()!.OrderMailBy).split(";").map(x => parseInt(x))
    }, [data.Mail_OrderBy]);
    const sortByOrder = useMemo(() => {
        return (data.Mail_OrderByOrderDesc ? data.Mail_OrderByOrderDesc : CompanyService.getCompanyAuth()!.OrderMailByOrderDesc).split(";").map(x => parseInt(x))
    }, [data.Mail_OrderByOrderDesc]);

    const filtersContext = useContext(AdvancedFiltersContext);
    const [showFilters, setShowFilters] = useState<boolean>((data.Mail_Include ?? false) && (data.Mail_IOFilter !== undefined && data.Mail_IOFilter !== ""));

    const reportTemplates = useMemo(() => CompanyService.getReportExports()
        .filter(x => x.Report === 1)
        .map(x => ({ value: x.ReportExportID, text: x.Name ?? TranslationService.translate.Default })), []);

    const groupByList = useMemo(() => [
        { AdditionalDefinitionID: -1015, Name: TranslationService.translate.IOStatus },
        { AdditionalDefinitionID: -1017, Name: TranslationService.translate.Currency },
        { AdditionalDefinitionID: -1007, Name: CompanyService.getGroupName() },
        ...CompanyService.getAdditionalDefinitions().filter((def) => (def.entity === 1)),
    ].map(x => ({ text: x.Name, value: x.AdditionalDefinitionID })), []);

    const sortByList = useMemo(() => [
        ...[-1010, -1023, -1024, -1009, -1011, -1012, -1015, -1017, -1008]
            .map(x => ({ AdditionalDefinitionID: x, Name: translateInvoiceSetFields(x)! })),
        { AdditionalDefinitionID: -1008, Name: TranslationService.translate.Group },
        ...CompanyService.getAdditionalDefinitions().filter((def) => (def.entity === 1)),
    ].map(x => ({ text: x.Name, value: x.AdditionalDefinitionID })), []);

    const applyFilter = (filters: string[]) => {
        setField("Mail_IOFilter")(FilterService.GetExtraFiltersRequestString(filters));
    }

    const onChangeIncludeInvoiceList = useCallback((value: boolean) => {
        setField("Mail_IncludeAttachment")(false);
        if (value) {
            setField("Mail_Include")(true);
        }
        else {
            setField("Mail_Include")(false);
            setField("Mail_IncludeClaimable")(false);
            setField("Mail_IncludeDue")(false);
            setField("Mail_IncludeDueClaimable")(false);
            setField("Mail_Attach")(false);
            setField("Mail_AttachClaimable")(false);
            setField("Mail_AttachDue")(false);
            setField("Mail_AttachDueClaimable")(false);
        }
    }, [setField]);

    useEffect(() => {
        if (ActionType.MailSend === data.Type && data.When !== 0) {
            setField("Mail_Include")(true);
            onChangeIncludeInvoiceList(true);
        }
        else {
            onChangeIncludeInvoiceList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.Type, data.When]
    )

    const onChangeInvoice = (value: InvoiceListModeOptions) => {
        if (data.Mail_IncludeDue || data.Mail_AttachDue) {
            setField("Mail_IncludeDue")(value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachDue")(value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else if (data.Mail_IncludeClaimable || data.Mail_AttachClaimable) {
            setField("Mail_IncludeClaimable")(value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachClaimable")(value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else if (data.Mail_IncludeDueClaimable || data.Mail_AttachDueClaimable) {
            setField("Mail_IncludeDueClaimable")(value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachDueClaimable")(value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else {
            setField("Mail_Include")(value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_Attach")(value === InvoiceListModeOptions.EmailAsAttachment);
        }
    }

    const onChangeClaimable = (value: InvoiceListTypeOptions) => {
        if (data.Mail_IncludeDue || data.Mail_IncludeClaimable || data.Mail_IncludeDueClaimable || data.Mail_Include) {
            setField("Mail_Include")(value === InvoiceListTypeOptions.AllPending || value === InvoiceListTypeOptions.OtherFilters);
            setField("Mail_IncludeClaimable")(value === InvoiceListTypeOptions.OnlyClaimable);
            setField("Mail_IncludeDue")(value === InvoiceListTypeOptions.OnlyDues);
            setField("Mail_IncludeDueClaimable")(value === InvoiceListTypeOptions.OnlyDuesClaimable);
        }
        else {
            setField("Mail_Attach")(value === InvoiceListTypeOptions.AllPending || value === InvoiceListTypeOptions.OtherFilters);
            setField("Mail_AttachClaimable")(value === InvoiceListTypeOptions.OnlyClaimable);
            setField("Mail_AttachDue")(value === InvoiceListTypeOptions.OnlyDues);
            setField("Mail_AttachDueClaimable")(value === InvoiceListTypeOptions.OnlyDuesClaimable);
        }
        setShowFilters(value === InvoiceListTypeOptions.OtherFilters);
    }

    const invoiceIncludeValue = () => {
        if (data.Mail_AttachDue || data.Mail_IncludeDue) {
            return InvoiceListTypeOptions.OnlyDues;
        }
        else if (data.Mail_AttachDueClaimable || data.Mail_IncludeDueClaimable) {
            return InvoiceListTypeOptions.OnlyDuesClaimable;
        }
        else if (data.Mail_AttachClaimable || data.Mail_IncludeClaimable) {
            return InvoiceListTypeOptions.OnlyClaimable;
        } else if (data.Mail_Include || data.Mail_Attach) {
            if (data.Mail_IOFilter) {
                return InvoiceListTypeOptions.OtherFilters;
            }
            else {
                return InvoiceListTypeOptions.AllPending;
            }
        }
    }

    const invoiceModeValue = () => {
        if (data.Mail_AttachDue || data.Mail_AttachClaimable || data.Mail_AttachDueClaimable || data.Mail_Attach) {
            return 1;
        }
        else {
            return 0
        }
    }

    const changeSort = (index: number, newVal: number) => {
        sortBy[index] = newVal;
        setField("Mail_OrderBy")(sortBy.join(";"));
    }

    const changeSortOrder = (index: number, newVal: boolean) => {
        sortByOrder[index] = newVal ? 1 : 0;
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    }

    const sortAdd = () => {
        const first = sortByList[1].value;
        sortBy.push(first);
        sortByOrder.push(0);
        setField("Mail_OrderBy")(sortBy.join(";"));
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    }

    const sortRemove = () => {
        sortByOrder.pop();
        sortBy.pop();
        setField("Mail_OrderBy")(sortBy.join(";"));
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    }

    return (
        <>
            <Checkbox onChange={setField("Mail_IncludeLink")} defaultValue={data.Mail_IncludeLink}
                text={
                    <>
                        {TranslationService.translate.AddLink}
                        <TooltipComponent placement="right" title={TranslationService.translate.AddLinkTip}>
                            <i className="fal fa-question-circle ms-2" />
                        </TooltipComponent>
                    </>
                } />


            <Checkbox text={
                data.Type === ActionType.ActionSendLetter ?
                    <>
                        {TranslationService.translate.IncludeInvoiceList}
                        <TooltipComponent placement="right" title={TranslationService.translate.AddInvoicesTooltip}>
                            <i className="fal fa-question-circle ms-2" />
                        </TooltipComponent>
                    </>
                    :
                    TranslationService.translate.IncludeInvoiceList
            } onChange={onChangeIncludeInvoiceList} value={Boolean(data.Mail_IncludeDue || data.Mail_IncludeClaimable || data.Mail_IncludeDueClaimable || data.Mail_Include || data.Mail_AttachDue || data.Mail_AttachClaimable || data.Mail_AttachDueClaimable || data.Mail_Attach)} />
            {(data.Mail_IncludeDue || data.Mail_IncludeClaimable || data.Mail_IncludeDueClaimable || data.Mail_Include || data.Mail_AttachDue || data.Mail_AttachClaimable || data.Mail_AttachDueClaimable || data.Mail_Attach) && <>
                <div className="row">
                    {ActionType.MailSend === data.Type &&
                        <Field title={TranslationService.translate.Invoices}>
                            <Dropdown items={getEnumArray(InvoiceListModeOptions)} onChange={onChangeInvoice} defaultValue={invoiceModeValue()} />
                        </Field>}
                    {((ActionType.MailSend === data.Type && !data.When) || ActionType.ActionSendLetter === data.Type) &&
                        <Field title={TranslationService.translate.Including}>
                            <Dropdown items={getEnumArray(InvoiceListTypeOptions)} onChange={onChangeClaimable} defaultValue={invoiceIncludeValue()} />
                        </Field>
                    }
                    <Field title={TranslationService.translate.ReportTemplate}>
                        <Dropdown items={reportTemplates} optionLabel={TranslationService.translate.Default} onChange={setField("Mail_ReportExportID")} defaultValue={data.Mail_ReportExportID} />
                    </Field>
                </div>
                <div className="row">
                    {CompanyService.getSetting("alloweditgroupmailby") && <Field title={TranslationService.translate.GroupBy}>
                        <Dropdown items={groupByList} onChange={setField("Mail_GroupBy")} defaultValue={data.Mail_GroupBy ?? parseInt(CompanyService.getCompanyAuth()!.GroupMailBy)} optionLabel={TranslationService.translate.None} />
                    </Field>}
                    {sortBy.map((x, i) =>
                        <Field key={i} title={i === 0 ? TranslationService.translate.SortBy : TranslationService.translate.ThenBy}>
                            <div className="row align-items-center">
                                <div className="col">
                                    <Dropdown items={sortByList} onChange={val => changeSort(i, val)} defaultValue={x} selectClasses={"d-inline"} />
                                </div>
                                <div className="col-auto">
                                    <Checkbox text={<i className="fa-regular fa-arrow-down-z-a"></i>} onChange={val => changeSortOrder(i, val)} defaultValue={sortByOrder[i] === 1} />
                                </div>
                            </div>
                        </Field>)}
                    <div className="col-4 mb-3">
                        <Label>
                            <div className="d-flex">
                                {sortBy.length < 3 &&
                                    <button type="button" onClick={sortAdd} className="btn btn-link text-start ps-0">{TranslationService.translate.AddOrder} <i className="fa-regular fa-plus" /></button>
                                }
                                {
                                    sortBy.length > 1 &&
                                    <button type="button" onClick={sortRemove} className="btn btn-link text-start pe-0">{TranslationService.translate.RemoveOrder} <i className="fa-regular fa-trash-can" /></button>
                                }
                            </div>
                        </Label>
                    </div>
                </div>
            </>}
            {showFilters &&
                <>
                    <div className="w-200px">
                        <button type="button" onClick={() => filtersContext.showFilters()} className="btn btn-link text-start ps-0">{TranslationService.translate.AddFilters}</button>
                    </div>
                    <AdvancedFilters page={FilterCollection.ActionInvoice} defaultValue={data.Mail_IOFilter} onFilterApply={applyFilter} showSaveFilter={false} />
                </>
            }
            {(data.Mail_IncludeDue || data.Mail_IncludeClaimable || data.Mail_IncludeDueClaimable || data.Mail_Include || data.Mail_AttachDue || data.Mail_AttachClaimable || data.Mail_AttachDueClaimable || data.Mail_Attach) &&
                <Checkbox text={TranslationService.translate.IncludeAttachment} onChange={setField("Mail_IncludeAttachment")} defaultValue={data.Mail_IncludeAttachment} />
            }
        </>);
}

export const AttachFile = ({ setField, data }: EditProps) =>
    <FileListEditor downloadFile={(fileId, fileName) => FileService.downloadAction(fileId, data.ActionID?.toString() ?? "", fileName)} onFilesChange={x => setField("files")(x.map(y => ({ fileName: y.name, id: y.id, response: "" })))} files={data.files?.map(x => ({ id: x.id, name: x.fileName }))} canEdit={true} />
