import React from "react";

const ReportsCardItem = ({ action, title = '', valueItem = '', percent }: React.PropsWithChildren<{
    title: string,
    valueItem?: number | string,
    percent?: string,
    action?: any
}>) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
            <div className="col">
                {title}
            </div>
            <div className="col">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div onClick={action} className="col" style={{ textAlign: 'end', cursor: action ? 'pointer' : 'default' }}>
                        {valueItem}
                    </div>
                    {percent &&
                        <div className="col-3" style={{ textAlign: 'end' }}>
                            {percent}
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default ReportsCardItem;