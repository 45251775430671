import { useContext, useMemo } from "react";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import { PickByValue } from "../../../../utils/Utils";
import ToastContext from "../../../shared/bootstrap/Toast";
import Dropdown from "../../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import Alarm from "./Components/Alarm";
import DateFormat from "./Components/DateFormat";
import Dictionaries from "./Components/Dictionaries";
import EditItem from "./Components/EditItem";
import ExcelOptions from "./Components/ExcelOptions";
import PrecedingProcesses from "./Components/PrecedingProcesses";
import SeparatorWithFixedColumns from "./Components/SeparatorWithFixedColumns";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";

const ImportTemplateEditClient = ({ data, reload, importsList = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse }) => {
    const { translate } = TranslationService;
    const requiredIds: string[] = [];
    const addFields = useMemo(() => {
        return CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Client);
    },[]);

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.ConfigureInvoicePaymentImport}  </h5>
        </div>)
    }

    const AdvancedOptions = ({ data, updateModel, model, setModel, requiredManager }: ImportTemplateComponentProps) => {
        const { showToast } = useContext(ToastContext);
        const updateNumberModel = <T extends keyof PickByValue<ImportTemplateListResponse.Item, number | undefined>,>(key: T) => (value?: number) => {
            setModel(x => ({ ...x, [key]: value }));
        }

        const customValidation = requiredManager.getValidation("validationCustomPersonIdentityID");
        customValidation.isValid = () => {
            if (!model.CustomPersonIdentityID || model.CustomPersonIdentityID === 22962) {
                return true;
            }
            const valid = model.Columns?.find(x => x.name === model.CustomPersonIdentityID?.toString()) !== undefined;
            if (!valid) {
                showToast(translate.TheFieldSelectedForUpdateMustHaveASpecifiedColumn);
            }
            return valid;
        }

        return (<div>
            <small className="text-muted mb-3">{translate.IfYouLeaveFormatBlank}</small>
            <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} />
            <CheckBoxEditor message={translate.UpdateExistingCustomerData} onChange={updateModel("UpdateIOs")} defaultValue={data?.UpdateIOs} />
            <UploadTemplate model={model} updateModel={updateModel} />
            <SeparatorWithFixedColumns updateModel={updateModel} data={data} model={model} />
            <ExcelOptions data={data} updateModel={updateModel} />
            <hr className="my-5" />
            <Dictionaries data={data} model={model} setModel={setModel} />
            <hr className="my-5" />
            <EditItem label={translate.UpdateDataUsingTheField} ><Dropdown onChange={updateNumberModel("CustomPersonIdentityID")} defaultValue={data?.CustomPersonIdentityID} items={addFields.map(x => ({ value: x.AdditionalDefinitionID, text: x.Name }))} /></EditItem>
            <hr className="my-5" />
            <PrecedingProcesses data={data} model={model} setModel={setModel} importsList={importsList} />
            <hr className="my-5" />
            <Alarm data={data} updateModel={updateModel} setModel={setModel} model={model} />
        </div>)
    }

    return (
        <ImportTemplateEditBase AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    )
}


export default ImportTemplateEditClient;