import { useState } from "react";

type SearchBarType = {
    onSearch: (value: string) => void
}

export const SearchBar = ({ onSearch }: SearchBarType) => {
    const [valueSearch, setValueSearch] = useState('')

    const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValueSearch(value)
        onSearch(value)
    }

    return (
        <div className="mb-3 row align-items-center show-child-on-hover">
            <div className="col">
                <input id='valueData' name='valueData' className="form-control" value={valueSearch} placeholder="Buscar configuración..." onChange={handleInputChange} />
            </div>
        </div>
    )
}
