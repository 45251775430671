
import { ScheduledListResponse, ScheduledRequest } from "../pages/reports/entities/ScheduledListResponse";
import RequestService from "./RequestService";

class ScheduledService {

    public static getScheduledTaskList(): Promise<ScheduledListResponse | Error> {
        return RequestService.post("/scheduledreport/list", {});
    }

    public static createScheduledSet(request: ScheduledRequest): Promise<ScheduledListResponse | Error> {
        return RequestService.post("/scheduledreport/set", request);
    }
}

export default ScheduledService;