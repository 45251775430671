import React from "react";
import { Link } from "react-router-dom";
import { DataType } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { formatCurrency, formatDateShort } from "../../../utils/FormatUtils";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import ClientDetailContext from "../ClientDetailContext";
import { InvoiceList } from "./entities/InvoiceListResponse";
import InvoiceDetail from "./InvoiceDetail";
import { TableHeaderWithFieldId } from "../../shared/Table";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import { CacheEntity, StorageService } from "../../../services/StorageService";

const InvoiceHistoryTableItem = (onCheckboxChange?: (invoiceId: number, checked: boolean) => void, headers?: TableHeaderWithFieldId[]) => function InvoiceTableItemImpl({ data }: { data: InvoiceList.Item }) {
    const specialCases = {
        "download": () => !data.HasFiles ? <td></td> : <td><i className="fas fa-fw fa-download pointer" onClick={(e) => { e.stopPropagation(); handleErrorWithToast(InvoiceService.downloadAllFilesFromInvoice(data.IOID)); }}></i></td>,
        "group": () => <td>{data.GroupID ? StorageService.getCache(CacheEntity.GroupName, data.GroupID) : ""}</td>,

    };
    return (
        <ClientDetailContext.Consumer>
            {({ invoiceIds, invoiceAll, reloadExtraData }) =>
                <TableContext.Consumer>
                    {({ reload }) => {
                        if (!headers) {
                            headers = InvoiceService.getTableHeaders();
                        }
                        return <tr key={data.IOID} style={{ cursor: "pointer" }} onClick={() => FloatingPanelService.showPanel(
                            {
                                children: <InvoiceDetail key={data.IOID} invoiceId={data.IOID} reload={() => { reload(); reloadExtraData(); }} />,
                                title: "",
                                width: 750,
                                height: 800,
                            }
                        )}>
                            {onCheckboxChange &&
                                <td onClick={event => event.stopPropagation()} width={40}>
                                    <input
                                        type="checkbox"
                                        className="checkbox-invoice"
                                        data-invoiceselected={data.IOID}
                                        defaultChecked={invoiceAll || invoiceIds.includes(data.IOID)}
                                        onChange={event => onCheckboxChange(data.IOID, (event.target as HTMLInputElement).checked)} />
                                </td>}
                            {headers.filter(x => x.fieldId !== "checkbox").map(header => <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialCases} />)}
                        </tr>;
                    }}
                </TableContext.Consumer>
            }
        </ClientDetailContext.Consumer>
    );
};

export const TableColumn = <T,>({
    data,
    header,
    specialCases = {}
}: {
    data: any,
    header: TableHeaderWithFieldId,
    specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) }
}) => {
    const { currentLanguage } = TranslationService;

    const getValue = () => {
        return 'implementar'
    };
    const value = getValue();
    switch (header.fieldId) {
        case "Name":
            return (<td className="is-link long-text">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.personId}`}>
                        {data.Person.Name}
                    </Link>
                </span>
            </td>)
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end"}>{formatCurrency(parseFloat(value), data.CurrencyID)}</td>;
        // case DataType.Number:
        //     return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
        default:
            return <td>{value}</td>;
    }
};

export default InvoiceHistoryTableItem;

