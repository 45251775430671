import { AdditionalDefinitionsListResponse } from "../../../../services/FieldConfigurationService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from '../../../../services/TranslationService';
import AdditionalDefinitionsEdit from "./AdditionalDefinitionsEdit";
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import FixedFieldEdit from "./FixedFieldEdit";
import CompanyService, { DataType } from "../../../../services/CompanyService";
import { LanguageCache } from "../../../../utils/PersistenceUtils";

const ClientFieldItem = (props: { data: (CompanyAuth.ConfigItem | AdditionalDefinitionsListResponse.Item), dragEvents: Object, reload: () => void }) => {    
    return ("AdditionalDefinitionID" in props.data) ? <AdditionalField {...props} data={props.data}/> : <FixedField {...props} data={props.data}/>;
}

const AdditionalField = ({ data, dragEvents, reload }: { data: AdditionalDefinitionsListResponse.Item, dragEvents: Object, reload: () => void }) => {
    const { translate } = TranslationService;
    const showAdditionalDefinitionsEditModal = (AdditionalDefinitions?: AdditionalDefinitionsListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={reload} fieldId={AdditionalDefinitions?.AdditionalDefinitionID} />,
            title: translate.EditCustomersFields,
            width: 600,
            height: 800,
            position: "center",
        })
    }
    return (<tr key={data.AdditionalDefinitionID} className="pointer configuration" onClick={() => showAdditionalDefinitionsEditModal(data)}
        {...dragEvents} data-id={data.AdditionalDefinitionID} >
        <td className="fst-italic">{data.Name}</td>
        <td>{TranslationService.getTranslation(data.TypeName.replaceAll(" ", ""))}</td>
        <td width={200}  style={{textAlign: "center"}}>{data.IsPK && <i className="fas fa-check fa-lg" />}</td>
        <td width={200} style={{textAlign: "center"}}>{data.IncludeInCustomerPortal && <i className="fas fa-check fa-lg" />}</td>
        <td width={200}><button className="edit"><i className="far fa-pen undefined"></i></button></td>
    </tr>)
}

const FixedField = ({ data, dragEvents, reload }: { data: CompanyAuth.ConfigItem, dragEvents: Object, reload: () => void }) => {
    const { translate } = TranslationService;
    const showAdditionalDefinitionsEditModal = (fixedField: CompanyAuth.ConfigItem) => {
        FloatingPanelService.showPanel({
            children: <FixedFieldEdit reload={reload} field={fixedField} data={data} />,
            title: translate.ClientFieldsEdit,
            width: 600,
            height: 380,
            position: "center",
        })
    }

    const typeNames = getDataTypeNames();
    const title = data.name ?? CompanyService.getEntityFieldTitle(data.Entity, data.field);
    const type = CompanyService.getEntityDefaultFieldType(data.Entity, data.field);
    return (<tr key={data.field} className="pointer configuration" onClick={() => showAdditionalDefinitionsEditModal(data)}
        {...dragEvents} data-id={data.field} draggable={false} >
        <td className="fw-bold">{title}</td>
        <td>{typeNames.find(x => x.value === type)?.text ?? ""}</td>
        <td width={200}  style={{textAlign: "center"}}>{false && <i className="fas fa-check fa-lg" />}</td>
        <td width={200} style={{textAlign: "center"}}>{data.IncludeInCustomerPortal && <i className="fas fa-check fa-lg" />}</td>
        <td width={200}><button className="edit"><i className="far fa-pen"></i></button></td>
    </tr>)
}

const dataTypesCache = new LanguageCache(() => [
    { value: DataType.Text, text: TranslationService.translate.Freetext },
    { value: DataType.List, text: TranslationService.translate.List },
    { value: DataType.Date, text: TranslationService.translate.Date },
    { value: DataType.Number, text: TranslationService.translate.Number },
    { value: DataType.Currency, text: TranslationService.translate.Currency },
    { value: DataType.Phone, text: TranslationService.translate.Phone },
    { value: DataType.Link, text: TranslationService.translate.Link },
]);

export const getDataTypeNames = () => dataTypesCache.get();

export default ClientFieldItem;