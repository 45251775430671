import { CompanyAuth } from "../entities/login/CompanyAuth";
import { ClienMapRequest, ClientDebtRecoveryAnalysisRequest, ClientReportPaymentAnalysis, ReportClientMapResponse, ReportCollectionType, ReportPaymentAnalysisResponseType } from "../pages/client/entities/ClientDebtRecoveryAnalysisRequest";
import { ClientDeleteListResponse } from "../pages/client/entities/ClientDeleteListResponse";
import { ClientEdit } from "../pages/client/entities/ClientEdit";
import { ClientGetAgingResponse } from "../pages/client/entities/ClientGetAgingResponse";
import { Client, ClientGetResponse } from "../pages/client/entities/ClientGetResponse";
import { ClientListRequest } from "../pages/client/entities/ClientListRequest";
import { ClientListResponse } from "../pages/client/entities/ClientListResponse";
import { ClientSearchResponse } from "../pages/client/entities/ClientSearchResponse";
import { ClientSetResponse } from "../pages/client/entities/ClientSetResponse";
import { ClientHistoryListRequest } from "../pages/reports/entities/ClientHistoryListRequest";
import { ClientHistoryListResponse } from "../pages/reports/entities/ClientHistoryListResponse";
import { CustomerAnalysisListRequest } from "../pages/reports/entities/CustomerAnalysisListRequest";
import { CustomerAnalysisListResponse } from "../pages/reports/entities/CustomerAnalysisListResponse";
import { EntityField } from "../pages/shared/entities/EntityField";
import { TableHeader, TableHeaderWithFieldId } from "../pages/shared/Table";
import CompanyService, { DataType, Entities } from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class ClientService {
    public static getDebtRecoveryAnalysis(request: ClientDebtRecoveryAnalysisRequest): Promise<ReportCollectionType | Error> {
        return RequestService.post("/client/debtrecoveryanalysis", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getClientMapReport(request: ClienMapRequest): Promise<ReportClientMapResponse | Error> {
        return RequestService.post("/client/map", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getPaymentsAnalysis(request: ClientReportPaymentAnalysis): Promise<ReportPaymentAnalysisResponseType | Error> {
        return RequestService.post("/transaction/paymentsummary", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getList(request: ClientListRequest): Promise<ClientListResponse | Error> {
        return RequestService.post("/client/list", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static search(query: string): Promise<ClientSearchResponse | Error> {
        const request = {
            query,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
        };
        return RequestService.post("/client/search", request);
    }
    public static get(id: number): Promise<ClientGetResponse | Error> {
        return RequestService.post("/client/get", { id, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getExtraValues(id: number): Promise<{ averageAging: number, averagePeriod: number, averageAgingPerson: number, averagePeriodPerson: number, } | Error> {
        return RequestService.post("/client/getextravalues", { id });
    }

    public static getEmailsAutocomplete(personId: number, query: string): Promise<{ query: string, suggestions: { value: string; data: string; }[] } | Error> {
        if (query.length < 2) {
            return Promise.resolve({ query, suggestions: [] });
        }
        return RequestService.post("/client/getemails", { personId, query });
    }

    public static setClient(client: ClientEdit, additionaldefinitions: CompanyAuth.AdditionalDefinition[]): Promise<ClientSetResponse | Error> {
        const request = {
            response: "Ok",
            item: { ...client, personName: client.Name },
            additionaldefinitions,
        };
        return RequestService.post("/client/set", request);
    }

    public static delete(id: number): Promise<{ response: string } | Error> {
        return RequestService.post("/client/delete", { id });
    }

    public static deleteList(text: string | null, segmentId: number | null, filter: string | null): Promise<{ response: ClientDeleteListResponse } | Error> {
        if (text === null) {
            text = "";
        }
        if (filter === null) {
            filter = "";
        }
        if (segmentId === null) {
            segmentId = 0;
        }
        return RequestService.post("/client/deleteList", { text, segmentId, filter });
    }

    public static export(request: ClientListRequest & { reid: number | null }, count: number) {
        return ExportService.requestExport("/client/export", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, count, "clients.xlsx");
    }

    public static getAging(id: number): Promise<ClientGetAgingResponse[] | Error> {
        return RequestService.post("/client/getaging", { id });
    }

    public static getLastImport(): Promise<{ last: string } | Error> {
        return RequestService.post("/import/getlast", {});
    }

    private static readonly clientHeadersIds = ["Name", "22962" /*Cuit*/, "22963" /*Categoria*/, "Address", "Phone", "22969" /*Ciudad*/, "22968" /*Tipo Cliente*/, "94806" /*Texto60*/, "113367" /*asdasdasd*/];
    public static getClientHeadersIds() {
        return ClientService.clientHeadersIds;
    }

    public static getClientHeaders() {
        const translate = TranslationService.translate;
        const fixedFields: TableHeaderWithFieldId[] = [
            {
                ...new TableHeader(
                    "client",
                    translate.Client,
                    false,
                    true,
                    "long-text"
                ),
                fieldId: "Name",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "address",
                    translate.Address,
                    false
                ),
                fieldId: "Address",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "phone",
                    translate.Phone,
                    false
                ),
                fieldId: "Phone",
                type: DataType.Text,
            },
        ];
        const additionalFields = CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Client && x.IncludeInList)
            .map(x => ({
                ...new TableHeaderWithFieldId(
                    "ioadditional." + (x.Type === DataType.List ? "list" : "text") + "field" + x.Field,
                    x.Name,
                    [DataType.Currency, DataType.Number].includes(x.Type),
                    true,
                    x.AdditionalDefinitionID.toString(),
                    x.Type
                ),
            }));
        const fields = [...fixedFields, ...additionalFields];

        ClientService.clientHeadersIds.forEach((fieldId, index) => {
            const oldIndex = fields.findIndex(x => x?.fieldId === fieldId);
            if (oldIndex === -1) {
                return;
            }
            const tmp = fields[oldIndex];
            fields[oldIndex] = fields[index];
            fields[index] = tmp;
        });
        return fields.filter(x => x !== undefined);
    }

    public static getCustomerAnalysisHeaders() {
        const translate = TranslationService.translate;
        const additionalDefinitionsIds = [22962 /*Cuit*/, 22963 /*Categoria*/, 22969 /*Ciudad*/, 22968 /*Tipo Cliente*/, 94806 /*Texto60*/, 113367 /*asdasdasd*/, 120332 /*Razón Social*/];
        const fixedFields: TableHeaderWithFieldId[] = [
            {
                ...new TableHeader(
                    "client",
                    translate.Client,
                    false,
                    true,
                    "long-text"
                ),
                fieldId: "Name",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "address",
                    translate.Address,
                    false,
                    false
                ),
                fieldId: "Address",
                type: DataType.Text,
            },
            {
                ...new TableHeader(
                    "phone",
                    translate.Phone,
                    false,
                    false
                ),
                fieldId: "Phone",
                type: DataType.Text,
            },
        ];
        const additionalFields = CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Client && additionalDefinitionsIds.includes(x.AdditionalDefinitionID))
            .map(x => ({
                ...new TableHeaderWithFieldId(
                    "ioadditional." + (x.Type === DataType.List ? "list" : "text") + "field" + x.Field,
                    x.Name,
                    [DataType.Currency, DataType.Number].includes(x.Type),
                    false,
                    x.AdditionalDefinitionID.toString(),
                    x.Type
                ),
            }));
        const fields = [...fixedFields, ...additionalFields];

        ClientService.clientHeadersIds.forEach((fieldId, index) => {
            const oldIndex = fields.findIndex(x => x?.fieldId === fieldId);
            if (oldIndex === -1) {
                return;
            }
            const tmp = fields[oldIndex];
            fields[oldIndex] = fields[index];
            fields[index] = tmp;
        });
        return fields.filter(x => x !== undefined);
    }

    public static getClientsHistoryList(request: ClientHistoryListRequest): Promise<ClientHistoryListResponse | Error> {
        return RequestService.post("/client/historyclientlist", {
            ...request,
            additionaldefinitions: CompanyService.getAdditionalDefinitions()
        });
    }

    public static getCustomerAnalysisList(request: CustomerAnalysisListRequest): Promise<CustomerAnalysisListResponse | Error> {
        return RequestService.post("/client/analysisreport", {
            ...request,
            additionaldefinitions: CompanyService.getAdditionalDefinitions()
        });
    }

    public static exportCustomerAnalysisList(request: ExportClientAnalysisReportRequest) {
        return ExportService.requestExport("/client/analysisreport",
            { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() },
            request.expectedRows, "clients_analysis_report.xlsx"
        );
    }
}

export type ExportClientAnalysisReportRequest = {
    "expectedRows": number,
    "id"?: number,
    "descending"?: number
    "filter"?: string,
    "sortColumn"?: string,
}

export enum ClientFields {
    Name = -21,
    Contact = -22,
    Email = -23,
    Phone = -24,
    Address = -25,
    Comments = -26,
    followupMail = -27,
    customerSite = -28,
    Segments = -29,
    CreditLimit = -30,
    invoiceCount = -31,
    amount = -32,
    dueAmount = -33,
    noDueAmount = -34,
    duePeriod = -36,
}

const ClientFieldTitleKey = {
    [ClientFields.Name]: "Name",
    [ClientFields.Contact]: "Contact",
    [ClientFields.Email]: "Email",
    [ClientFields.Phone]: "Phone",
    [ClientFields.Address]: "Address",
    [ClientFields.Comments]: "Comments",
    [ClientFields.followupMail]: "FollowUpMail",
    //[ClientFields.site]: 'CustomerSite',
    [ClientFields.Segments]: "Segments",
    [ClientFields.CreditLimit]: "CreditLimit",
    [ClientFields.invoiceCount]: "invoiceCount",
    [ClientFields.amount]: "amount",
    [ClientFields.dueAmount]: "dueAmount",
    [ClientFields.noDueAmount]: "noDueAmount",
    [ClientFields.customerSite]: "CustomerSite",
    [ClientFields.duePeriod]: "duePeriod",
};

export const getClientFieldTitle = (field: ClientFields) => TranslationService.getTranslation(ClientFieldTitleKey[field]);
export const getClientFieldIsDefault = (id: number) => Object.values(ClientFields).includes(id);
export const getClientDefaultFieldType = (id: number) => {
    switch (id) {
        //case ClientFields.Group:
        //    return DataType.Group;
        case ClientFields.Phone:
            return DataType.Phone;
        case ClientFields.followupMail:
        case ClientFields.invoiceCount:
            return DataType.Number;
        case ClientFields.CreditLimit:
        case ClientFields.amount:
        case ClientFields.dueAmount:
        case ClientFields.noDueAmount:
            return DataType.Currency;
        case ClientFields.customerSite:
            return DataType.Link;
        case ClientFields.Name:
        case ClientFields.Contact:
        case ClientFields.Email:
        case ClientFields.Address:
        case ClientFields.Comments:
        case ClientFields.Segments:
        case ClientFields.duePeriod:
        default:
            return DataType.Text;
    }
};

export const getClientDefaultFieldProperty = (id: number) => ClientFields[id];

export const getClientFieldDisplayValue = (field: EntityField, client: Client) => {
    if (getClientFieldIsDefault(field.id)) {
        const clientProp = getClientDefaultFieldProperty(field.id);
        return client[clientProp as keyof Client];
    }
    const add = client.additionals.find((additional) => (additional.id === field.id) || (additional["Id" as keyof typeof additional] === field.id));
    const value = add?.value ?? (add ? add["Value" as keyof typeof add] : undefined);
    if (!value) { return undefined; }
    switch (field.type) {
        case DataType.List:
            return field.listItems.find(x => x.id.toString() === value)?.value;
        case DataType.Date:
            const valueDate = new Date(value);
            return new Date(valueDate.getTimezoneOffset() * 60 * 1000 + valueDate.getTime()).toJSON();
        case DataType.Text:
        default:
            return value;
    }
};

export default ClientService;