import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import ActivityService from "../../../services/ActivityService";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { ActivityListResponse } from "./entities/AcitivityListResponse";

export class ActivityTableContextValues extends TableContextValues<ActivityListResponse, { extraFilters: string[] }> {
    constructor(public quickFilter: string) {
        super();
    }
}
let tab = "0";
let currentRequest = "";
const ActivityTableProvider = (props: PropsWithChildren<{ personid?: string, groupId?: number, pageSize?: number }>) => {
    const [response, setResponse] = useState<ActivityListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<{ extraFilters: string[] }>({ extraFilters: [] });
    const [quickfilter, setQuickfilter] = useState<string>("0");

    const getFilters = useCallback(() => {
        const request = {
            personid: props.personid,
            page: currentPage.toString(),
            quickfilter,
            filter: filters.extraFilters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&"),
            groupid: props.groupId,
            pagesize: props.pageSize?.toString()
        };
        return request;
    }, [currentPage, filters.extraFilters, props.groupId, props.pageSize, props.personid, quickfilter]);

    const requestData = async (skipLoading?: boolean) => {
        const ourRequest = v4();
        currentRequest = ourRequest;
        if (!skipLoading) {
            setLoading(true);
        }
        tab = quickfilter;
        const request = getFilters();
        const result = await ActivityService.getList(request);
        if (currentRequest !== ourRequest) {
            return;
        }
        if (tab !== quickfilter) {
            return;
        }
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);

        setResponse(result);
        _setCurrentPage(result.page);
        setPageCount(totalPages);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [getFilters, quickfilter]);
    useEffect(() => {
        requestDataCallback();
    }, [currentPage, requestDataCallback]);

    const setCurrentPage = (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            _setCurrentPage(page);
        }
    }

    const applySearch = (selected: string) => {
        setQuickfilter(selected);
        setCurrentPage(0);
    }

    const applyFilters = (filters: { extraFilters: string[] }) => {
        console.log({ filters })
        setFilters(filters);
    }

    const value = new ActivityTableContextValues(quickfilter);
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.currentPage = currentPage;
    value.setCurrentPage = setCurrentPage;
    value.pageCount = pageCount;
    value.reload = requestData;
    value.applySearch = applySearch;
    value.applyFilters = applyFilters;

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default ActivityTableProvider;

