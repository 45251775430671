import { useCallback, useEffect, useState } from "react";
import { Sort, SortDirection } from "../shared/entities/Sort";
import { v4 } from "uuid";
import SegmentService from "../../services/SegmentService";
import { SegmentListResponse } from "../../entities/segment/SegmentListResponse";
import TableContext, { TableContextValues } from "../task/TableContext";

let reqId = v4();

export class SegmentTableContextValues extends TableContextValues<SegmentListResponse, any> {
    constructor(
        public max: number,
        public toggleHidden: () => void,
        public showHidden: boolean,) {
        super();
    }
}

const SegmentTableProvider = (props: React.PropsWithChildren<{}>) => {
    const [response, setResponse] = useState<SegmentListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [sort, _setSort] = useState<Sort>({ sortColumn: 'dueDate', sortDirection: SortDirection.Descending });

    const requestData = async () => {
        setLoading(true);
        reqId = v4();
        const ourRequest = reqId;
        const result = await SegmentService.getList();
        if (ourRequest !== reqId) {
            return;
        }
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);
    useEffect(() => {
        requestDataCallback();
    }, [sort, requestDataCallback]);

    const setSortColumn = (key: string) => {
        _setSort({
            sortColumn: key,
            sortDirection: sort.sortColumn === key ? (sort.sortDirection === 1 ? 0 : 1) : 0
        })
    }

    const calculateMax = (response: SegmentListResponse | undefined) => {
        if (response === undefined) return 0;
        return Math.max(...response.list.flatMap(x => x.items).map(x => x.amount ?? 0));
    }

    const value = new SegmentTableContextValues(
        calculateMax(response),
        () => setShowHidden(x => !x),
        showHidden,
    );
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.reload = requestData;
    value.sort = sort;
    value.setSortColumn = setSortColumn;

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default SegmentTableProvider;

