import { useContext, useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import ToastContext from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import FieldEdit from "./InvoiceMassiveChangesFieldEdit";
import ModalService from "../../shared/bootstrap/Modal";

export const InvoiceChangeStatusForm = ({ onSubmit, personId }: { onSubmit: (statusEdit: InvoiceEditStatus) => Promise<void>, personId: string }) => {
    const [status, setStatus] = useState(new InvoiceEditStatus(CompanyService.getUserid()));
    const [isSaving, setIsSaving] = useState(false);
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const statusList = CompanyService.getIoStatus();

    const fields = CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
        .filter(x => x.IOStatusDependencies.filter(y => y.IOStatusID === status.statusId).length > 0)
        .map(x => new EntityField(x))
        .sort(sortEntityFields(CompanyService.getIoSortedFields().split(",")));

    const requiredFields = fields.map(field => ({
        id: field.id, required: CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
            .find(x => x.AdditionalDefinitionID === field.id)
            ?.IOStatusDependencies.find(y => y.IOStatusID === status.statusId)
            ?.Mandatory
    }))

    const defValues = fields
        .filter(x => x.type === DataType.Date && !status.additionals.map(x => x.Id).includes(x.id))
        .map(x => ({ Id: x.id, Value: new Date().toJSON() }));

    const fieldsEvaluate = status.additionals.map(f => f.Id)
    const requiredFieldsIds = requiredFields.map(f => f.id)
    const validateField = requiredFieldsIds.find(fe => !fieldsEvaluate.includes(fe))

    // const statusFieldChange = (field: EntityField) => {
    //     const onChange = (value: string | undefined) => {
    //         if (value && field.type === DataType.Date) {
    //             value = new Date(value).toJSON();
    //         }
    //         const newAdditionals = [...status.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }];
    //         setStatus({ ...status, additionals: newAdditionals });
    //     };
    // const required = CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
    //     .find(x => x.AdditionalDefinitionID === field.id)
    //     ?.IOStatusDependencies.find(y => y.IOStatusID === status.statusId)
    //     ?.Mandatory;
    //     if (required) {
    //         return Cast<typeof onChange>(requiredManager.makeRequired(onChange));
    //     }
    //     return onChange;
    // };

    const onChange = (value: string | undefined, field?: EntityField) => {
        if (value && field?.type === DataType.Date) {
            value = new Date(value).toJSON();
        }
        const newAdditionals = field ? [...status.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }] : [];
        setStatus({ ...status, additionals: newAdditionals });
    };

    const statusChange = (id?: number) => {
        setStatus(new InvoiceEditStatus(status.userId, id));
    };

    const CanSubmit = () => {
        if (validateField) {
            showToast((translate.Select + ' ' + fields.find(el => el.id === validateField)?.title) || translate.Field, undefined, "warning");
            return false;
        }
        return true;
    };

    const saveStatus = async () => {
        setIsSaving(true);
        const canSubmit = await CanSubmit();
        if (isSaving || !canSubmit) {
            setIsSaving(false);
            return;
        } else {
            await onSubmit(status);
            setIsSaving(false);
        }
    };

    if (defValues.length > 0) {
        setStatus(x => ({ ...x, additionals: [...x.additionals, ...defValues] }));
    }

    return (
        <>
            <div className="modal-body">
                <div className="d-flex input-column mb-3">
                    <label className="form-label">
                        {translate.Status}:
                    </label>
                    <Dropdown optionLabel={translate.Select} onChange={statusChange} items={statusList.map(x => ({ value: x.IOStatusID, text: x.Value }))} />
                </div>

                {fields.map(field =>
                    <FieldEdit key={field.id} field={field} onChange={value => onChange(value, field)} status={status} personId={personId} />
                )}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={ModalService.hideModal}>{translate.Close}</button>
                <button className='btn btn-primary' onClick={saveStatus} disabled={status.statusId === undefined}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </div>
        </>
    );
};