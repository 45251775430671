import { ChangeEvent, useContext, useMemo } from "react";
import { FilterCollection } from "../../../services/FilterService";
import InvoiceService from "../../../services/InvoiceService";
import AdvancedFilters from "../../shared/components/AdvancedFilters";
import Table, { TableHeaderWithFieldId } from "../../shared/Table";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableItem from "./InvoiceTableItem";
import InvoiceTableProvider from "./InvoiceTableProvider";
import InvoiceTableTotal from "./InvoiceTableTotal";
import InvoiceMassiveChanges from "./InvoiceMassiveChanges";
import ClientDetailContext from "../ClientDetailContext";
import TableContext from "../../task/TableContext";
import CompanyService, { DataType } from "../../../services/CompanyService";
import { AdvancedFiltersProvider } from "../../shared/components/AdvancedFiltersContext";

const InvoiceList = ({ client, groupId, setReloads = undefined, ioTreeFilters = "" }: { ioTreeFilters: string, client: { PersonId: number, Name: string }, groupId?: number, setReloads?: { invoice: () => void } }) => {
    const { invoiceIds, invoiceAll, setInvoiceAll, setInvoiceIds } = useContext(ClientDetailContext);

    const getPagedInvoiceIds = () => {
        const invoiceIdList: number[] = [];
        const checkboxes = document.getElementsByClassName("checkbox-invoice");
        for (let index = 0; index < checkboxes.length; index++) {
            const element = checkboxes[index] as HTMLInputElement;
            const invoiceId = element.dataset["invoiceselected"];
            if (invoiceId) {
                invoiceIdList.push(parseInt(invoiceId));
            }
        }
        return invoiceIdList;
    };

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        let newIds: number[] = [];
        if (checked) {
            newIds = [...invoiceIds, invoiceId];
        }
        else {
            newIds = invoiceIds.filter(x => x !== invoiceId);
        }
        setInvoiceIds(newIds);
        setInvoiceAll(false);
    };

    const tableHeaders = useMemo(() => {
        const toggleAllCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
            const checked = (event.target as HTMLInputElement).checked;
            setInvoiceIds(checked ? getPagedInvoiceIds() : []);
            setInvoiceAll(checked);
        };
        const headers = InvoiceService.getTableHeaders(true, true);
        if (CompanyService.canDo("editio")) {
            headers.unshift(new TableHeaderWithFieldId("Checkbox", () => <input type="checkbox" onChange={toggleAllCheckboxes} checked={invoiceAll} />, false, false, "checkbox", DataType.IOStatus, "w-30px"));
        }
        headers.push(new TableHeaderWithFieldId("download", "", false, false, "download", DataType.Download));
        return headers;
    }, [invoiceAll, setInvoiceAll, setInvoiceIds]);

    return (
        <InvoiceTableProvider clientId={client.PersonId} groupId={groupId}>
            <TableContext.Consumer>
                {({ reload }) => { if (setReloads) { setReloads.invoice = reload; } return (<></>); }}
            </TableContext.Consumer>
            <AdvancedFiltersProvider>
                <InvoiceTableHeader client={client} ioTreeFilters={ioTreeFilters} reload={() => setReloads ? Object.values(setReloads).map(x => x()) : undefined} />
                <AdvancedFilters page={FilterCollection.Invoice} />
            </AdvancedFiltersProvider>
            <InvoiceMassiveChanges personId={client.PersonId} />
            <Table headers={tableHeaders} item={InvoiceTableItem(CompanyService.canDo("editio") ? toggleOneCheckbox : undefined, tableHeaders)} stickyHeader={true}>
                <InvoiceTableTotal tableHeaders={tableHeaders} />
            </Table>
        </InvoiceTableProvider>
    );
};

export default InvoiceList;