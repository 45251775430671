import { PropsWithChildren, useMemo } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { ImportPreviewResponse } from "../../../entities/import/ImportPreview";
import React from "react";
import { v4 } from "uuid";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { formatCurrency, formatDateDigits } from "../../../utils/FormatUtils";
import { BadlySpelled, OptionalMap } from "../../../utils/Utils";
import { ImportTemplateListResponse } from "../entities/ImportTemplateListResponse";
import ImportTemplateService from "../../../services/ImportTemplateService";

const ImportStatus = ({ preview, submit, template }: { preview: ImportPreviewResponse, submit: () => void, template: ImportTemplateListResponse.Item }) => {
	const canImport = useMemo(() =>
		!(	preview.repateatedcancel ||
			preview.validdates ||
			preview.alerterror ||
			preview.alertblank)
		, [preview.alertblank, preview.alerterror, preview.repateatedcancel, preview.validdates]);
	const templateFields = useMemo(() => ImportTemplateService.getTemplateFields(template.Entity), [template.Entity]);
	const replaceNum = (translation: string, num: number) => translation.replace("{num}", num.toString())
		.replaceAll("(s)", num > 1 ? "s" : "")
		.replace("(n)", num > 1 ? "n" : "")
	return (
		<section className="importContainer py-3">
			<h2 className="h2 mt-3 mb-0">{TranslationService.translate.ImportResult}:</h2>
			<div className="accordion" id="accordionExample">
				{Boolean(preview.newcount) && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeCreated, preview.newcount)}>
					<StatusTable items={preview.newios} includeAdditionals={true} includeIndex={true} />
				</AccordionItem>}
				{Boolean(preview.updatecount) && <AccordionItem title={replaceNum(TranslationService.translate.DataForInvoicesWillBeModified, preview.updatecount)}>
					<StatusTable items={preview.updates} includeAdditionals={false} includeIndex={false} />
				</AccordionItem>}
				{Boolean(preview.partialcount) && <AccordionItem title={replaceNum(TranslationService.translate.PartialPaymentsWillBeCreatedForInvoices, preview.partialcount)}>
					<StatusTable items={preview.partials} includeAdditionals={false} includeIndex={false} />
				</AccordionItem>}
				{Boolean(preview.recoveredcount) && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeMarkedAsNotPaid, preview.recoveredcount)}>
					<StatusTable items={BadlySpelled(preview, "recoveredios", "recoveredos")} includeAdditionals={false} includeIndex={false} />
				</AccordionItem>}
				{Boolean(preview.fullcount) && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeMarkedAsFullPaid, preview.fullcount)}>
					<StatusTable items={preview.fullios} includeAdditionals={false} includeIndex={false} />
				</AccordionItem>}
				{Boolean(preview.personcount) && <AccordionItem title={replaceNum(TranslationService.translate.CustomersWillBeCreated, preview.personcount)}>
					<table className="table">
						<thead>
							<tr>
								<th className="white-space-nowrap">{TranslationService.translate.Client}</th>
							</tr>
						</thead>
						<tbody>
							{preview.persons.map(x => <tr><td>{x}</td></tr>)}
						</tbody>
					</table>
				</AccordionItem>}
				{Boolean(preview.errorcount) && <AccordionItem title={replaceNum(TranslationService.translate.RowsWillBeOmittedForContainingInconsistentInformation, preview.errorcount)}>
					<div className="table-container">
						<table className="table">
							<thead>
								<tr>
									<th></th>
									{preview.errors[0].values.slice(0,-1).map((x,i) => <th key={i} className="white-space-nowrap text-center">
										{templateFields.find(x => x.fieldId === template.Columns?.find(x => parseInt(x.ix) === (i+1))?.name)?.label()}
									</th>)}
								</tr>
								<tr>
									<th></th>
									{preview.errors[0].values.slice(0,-1).map((_, index) => {
											return <th key={index} className="white-space-nowrap mw-100 text-center">
												{indexToExcelLetter(index)}
											</th>;
										})}
								</tr>
							</thead>
							<tbody>
								{preview.errors.map(x => <tr>
									{x.values.map((y,i) => <td className={"ellipsis-oneline text-center w-200px" + (x.columns.includes(i.toString()) ? " bg-danger-light" : "")}>{y}</td>)}
								</tr>)}
							</tbody>
						</table>
					</div>
				</AccordionItem>}
			</div>
			{false && <div className="alert alert-primary" role="alert">
				<i className="me-2 fa-regular fa-circle-info"></i>{TranslationService.translate.OnceThisFileIsImporteTheTotalDebtWillBe.replace("{companyName}", CompanyService.getCompanyName())} <strong>U$D 129.100.345.396</strong>
			</div>}
			{canImport && <div className="actions">
				<button className="btn btn-primary" onClick={submit}>
					{TranslationService.translate.FinishImport}
				</button>
			</div>}
		</section>
	);
}

const AccordionItem = React.memo((props: PropsWithChildren<{ title: string }>) => {
	const idHeader = "accordion-" + v4();
	const idContent = "accordion-" + v4();
	return (<div className="accordion-item">
		<h2 className="accordion-header" id={idHeader}>
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + idContent} aria-expanded="false" aria-controls={idContent}>
				{props.title}
			</button>
		</h2>
		<div id={idContent} className="accordion-collapse collapse" aria-labelledby={idHeader} data-bs-parent="#accordionExample">
			<div className="accordion-body">
				{props.children}
			</div>
		</div>
	</div>);
});

const StatusTable = ({ items, includeIndex, includeAdditionals }: { includeIndex: boolean, includeAdditionals: boolean, items: ImportPreviewResponse.Newio[] }) => {
	return (
		<div className="table-container">
			<table className="table">
				<thead>
					<tr>
						{includeIndex && <th></th>}
						<th className="white-space-nowrap">{TranslationService.translate.Client}</th>
						<th className="white-space-nowrap">{TranslationService.translate.IssueDate}</th>
						<th className="white-space-nowrap">{TranslationService.translate.AmountButPendingSometimes}</th>
						<th className="white-space-nowrap">{TranslationService.translate.Currency}</th>
						<th className="white-space-nowrap">{TranslationService.translate.DueDate}</th>
						<th className="white-space-nowrap">{TranslationService.translate.InvoiceNumber}</th>
						<th className="white-space-nowrap">{TranslationService.translate.Comments}</th>
						<th className="white-space-nowrap">{CompanyService.getGroupName()}</th>
						{includeAdditionals && CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice).map(x => <th className="white-space-nowrap">{x.Name}</th>)}
					</tr>
				</thead>
				<tbody>
					{items.map(x => <tr className="pointer">
						{includeIndex && <td className="ellipsis-oneline w-200px">{x.Index}</td>}
						<td className="ellipsis-oneline w-200px">{x.PersonKey}</td>
						<td className="ellipsis-oneline w-200px">{formatDateDigits(new Date(x.emittedDate))}</td>
						<td className="ellipsis-oneline w-200px">{formatCurrency(x.amount, x.CurrencyID)}</td>
						<td className="ellipsis-oneline w-200px">{x.CurrencySymbol}</td>
						<td className="ellipsis-oneline w-200px">{OptionalMap(x.dueDate, date => formatDateDigits(new Date(date)))}</td>
						<td className="ellipsis-oneline w-200px">{x.referenceNumber}</td>
						<td className="ellipsis-oneline w-200px">{x.description}</td>
						<td className="ellipsis-oneline w-200px">{x.group}</td>
						{includeAdditionals && x.values.slice(20).map(val => <td className="ellipsis-oneline w-200px">{val}</td>)}
					</tr>)}
				</tbody>
			</table>
		</div>);
}

function indexToExcelLetter(index: number) {
	return OptionalMap(index.toString(26).split(""), split => split.map((num, i) => num.charCodeAt(0) - split.length + 1 + i).map(x => ((x < 58) ? 17 : 10) + x).map((y, i) => String.fromCharCode(y).toUpperCase()).join(""))!;
}

export default ImportStatus;