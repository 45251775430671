import TabPosition from "../shared/TabPosition";
import React, { useCallback, useEffect, useState } from 'react';
import { TranslationService } from '../../services/TranslationService';
import DashboardPanel from "./DashboardPanel/DashboardPanel";
import DashboardPanelService from "../../services/DashboardPanelService";
import ErrorMessage from "../shared/components/Error";
import CompanyService from "../../services/CompanyService";
import { DashboardPanelEntity } from "../../entities/company/DashboardPanel/DashboardPanelEntity";
import { useDatasource } from "../../utils/RequestUtils";
import ClientService from "../../services/ClientService";
import { formatDateShort, formatTimeShort } from "../../utils/FormatUtils";
import { restTimezone } from "../../utils/ParseUtils";
import { OptionalMap } from "../../utils/Utils";

const Company: React.FC = () => {
	const { translate } = TranslationService;
	const [error, setError] = useState(false);
	const [dashboardPanel, setDashboardPanel] = useState<DashboardPanelEntity>();
    const lastImport = useDatasource(ClientService.getLastImport,[]).value;

	const requestData = async () => {
		const result = await DashboardPanelService.get(CompanyService.getDashboardPanelId() ?? undefined);
		if (result instanceof Error) {
			setError(true);
			return;
		}
		setDashboardPanel(result.item);
		setError(false);
	}

	const requestDataCallback = useCallback(requestData, []);

	useEffect(() => {
		requestDataCallback();
	}, [requestDataCallback]);

	if (error) {
		return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
	}

	return (
		<TabPosition module="company">
			<section className="dashboard">
				<div className="container-fluid pb-0">
					{CompanyService.getCompanyAuthorization().includes("enableshowlastimport") && <>
                    {lastImport && <p>
                        {TranslationService.translate.LastInvoiceImport}
						{": "}
                        {OptionalMap(restTimezone(new Date(lastImport.last)), x => formatDateShort(x) +" "+ formatTimeShort(x))}
                    </p>}
					</>}
				</div>
			</section>
			<DashboardPanel dashboardPanel={dashboardPanel}></DashboardPanel>
		</TabPosition>
	)
}

export default Company;