import { SegmentGetResponse } from "../entities/segment/SegmentGetResponse";
import { SegmentListResponse } from "../entities/segment/SegmentListResponse";
import { AdditionalDefinitionItems } from "../pages/segment/component/Criterion";
import { SegmentModel } from "../pages/segment/EditSegment";
import { CriterionType, Operator } from "../pages/segment/providers/CriteriaContext";
import CompanyService from "./CompanyService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class SegmentService {
    public static get(id: number): Promise<Error | SegmentGetResponse> {
        return RequestService.post("/segment/get", { id });
    }
    public static getList(): Promise<Error | SegmentListResponse> {
        return RequestService.post("/segment/list", {});
    }
    public static set(segment: SegmentModel): Promise<Error | unknown> {
        return RequestService.post("/segment/set", segment);
    }
    public static setFolder(name: string, Id?: number): Promise<Error | { Id: number }> {
        return RequestService.post("/segment/setfolder", { name, Id });
    }
    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/segment/delete", { id });
    }
    public static deleteFolder(id: number): Promise<Error | unknown> {
        return RequestService.post("/segment/deleteFolder", { id });
    }
    public static clone(id: number, count: number = 1): Promise<Error | unknown> {
        return RequestService.post("/segment/clone", { id, count });
    }
    public static getCriteria(translate: typeof TranslationService.translate = TranslationService.translate) {
        return ({
            criterionList: [
                { id: 1, name: TranslationService.translate.TotalAmount, type: CriterionType.Number_Amount, tooltip: TranslationService.translate.Tip2 },
                { id: 13, name: TranslationService.translate.AmountRec, type: CriterionType.Number_Amount, tooltip: null },
                { id: 4, name: TranslationService.translate.AmountOfOverdueDebt, type: CriterionType.Number_Amount, tooltip: TranslationService.translate.Tip1 },
                { id: 14, name: TranslationService.translate.DueAmountRec, type: CriterionType.Number_Amount, tooltip: null },
                { id: 12, name: TranslationService.translate.NoDueAmount, type: CriterionType.Number_Amount, tooltip: TranslationService.translate.Tip1 },
                { id: 15, name: TranslationService.translate.NoDueAmountRec, type: CriterionType.Number_Amount, tooltip: null },
                { id: 0, name: TranslationService.translate.DueDays, type: CriterionType.Date, tooltip: TranslationService.translate.Tip3 },
                { id: 16, name: TranslationService.translate.DueDaysRec, type: CriterionType.Date, tooltip: null },
                { id: 9, name: TranslationService.translate.NextDueDays, type: CriterionType.Date, tooltip: TranslationService.translate.Tip4 },
                { id: 17, name: TranslationService.translate.NextDueDaysRec, type: CriterionType.Date, tooltip: null },
                { id: 11, name: TranslationService.translate.DaysSincePendingInvoicesIssuing, type: CriterionType.Date, tooltip: TranslationService.translate.Tip5 },
                { id: 18, name: TranslationService.translate.DebtDaysRec, type: CriterionType.Date, tooltip: null },
                { id: 2, name: TranslationService.translate.CustomerFieldValue, type: CriterionType.Client, tooltip: TranslationService.translate.Tip6 },
                { id: 8, name: TranslationService.translate.FieldIO, type: CriterionType.Invoice, tooltip: TranslationService.translate.Tip7 },
                { id: 10, name: TranslationService.translate.IOPendingValueOf, type: CriterionType.Invoice, tooltip: TranslationService.translate.Tip8 },
                { id: 32, name: TranslationService.translate.FieldIODue, type: CriterionType.Invoice, tooltip: null },
                { id: 21, name: TranslationService.translate.FieldIORec, type: CriterionType.Invoice, tooltip: null },
                { id: 33, name: TranslationService.translate.FieldIODueRec, type: CriterionType.Invoice, tooltip: null },
                { id: 6, name: TranslationService.translate.Cant, type: CriterionType.Number_Amount, tooltip: TranslationService.translate.Tip9 },
                { id: 19, name: TranslationService.translate.CantRec, type: CriterionType.Number_Amount, tooltip: null },
                { id: 7, name: TranslationService.translate.DueCant, type: CriterionType.Number_Amount, tooltip: TranslationService.translate.Tip10 },
                { id: 20, name: TranslationService.translate.DueCantRec, type: CriterionType.Number_Amount, tooltip: null },
                { id: 3, name: TranslationService.translate.LastActivityDate, type: CriterionType.ActivityType, tooltip: TranslationService.translate.Tip11 },
                { id: 25, name: TranslationService.translate.LastActivityManual, type: CriterionType.ActivityType, tooltip: TranslationService.translate.TipActivityManual },
                { id: 28, name: TranslationService.translate.LastActivityEffective, type: CriterionType.ActivityType, tooltip: TranslationService.translate.TipActivityEffective },
                { id: 26, name: TranslationService.translate.LastActivityAuto, type: CriterionType.ActivityType, tooltip: TranslationService.translate.TipActivityAuto },
                { id: 22, name: TranslationService.translate.FieldIONot, type: CriterionType.Invoice, tooltip: null },
                { id: 23, name: TranslationService.translate.FieldIONotPending, type: CriterionType.Invoice, tooltip: null },
                { id: 24, name: TranslationService.translate.FieldIONotRec, type: CriterionType.Invoice, tooltip: null },
                { id: 27, name: TranslationService.translate.LastPaymentDate, type: CriterionType.Date, tooltip: null },
                { id: 29, name: TranslationService.translate.LastModified, type: CriterionType.Date, tooltip: null },
                { id: 30, name: TranslationService.translate.LastModifiedManual, type: CriterionType.Date, tooltip: null },
                { id: 31, name: TranslationService.translate.CreditPercent, type: CriterionType.Number_Amount, tooltip: null }
            ],
            clientDefs: [
                { AdditionalDefinitionID: -1, Name: translate.Name, Field: 2, type: 0, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -6, Name: translate.Notes, Field: 2, type: 0, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                ...CompanyService.getAdditionalDefinitions().filter((def) => def.type !== 4 && def.entity === 0),
            ],
            invoiceDefs: [
                { AdditionalDefinitionID: -2, Name: translate.ReferenceNumber, Field: 8, type: 0, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -6, Name: translate.EmmitedDate, Field: 2, type: 2, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -7, Name: translate.DueDate, Field: 8, type: 2, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -4, Name: translate.Amount, Field: 8, type: 3, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -5, Name: translate.PendingAmount, Field: 8, type: 3, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -8, Name: translate.Observations, Field: 8, type: 0, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                { AdditionalDefinitionID: -3, Name: CompanyService.getGroupName(), Field: 8, type: 0, AdditionalDefinitionItems: [] as AdditionalDefinitionItems },
                ...CompanyService.getAdditionalDefinitions().filter((def) => def.entity === 1),
                { AdditionalDefinitionID: -9, Name: translate.IOStatus, Field: 8, type: 1, AdditionalDefinitionItems: CompanyService.getIoStatus().map(x => ({ AdditionalDefinitionItemID: x.IOStatusID, Value: x.Value })) },
            ],
        });
    }
    public static getOperators(translate: typeof TranslationService.translate = TranslationService.translate) {
        const ToValueTextTuple = (operators: Operator[]) => operators.map(x => ({ value: x, text: TranslationService.getTranslation(Operator[x]) }));
        const dateOperators = ToValueTextTuple([Operator.Greater, Operator.Lower, Operator.Equal,]);
        const textOperators = ToValueTextTuple([Operator.Contains, Operator.DoesntContain, Operator.Equal, Operator.Empty,]);
        const amountOperators = ToValueTextTuple([Operator.Greater, Operator.Lower, Operator.Equal, Operator.NotEqual,]);
        const listOperators = ToValueTextTuple([Operator.Equal, Operator.NotEqual, Operator.Empty,]);
        listOperators.find(x => x.value === Operator.NotEqual)!.text = translate.IsNot;
        amountOperators.find(x => x.value === Operator.NotEqual)!.text = translate.IsNot;
        return { dateOperators, textOperators, amountOperators, listOperators, };
    }
}

export default SegmentService;