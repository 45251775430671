import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import MenuButton, { MenuButtonActions } from "./MenuButton";

const ExportButton = ({ reportTypeId, onClick }: { reportTypeId: number, onClick: (reportExportId: number | null) => void }) => {
    const exportTypes = CompanyService.getReportExports().filter(x => x.Report === reportTypeId);
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => onClick(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === reportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => onClick(x.ReportExportID) })),
    ];
    if (exportTypes.length === 0) {
        return <button className="btn btn-tertiary" onClick={() => onClick(null)}>{TranslationService.translate.ExportToXls}</button>
    }
    else {
        return (
            <MenuButton text={TranslationService.translate.ExportToXls} actions={exportItems} extraClassName="select-export" icon="far fa-solid fa-file-export pe-1" />
        )
    }
}

export default ExportButton;