import { BrowserRouter } from 'react-router-dom'
import Routes from './pages/shared/Routes.tsx';
import { LanguageProvider } from './pages/shared/LanguageContext';
import { AuthenticationProvider } from './pages/shared/AuthenticationContext';
import { ModalContainer } from './pages/shared/bootstrap/Modal';
import { ToastProvider } from './pages/shared/bootstrap/Toast';
import { FloatingPanel } from './pages/shared/FloatingPanel';
import { NavigationHistoryProvider } from './pages/shared/NavigationHistory';
import "./utils/ArrayExtensionMethods";

function App() {
  return (
    <div>
      <LanguageProvider>
        <AuthenticationProvider>
          <ToastProvider>
            <BrowserRouter>
              <NavigationHistoryProvider>
                <Routes />
                <ModalContainer />
                <FloatingPanel
                  id="floating-window"
                  height={200}
                  width={400}
                  resizable={true}
                  titleBar={{
                    title: "React App Window",
                    buttons: { minimize: true, maximize: true, close: false },
                  }}
                >
                  <div>...</div>
                </FloatingPanel>
              </NavigationHistoryProvider>
            </BrowserRouter>
          </ToastProvider>
        </AuthenticationProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;
