import { CheckBoxEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import DateFormat from "./Components/DateFormat";
import ExcelOptions from "./Components/ExcelOptions";
import SeparatorSimple from "./Components/SeparatorSimple";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";


const ImportTemplateEditTransaction = ({ data, reload }: { data?: ImportTemplateListResponse.Item, reload: () => void }) => {
const { translate } = TranslationService;
    const requiredIds = ["client", "date", "referenceNumber", "amount"];

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.ConfigureInvoicePaymentImport} </h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {translate.RequirementsOfTheFileToImportTransaction.split("\n").map((x, i) => <div key={i} className="text-muted ps-3">{x}</div>)}
            </details>
        </div>)
    }

    const AdvancedOptions = ({ data, updateModel, model}: ImportTemplateComponentProps) => {
        return (<div>
            <small className="text-muted">{translate.IfYouLeaveFormatBlank}</small>
            <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} />
            <CheckBoxEditor message={translate.IgnoreNewPaymentsToPaidInvoices} onChange={updateModel("ValidateAmount")} defaultValue={data?.ValidateAmount} />
            <UploadTemplate formatsAllowed=".xlsx,.csv" model={model} updateModel={updateModel} />
            <SeparatorSimple updateModel={updateModel} data={data} />
            <ExcelOptions data={data} updateModel={updateModel} />
        </div>)
    };

    return (
        <ImportTemplateEditBase AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    )
}



export default ImportTemplateEditTransaction;