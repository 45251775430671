import { ReactNode, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CompanyService from '../../../services/CompanyService';
import FloatingPanelService from '../../shared/FloatingPanel';
import { TranslationService } from '../../../services/TranslationService';
import TableContext from '../../task/TableContext';
import ClientEdit from '../ClientEdit';
import InvoiceNew from '../invoice/InvoiceNew';
import SearchInput from '../../shared/components/SearchInput';
import MenuButton from '../../shared/components/MenuButton';
import ClientService from '../../../services/ClientService';
import { ClientTableContextValues } from '../providers/ClientTableProvider';
import AdvancedFilters from '../../shared/components/AdvancedFilters';
import AdvancedFiltersContext from '../../shared/components/AdvancedFiltersContext';
import { FilterCollection } from '../../../services/FilterService';
import ExportButton from '../../shared/components/ExportButton';
import { ClientListRequest } from '../entities/ClientListRequest';
import { NavigationHistoryContext } from '../../shared/NavigationHistory';
import { ImportService } from '../../../services/ImportService';

const FilterContainer = ({ showActions = false, enableImport = true, additionalAction = null }:
    { showActions?: boolean, enableImport?: boolean, additionalAction?: ReactNode }) => {
    const filtersContext = useContext(AdvancedFiltersContext);
    const tableContext = useContext(TableContext);

    const { applySearch, reload, request } = tableContext as ClientTableContextValues;
    const { translate } = TranslationService;
    const history = useHistory<{ prevRequest?: ClientListRequest } | undefined>();

    const { state, search } = history.location;
    const urlParams = new URLSearchParams(search);
    const filterQS = (state?.prevRequest ? state.prevRequest.filter : urlParams.get("filter")) ?? '';

    const showNewClientPanel = () => {
        FloatingPanelService.showPanel({
            title: translate.NewClient,
            children: <ClientEdit onClientSave={reload} />,
            width: 800,
            height: 669,
        });
    }
    const { setCurrentPath } = useContext(NavigationHistoryContext);
    setCurrentPath({
        name: TranslationService.translate.Clients + ` (${TranslationService.translate.Filtered})`,
        link: () => {
            history.push(`/${TranslationService.currentLanguage}/client`, { prevRequest: request })
        },
    });

    const showNewInvoicePanel = () => {
        FloatingPanelService.showPanel({
            title: translate.NewInvoice,
            children: <InvoiceNew reload={reload} />,
            height: 800,
            width: 750,
        });
    }

    const requestExport = (reportId: number | null) => {
        const { request, response } = tableContext as ClientTableContextValues;
        ClientService.export({ ...request, reid: reportId }, response!.itemCount);
    }

    // const actions: MenuButtonActions = [];
    // if (CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio")) {
    //     actions.push({ text: TranslationService.translate.Invoice, action: showNewInvoicePanel });
    // }

    // if (CompanyService.canDo("newperson") && !CompanyService.getSetting("hidecreateperson")) {
    //     actions.push({ text: TranslationService.translate.Customer, action: showNewClientPanel });
    // }

    const onApplySearch = (keyword: string) => {
        applySearch(keyword);
    }

    const applyFilter = (filters: string[]) => {
        const filterToApply = { extraFilters: filters };
        tableContext.applyFilters(filterToApply);
    }
    const imports = useMemo(() => {
        const importEntities = ImportService.getImports().filter(x => x.isEnabled);
        const openImportPage = (entityId: number) =>
            history.push(`/${TranslationService.currentLanguage}/ImportTemplateManual?entityId=${entityId}`);
        return importEntities.map((x, i) => ({ text: x.text, action: () => openImportPage(x.value), }));
    }, [history]);

    return (
        <>
            <div className="genericHeader">
                <div className="searcherFilterHeader" style={{ height: 40 }}>
                    <SearchInput onSearch={onApplySearch} style={{ width: 400 }} />
                    <button onClick={() => filtersContext.showFilters()} className="btn btn-tertiary" type="button" aria-expanded="false" aria-controls="btn-collapse">
                        <i className="far fa-filter" />{TranslationService.translate.Filters}
                    </button>
                </div>
                <div className="actionButtonsHeader">
                    {additionalAction}
                    {CompanyService.canDo("importdata") && enableImport && <MenuButton text={TranslationService.translate.ImportAction} actions={imports} icon="far fa-solid fa-file-import pe-1" />}
                    {CompanyService.canDo("export") && <ExportButton reportTypeId={2} onClick={(report) => requestExport(report)}></ExportButton>}
                    {/* {actions.length > 0 && showActions && <MenuButton text={TranslationService.translate.New} actions={actions} />} */}
                    {(CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio")) &&
                        <button type="button" className="btn btn-primary" onClick={showNewInvoicePanel}>
                            <i className="far fa-solid fa-file-circle-plus "></i>{translate.NewInvoice}
                        </button>}
                    {(CompanyService.canDo("newperson") && !CompanyService.getSetting("hidecreateperson")) &&
                        <button type="button" className="btn btn-primary" onClick={showNewClientPanel}>
                            <i className="far fa-solid fa-user-plus pe-1"></i>{translate.NewClient}
                        </button>}
                </div>
            </div>
            <AdvancedFilters defaultValue={filterQS} page={FilterCollection.Client} onFilterApply={applyFilter} />
        </>
    );
};

export default FilterContainer;