import { useMemo, useState } from 'react';
import { TranslationService } from '../../../services/TranslationService';
import WhatsappService, { WhatsappTemplateItemType } from "../../../services/WhatsappService";
import { Field } from "../../action/Edit/components/FieldHelpers";
import Dropdown from "../../shared/components/Dropdown";
import CompanyService, { Entities } from '../../../services/CompanyService';
import { EntityField, sortEntityFields } from '../../shared/entities/EntityField';
import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import { ToastService } from '../../shared/bootstrap/Toast';
import FloatingPanelService from '../../shared/FloatingPanel';

const WhatsappTemplateEdit = ({ template, reload }: { template: WhatsappTemplateItemType, reload: () => Promise<void> }) => {
    const dataParameters = template.Parameters;
    const elements = Array.from({ length: dataParameters || 0 }, (_, index) => index + 1);
    const [data, setData] = useState<any[]>(
        template.FieldList?.split(',').map((f, index) => ({ param: index + 1, data: `${f}`, valueText: `${f}` })) ||
        elements.map((elIndex) => ({ param: elIndex, data: `{{${elIndex}}}`, valueText: `{{${elIndex}}}` }))
    )
    const [isSaving, setIsSaving] = useState(false);
    const [emptyValueDetected, setEmptyValueDetected] = useState(!Boolean(template.FieldList) || false)

    const disableSabeButton = useMemo(() => data.some(el => el.data.includes('{{')) || emptyValueDetected, [data, emptyValueDetected])

    const fixedFields = useMemo(() => {
        const clientFieldOptions = [
            ...CompanyService.getConfigItemsFiltered(Entities.Client).map(x => new EntityField(x)),
            ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)),
        ]
            .sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")))
            .map(x => ({ value: x.id.toString(), text: x.title ?? '' }));

        const insertFieldOptions = [
            { text: TranslationService.translate.InsertField, value: "" },
            ...clientFieldOptions,
        ];
        return insertFieldOptions;
    }, []);

    const templateFormated = useMemo(() => {
        let texto = template.Structure;

        if (template.FieldList) {
            data.forEach(({ param, valueText }) => {
                console.log({ param, valueText, FieldList: template.FieldList })
                texto = texto.replace(`{{${param}}}`, `{{${fixedFields.find(f => f.value === valueText)?.text || valueText}}}`)
            });
        }
        return texto
    }, [template.Structure, template.FieldList, data, fixedFields])

    const handleChangeWhatsAppTemplate = async () => {
        setIsSaving(true);
        const CompanyID = CompanyService.getCompanyToken()?.companyid;
        if (CompanyID) {
            const stringDeData = data.map(item => item.data).join(',');

            const result = await WhatsappService.setTemplate({
                WATemplateID: template.WATemplateID,
                CompanyID: parseInt(CompanyID),
                FieldList: stringDeData
            })
            if (result instanceof Error) {
                ToastService.showToast(TranslationService.translate.WhatsAppErrorTemplate, '', 'danger')
                return;
            } else {
                ToastService.showToast(TranslationService.translate.WhatsAppTemplateUpdated, '', 'success')
            }
        }
        setIsSaving(false);
        FloatingPanelService.hidePanel()
        await reload()
    }

    return (
        <>
            <div className="floatingBody p-4">
                <div className="row">
                    <Field colClass='col-8' title={TranslationService.translate.WhatsAppTemplate}>
                        <textarea
                            disabled
                            className="form-control"
                            value={templateFormated}
                            style={{ resize: "none" }}
                            rows={6} />
                    </Field>
                    <div className="col">
                        {elements?.map((elIndex) =>
                            <InsertFields
                                key={elIndex}
                                index={elIndex}
                                setEmptyValueDetected={setEmptyValueDetected}
                                setData={setData}
                                data={data}
                                fixedFields={fixedFields}
                                templateFields={template.FieldList} />)}
                        {disableSabeButton && <p className='text-danger'>{TranslationService.translate.MissingRequiredFields}</p>}
                    </div>
                </div>
            </div>
            <FloatingPanelFooter>
                <button className='btn btn-primary me-3' disabled={disableSabeButton} onClick={handleChangeWhatsAppTemplate}>
                    {TranslationService.translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
}



const InsertFields = ({
    index = 1,
    data,
    setData,
    fixedFields,
    templateFields,
    setEmptyValueDetected
}: {
    index: number,
    data: any[],
    setData: (value: any) => void,
    fixedFields: {
        text: string;
        value: string;
    }[],
    templateFields: any,

    setEmptyValueDetected: (value: boolean) => void,
}) => {
    const valueDefault = fixedFields.find(e => e.value === templateFields?.split(',')[index - 1])?.value;

    const onChange = (value: string) => {
        if (value?.length > 0) {
            setEmptyValueDetected(false)
            const valueChange = data.find(el => el.param === index);
            valueChange.data = value;
            valueChange.valueText = fixedFields.find(el => el.value === value)?.text || 'Otro';
            const newDataArr = data.filter(el => el.param !== index);
            setData([...newDataArr, valueChange])
        }
    }

    return (
        <Field colClass='col' title={TranslationService.translate.Field + ' ' + index}>
            <Dropdown onChange={onChange} items={fixedFields} defaultValue={valueDefault} />
        </Field>);
}

export default WhatsappTemplateEdit;
