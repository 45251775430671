import { useCallback, useContext, useEffect, useState } from "react";
import ClientService from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import ReportsBrackets from "./components/ReportsBrackets";
import ReportsCardContainer from "./components/ReportsCardContainer";
import { ReportCollectionType } from "../client/entities/ClientDebtRecoveryAnalysisRequest";
import { formatCurrency, formatIntizaDate } from "../../utils/FormatUtils";
import { percentFormatCollection } from "../../utils/PercentCollection";
import { DatePickerEditor } from "../shared/components/Editors";
import ReportsCardItem from "./components/ReportsCardItem";
import Loading from "../shared/components/Loading";
import AdvancedFiltersContext from "../shared/components/AdvancedFiltersContext";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import { FilterCollection } from "../../services/FilterService";
import ErrorMessage from "../shared/components/Error";
import moment from "moment";
import { useHistory } from "react-router-dom";

const today = moment();
const firstDayDefault = today.clone().subtract(1, 'months').startOf('month');
const lastDayDefault = today.clone().subtract(1, 'months').endOf('month');
const restarDia = (fechaTexto: string) => {

    // Parsear la cadena de texto en una fecha
    const fecha = new Date(
        parseInt(fechaTexto.substring(0, 4)), // Año
        parseInt(fechaTexto.substring(4, 6)) - 1, // Mes (se resta 1 porque los meses en JavaScript son indexados desde 0)
        parseInt(fechaTexto.substring(6, 8)) // Día
    );

    // Restar un día
    fecha.setDate(fecha.getDate() - 1);

    // Obtener los componentes de la fecha
    const año = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses son indexados desde 0
    const dia = String(fecha.getDate()).padStart(2, '0');

    // Formatear la fecha en el mismo formato
    const fechaFormateada = `${año}${mes}${dia}`;

    return fechaFormateada
}

function isValidDate(fechaString: string) {
    const fecha = Date.parse(fechaString);
    return !isNaN(fecha) && new Date(fecha).toString() !== "Invalid Date";
}

export const encodeFilters = (filters: string[]) => {
    return filters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&");
}

export const encodeFiltersToLink = (filters: string[], customIndex?: number) => {
    let counter = customIndex || 0

    return filters.map((value) => {
        counter++;
        return "filter" + counter + "=" + encodeURIComponent(value)
    }).join("&");
}

const getDmy = (date: string) => {
    const d = date.substring(6, 8)
    const m = date.substring(4, 6)
    const y = date.substring(0, 4)
    return [d, m, y]
}

const ReportCollection = () => {
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState<string>()
    const [response, setResponse] = useState<ReportCollectionType>()
    const [loading, setLoading] = useState(true)
    const [filtersSelected, setFiltersSelected] = useState({
        from: firstDayDefault.format('YYYYMMDD'),
        to: lastDayDefault.format('YYYYMMDD'),
        filter: ""
    })
    const [filtersMap, setFiltersMap] = useState<string[]>([])

    const history = useHistory();
    const filtersContext = useContext(AdvancedFiltersContext);
    const { translate, currentLanguage } = TranslationService;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getData() }, [])

    const getData = async () => {
        setLoading(true)
        if (!filtersSelected.from || !filtersSelected.to) {
            setMsgError(translate.CompleteToContinue)
            setLoading(false);
            setError(true);
            return
        }
        const result = await ClientService.getDebtRecoveryAnalysis(filtersSelected)
        if (result instanceof Error) {
            setMsgError(translate.ErrorProcessingRequest)
            setError(true);
            setLoading(false);
            setResponse(undefined)
            return;
        }
        setMsgError(undefined)
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const handleFilterChange = (name: string, value: any) => {
        let dataValue: string | undefined = undefined
        if (isValidDate(value)) {
            dataValue = formatIntizaDate(new Date(value))
        }
        setFiltersSelected(prev => ({ ...prev, [name]: dataValue }))
    }

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters])
        const filterEncoded = encodeFilters(filters)
        setFiltersSelected(p => ({ ...p, filter: filterEncoded }))
    };

    const navigateClientFilter = useCallback((customerKey: number) => {
        let customPath = `/${currentLanguage}`

        const [dsdr, msdr, ysdr] = getDmy(filtersSelected.from)
        const [dedr, medr, yedr] = getDmy(filtersSelected.to)
        const previousDate = restarDia(filtersSelected.from)
        const [dpdr, mpdr, ypdr] = getDmy(previousDate)

        switch (customerKey) {
            // Situación al inicio Clientes con deuda   
            case 0:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}${filtersMap.length > 0 ? '&filter=' + encodeURIComponent(encodeFilters(filtersMap) || "") : ''}`
                break;
            // Situación al inicio Total pendiente  
            case 7:
                customPath = `${customPath}/report/historyiolist?from=${ysdr}-${msdr}-${dsdr}${filtersMap.length > 0 ? '&filter=' + encodeURIComponent(encodeFilters(filtersMap) || "") : ''}`
                break;
            case 1:
                customPath = `${customPath}/client?filter=filter0%3dselFields%253d-22%2526selDateActivityType%253d${dpdr}%252f${mpdr}%252f${ysdr}%2b-%2b${dedr}%252f${medr}%252f${yedr}%2526selActivityType%253d4${filtersMap.length > 0 ? '%26' + encodeURIComponent(encodeFiltersToLink(filtersMap, 0) || "") : ''}`
                break;
            case 2:
                customPath = `${customPath}/client?filter=filter0%3dselFields%253d-22%2526selDateActivityType%253d${dpdr}%252f${mpdr}%252f${ysdr}%2b-%2b${dedr}%252f${medr}%252f${yedr}%2526selActivityType%253d5${filtersMap.length > 0 ? '%26' + encodeURIComponent(encodeFiltersToLink(filtersMap, 0) || "") : ''}`
                break
            case 3:
                customPath = `${customPath}/client?filter=filter0%3dselFields%253d-22%2526selDateActivityType%253d${dpdr}%252f${mpdr}%252f${ysdr}%2b-%2b${dedr}%252f${medr}%252f${yedr}%2526selActivityType%253d3${filtersMap.length > 0 ? '%26' + encodeURIComponent(encodeFiltersToLink(filtersMap, 0) || "") : ''}`
                break
            // Situación al cierre Clientes con deuda
            case 4:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}&to=${yedr}-${medr}-${dedr}${filtersMap.length > 0 ? '&filter=' + encodeURIComponent(encodeFilters(filtersMap) || "") : ''}`
                break
            // Situación al cierre Clientes con deuda
            case 5:
                customPath = `${customPath}/report/payment?filter=filter0%3dselFields%253d-303%2526selDate%253d${ysdr}${msdr}${dsdr}%2b-%2b${ypdr}${mpdr}${dpdr}%26filter1%3dselFields%253d-1023%2526selDate%253d%2b-%2b${yedr}${medr}${dedr}${filtersMap.length > 0 ? '%26' + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ''}`
                break
            // Situación al cierre Total pendiente
            case 6:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}&to=${yedr}-${medr}-${dedr}${filtersMap.length > 0 ? '&filter=' + encodeURIComponent(encodeFilters(filtersMap) || "") : ''}`
                break
        }
        history.push(customPath)
    }, [currentLanguage, history, filtersMap, filtersSelected.from, filtersSelected.to])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getData() }, [filtersSelected.filter])

    return (
        <div className="container-fluid padding reports">
            <h2>{translate.RecoveryAnalysis}</h2>
            <div className="genericHeader">
                <div className="searcherFilterHeader align-items-center mb-3">
                    <label style={{ minWidth: 100 }}>{translate.StartDatev2}:</label>
                    <DatePickerEditor onChange={v => handleFilterChange('from', v)} defaultValue={filtersSelected.from} />

                    <label style={{ minWidth: 80 }}>{translate.EndDate}:</label>
                    <DatePickerEditor onChange={v => handleFilterChange('to', v)} defaultValue={filtersSelected.to} />

                    <button type="button" className="btn btn-primary" onClick={getData}>{translate.ApplyFilters}</button>
                    <button type="button" className="btn btn-tertiary" data-bs-toggle="tooltip" onClick={() => filtersContext.showFilters()}>
                        <i className="far fa-filter"></i> {translate.AddFilters}
                    </button>
                </div>
            </div>
            <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportCollection} />
            {loading &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <Loading />
                </div>}

            {!loading && error &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <ErrorMessage message={msgError} onRefresh={getData} />
                </div>}

            {!loading && !error && response &&
                <div className="d-flex tree ">
                    <div className="d-flex align-items-center">
                        <ReportsCardContainer title={translate.InitialSituation} tooltip={false}>
                            <ReportsCardItem title={translate.ClientDebt} valueItem={response.data[0].Cant} action={() => navigateClientFilter(0)} />
                            <ReportsCardItem title={translate.TotalPending} valueItem={formatCurrency(response.data[0].Amount)} action={() => navigateClientFilter(7)} />
                        </ReportsCardContainer>
                    </div>
                    <div className="d-flex gap-4">
                        <ReportsBrackets />
                        <div className="d-flex flex-column justify-content-center gap-3">
                            <ReportsCardContainer title={translate.ManualActivity}>
                                <ReportsCardItem
                                    title={translate.Clients}
                                    valueItem={response.data[1].Cant}
                                    percent={percentFormatCollection(response.data[1].Cant, response.data[0].Cant)} action={() => navigateClientFilter(1)} />
                                <ReportsCardItem
                                    title={translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[1].Amount)}
                                    percent={percentFormatCollection(response.data[1].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Collected}
                                    valueItem={formatCurrency(response.data[1].Paid)}
                                    percent={percentFormatCollection(response.data[1].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Pending}
                                    valueItem={formatCurrency(response.data[1].Amount - response.data[1].Paid)}
                                    percent={percentFormatCollection((response.data[1].Amount - response.data[1].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>

                            <ReportsCardContainer title={translate.AutomaticManagement}>
                                <ReportsCardItem
                                    title={translate.Clients}
                                    valueItem={response.data[2].Cant}
                                    percent={percentFormatCollection(response.data[2].Cant, response.data[0].Cant)} action={() => navigateClientFilter(2)} />
                                <ReportsCardItem
                                    title={translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[2].Amount)}
                                    percent={percentFormatCollection(response.data[2].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Collected}
                                    valueItem={formatCurrency(response.data[2].Paid)}
                                    percent={percentFormatCollection(response.data[2].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Pending}
                                    valueItem={formatCurrency(response.data[2].Amount - response.data[2].Paid)}
                                    percent={percentFormatCollection((response.data[2].Amount - response.data[2].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>

                            <ReportsCardContainer title={translate.WithoutManagement}>
                                <ReportsCardItem
                                    title={translate.Clients}
                                    valueItem={response.data[3].Cant}
                                    percent={percentFormatCollection(response.data[3].Cant, response.data[0].Cant)}
                                    action={() => navigateClientFilter(3)} />
                                <ReportsCardItem
                                    title={translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[3].Amount)}
                                    percent={percentFormatCollection(response.data[3].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Collected}
                                    valueItem={formatCurrency(response.data[3].Paid)}
                                    percent={percentFormatCollection(response.data[3].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={translate.Pending}
                                    valueItem={formatCurrency(response.data[3].Amount - response.data[3].Paid)}
                                    percent={percentFormatCollection((response.data[3].Amount - response.data[3].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>
                        </div>
                        <ReportsBrackets revert={true} />
                        <div className="d-flex align-items-center">
                            <ReportsCardContainer title={translate.ClosingSituation} tooltip={false}>
                                <ReportsCardItem
                                    title={translate.ClientDebt}
                                    valueItem={response.data[4].Cant}
                                    percent={percentFormatCollection(response.data[4].Cant, response.data[0].Cant)}
                                    action={() => navigateClientFilter(4)} />
                                <ReportsCardItem
                                    title={translate.TotalCollected}
                                    valueItem={formatCurrency(response.data[4].Paid)}
                                    percent={percentFormatCollection(response.data[4].Paid, response.data[0].Amount)}
                                    action={() => navigateClientFilter(5)} />
                                <ReportsCardItem
                                    title={translate.TotalPending}
                                    valueItem={formatCurrency(response.data[4].Amount)}
                                    percent={percentFormatCollection(response.data[4].Amount, response.data[0].Amount)}
                                    action={() => navigateClientFilter(6)} />
                            </ReportsCardContainer>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default ReportCollection;