import { useState } from "react";

export type TabMenuItem<T> = {
    title: JSX.Element,
    component: (props: T) => JSX.Element,
}
export type TabMenuProps<T> = { 
    items: [TabMenuItem<T>, ...TabMenuItem<T>[]],
    props: T
 };
const TabMenu = <T extends Record<string,unknown>,>({ items, props }: TabMenuProps<T>) => {
    const [selected, setSelected] = useState(0);
    const CurrentTab = items[selected].component;
    return (
        <>
            <div className="row g-0 tabsGroup">
                {items.map((x, i) => {
                    return (
                        <div className="col-auto px-0" key={i}>
                            <button key={i} type="button" onClick={() => setSelected(i)}
                                className={"btn btn-tab" + (selected !== i ? "" : " active")}>
                                {x.title}
                            </button>
                        </div>
                    );
                })}
            </div>
            <CurrentTab {...props} />
        </>
    );
};

export default TabMenu;