import { useEffect, useRef, useState } from "react";

export type FilterDropdownButtonProps = {
    title: string | JSX.Element;
    items: { key: string, onClick: () => void }[];
}
const FilterDropdownButton = (props: FilterDropdownButtonProps) => {

    const [chevronClass, setChevronClass] = useState("fa-chevron-down");
    const collapsable = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onCollapse = () => {
            setChevronClass("fa-chevron-down");
        };
        const onShow = () => {
            setChevronClass("fa-chevron-up");
        };
        const col = collapsable.current;
        col?.addEventListener("hidden.bs.dropdown", onCollapse);
        col?.addEventListener("shown.bs.dropdown", onShow);
        return () => {
            col?.removeEventListener("hidden.bs.dropdown", onCollapse);
            col?.removeEventListener("shown.bs.dropdown", onShow);
        }
    }, []);
    
    if (props === undefined) {
        return <></>;
    }
    return (
        <div className="dropdown dropdown-intiza" ref={collapsable}>
            <button className="btn dropdown-toggle p-0" type="button" data-bs-toggle="dropdown">
                <div className="select-intiza">
                    {props.title} <i className={"fas ms-2 " + chevronClass}></i>
                </div>
            </button>
            <ul className="dropdown-menu">
                {props.items.map((item: { key: string, onClick: () => void }, index: number) =>
                    <button key={item.key} className="dropdown-item" type="button" onClick={item.onClick}>
                        {item.key}
                    </button>
                )}
            </ul>
        </div>
    );
};

export default FilterDropdownButton;