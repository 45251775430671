import { ToastService } from "../pages/shared/bootstrap/Toast";
import FileService from "./FileService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class ExportService {
    public static async requestExport(endpoint: string, request: {}, count: number, fileName: string = "export.xls") {
        ToastService.showToast(TranslationService.translate.Exporting + "...");

        const response: { Response: "Ok", file: string, processrequestid?: number } | Error =
            await RequestService.post(endpoint, { ...request, expectedRows: count });
            
        if (response instanceof Error) {
            ToastService.showToast(TranslationService.translate.Network, undefined, "danger");
            return;
        }
        if (response.processrequestid) {
            ToastService.showToast(TranslationService.translate.ExportSentToMail, undefined, "success");
            return;
        }
        FileService.download(response.file, fileName);
    }
}

export default ExportService;