import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { ReportAgingRequest } from "../../entities/reports/ReportAging/ReportAgingRequest";
import { ReportAgingListResponse } from "../../entities/reports/ReportAging/ReportAgingResponse";
import CompanyService from "../../services/CompanyService";
import FilterService, { FilterCollection, FilterEntity } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { TranslationService } from "../../services/TranslationService";
import { formatInteger } from "../../utils/FormatUtils";
import { sumList } from "../../utils/Utils";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import AdvancedFiltersContext from "../shared/components/AdvancedFiltersContext";
import Dropdown from "../shared/components/Dropdown";
import Table, { TableHeader } from "../shared/Table";
import TooltipComponent from "../shared/TooltipComponent";
import TableContext, { TableContextValues } from "../task/TableContext";

export class ReportAgingGroupTableContextValues extends TableContextValues<ReportAgingListResponse, { extraFilters: string[] }> {
    constructor(public totalAmount: number) {
        super();
    }
}
const ReportAgingGroup = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportAgingListResponse>({} as ReportAgingListResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [groupBy, setGroupBy] = useState<{ value: number, id: string }[]>([{ id: v4(), value: 0 }]);
    const [filter, setFilter] = useState<string>();
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [showPercentages, setShowPercentages] = useState(false);
    const filtersContext = useContext(AdvancedFiltersContext);
    const [ids, setIds] = useState<number[]>([]);

    useEffect(() => {
        const newIds = groupBy.map(x => x.value).filter(x => x !== 0);
        const areNotTheSame = !newIds.every((curr, index) => curr === ids[index]);
        if (newIds.length !== ids.length || areNotTheSame) {
            setIds(newIds);
        }
    }, [groupBy, ids]);

    const requestData = async () => {
        setLoading(true);
        if (ids.length === 0) {
            setLoading(false);
            setError(false);
            return;
        }
        const reportAgingRequest: ReportAgingRequest = {
            filter,
            ids,
            page: currentPage,
        };
        const result = await InvoiceService.getReportAging(reportAgingRequest);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setPageCount(totalPages);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [ids, filter, currentPage]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const applyFilters = (filters: string[]) => {
        setFilter(filters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&"));
    };

    const tableValues = new ReportAgingGroupTableContextValues(response.totalAmount ?? 0);
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.currentPage = currentPage;
    tableValues.pageCount = pageCount;

    const headers: TableHeader[] = [
        ...CompanyService.GetAgeingValues().map(x => new TableHeader(x.filterVal, x.name, true, false, (x.minDays === undefined || (0 > x.minDays)) ? "txt-red" : "txt-blue")),
        new TableHeader("dues", translate.Dues, true, false, "txt-red"),
        new TableHeader("notdue", translate.NotDue, true, false, "txt-blue"),
        new TableHeader("total", translate.Total, true, false),
    ];

    const items = useMemo(() =>
        FilterService.GetFiltersForEntities([FilterEntity.Client, FilterEntity.Invoice])
            .flatMap(x => [({ text: x.title, isTitle: true }), ...x.definitions.map(y => ({ text: y.Name, value: parseInt(y.Field.replace("additional-", "")), key: y.Entity + "%" + y.Field }))]), []);

    if (response?.list?.at(0)?.GroupedFields !== undefined) {
        headers.unshift(...groupBy.map(x => items.find(y => "value" in y && y.value === x.value)?.text).filter(x => x).map(x => new TableHeader(x ?? "", x ?? "", false, false)));
    }

    const addGrouping = () => {
        groupBy.push({ id: v4(), value: 0 });
        setGroupBy([...groupBy]);
    };

    const delGrouping = (index: number) => {
        groupBy.splice(index, 1);
        setGroupBy([...groupBy]);
        requestDataCallback();
    };

    const onFilterChange = (value: number, index: number) => {
        groupBy[index].value = value;
        setGroupBy([...groupBy]);
        requestDataCallback();
    };

    const handleExport = async () => {
        InvoiceService.exportAgingGroup(response.itemCount, ids, filter ?? "");
    }
    return (
        <TableContext.Provider value={tableValues}>
            <div className="container-fluid padding">
                <div className="card mh-100">
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-3">{TranslationService.translate.AgingAnalysis}</h2>
                    </div>
                    <div className="genericHeader">
                        <div className="searcherFilterHeader">
                            <button className="btn btn-tertiary" type="button" onClick={() => filtersContext.showFilters()} style={{ height: 40 }}>
                                <i className="far fa-filter" />{TranslationService.translate.Filters}
                            </button>
                            <label className="col-form-label">{translate.GroupInvoicesBy}</label>
                            <div>
                                {groupBy.map((x, index) => (
                                    <div key={x.id} className="d-flex pb-3">
                                        <Dropdown items={items} onChange={(value) => onFilterChange(value ?? 0, index)} optionLabel={TranslationService.translate.Select} />
                                        {
                                            groupBy.length > 1 &&
                                            <button className="btn btn-link" onClick={() => delGrouping(index)}><i className="fa fa-trash text-danger"></i></button>
                                        }
                                    </div>
                                ))}
                            </div>
                            <button className="btn btn-tertiary pl-0" onClick={() => addGrouping()} style={{ height: 40 }}>{translate.AddGroupBy}</button>
                            <label className="col-form-label">{translate.Show}</label>
                            <Dropdown items={[{ text: translate.Amount, value: 0 }, { text: translate.Percentage, value: 1 },]} onChange={() => setShowPercentages(x => !x)} />
                            <label className="col-form-label">{TranslationService.translate.ValuesExpressedBy} {CompanyService.getCurrencies().find(x => x.CurrencyId === CompanyService.getDefaultCurrencyId())!.Symbol}</label>
                        </div>
                        <div>
                            {CompanyService.canDo("export") &&
                                <button className="btn btn-primary" onClick={handleExport}>{TranslationService.translate.ExportToXls}</button>}
                        </div>
                    </div>
                    <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportInvoice} />
                    {ids.length > 0 && <Table stickyHeader={true} headers={headers} item={ReportAgingGroupItem(showPercentages, response.totalAmount)}>
                        <ReportInvoiceGroupTotalUSD response={response} showPercentage={showPercentages} />
                    </Table>}
                </div>
            </div>
        </TableContext.Provider>
    );
};


const ReportAgingGroupItem = (showPercentage: boolean, totalAmount: number) => ({ data }: { data: ReportAgingListResponse.Item }) => {
    const intoPercentage = (value: number) => formatInteger(value / totalAmount * 100) + "%";
    const intoCurrency = formatInteger;
    const [tableFormat, tooltipFormat] = showPercentage ? [intoPercentage, intoCurrency] : [intoCurrency, intoPercentage]
    return (
        <tr>
            {data.GroupedFields?.map(x => <td key={x.showName}>{x.value}</td>)}
            {Array.from({ length: 8 }).map((x, i) => <td className="text-end" key={i}><TooltipComponent title={tooltipFormat(data["Item" + i as "Item1"])}>{tableFormat(data["Item" + i as "Item1"])}</TooltipComponent></td>)}
            <td className="text-end"><TooltipComponent title={tooltipFormat(data.due)}>{tableFormat(data.due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(data.amount - data.due)}>{tableFormat(data.amount - data.due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat((data.amount))}>{tableFormat(data.amount)}</TooltipComponent></td>
        </tr>
    );
};

const ReportInvoiceGroupTotalUSD = ({ response, showPercentage }: { response: ReportAgingListResponse, showPercentage: boolean }) => {
    const { totalAmount } = useContext(TableContext) as ReportAgingGroupTableContextValues;
    const intoPercentage = (value: number) => formatInteger(value / totalAmount * 100) + "%";
    const intoCurrency = formatInteger;
    const [tableFormat, tooltipFormat] = showPercentage ? [intoPercentage, intoCurrency] : [intoCurrency, intoPercentage]
    const due = sumList(response.list, x => x.due);
    const noDue = sumList(response.list, x => x.amount - x.due);
    const total = response.totalAmount;
    return (
        <tr className="font-weight-bold">
            <td>{TranslationService.translate.Total}</td>
            {response.list[0].GroupedFields.filter((x, i) => i !== 0).map(x => <td key={x.showName}></td>)}
            {response.totals.map((x, i) => <td key={i} className="text-end"><TooltipComponent title={tooltipFormat(x)}>{tableFormat(x)}</TooltipComponent></td>)}
            <td className="text-end"><TooltipComponent title={tooltipFormat(due)}>{tableFormat(due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(noDue)}>{tableFormat(noDue)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(total)}>{tableFormat(total)}</TooltipComponent></td>
        </tr>
    );
};
export default ReportAgingGroup;