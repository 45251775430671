import { useMemo, useState } from "react";
import { Field } from "../../action/Edit/components/FieldHelpers";
import Dropdown from "./Dropdown";
import { TranslationService, TranslationsKey } from "../../../services/TranslationService";
import ImportTemplateService from "../../../services/ImportTemplateService";
import { handleErrorWithToast, useDatasource } from "../../../utils/RequestUtils";
import Loading from "./Loading";
import ErrorMessage from "./Error";
import ImportStatus from "./ImportStatus";
import { ToastService } from "../bootstrap/Toast";
import { ImportService } from "../../../services/ImportService";
import { ImportPreviewResponse } from "../../../entities/import/ImportPreview";
import { useLocation } from "react-router";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import FileService from "../../../services/FileService";
import { ImportTemplateListResponse } from "../entities/ImportTemplateListResponse";

const ImportTemplateManual = () => {
	type ImportTemplateTypeInfo = {
		titleKey: TranslationsKey;
		templates: []
	}
	enum Status {
		NoFile,
		Loading,
		NeedAproval,
		Imported,
		Error,
	}
	const { value: importTemplateList, isError, isLoading, reload } = useDatasource(ImportTemplateService.getList, []);
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	const entityId = parseIntOrDefault(urlParams.get("entityId"), 0);
	const [templateTypeId] = useState(entityId);
	const [template, setTemplate] = useState<ImportTemplateListResponse.Item>();
	const [importStatus, setImportStatus] = useState(Status.NoFile);
	const [preview, setPreview] = useState<ImportPreviewResponse>();
	const [newImport, setImport] = useState<{ async: 0 | 1, importid: number, processid: number }>();

	const onFileUpload = async (fileList: FileList | null) => {
		const validExtensions = [".xls", ".xlsx"];
		if (!(fileList?.length) || template === undefined) { return; }
		const file = fileList[0];
		if (file.size > 24000000) {
			ToastService.showToast(TranslationService.translate.FileSizeError, undefined, "danger")
			return false;
		}
		if (validExtensions && !validExtensions.find(x => file.name.endsWith(x))) {
			ToastService.showToast(TranslationService.translate.WarningFileExtention, undefined, "danger")
			return false;
		}
		setPreview(undefined);
		setImportStatus(Status.Loading);
		const fileUpload = await handleErrorWithToast(FileService.upload(file));
		const result = await handleErrorWithToast(ImportService.set(template.ImportTemplateID, fileUpload.id),
			() => setImportStatus(Status.Error));
		if (result.async === 1) {
			ToastService.showToast(TranslationService.translate.ProcessRunning, undefined, "success");
			setImportStatus(Status.Imported);
			return;
		}
		setImport(result);
		const preview = await handleErrorWithToast(ImportService.preview(result.importid),
			() => setImportStatus(Status.Error));
		setPreview(preview);
		setImportStatus(Status.NeedAproval);
	}

	const typeInfo = useMemo(() => {
		if (isLoading || isError) {
			return {} as ImportTemplateTypeInfo;
		}
		const titles: TranslationsKey[] = ["ImportListOfOutstandingInvoices"];
		const titleKey = titles[templateTypeId] ?? "ImportListOfOutstandingInvoices";
		const templates = importTemplateList!.list.filter(x => x.entity === templateTypeId).map(x => ({ text: x.Name, value: x }));
		if (!templates.find(x => x.value === template)) {
			setTemplate(templates[0].value);
		}
		return {
			titleKey,
			templates,
		}
	}, [importTemplateList, isError, isLoading, template, templateTypeId]);

	if (isLoading) {
		return <Loading />
	}
	if (isError) {
		return <ErrorMessage onRefresh={reload} />
	}

	const submit = async () => {
		setImportStatus(Status.Loading);
		const result = await ImportService.importData(newImport!.importid);
		if (result instanceof Error) {
			setImportStatus(Status.Error);
		} else {
			setImportStatus(Status.Imported);
		}
	}

	return (
		<div className="container-fluid padding">
			<div className="card">
				<h2 className="h2 my-3 mb-4">{TranslationService.translate[typeInfo.titleKey]}</h2>
				<Field title={TranslationService.translate.ProcessTheFileUsingTheTemplate} colClass="col-xxl-5 col-7">
					<div className="row align-items-center gap-x-3">
						<Dropdown onChange={x => setTemplate(x)} items={typeInfo.templates} selectClasses="col" defaultValue={template} />
						<div className="col-auto">
							<input type="file" onChange={e => onFileUpload(e.target.files)}
								className="d-none" id="file-input-new-template" />
							<label htmlFor="file-input-new-template" className="btn btn-primary mb-0">
								{TranslationService.translate.ImportFile}</label>
						</div>
						<div className="col-auto">
							<button className="btn btn-link"
								onClick={() => ImportTemplateService.showImportTemplateEditModal(
									template, importTemplateList, reload)}>
								{TranslationService.translate.ChangeSettings}
							</button>
						</div>
					</div>
				</Field>
				{false && <div className="row align-items-center my-2">
					<div className="col-auto">
						<div className="d-flex flex-column">
							<label className="mb-0">
								<b>¡ATENCIÓN! </b>
								Una vez recibido el archivo, Intiza lo procesará automáticamente y luego enviará un email con los resultados
							</label>
						</div>
					</div>
				</div>}
				{importStatus === Status.Loading && <div className="loading my-4">
					<div className="d-flex align-items-center justify-content-center flex-column">
						<i className="fa-solid fa-spinner fa-spin fs-4"></i>
					</div>
					<p>{TranslationService.translate.ProcessingData}...</p>
				</div>}
				{importStatus === Status.NeedAproval && preview && <>
					<hr />
					<ImportStatus preview={preview} submit={submit} template={template!}/>
				</>}
				{importStatus === Status.Imported && <div className="alert alert-success" role="alert">
					<i className="fa-regular fa-circle-check me-2"></i>Importación finalizada correctamente
				</div>}
				{importStatus === Status.Error && <div className="alert alert-danger" role="alert">
					<i className="fa-regular fa-triangle-exclamation me-2"></i>Ocurrió un error con la importación
				</div>}
			</div>
		</div>
	)

}

export default ImportTemplateManual;