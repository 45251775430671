import { useCallback } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { useEndpointData } from "../../../utils/TableUtils";
import { ReportReceivablesSummaryService } from "../../../services/ReportReceivablesSummaryService";
import { Cast } from "../../../utils/Utils";
import { MonthlyDataRow } from "../../../entities/reports/ReportReceivablesSummary/MonthlyDataResponse";
import { formatCurrency, formatDateMonthYearShort } from "../../../utils/FormatUtils";
import { TableHeader } from "../../shared/Table";

export const RrsMonthlyDetail = ({ filter }: { filter: string }) => {
    const endpoint = useCallback(() => ReportReceivablesSummaryService.getMonthlyData(filter), [filter]);
    const tableValue = useEndpointData(endpoint);
    if (tableValue.response) {
        const typeErased = Cast<typeof tableValue.response & { list: typeof tableValue.response.data }>(tableValue.response);
        typeErased.list = typeErased.data;
    }
    return (
        <>
            <div className="card mh-100 mb-2">
                <div className="row justify-content-end align-items-center">
                    <div className="col-4 d-flex justify-content-center">
                        <h5 className="h5">{TranslationService.translate.MonthlyDetail}</h5>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <a className="btn btn-tertiary" href="/">{TranslationService.translate.ExportToXls}</a>
                    </div>
                </div>
                <table className="table genericTable table-ellipsis mt-3 rrsTable">
                    <thead>
                        {getHeaders().map(x => <th key={x.key} className={x.numeric ? "text-end" : ""}>
                            {x.label()}
                        </th>)}
                    </thead>
                    <tbody>
                        {tableValue.response?.data.slice(0, 25).map(x => <MonthlyDetailItem data={x} />)}
                    </tbody>
                </table>
            </div>
        </>
    );
}

const MonthlyDetailItem = ({ data }: { data: MonthlyDataRow }) =>
    <tr>
        <td>{formatDateMonthYearShort(new Date(data.date))}</td>
        <td className="text-end">{formatCurrency(data.debt)}</td>
        <td className="text-end">{formatCurrency(data.payment)}</td>
        <td className="text-end">{data.percCollectedObj}</td>
        <td className="text-end">{formatCurrency(data.newdebt)}</td>
        <td className="text-end">{formatCurrency(data.newpayment)}</td>
        <td className="text-end">{data.newPercCollectedObj}</td>
        <td className="text-end">{formatCurrency(data.total)}</td>
    </tr>;


const getHeaders = () => [
    new TableHeader("date", "", false, false),
    new TableHeader("debtbegginging", TranslationService.translate.DebtAtBeginningOfTheMonth, true, false),
    new TableHeader("collections", TranslationService.translate.Collections, true, false),
    new TableHeader("collected", "% " + TranslationService.translate.Collected2, true, false),
    new TableHeader("monthsales", TranslationService.translate.MonthSales, true, false),
    new TableHeader("postcollections", TranslationService.translate.Collections, true, false),
    new TableHeader("postcollected", "% " + TranslationService.translate.Collected2, true, false),
    new TableHeader("debtend", TranslationService.translate.DebtAtEndOfTheMonth, true, false),
];