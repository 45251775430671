/* eslint-disable no-extend-native */

declare global {
    interface Array<T> {
        findThen(search: (value: T) => boolean, map: (value: T) => void, defaultValue?: T): T | undefined;
        filterFalsey(): DefinitelyTruthy<T>[];
        insert(index: number, ...items: T[]): T[];
    }
}

Array.prototype["findThen"] = function <T>(search: (value: T) => boolean, then: (value: T) => void, defaultValue?: T): T | undefined {
    const self = this as T[];
    const value = self.find(search) ?? defaultValue;
    if (value !== undefined) {
        then(value);
    }
    return value;
}

type DefinitelyTruthy<T> =
    T extends false ? never :
    T extends 0 ? never :
    T extends "" ? never :
    T extends null ? never :
    T extends undefined ? never :
    T

Array.prototype["filterFalsey"] = function <T>(): DefinitelyTruthy<T>[] {
    const self = this as T[];
    return self.filter(x => x) as DefinitelyTruthy<T>[];
}

Array.prototype["insert"] = function <T>(index: number, ...items: T[]): T[] {
    const self = this as T[];
    self.splice(index, 0, ...items);
    return self;
}

export { };