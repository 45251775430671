import { useContext, useState } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { DatePickerEditor, TagsDropdownEditor, HasValidationEditor, HasValidationEditorProps, FileListEditor, AutocompleteSelect, FileApi, TextAreaBigEditor } from '../../shared/components/Editors';
import { TranslationService } from '../../../services/TranslationService';
import { TaskEdit } from '../entities/TaskEdit';
import { TaskStatus, getStatusTranslateKey } from '../entities/TaskStatus';
import ToastContext from "../../shared/bootstrap/Toast";
import GroupService from '../../../services/GroupService';
import ClientService from '../../../services/ClientService';
import TaskService from '../../../services/TaskSerivce';
import FloatingPanelService from '../../shared/FloatingPanel';
import TagService from '../../../services/TagService';
import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import CompanyService from '../../../services/CompanyService';
import { RequiredManager, ValidationMessage } from '../../shared/RequieredManager';

type NewTaskProp = {
    onNewTask?: () => void
} & (
        { personId: string, personName: string } |
        { personId?: undefined, personName?: undefined }
    );

const NewTaskForm = ({ onNewTask = undefined, personId = undefined, personName = undefined }: NewTaskProp) => {
    const loggedUserId = CompanyService.getUserid() ?? "";
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const [taskEdit, setTaskEdit] = useState(new TaskEdit(loggedUserId, personId));
    const [isSaving, setIsSaving] = useState(false);
    const [clientMandatoryMessage, setClientMandatoryMessage] = useState(false);
    const requiredManger = new RequiredManager();
    const validationFunctionArray: (() => boolean)[] = [];
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ value: x.Id, text: x.Value ?? '', key: x.Id })) ?? [];
    const getActivityTypeOptions = CompanyService.getActivityTypes().filter(x => x.Task).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName ?? '' })) ?? [];
    const getStatusOptions = [TaskStatus.Pending, TaskStatus.Fulfilled].map(x => ({ value: x as number, text: TranslationService.getTranslation(getStatusTranslateKey(x)) }));
    const getReminderHourOptions = [...Array(24).keys()].map(x => ({ value: x.toString(), text: (x + ":00").padStart(5, "0") }));

    const taskFieldChange = (propName: keyof TaskEdit) => (newValue: string | number | boolean | undefined) => {
        setTaskEdit(x => ({ ...x, [propName]: newValue }));
    }

    const taskFileChange = (fileIdList: FileApi[]) => {
        const files = fileIdList.map(x => x.id);
        setTaskEdit(x => ({ ...x, files }));
    }

    const clientSelectOptions = async (inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (inputValue.length < minInputLength) { return list; }
        const result = await ClientService.search(inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.id.toString(), label: x.name }));
        }
        return list;
    }

    const showDoneDate = taskEdit.statusId !== undefined && +taskEdit.statusId === TaskStatus.Fulfilled;

    const canSaveTask = () => {
        setClientMandatoryMessage(!taskEdit.clientId);
        return requiredManger.validate() && validationFunctionArray.filter(x => !x()).length === 0 && taskEdit.clientId;
    }

    const saveTask = async () => {
        if (isSaving || !canSaveTask()) {
            return;
        }
        setIsSaving(true);
        if (taskEdit.tagsCsv) {
            const tagResult = await TagService.createMissingTags(taskEdit.tagsCsv);
            if (tagResult instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSaving(false);
                return;
            }
            setTaskEdit(taskEdit => ({ ...taskEdit, tagsCsv: result }));
            taskEdit.tagsCsv = tagResult;
        }

        const result = await TaskService.setTask(taskEdit, loggedUserId);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSaving(false);
        }
        else {
            showToast(translate.TaskSaved, undefined, "success");
            setTaskEdit(new TaskEdit(loggedUserId?.toString()));
            setIsSaving(false);
            onNewTask && onNewTask();
            FloatingPanelService.hidePanel();
        }
    }
    return (
        <>
            <div className="floatingBody p-4">
                <div className="d-flex input-column mb-3">
                    <label className="form-label">{translate.Client}</label>
                    {personId === undefined &&
                        <AutocompleteSelect loadOptions={clientSelectOptions} onChange={clientId => { taskFieldChange('clientId')(clientId?.value); setClientMandatoryMessage(!clientId) }} />}
                    {personId !== undefined && <p className=''>{personName}</p>}
                    {clientMandatoryMessage && <p className='text-danger mb-0'>{translate.ClientMandatoryMessage}</p>}
                </div>
                {CompanyService.getGroupName()?.length > 0 && <div className='row'>
                    <div className='col'>
                        <div className="d-flex input-column mb-3">
                            <label className="form-label">{CompanyService.getGroupName()}</label>
                            <AutocompleteSelect loadOptions={value => GroupService.getGroupSelectOptions(taskEdit.clientId, value)} onChange={x => taskFieldChange('groupId')(x?.value)} />
                        </div>
                    </div>
                </div>}
                <div className="row">
                    <div className="col">
                        <div className="d-flex input-column mb-3">
                            <label className="form-label">{translate.Responsible}</label>
                            <Dropdown optionLabel={translate.NotAssigned} onChange={taskFieldChange('userResponsibleId')} items={getUserOptions}></Dropdown>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex input-column mb-3">
                            <label className="form-label">{translate.ExpirationDay}</label>
                            <HasValidationEditor {...new HasValidationEditorProps(
                                DatePickerEditor,
                                taskFieldChange('dueDate'),
                                <p className='text-danger mb-0'>{translate.RequiredField}</p>,
                                undefined,
                                taskEdit.dueDate,
                                validationFunctionArray
                            )} />
                        </div>
                    </div>
                </div>
                {taskEdit.userResponsibleId && taskEdit.userResponsibleId !== loggedUserId &&
                    <>
                        <div className="form-check mb-2">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" onChange={e => taskFieldChange('notifyResponsible')(e.currentTarget.checked)}></input>
                                {translate.TaskEditNotifyResponsibleLabel}
                            </label>
                        </div>
                        <div className="form-check pl-0 mb-3">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" onChange={e => taskFieldChange('notifyCompleted')(e.currentTarget.checked)} />
                                {translate.TaskEditNotifyCompletedLabel}
                            </label>
                        </div>
                    </>
                }

                <div className="row">
                    <div className="col">
                        <div className="d-flex input-column mb-3">
                            <label className="form-label">{translate.Validator}</label>
                            <Dropdown optionLabel={translate.NotAssigned} onChange={taskFieldChange('userValidatorId')} items={getUserOptions}></Dropdown>
                        </div>
                    </div>
                    <div className="col">
                        <label className="form-label">{translate.ActivityType2}</label>
                        <Dropdown optionLabel={translate.None} onChange={requiredManger.makeRequiredIf(CompanyService.getSetting("mandatorytasktype"),
                            taskFieldChange('activityTypeId'), "activitytype")} items={getActivityTypeOptions} defaultValue={taskEdit.activityTypeId} />
                        <ValidationMessage onChange={CompanyService.getSetting("mandatorytasktype") ? requiredManger.getValidation("activitytype") : () => { }} defaultValue={taskEdit.activityTypeId} />
                    </div>
                </div>
                <div className="d-flex input-column mb-3">
                    <label className="form-label">{translate.Description}</label>
                    <HasValidationEditor {...new HasValidationEditorProps(
                        TextAreaBigEditor,
                        taskFieldChange('description'),
                        <p className='text-danger mb-0'>{translate.DescriptionMandatoryMessage}</p>,
                        undefined,
                        undefined,
                        validationFunctionArray
                    )} />
                </div>
                {Boolean(CompanyService.getTags()?.length) && <div className="d-flex input-column mb-3">
                    <div className="row">
                        <div className="col">
                            <label className="form-label">{translate.Tags}</label>
                            <TagsDropdownEditor onChange={taskFieldChange('tagsCsv')} defaultValue="" />
                        </div>
                        <div className="col">
                            <label className="form-label">{translate.Status}</label>
                            <Dropdown onChange={taskFieldChange('statusId')} items={getStatusOptions}></Dropdown>
                        </div>
                    </div>
                </div>}
                {showDoneDate && <div className="d-flex input-column mb-3">
                    <div className="row">
                        <div className="col">
                            <label className="form-label">{translate.Date}</label>
                            <HasValidationEditor {...new HasValidationEditorProps(
                                DatePickerEditor,
                                taskFieldChange('dateDone'),
                                <p className='text-danger'>{translate.RequiredField}</p>,
                                (newVal: string | undefined) => { return !!newVal || !(taskEdit.statusId !== undefined && parseInt(taskEdit.statusId) === TaskStatus.Fulfilled) },
                                taskEdit.dateDone,
                                validationFunctionArray
                            )} />
                        </div>
                    </div>
                </div>}
                <div className="form-check mb-2">
                    <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" onChange={e => {
                            taskFieldChange("reminderDay")(taskEdit.dueDate);
                            taskFieldChange("reminderHour")("10");
                            taskFieldChange("emailReminder")(e.currentTarget.checked);
                        }} />
                        {translate.SendEmailReminder}
                    </label>
                </div>
                {taskEdit.emailReminder &&
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-check mb-2">
                                <label className="form-label">{translate.Date}</label>
                                <HasValidationEditor {...new HasValidationEditorProps(
                                    DatePickerEditor,
                                    taskFieldChange('reminderDay'),
                                    <p className='text-danger'>{translate.RequiredField}</p>,
                                    undefined,
                                    taskEdit.reminderDay,
                                    validationFunctionArray
                                )} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-check mb-2">
                                <label className="form-label">{translate.Hour}</label>
                                <Dropdown onChange={taskFieldChange('reminderHour')} items={getReminderHourOptions} defaultValue={taskEdit.reminderHour}></Dropdown>
                            </div>
                        </div>
                    </div>
                }
                <FileListEditor onFilesChange={taskFileChange} canEdit={true} />
            </div>
            <FloatingPanelFooter>
                <button className='btn btn-primary me-3' onClick={saveTask}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )
}

export default NewTaskForm;