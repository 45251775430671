import { InvoiceGetResponse } from "../pages/client/invoice/entities/InvoiceGetResponse";
import TransactionListRequest from "../pages/transaction/entities/TransactionListRequest";
import { TransactionListResponse } from "../pages/transaction/entities/TransactionListResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

class TransactionService {
    public static setTransaction(transaction: InvoiceGetResponse.Transaction): Promise<Error|unknown> {
        return RequestService.post("/transaction/set", {
            item: transaction,
            additionaldefinitions: CompanyService.getAdditionalDefinitions() ?? [],
            currencies: CompanyService.getCurrencies() ?? [],
        });
    }
    public static delete(transactionId: number): Promise<Error|unknown> {
        return RequestService.post("/transaction/delete", { id: transactionId.toString() });
    }
    public static getList(request: TransactionListRequest): Promise<Error|TransactionListResponse> {
        return RequestService.post("/transaction/list", {...request, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies()});
    }
    public static export(filters: {}, count: number){
        return ExportService.requestExport("/transaction/export", { ...filters, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, count);
    }
}

export default TransactionService;