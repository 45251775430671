import { CSSProperties, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";

const SearchInput = ({ onSearch, style }: { onSearch: (value: string) => void, style?: CSSProperties }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const search = () => {
        onSearch(searchTerm);
    }

    return (
        <div className="search-input input-group">
            <button className="btn btn-outline-secondary" type="button" onClick={search}><i className="far fa-search"></i></button>
            <input type="text" style={style} className="form-control " placeholder={TranslationService.translate.Find} onKeyPress={event => event.key === "Enter" && search()} onChange={(e) => setSearchTerm(e.target.value)}></input>
        </div>
    );
}

export default SearchInput;