import { useContext, useEffect, useState } from "react";
import { DataType } from "../../../../services/CompanyService";
import FieldConfigurationService, { AdditionalDefinitionsListResponse } from "../../../../services/FieldConfigurationService";
import ToastContext from "../../../shared/bootstrap/Toast";
import Dropdown from "../../../shared/components/Dropdown";
import { CheckBoxEditor, TextEditor } from "../../../shared/components/Editors";
import { TranslationService } from '../../../../services/TranslationService';
import TooltipComponent from "../../../shared/TooltipComponent";
import Collapsable from "../../../shared/bootstrap/Collapsable";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import Loading from "../../../shared/components/Loading";


const AdditionalDefinitionsEdit = ({ reload, fieldId = undefined }: { reload: () => void, fieldId?: number }) => {
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [isLoading, setIsLoading ] = useState<boolean>(true);
    const [field, setField] = useState<AdditionalDefinitionsListResponse.Item>();

    const dataTypeOptions = [
        { value: DataType.Text, text: translate.Freetext },
        { value: DataType.List, text: translate.List },
        { value: DataType.Date, text: translate.Date },
        { value: DataType.Number, text: translate.Number },
        { value: DataType.Currency, text: translate.Currency },
        { value: DataType.Phone, text: translate.Phone },
        { value: DataType.Link, text: translate.Link },
    ]

    useEffect(() => {
        const loadUser = async () => {
            if (!fieldId) { return; }
            const result = await FieldConfigurationService.get(fieldId);
            if (result instanceof Error || result === undefined) {
                setIsLoading(false);
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return undefined;
            }
            //Hacer algo con el result.item
            setField(result!.item);
            setIsLoading(false);
            return;
        }

        loadUser();
    }, [fieldId, showToast, translate, isLoading])

    const updateField = (propName: string) => (value: string | string[] | boolean | DataType | undefined) => {
        setField(field => ({ ...field, [propName]: value }) as AdditionalDefinitionsListResponse.Item);
    }
    
    const [showMenu, setShowMenu] = useState(false);
    const columnWidth = [
        { value: "Normal", text: translate.Regular },
        { value: "Grande", text: translate.Big },
        { value: "Muy grande", text: translate.Bigger },
    ]

    if (isLoading) {
        return (<Loading />)
    }

    return (
    <>
        <div className="floatingBody p-4">
            <div className="form-group mb-3">
                <label>{translate.Name}</label>
                {<TextEditor onChange={updateField("Name")} value={field?.Name}></TextEditor>}
            </div>
            {field?.Type === DataType.Link &&  <div className="form-group mb-3">
                <label>{translate.LinkText}</label>
                {<TextEditor onChange={() => { }} ></TextEditor>}
            </div>}
            <div className="form-group mb-3">
                <label>{translate.Type}</label>
                {<Dropdown onChange={updateField("Type")} items={dataTypeOptions} value={field?.Type}></Dropdown>}
            </div>

                {field?.Type === DataType.List &&
                    <section>

                        <hr className="my-4"></hr>
                        <h5 className="mb-4">{translate.ListingOptions}</h5>
                        <CheckBoxEditor onChange={() => { }} message={translate.AutoCompleteOnImport} ></CheckBoxEditor>
                        <table className="table optionsList w-100 mt-2">
                            <thead>
                                <tr>
                                    <th className="w-50">{translate.Option}</th>
                                    <th className="w-25 text-center">{translate.Default}</th>
                                    <th className="w-25"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{<TextEditor onChange={() => { }}></TextEditor>}</td>
                                    <td>
                                        <div className="position-relative">
                                            <i className="position-absolute top-50 start-50 translate-middle fas fa-check fa-lg"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-row-nowrap">
                                            <button className="edit w-100"><i className="far fa-pen undefined"></i></button>
                                            <button className="edit w-100"><i className="far fa-trash text-danger"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{<TextEditor onChange={() => { }}></TextEditor>}</td>
                                    <td>
                                        <div className="position-relative d-none">
                                            <i className="position-absolute top-50 start-50 translate-middle fas fa-check fa-lg"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-row-nowrap">
                                            <button className="edit w-100"><i className="far fa-pen undefined"></i></button>
                                            <button className="edit w-100"><i className="far fa-trash text-danger"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="addRow">
                                    <td>{<TextEditor onChange={() => { }}></TextEditor>}</td>
                                    <td>
                                        <div className="position-relative d-flex justify-content-center">
                                            <CheckBoxEditor onChange={() => { }} message=""></CheckBoxEditor>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-link">+ {translate.Add}</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
             
                    </section>
                }

            <div className="">
                <button className="btn btn-link px-0" onClick={() => setShowMenu(!showMenu)}>{showMenu === false ? `+ ${translate.ShowAdvancedOptions}` : `- ${translate.HideAdvancedOptions}`}</button>
            </div>
            <hr className="my-4"></hr>
            <Collapsable show={showMenu} >
                <div className="mt-2">
                    {(field?.Type === DataType.Text || field?.Type === DataType.List) &&
                    <div className="d-flex gap-3 align-items-center mb-3">
                        <div className="form-check ps-0">
                            <label className="form-check-label mb-0">{translate.ColumnWidth}</label>
                        </div>
                        <Dropdown onChange={() => { }} items={columnWidth}></Dropdown>
                    </div> }
                    {/* <div className="form-group mb-3">
                        <label>Nombre público</label>
                        {<TextEditor onChange={() => { }} ></TextEditor>}
                    </div> */}

                    {/* CHECKS CLIENT FIELDS */}
       
                        <CheckBoxEditor onChange={updateField("")} message={translate.ThisFieldIdentifiesTheCustomer} addClass={"text-danger"} ></CheckBoxEditor>
                        <div className="d-flex">
                            <CheckBoxEditor onChange={updateField("")} message={translate.IncludeInReports} ></CheckBoxEditor>
                            <TooltipComponent title={translate.IncludeInvoicesPaymentsActivitiesTags}>
                                <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                        </div>
                        <CheckBoxEditor onChange={updateField("")} message={translate.HiddenInDetails} ></CheckBoxEditor>
                        {field?.Type === DataType.Phone && <CheckBoxEditor onChange={updateField("")} message={translate.TypeWhatsApp} ></CheckBoxEditor>}
                        <CheckBoxEditor onChange={updateField("")} message={translate.ShowRight} ></CheckBoxEditor>               
                        <CheckBoxEditor onChange={updateField("")} message={translate.AllowManualEdit} ></CheckBoxEditor>
                        <CheckBoxEditor onChange={updateField("")} message={translate.IncludeInFilters} ></CheckBoxEditor>
                        <CheckBoxEditor onChange={updateField("")} message={translate.IncludeInCustomerPortal} ></CheckBoxEditor>





                        {/* CHECKS INVOICED FIELDS */}
                        {/* <div className="d-flex">
                        <CheckBoxEditor onChange={updateField("")} message={"Mostrar este campo en la lista de facturas"} ></CheckBoxEditor>
                        <TooltipComponent title="Si destildas esta opción se ocultará este campo de la tabla de facturas que se encuentra en la pantalla de un cliente. Podrás ver este campo en el detalle de la factura" >
                            <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                    </div>
                    <div className="d-flex">
                        <CheckBoxEditor onChange={updateField("")} message={"Mostrar este campo al cliente"} ></CheckBoxEditor>
                        <TooltipComponent title="Si tildas esta opción el campo se incluirá en las listas de facturas que se envían por mail a clientes y en la plataforma de clientes." >
                            <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                    </div>
                    <div className="d-flex">
                        <CheckBoxEditor onChange={updateField("")} message={"Totalizar"}></CheckBoxEditor>
                        <TooltipComponent title="Muestra la suma total de este campo en el listado de facturas" >
                            <i className="ms-2 mt-1 fal fa-question-circle"></i></TooltipComponent>
                    </div>
                    <CheckBoxEditor onChange={updateField("")} message={"Incluir en listados"} ></CheckBoxEditor>
                    <CheckBoxEditor onChange={updateField("")} message={"Incluir en mails a clientes y en portal"} ></CheckBoxEditor>
            */}
                        {/* CHECKS PAYMENT FIELDS */}
                        {/* <CheckBoxEditor onChange={updateField("IncludeInClientExport")} message={"Incluir en reportes"} value={field?.IncludeInClientExport}></CheckBoxEditor> */} 
                    </div>
            </Collapsable>
            
            {/* <p className="my-3">
                <b>¡ATENCIÓN! </b>Este campo está asociado a un proceso automático de actualización de facturas. Por esta razón no es posible eliminarlo.
            </p> */}

            {/* <CheckBoxEditor onChange={updateField("IncludeInClientExport")} message={"TRANSLATE - incluir en reporte"} value={field?.IncludeInClientExport}></CheckBoxEditor>
        <CheckBoxEditor onChange={updateField("Hidden")} message={"Hidden"} value={field?.Hidden}></CheckBoxEditor>
        <CheckBoxEditor onChange={updateField("Right")} message={"Right"} value={field?.Right}></CheckBoxEditor>
        <CheckBoxEditor onChange={updateField("ManualEdit")} message={"ManualEdit"} value={field?.ManualEdit}></CheckBoxEditor>
        <CheckBoxEditor onChange={updateField("ExcludeInFilters")} message={"!!ExcludeInFilters"} value={!!field?.ExcludeInFilters}></CheckBoxEditor>
        <CheckBoxEditor onChange={updateField("IncludeInCustomerPortal")} message={"IncludeInCustomerPortal"} value={field?.IncludeInCustomerPortal}></CheckBoxEditor>
        {potato()} */}
        </div>
        <FloatingPanelFooter>
            <button className="btn btn-danger">{TranslationService.translate.Delete}</button>
            <button className="btn btn-primary">{TranslationService.translate.Save}</button>
        </FloatingPanelFooter>
    </>

    );
}

export default AdditionalDefinitionsEdit;