import React, { useContext, useEffect, useState } from "react";
import InvoiceService from "../../../services/InvoiceService";
import { InvoiceListResponse } from "./entities/InvoiceListResponse";
import TableContext from "../../task/TableContext";
import CompanyService from "../../../services/CompanyService";
import { GroupType, InvoiceGroupWithChildren } from "./InvoiceGroupWithChildren";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";

type GroupTypeResponse = {
    route: string,
    tree: GroupType[] | [],
    maxDEbt: number,
}

type InvoiceGroupsType = {
    personid: number
    groups: number[]
    filtersSetter: string[]
    setFiltersSetter: (value: string[]) => void
}

export const InvoiceGroups = ({ personid, groups, setFiltersSetter, filtersSetter }: InvoiceGroupsType) => {
    const [data, setData] = useState<GroupTypeResponse>();
    const [loading, setLoading] = useState(true);
    const response = useContext(TableContext).response as InvoiceListResponse | undefined;
    const headersObject: { [id: number]: string } = {};
    CompanyService.getAdditionalDefinitions().forEach(x => { headersObject[x.AdditionalDefinitionID] = x.Name; });
    const headers = groups.map(numero => headersObject[numero]).join(" / ");

    useEffect(() => {
        const info = async () => {
            const res = await InvoiceService.getInvoiceGroups({ personid: personid.toString() });
            if (res instanceof Error) {
                return;
            }
            setData(res);
            setLoading(false);
        };
        info();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return loading ? <Loading /> : (
        <table className="table">
            <thead>
                <tr>
                    <th>{headers}</th>
                    <th className='text-end'>{TranslationService.translate.Invoices}</th>
                    <th className='text-end'>{TranslationService.translate.Amount}</th>
                    <th>{TranslationService.translate.Due}/{TranslationService.translate.NoDue}</th>
                </tr>
            </thead>
            <tbody>
                {data?.tree && data.tree.map((dataValue, index) =>
                    <InvoiceGroupWithChildren
                        key={index}
                        spacing={2}
                        dataValues={dataValue}
                        maxDebt={data.maxDEbt}
                        currencyTotals={response?.currencyTotals}
                        setFiltersSetter={setFiltersSetter}
                        filtersSetter={filtersSetter}
                        parentChecked={false}
                    />)}
            </tbody>
        </table>
    );
};

