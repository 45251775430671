import { Redirect, Route, Switch } from "react-router-dom";
import BaseSite from "./BaseSite";
import AuthSite from "../account/AuthSite";
import { useContext, useLayoutEffect } from "react";
import LanguageContext from "./LanguageContext";
import Logout from "./Logout";
import SwitchCompany from "./SwitchCompany";
import {
  Language,
  TranslationService,
} from "../../services/TranslationService";
import LogonAuthToken from "../account/LogonAuthToken";

type SProps = { lang: Language };

const S: React.FC<SProps> = (props: SProps) => {
  const { setCurrentLanguage } = useContext(LanguageContext);

  TranslationService.setCurrentLanguage(props.lang);
  useLayoutEffect(() => {
    setCurrentLanguage(props.lang);
  });

  // Register here all NO BaseSite endpoints
  return (
    <Switch>
      <Route path="/:lang/company">
        <BaseSite lang={props.lang} />
      </Route>
      <Route path="/:lang/account/edit">
        <BaseSite lang={props.lang} />
      </Route>

      <Route path="/:lang/logonauthtoken">
        <LogonAuthToken lang={props.lang} />
      </Route>

      <Route path="/:lang/logon">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/recoverpassword">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/home/recoverpassword">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/changepassword">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/account/changepassword">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/recover">
        <AuthSite lang={props.lang} />
      </Route>

      <Route path="/:lang/account/completesignin">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/completesignin">
        <AuthSite lang={props.lang} />
      </Route>
      <Route path="/:lang/2af">
        <AuthSite lang={props.lang} />
      </Route>

      <Route path="/:lang/logout">
        <Logout lang={props.lang} />
      </Route>
      <Route path="/:lang/switchcompany">
        <SwitchCompany lang={props.lang} />
      </Route>

      <Route>
        <BaseSite lang={props.lang} />
      </Route>
    </Switch>
  );
};

const Routes: React.FC = () => {
  // Register here all the languages and set the final redirect with the default language
  return (
    <Switch>
      <Route path="/es">
        <S lang="es" />
      </Route>
      <Route path="/en">
        <S lang="en" />
      </Route>
      <Route path="/pt">
        <S lang="pt" />
      </Route>
      <Redirect to={`/es/company`} />
    </Switch>
  );
};

export default Routes;
