import moment, { Moment } from "moment";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { TranslationService } from "../../../services/TranslationService";
import { formatDateDigits } from "../../../utils/FormatUtils";

const localeEs = {
    "format": "YYYYMMDD",
    "separator": " - ",
    "applyLabel": "Aceptar",
    "cancelLabel": "Cancelar",
    "fromLabel": "Desde",
    "toLabel": "Hasta",
    "customRangeLabel": "Rango de fechas",
    "weekLabel": "S",
    "daysOfWeek": ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    "monthNames": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    "firstDay": 1,
    "today": "Hoy",
    "yesterday": "Ayer",
    "last7Days": "Últimos 7 días",
    "lastMonth": "Mes anterior",
    "currentMonth": "Mes actual",
    "currentYear": "Año actual",
    "startDate": "Fecha de inicio",
    "allDatesBefore": "Anterior a",
    "allDatesAfter": "Posterior a",
};

const localeEn = {
    "format": "YYYYMMDD",
    "separator": " - ",
    "applyLabel": "Done",
    "cancelLabel": "Cancel",
    "fromLabel": "From",
    "toLabel": "To",
    "customRangeLabel": "Date range",
    "weekLabel": "W",
    "daysOfWeek": [
        "Sun",
        "Mon",
        "Tue",
        "Wen",
        "Thu",
        "Fri",
        "Sat"
    ],
    "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    "firstDay": 1,
    "today": "Today",
    "yesterday": "Yesterday",
    "last7Days": "Last 7 days",
    "lastMonth": "Last month",
    "currentMonth": "Current month",
    "currentYear": "Current year",
    "dateRange": "Dates range",
    "startDate": "Start date",
    "allDatesBefore": "Before",
    "allDatesAfter": "After",
};

const localePt = {
    "format": "YYYYMMDD",
    "separator": " - ",
    "applyLabel": "Feito",
    "cancelLabel": "Cancelar",
    "fromLabel": "De",
    "toLabel": "Até",
    "customRangeLabel": "Intervalo de datas",
    "weekLabel": "S",
    "daysOfWeek": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    "monthNames": ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
    "firstDay": 1,
    "today": "Hoje",
    "yesterday": "Ontem",
    "last7Days": "Últimos 7 dias",
    "lastMonth": "Mês anterior",
    "currentMonth": "Mês atual",
    "currentYear": "Ano corrente",
    "allDatesBefore": "Anterior a",
    "allDatesAfter": "Depois",
};
const locales = {
    "es": localeEs,
    "en": localeEn,
    "pt": localePt,
};
let chosing = "";
const RangedDatepicker = (props: { onChange: (start?: Date, end?: Date) => void, defaultValue?: string | null }) => {
    const { currentLanguage } = TranslationService;
    const defaultValue = props.defaultValue !== undefined && props.defaultValue !== null ?
        props.defaultValue.split("-").map(date => moment(date, "YYYYMMDD")) :
        [moment(), moment()];

    const [state, setState] = useState({
        start: defaultValue[0],
        end: defaultValue[1] ?? defaultValue[0],
        empty: props.defaultValue === null || props.defaultValue === "",
    });

    const { start, end, empty } = state;
    const handleCallback = (start: Moment, end: Moment, selectedOption: string | undefined) => {
        if ((selectedOption === locale.allDatesBefore || selectedOption === locale.allDatesAfter) && chosing === "") {
            chosing = selectedOption;
            return;
        }
        if (chosing === locale.allDatesBefore || chosing === locale.allDatesAfter) {
            const newStart = chosing === locale.allDatesBefore ? moment().set({ "year": 1973, "month": 1, "days": 1 }) : end;
            const newEnd = chosing === locale.allDatesAfter ? moment().set({ "year": 2073, "month": 11, "days": 31 }) : end;
            setState(x => ({ ...x, empty: false }));
            props.onChange(newStart.toDate(), newEnd.toDate());
            chosing = "";
            return;
        }
        chosing = "";
        setState(x => ({ ...x, empty: false }));
        props.onChange(start.toDate(), end.toDate());
    };
    const label = empty ? "" : start.valueOf() === end.valueOf() ?
        formatDateDigits(start.toDate()) :
        formatDateDigits(start.toDate()) + " - " + formatDateDigits(end.toDate());
    const locale = locales[currentLanguage as keyof typeof locales];

    const clear = () => {
        setState({
            start: defaultValue[0],
            end: defaultValue[1] ?? defaultValue[0],
            empty: true
        });
        props.onChange(undefined, undefined);
    };

    return (
        <div className="dateRange" style={{ position: "relative" }}>
            <DateRangePicker
                initialSettings={{
                    startDate: empty ? undefined : start.toDate(),
                    endDate: empty ? undefined : end.toDate(),
                    ranges: {
                        [locale.today]: [moment().toDate(), moment().toDate()],
                        [locale.yesterday]: [
                            moment().subtract(1, "days").toDate(),
                            moment().subtract(1, "days").toDate(),
                        ],
                        [locale.last7Days]: [
                            moment().subtract(7, "days").toDate(),
                            moment().toDate(),
                        ],
                        [locale.currentMonth]: [
                            moment().startOf("month").toDate(),
                            moment().endOf("month").toDate(),
                        ],
                        [locale.lastMonth]: [
                            moment().subtract(1, "month").startOf("month").toDate(),
                            moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                        [locale.currentYear]: [
                            moment().startOf("year").toDate(),
                            moment().endOf("year").toDate(),
                        ],
                        [locale.allDatesBefore]: [
                            moment().set({ "year": 1973, "month": 0, "days": 1 }),
                            moment().toDate()
                        ],
                        [locale.allDatesAfter]: [
                            moment().toDate(),
                            moment().set({ "year": 2073, "month": 11, "days": 31 })
                        ]
                    },
                    locale: locale,
                }}
                onCallback={handleCallback}
                onHide={(x, y) => {
                    if (y.chosenLabel === locale.allDatesBefore || y.chosenLabel === locale.allDatesAfter) {
                        const chosen = y.chosenLabel;
                        setTimeout(() => {
                            y.setStartDate(moment());
                            y.setEndDate(moment());
                            y.showCalendars();
                            y.show();
                            y.autoApply = true;
                            y.singleDatePicker = true;
                            y.updateView();
                            y.container.find(".ranges li").removeClass("active");
                            y.container.find(".ranges li[data-range-key='" + chosen + "']").addClass("active");
                        }, 10);
                    }
                }}>
                <div
                    id="reportrange"
                    className="col"
                    style={{
                        background: "#fff",
                        cursor: "pointer",
                        padding: "8px 10px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #C7C7C7",
                        borderRadius: "0.375rem",
                        fontSize: "14px",
                        lineHeight: "22px"
                    }}
                >
                    <i className="fa-regular fa-calendar me-2"></i>&nbsp;
                    <span style={{ flexGrow: 1, fontSize: label.length > 14 ? 13 : 14 }}>{label}</span>
                    {!state.empty && <i className="fa fa-times has-pointer" onClick={clear}></i>}
                    <i className="fas ms-2 fa-chevron-down ps-2"></i>
                </div>
            </DateRangePicker>
        </div >
    );
};

export default RangedDatepicker;

