import axios, { AxiosError, AxiosRequestConfig } from "axios";
import AuthenticationService from "./AuthenticationService";
import {
    IAuthenticationData,
    AUTHENTICATION_DATA_STORAGE_KEY,
} from "../pages/shared/AuthenticationContext";
import { getCache } from "../utils/PersistenceUtils";

const baseUrl =
    process.env.REACT_APP_INTIZA_COMPANY_AUTH ??
    process.env.REACT_APP_INTIZA_COMPANY_AUTH + "";
type TData = {};
type RequestPromiseExtraProperties = {
    abortController: AbortController;
    endpoint: string;
};
export type RequestPromise<TResponse> = Promise<
    TResponse | AxiosError<TResponse, TData>
> &
    RequestPromiseExtraProperties;
class RequestService {
    public static getBaseUrl() {
        return baseUrl;
    }
    public static currentAbortController = new AbortController();
    public static post<TResponse = {}>(
        endpoint: string,
        data: TData,
        withAuthorization = true,
        requestBaseUrl: string = baseUrl
    ): Promise<TResponse | AxiosError<TResponse, TData>> {
        const headers: { Authorization?: string; "Content-Type": string } = {
            "Content-Type": "application/json",
        };

        const abortController = new AbortController();
        RequestService.currentAbortController = abortController;
        const authPromise = withAuthorization
            ? AuthenticationService.getAuthenticationToken().then(
                (authenticationToken) => {
                    if (!authenticationToken) {
                        window.location.pathname = "/";
                        return Error("no auth token");
                    }
                    headers.Authorization = "Bearer " + authenticationToken;
                }
            )
            : Promise.resolve();
        const axiosRequest = authPromise.then(() => {
            const config: AxiosRequestConfig<TData> = {
                method: "post",
                timeout: 20000,
                url: requestBaseUrl + endpoint,
                headers,
                data,
                signal: abortController.signal,
            };
            const response = axios(config)
                .then((x) => this.refreshList(x.data))
                .catch((x: AxiosError<TResponse, TData>) => x)
                .then(x => {
                    if (x instanceof Error) {
                        return x;
                    }
                    const interceptions = RequestService.interceptList[endpoint]?.splice(0, 9999) ?? [];
                    interceptions.forEach(inter => inter(x));
                    return x;
                });
            return response;
        });
        const request = Object.assign(axiosRequest, {
            abortController,
            endpoint,
        }) as RequestPromise<TResponse>;
        return request;
    }

    public static async togglefilteruseridpaused(value: boolean) {
        const authenticationData = getCache<IAuthenticationData>(
            AUTHENTICATION_DATA_STORAGE_KEY
        );
        if (!authenticationData) {
            return;
        }
        await AuthenticationService.refreshToken({
            refreshToken: authenticationData,
            filteruseridpaused: Number(value) as 0 | 1,
        });
    }

    private static async refreshList<TResponse extends Record<string, unknown>>(data: TResponse) {
        if (data && typeof data === "object" && "refreshlists" in data) {
            const authenticationData = getCache<IAuthenticationData>(
                AUTHENTICATION_DATA_STORAGE_KEY
            );
            if (!authenticationData) {
                return;
            }
            await AuthenticationService.refreshToken({
                refreshToken: authenticationData,
                refreshlists: 1,
            });
        }
        return data;
    }

    public static async downloadFile(fileDownloadUrl: string, fileName = "export.xlsx"): Promise<Error | unknown> {
        const headers: { Authorization?: string, "Content-Type": string } = {
            "Content-Type": "application/json"
        };
        const authenticationToken = await AuthenticationService.getAuthenticationToken();
        if (authenticationToken === null) {
            window.location.pathname = "/";
            return Error("no auth token");
        }
        headers.Authorization = "Bearer " + authenticationToken;
        try {
            const response = await axios({
                url: baseUrl + fileDownloadUrl,
                method: "GET",
                responseType: "blob",
                headers,
            });
            let fileName = response.headers["content-disposition"].replaceAll("attachment; filename=\"", "");
            fileName = fileName.substring(0, fileName.length - 1);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            return Error("status error");
        }
    }
    private static interceptList: Record<string, ((result: any) => void)[]> = {};
    static intercept(path: string, func: (typeof RequestService.interceptList)[string][number]) {
        this.interceptList[path] = this.interceptList[path] ?? [];
        this.interceptList[path].push(func);
    }
}

// used for testing purposes :)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default RequestService;
