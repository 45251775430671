import { useState } from 'react'
import { TranslationService } from '../../../../services/TranslationService';
import { ConfigurationItem } from './ConfigurationItem';

type ChildType = {
    name: string;
    component: JSX.Element | null;
}

type CollapsableConfigurationType = {
    titleGroup: string,
    children: ChildType[],
    valueSearch?: string,
}

export const CollapsableConfiguration = ({ titleGroup, children, valueSearch }: CollapsableConfigurationType) => {
    const [openAction, setOpenAction] = useState(false)
    return (
        <>
            {!Boolean(valueSearch) ?
                <tr onClick={() => setOpenAction(x => !x)} className="">
                    <td valign="middle">
                        <span className="arrowDown mb-2"><i className={"fa-solid fa-fw pe-3 " + (!openAction ? "fa-chevron-right" : "fa-chevron-down")}></i></span>
                        <span className="iconFolder"><i className={"fa-light fa-fw mx-2 fa-folder" + (openAction ? "-open" : "")}></i></span>
                        <span className="folderName">{TranslationService.getTranslation(titleGroup)}</span>
                    </td>
                </tr> : null}
            {children
                .filter(ele => TranslationService.getTranslation(ele.name)?.toLowerCase().includes(valueSearch?.toLowerCase() || ''))
                .map(({ name, component }, index) =>
                    <ConfigurationItem
                        key={index + name}
                        component={component}
                        openAction={Boolean(valueSearch) ? true : openAction} />
                )}
        </>
    )
}
