import { useContext, useEffect } from "react";
import { TranslationService } from "../../../services/TranslationService";
import Dropdown from "../../shared/components/Dropdown";
import TableContext from "../../task/TableContext";
import FilterDropdownButton from "../../shared/filter/FilterDropdownButton";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { InvoiceTableContextValues } from "./InvoiceTableProvider";
import InvoiceNew from "./InvoiceNew";
import FloatingPanelService from "../../shared/FloatingPanel";
import { NewReciept } from "../components/NewReceipt";
import AdvancedFiltersContext from "../../shared/components/AdvancedFiltersContext";
import AdvancedFilterService from "../../../services/AdvancedFilterService";

const InvoiceTableHeader = ({ client, ioTreeFilters, reload}: { client: { PersonId: number, Name: string }, ioTreeFilters: string, reload: () => void }) => {
    const { translate } = TranslationService;
    const filtersContext = useContext(AdvancedFiltersContext);
    const tableContext = useContext(TableContext);
    const { applyFilters } = tableContext;

    const exportInvoices = async (reportId: number | null) => {
        const { getFilters, response } = tableContext as InvoiceTableContextValues;
        InvoiceService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };

    const fixedFiltersChange = (value: number) => {
        applyFilters({ quickfilter: value, quickExtraFilter: "" });
    };

    const fixedFilterChildChange = (value: number, parentTitle: string) => {
        if (parentTitle === translate.ByStatus) {
            const definition = {
                AdditionalDefinitionItems: CompanyService.getIoStatus().map(x => ({ Label: x.Value, Value: x.IOStatusID.toString() })),
                Entity: Entities.Invoice,
                Name: translate.IOStatus,
                Field: "iostatus",
                Type: DataType.List
            };

            const filter = AdvancedFilterService.filterToString({
                kind: DataType.List,
                definition: definition,
                value: value.toString(),
                operator: 0
            });
            applyFilters({ quickfilter: "2", quickExtraFilter: filter });
        }
    };

    const statusList = CompanyService.getIoStatus();

    const invoiceFilters = [
        { value: 1, text: translate.PendingInvoices },
        { value: 0, text: translate.DueInvoices },
        { value: 4, text: translate.ClaimableInvoices },
        { value: 2, text: translate.AllInvoices },
        {
            text: translate.ByStatus, childItems: statusList.map(x => {
                return { text: x.Value, value: x.IOStatusID };
            })
        }
    ];

    const showNewInvoiceModal = () => {
        FloatingPanelService.showPanel({
            children: <InvoiceNew reload={reload} client={client} />,
            title: translate.NewInvoice,
            height: 800,
            width: 750,
        });
    };

    const showNewRecieptModal = () => {
        FloatingPanelService.showPanel({
            children: <NewReciept personId={client.PersonId} />,
            title: translate.NewReciept,
            height: 800,
            width: 750,
        });
    };

    const exportItems = [
        {
            key: translate.Default,
            onClick: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === 1)
            .map(x => ({ key: x.Name ?? TranslationService.translate.Default, onClick: () => exportInvoices(x.ReportExportID) })),
    ];

    useEffect(() => {
        applyFilters({ iotree: ioTreeFilters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ioTreeFilters]);


    return (
        <div className="card-header d-flex align-items-center flex-row mb-3" id="invoice-table-header">
            <div className="col d-flex flex-row gap-3">
                <Dropdown onChange={fixedFiltersChange} onChildItemChange={fixedFilterChildChange} items={invoiceFilters} />
                <button className="btn btn-tertiary" onClick={() => filtersContext.showFilters()}><i className="far fa-filter"></i>{translate.Filters}</button>
                <div className="col d-flex flex-row justify-content-end gap-3">
                    {CompanyService.canDo("export") &&
                        <FilterDropdownButton items={exportItems} title={<><i className="far fa-arrow-to-bottom pe-1"></i> {translate.ExportToXls}</>} />
                    }
                    {CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio") &&
                        <button type='button' className="btn btn-secondary" onClick={showNewInvoiceModal}>
                            + {translate.AddInvoice}
                        </button>}

                    {CompanyService.canDo("hasReceipt") &&
                        <button type='button' className="btn btn-secondary" onClick={showNewRecieptModal}>
                            + {translate.NewReciept}
                        </button>}
                </div>
            </div>
        </div>
    );
};

export default InvoiceTableHeader;