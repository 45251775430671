import React, { useState } from 'react';
import FloatingPanelService from './FloatingPanel';

type ITabPositionContext = {
    currentTabPosition: string | null,
    setCurrentTabPosition: (value: string) => void,
}

const defaultTabPositionContext: ITabPositionContext = {
    currentTabPosition: '',
    setCurrentTabPosition: (value: string) => {
        console.debug('defaultTabPositionContext', 'setCurrentTabPosition', value);
        console.error('No TabPositionContext defined');
    },
}

const TabPositionContext = React.createContext<ITabPositionContext>(defaultTabPositionContext);

const TabPositionProvider = (props: any) => {

    const [currentTabPosition, _setCurrentTabPosition] = useState<string | null>(null);
    const setCurrentTabPosition = (pos: string) => {
        if (currentTabPosition === pos){
            return;
        }
        FloatingPanelService.hidePanel();
        _setCurrentTabPosition(pos);
    }

    const value: ITabPositionContext = {
        currentTabPosition,
        setCurrentTabPosition,
    }

    return (
        <TabPositionContext.Provider value={value}>
            {props.children}
        </TabPositionContext.Provider>
    )
}

export { TabPositionProvider };
export default TabPositionContext;