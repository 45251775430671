
import Dropdown from "../../../shared/components/Dropdown";
import { useState, useEffect } from "react";
import ActionService, { ActionType } from "../../../../services/ActionService";
import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import { endpointRequest } from "../../../../utils/RequestUtils";
import { OptionalMap } from "../../../../utils/Utils";
import { Field } from "./FieldHelpers";
import { EditProps, strEvent } from "../EditAction";
import { ValidationMessage } from "../../../shared/RequieredManager";
import CompanyService from "../../../../services/CompanyService";

export const Title = ({ data, setField, propGen: props }: EditProps) => {
    enum ActionOptionTypes {
        Custom,
        NewInvoiceNotification,
    }
    const showOptionTypes = data.Type === ActionType.MailSend;
    const selectedOptionType = OptionalMap(data.When, x => Math.max(x, 0));
    const [folders, setFolders] = useState<{ value: number; text: string; }[]>();
    useEffect(() => {
        endpointRequest(ActionService.list, x => {
            setFolders(x.list.map(y => ({ value: y.id, text: y.name! })).filter(y => y.text?.length));
        });
    }, []);

    const nameProps = props.str.required("Name");
    const actionProps = props.num.required("Type");
    const typeProps = props.num.required("When");
    return (<>
        <div className="row">
            <Field title={TranslationService.translate.Description}>
                <input className="form-control" {...strEvent(nameProps)} />
                <ValidationMessage {...nameProps} message={TranslationService.translate.EnterName} />
            </Field>
            {folders ?
                <Field title={TranslationService.translate.Folder}>
                    <Dropdown optionLabel={TranslationService.translate.None}
                        onChange={setField("ActionFolderID")}
                        value={data.ActionFolderID} items={folders.slice()} />
                </Field>
                :
                <Field title={TranslationService.translate.Folder}>
                    <Dropdown optionLabel={TranslationService.translate.None}
                        onChange={setField("ActionFolderID")}
                        items={[]} />
                </Field>
            }
        </div>
        <div className="row">
            <Field title={TranslationService.translate.Action}>
                <Dropdown optionLabel={TranslationService.translate.Select} {...actionProps}
                    items={getEnumArray(ActionType).filter(el => {
                        if (!CompanyService.canShowWhatsApp()) {
                            return el.value !== 7 ? el : undefined
                        } else {
                            return el
                        }
                    })} />
                <ValidationMessage {...actionProps} message={TranslationService.translate.EnterType} />
            </Field>
            {showOptionTypes && <Field title={TranslationService.translate.Type}>
                <Dropdown items={getEnumArray(ActionOptionTypes).reverse()}
                    optionLabel={TranslationService.translate.Select} {...typeProps}
                    defaultValue={selectedOptionType} />
                <ValidationMessage {...typeProps} defaultValue={selectedOptionType}
                    validationMethod={x => data.Type !== ActionType.MailSend || x !== undefined}
                    message={TranslationService.translate.EnterType} />
            </Field>}
        </div>
    </>);
}