import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService'
import { formatCurrency } from '../../../utils/FormatUtils'

export type GroupType = {
    Children: GroupType[] | [],
    amount: number,
    cant: number,
    due: number,
    iocount: number,
    max: number,
    tree: string,
    value: string,
    valueid?: string
    index?: number
}

type InvoiceGroupWithChildrenType = {
    dataValues: GroupType
    parentChecked: boolean | undefined
    spacing: number
    currencyTotals: any
    maxDebt: number
    filtersSetter: string[]
    setFiltersSetter: (val: any) => void
    setConfirmWasCheckedParent?: (val: any) => void
}

interface TreeNode {
    tree: string;
    Children: TreeNode[];
}

function concatTreeValues(obj: TreeNode): string {
    const result: string[] = [];
    if (obj && obj.tree) { result.push('"' + obj.tree + '"'); }
    if (obj && obj.Children && obj.Children.length > 0) {
        obj.Children.forEach((child) => result.push(concatTreeValues(child)));
    }
    return result.join(",");
}

export const InvoiceGroupWithChildren = ({
    dataValues,
    spacing,
    parentChecked,
    currencyTotals,
    maxDebt,
    filtersSetter,
    setFiltersSetter,
    setConfirmWasCheckedParent = () => { },
}: InvoiceGroupWithChildrenType) => {
    const refInput = useRef<any>()
    const [valueChecked, setValueChecked] = useState<boolean | undefined>()
    const [confirmChildWasChecked, setConfirmChildWasChecked] = useState<any>([])
    const [showChildren, setShowChildren] = useState<boolean>(false)

    const { due, amount, value, iocount, Children, index } = dataValues
    const currentValueChecked = useMemo(() => valueChecked || parentChecked, [valueChecked, parentChecked])

    // Calculatede Values
    const spaceMargin = spacing;
    const hasChildren = Children.length > 0
    const noDueShow = amount - due
    const dueCalc = ((due * 100) / maxDebt)
    const noDueCalc = ((noDueShow * 100) / maxDebt)
    const percentDue = `${dueCalc}%`
    const percentNoDue = `${noDueCalc}%`

    // validations
    const childsUnchecked = confirmChildWasChecked.length === 0 && refInput.current?.indeterminate
    const someChildsChecked = confirmChildWasChecked.length < Children.length && confirmChildWasChecked.length > 0
    const allChildsChecked = confirmChildWasChecked.length === Children.length

    const handleCheckValues = useCallback((checked: boolean) => {
        const valueAdded = index + value
        const iotree = concatTreeValues(dataValues)
        const isInArray = filtersSetter.includes(iotree)

        if (checked && !isInArray) {
            setFiltersSetter((p: string[]) => ([...p, iotree]))
        } else if (!checked && isInArray) {
            const newFilterSet = [...new Set(filtersSetter)].filter((item) => item !== iotree);
            setFiltersSetter(newFilterSet)
        }
        setValueChecked(checked)
        setConfirmWasCheckedParent((prev: any) => checked ? ([...prev, valueAdded]) : ([...prev.filter((p: any) => p !== valueAdded)]))
    }, [dataValues, filtersSetter, index, setConfirmWasCheckedParent, setFiltersSetter, value])

    const handleShowChildren = () => setShowChildren((p: boolean) => !p)

    useEffect(() => {
        if (hasChildren) {
            if (childsUnchecked) {
                refInput.current.indeterminate = false
                handleCheckValues(false)
            } else if (someChildsChecked) {
                refInput.current.indeterminate = true
            } else if (allChildsChecked) {
                refInput.current.indeterminate = false
                if (currentValueChecked) { handleCheckValues(false) }
                else { handleCheckValues(true) }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChildWasChecked, hasChildren])


    return (
        <>
            <tr>
                <td className={`d-flex align-items-center col`}>
                    <div className='col-1' style={{ marginLeft: `${spaceMargin}%` }} onClick={handleShowChildren}>
                        {hasChildren &&
                            <>
                                {showChildren ?
                                    <i className="fas fa-minus icono-input" /> :
                                    <i className="fas fa-plus icono-input" />}
                            </>
                        }
                    </div>
                    <div style={{ marginLeft: `${spaceMargin / 2}%` }}>
                        <input
                            ref={refInput}
                            className="checkbox-invoice me-3"
                            type="checkbox"
                            name="check-invoice"
                            onChange={(e) => handleCheckValues(e.target.checked)}
                            checked={currentValueChecked}
                        />
                        <span>{value}</span>
                    </div>
                </td>
                <td className='col text-end'>{iocount}</td>
                <td className='col text-end'>{formatCurrency(amount, currencyTotals)}</td>
                <td className='popover__wrapper'>
                    <div className="progress progress-amount">
                        <div className="progress-bar bg-danger bg-gradient progress-bar-stripped bg-opacity-75" style={{ width: percentDue }} />
                        <div className="progress-bar bg-primary bg-gradient bg-opacity-75" style={{ width: percentNoDue }} />
                    </div>
                    <div className="popover__content">
                        <p className="popover__message" style={{ color: 'red' }}>{TranslationService.translate.Due}: {formatCurrency(due, currencyTotals)}</p>
                        <p className="popover__message" style={{ color: 'blue' }}>{TranslationService.translate.NoDue}: {formatCurrency(noDueShow, currencyTotals)}</p>
                    </div>
                </td>
            </tr>

            {showChildren && hasChildren && Children.map((value, index) =>
                <InvoiceGroupWithChildren
                    key={index}
                    dataValues={{ ...value, index }}
                    spacing={spaceMargin + 4}
                    maxDebt={maxDebt}
                    currencyTotals={currencyTotals}
                    parentChecked={currentValueChecked}
                    setFiltersSetter={setFiltersSetter}
                    filtersSetter={filtersSetter}
                    setConfirmWasCheckedParent={setConfirmChildWasChecked}
                />
            )}

        </>
    )
}
