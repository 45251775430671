import { useState, useEffect, useContext, ChangeEvent, useMemo } from "react";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import RangedDatepicker from "./DateRangePicker";
import { formatDateDigits } from "../../../utils/FormatUtils";
import { AutocompleteClient, AutocompleteGroup, MultiselectEditor, TagsDropdownEditor } from "./Editors";
import FilterService, { FilterCollection, FilterDefinition, FilterOption } from "../../../services/FilterService";
import CompanyService, { DataType } from "../../../services/CompanyService";
import AdvancedFilterService, { Filter, FilterActivityTypeWithDate, FilterLine } from "../../../services/AdvancedFilterService";
import Collapsable from "../bootstrap/Collapsable";
import AdvancedFiltersContext, { AdvancedFiltersContextValues } from "./AdvancedFiltersContext";
import ModalService, { ModalParams } from "../bootstrap/Modal";
import Dropdown, { DropdownProps } from "./Dropdown";
import ActivityService from "../../../services/ActivityService";
import { Moment } from "moment";
import moment from "moment";

type AdvancedFiltersParams = {
    page: FilterCollection,
    onFilterApply?: (filters: string[]) => void,
    defaultValue?: string,
    onAppliedFiltersCount?: (filterCount: number) => void,
    customContext?: AdvancedFiltersContextValues;
    showSaveFilter?: boolean;
}

const AdvancedFilters = ({ page, onFilterApply = undefined, defaultValue = undefined, customContext = undefined, showSaveFilter = true }: AdvancedFiltersParams) => {
    const filtersOptions = FilterService.GetFiltersForPage(page);
    const context = useContext(AdvancedFiltersContext);
    const [filters, setFilters] = useState<FilterLine[]>(AdvancedFilterService.parseFilters(defaultValue, filtersOptions));
    const { applyFilters } = useContext(TableContext);

    useEffect(() => {
        (customContext ?? context).showFilters = () => {
            const modalParams = new ModalParams();
            modalParams.children = <FilterModal defaultFilters={filters} filtersOptions={filtersOptions} onFilterApply={_applyFilters} showSaveFilter={showSaveFilter} />;
            modalParams.closeClickingOutside = false;
            modalParams.title = TranslationService.translate.AdvancedFilters;
            modalParams.size = "modal-xl";
            ModalService.showModal(modalParams);
        };
    });

    const _applyFilters = (filters: FilterLine[]) => {
        setFilters(filters);
        const filter = { extraFilters: filters.map(x => x.encodedFilter).filter(x => x !== undefined && x.length > 0) };
        if (onFilterApply) {
            onFilterApply(filter.extraFilters);
        }
        else {
            applyFilters(filter);
        }
    };

    const deleteFilter = (filter: FilterLine) => {
        _applyFilters(filters.filter(x => x.id !== filter.id));
    };

    const filtersToShow = filters.filter(x => !x.isEmpty());

    return (
        <Collapsable show={filtersToShow.length > 0}>
            <div className="chipsRowFilter">
                {filtersToShow.map(x =>
                    <div className="chipItem" key={x.id}>
                        {AdvancedFilterService.FriendlyName(x)}<i className="far fa-times ms-2 pointer" onClick={() => deleteFilter(x)}></i>
                    </div>)}
            </div>
        </Collapsable>
    );
};
const FilterModal = ({ filtersOptions, defaultFilters, onFilterApply, showSaveFilter }: { filtersOptions: FilterOption[], defaultFilters: FilterLine[], onFilterApply: (filters: FilterLine[]) => void, showSaveFilter: boolean }) => {
    const [filters, setFilters] = useState<FilterLine[]>(defaultFilters.length === 0 ? [new FilterLine()] : defaultFilters);

    const deleteFilter = (item: FilterLine) => {
        const newFilters = [...filters.filter(x => x.id !== item.id)];
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([...filters, new FilterLine()]);
    };

    const updateFilter = (item: FilterLine, value: Filter) => {
        Object.assign(item, value);
        setFilters([...filters]);
    };

    const deleteFilters = () => {
        setFilters([new FilterLine()]);
    };

    const applyFilter = () => {
        const newFilters = [...filters.filter(x => Number(x.kind) !== -1)];
        newFilters.forEach(x => x.encodedFilter = AdvancedFilterService.filterToString(x));
        onFilterApply(newFilters);
        ModalService.hideModal();
    };

    return (
        <>
            <div className="modal-body">
                {filters.map((item, index) =>
                    <FilterComponent key={item.id} deleteFilter={() => deleteFilter(item)} updateFilter={value => updateFilter(item, value)} options={filtersOptions} defaultValue={item.isEmpty() ? undefined : item} />)}
                <div className="filtersActions mt-2">
                    <div className="addRow">
                        <button className="btn btn-link p-0" onClick={addFilter}>
                            <i className="fas fa-plus me-2"></i>{TranslationService.translate.AddFilters}</button>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-underline btn-underline-danger px-3" onClick={deleteFilters}><i className="far fa-trash me-2"></i>{TranslationService.translate.ClearFilters}</button>
                {/* {showSaveFilter &&
                    <button type="button" className="btn btn-underline px-3" onClick={saveFilter}><i className="far fa-download me-2"></i>{TranslationService.translate.SaveFilter}</button>
                } */}
                <button type="button" className="btn btn-primary" onClick={applyFilter}>{TranslationService.translate.Filter}</button>
            </div>
        </>
    );
};

const FilterComponent = (props: { options: FilterOption[], deleteFilter: () => void, updateFilter: (filter: Filter) => void, defaultValue?: Filter }) => {
    const [filter, setFilter] = useState(props.defaultValue);
    const { translate } = TranslationService;

    const SelectOperations = ({ value, operators }: { value?: number, operators: readonly string[] }) => {
        const onChange = (value: string) => {
            const newFilter = filter!;
            newFilter.operator = operators.indexOf(value);
            if (value === "Empty") {
                newFilter.value = "";
            }
            updateFilterValue(newFilter);
            setFilter(newFilter);
        };
        return (
            <div className="col-md-4">
                <Dropdown value={operators[value ?? 0]} onChange={onChange} items={operators.filter(x => x).map(x => ({ text: TranslationService.getTranslation(x), value: x }))} />
            </div>
        );
    };

    const MultipleSelectOperations = ({ selectedValues, keyValues }: { selectedValues?: { label: string, value: string }[], keyValues: { label: string, value: string }[] }) => {
        const { translate } = TranslationService;
        const [values, setValues] = useState(selectedValues);
        const onChange = (values: string[] | undefined) => {
            const newFilter = filter!;
            if (!values) {
                updateFilterValue(newFilter);
                setFilter(newFilter);
                return;
            }

            setValues(values.map(x => keyValues.find(y => y.value === x)!));
            newFilter.value = values.join(",");
            updateFilterValue(newFilter);
            setFilter(newFilter);
        };

        return (
            <div className="col-md-4">
                <MultiselectEditor placeholder={translate.Select} callback={onChange} value={values} items={keyValues} />
            </div>
        );
    };

    const onFilterChange = (property: string) => (value: string) => {
        const newFilter = filter!;
        Object.assign(newFilter, { [property]: value });
        setFilter({ ...newFilter });
        updateFilterValue({ ...newFilter });
    };

    const onChangeValueInput = (event: ChangeEvent<HTMLInputElement>) => onChangeValue(event.target.value);
    const onChangeValue = onFilterChange("value");

    const SelectValue = ({ value, keyValues }: { value?: string, keyValues: { Label: string, Value: string }[] }) => {
        return (
            <div className="col-md-4">
                <Dropdown value={value} onChange={onChangeValue} items={keyValues.map(x => ({ text: x.Label, value: x.Value }))} />
            </div>
        );
    };



    const handleFilter = (definition: undefined | FilterDefinition) => {
        if (definition === undefined) {
            setFilter(undefined);
            return;
        }
        const filter: Filter = { kind: definition.Type, definition: definition };

        setDefaultValues(filter);
        updateFilterValue(filter);
        setFilter({ ...filter, operator: 0 });
        function setDefaultValues(filter: Filter) {
            switch (filter.kind) {
                case DataType.List:
                    //filter.value = filter.definition.AdditionalDefinitionItems[0].Value;
                    filter.value = undefined;
                    break;
                case DataType.YesNo:
                    filter.value = "0";
                    break;
                case DataType.Date:
                    filter.value = formatDateDigits(new Date(), "ja").replaceAll("/", "");
                    break;
                case DataType.Priority:
                    filter.value = "1";
                    break;
                default:
                    break;
            }
        }
    };

    const updateFilterValue = (filter: Filter) => {
        props.updateFilter(filter);
    };

    const formatDefinitionKey = (definition: FilterDefinition) => {
        return definition.Entity + "%" + definition.Field;
    };

    const mainDropdownItems: DropdownProps<FilterDefinition>["items"] = props.options
        .flatMap(x => [({ text: x.title, isTitle: true }), ...x.definitions.map(y => ({ text: y.Name, value: y, key: formatDefinitionKey(y) }))]);

    const values = filter?.value?.split(",");
    const mappedItems = filter?.definition.AdditionalDefinitionItems.map(x => ({ label: x.Label, value: x.Value }));
    const selectedValues = mappedItems?.filter(x => values?.includes(x.value));
    const activityTypeFilterItems = useMemo(() => ActivityService.ActivityTypeFilterItems
        .map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })), []);

    const typeFilters = useMemo(() => ActivityService.TypeFilterItems
        .map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })), []);

    return (
        <div className="filterRow-bg mb-3">
            <div className="row me-2">
                <div className="col-md-4">
                    <Dropdown value={filter?.definition} onChange={handleFilter} optionLabel={translate.Select} items={mainDropdownItems} showSearchFilter={true} />
                </div>
                {filter && DataType.List === filter.kind && <>
                    <SelectOperations operators={AdvancedFilterService.listOperators} value={filter.operator} />
                    {filter.operator !== 7 &&
                        <MultipleSelectOperations selectedValues={selectedValues} keyValues={mappedItems!}></MultipleSelectOperations>
                    }
                </>}
                {filter && DataType.ListNoOperator === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={filter.definition.AdditionalDefinitionItems} />
                </>}
                {filter && DataType.Date === filter.kind && <DateTextboxValue defaultValue={filter.value} onChangeValue={onChangeValue} />}
                {filter && [DataType.Currency, DataType.Number, DataType.DaysOld].includes(filter.kind) && <>
                    <SelectOperations operators={AdvancedFilterService.numericOperator} value={(filter!).operator} />
                    <div className="col"><input onChange={onChangeValueInput} className="form-control" defaultValue={filter.value} type="number" /></div>
                    {filter.kind === DataType.DaysOld && <div className="col-auto"><input type="text" readOnly className="form-control-plaintext" value={translate.DaysOld} /></div>}
                </>}
                {filter && DataType.DayCompare === filter.kind && <>
                    <SelectOperations operators={AdvancedFilterService.numericOperator} value={(filter!).operator} />
                    <div className="col"><input onChange={onChangeValueInput} className="form-control" defaultValue={filter.value} type="number" /></div>
                    <div className="col-auto"><input type="text" readOnly className="form-control-plaintext" value={translate.Days} /></div>
                </>}
                {filter && DataType.Tags === filter.kind &&
                    <div className="col">
                        <TagsDropdownEditor onChange={onChangeValue} defaultValue={filter.value} forceMultiple={true} />
                    </div>
                }
                {filter && DataType.ActivityType === filter.kind &&
                    <div className="col-md-4">
                        <MultiselectEditor callback={x => onChangeValue(x?.join(",") ?? "")} value={filter.value?.split(",").map(x => filter.definition.AdditionalDefinitionItems.find(y => y.Value === x)!).filter(x => x).map(x => ({ value: x.Value, label: x.Label }))} items={filter.definition.AdditionalDefinitionItems.map(x => ({ value: x.Value, label: x.Label }))} />
                    </div>
                }
                {filter && DataType.User === filter.kind &&
                    <SelectValue value={filter.value} keyValues={CompanyService.getUsers().map(x => ({ Label: x.Value, Value: x.Id }))} />
                }
                {filter && [DataType.Client, DataType.ClientGroup].includes(filter.kind) &&
                    <div className="col"><AutocompleteClient onChange={onChangeValue} defaultValue={filter.value} /></div>
                }
                {filter && [DataType.ClientGroup].includes(filter.kind) &&
                    <div className="col row align-items-center">
                        <div className="col-auto">{CompanyService.getGroupName()}:</div><div className="col"><AutocompleteGroup onChange={x => onFilterChange("operator")(x?.value ?? "")} clientId={filter.value ?? ""} /></div></div>
                }
                {filter && DataType.Priority === filter.kind &&
                    <SelectValue value={filter.value} keyValues={Array.from({ length: CompanyService.getTotalPriorities() }).map((x, i) => i + 1).map(x => ({ Label: x.toString(), Value: x.toString() }))} />
                }
                {filter && [DataType.Text, DataType.Phone, DataType.Link, DataType.TextWithOperator].includes(filter.kind) && <>
                    <SelectOperations operators={AdvancedFilterService.textWithOperatorOperators} value={filter.operator} />
                    {filter.operator !== 7 && <div className="col"><input onChange={onChangeValueInput} defaultValue={filter.value} className="form-control" /></div>}
                </>}
                {filter && DataType.ExpirationDate === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={AdvancedFilterService.expirationDateOptionsUntranslated.map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })).concat(CompanyService.GetAgeingValues().map(x => ({ Label: x.name, Value: x.filterVal })))} /></>
                }
                {filter && DataType.YesNo === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={[{ Value: "0", Label: translate.No }, { Value: "1", Label: translate.Yes }]} /></>
                }
                {filter && filter.kind === DataType.Percentage && <>
                    <SelectOperations operators={AdvancedFilterService.percentageOperators} value={filter.operator} />
                    <div className="col"><input onChange={onChangeValueInput} className="form-control" type={"number"} defaultValue={filter.value} /></div>
                    <div className="col-auto"><input type="text" readOnly className="form-control-plaintext" value={"% " + translate.OfUsedCredit} /></div>
                </>}
                {filter && DataType.Status === filter.kind &&
                    <SelectValue value={filter.value} keyValues={filter.definition.AdditionalDefinitionItems} />
                }
                {filter && DataType.Type === filter.kind &&
                    <SelectValue value={filter.value} keyValues={typeFilters} />
                }
                {filter && DataType.ActivityTypeWithDate === filter.kind && <>
                    <div className="col-md-3">
                        <DateTextboxValue defaultValue={filter.value ?? null} onChangeValue={onChangeValue} />
                    </div>
                    <div className="col-md-2">
                        <select className="form-control h-40px" value={(filter as FilterActivityTypeWithDate).activityTypeFilter} onChange={(e) => onFilterChange("activityTypeFilter")(e.target.value)}>
                            <option>{translate.All}</option>
                            {activityTypeFilterItems.map((item: { Label: string, Value: string }) => (
                                <option key={item.Value} value={item.Value}>{item.Label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select className="form-control h-40px" value={(filter as FilterActivityTypeWithDate).userId} onChange={(e) => onFilterChange("userId")(e.target.value)}>
                            <option>{translate.All}</option>
                            {CompanyService.getUsers().map((item) => (
                                <option key={item.Id} value={item.Id}>{item.Value}</option>
                            ))}
                        </select>
                    </div>
                </>}
            </div>
            <div className="delete">
                <button className="btn" onClick={props.deleteFilter}><i className="fas fa-times"></i></button>
            </div>
        </div>
    );
};

const DateTextboxValue = ({ onChangeValue, defaultValue }: { defaultValue?: string | null, onChangeValue: (value: string) => void }) => {
    const [rangedDefaultDate, setRangedDefaultDate] = useState(defaultValue);
    const [daysCount, setDaysCount] = useState<Moment>();
    const operators = [
        { text: TranslationService.translate.Equal, value: (day?: Moment) => [day, day] },
        { text: TranslationService.translate.Greater, value: (day?: Moment) => [day?.clone(), day?.add(50, "years")] },
        { text: TranslationService.translate.LessThan, value: (day?: Moment) => [day?.clone().add(-50, "years"), day] },
    ];
    const [daysOperator, setDaysOperator] = useState(() => operators[0].value);

    const updateRangedDatepicker = (days?: Moment, op?: typeof daysOperator) => {
        const opValue = op ?? daysOperator;
        const daysValue = days ?? daysCount;
        const [start, end] = opValue(daysValue);
        setRangedDefaultDate(!start ? null : (start.format("YYYYMMDD") + "-" + (end ?? start).format("YYYYMMDD")));
        onChange(start?.toDate(), end?.toDate());
    };
    const formatDate = (date: Date) => formatDateDigits(date, "ja").replaceAll("/", "");
    const onChange = (start?: Date, end?: Date) => {
        if (start === undefined) {
            onChangeValue("");
            return;
        }
        if (end === undefined) {
            onChangeValue("");
            return;
        }
        const value = (start.setHours(0, 0, 0, 0) === end.setHours(0, 0, 0, 0)) ? formatDate(start) : (formatDate(start) + "-" + formatDate(end))
        onChangeValue(value);
    };

    return (<>
        <div className="col">
            <RangedDatepicker key={rangedDefaultDate} onChange={onChange} defaultValue={rangedDefaultDate} />
        </div>
        <div className="col row align-items-center">
            <div className="col-auto">|</div>
            <div className="col ps-0">
                <Dropdown items={operators} onChange={(op) => {
                    setDaysOperator(() => op);
                    updateRangedDatepicker(undefined, op);
                }} />
            </div>
            <div className="col">
                <input className="form-control d-block-inline" type="number" onChange={(e) => {
                    const value = isNaN(e.target.valueAsNumber) ? undefined : moment().add(e.target.valueAsNumber, "day");
                    setDaysCount(value);
                    updateRangedDatepicker(value, undefined);
                }} />
            </div>
            <div className="col-auto">{TranslationService.translate.Days}</div>
        </div>
    </>);
};

export default AdvancedFilters;