import { PropsWithChildren, useEffect } from "react";
import { TranslationService } from "../../services/TranslationService";
import { formatDateTimeShort } from "../../utils/FormatUtils";
import Table, { TableHeader } from "../shared/Table"
import { ActionDetailResponse } from "./entities/ActionDetailResponse";
import { ActionDetailTableProvider } from "./providers/ActionDetailTableProvider"
import { ActionType } from "../../services/ActionService";
import { restTimezone } from "../../utils/ParseUtils";

export const ActionDetail = ({ id, type }: { id: number, type: ActionType }) => {
    const { translate } = TranslationService;
    const tableHead: TableHeader[] = [
        new TableHeader("date", translate.Date, false, false),
        new TableHeader("cant", translate.SendType, false, false),
        new TableHeader("sent", translate.SentPlural, false, false),
    ];
    let tableItem = ActionDetailItem;
    if ([ActionType.MailSend, ActionType.ActionSendLetter].includes(type)) {
        tableHead.push(
            new TableHeader("noemail", translate.WithoutEmail, false, false),
            new TableHeader("noio", translate.WithoutInvoices, false, false),
            new TableHeader("internalmails", translate.InternalEmails, false, false),);
        tableItem = ActionDetailItemExpanded;
    } else if (type === ActionType.ActionSMSSend) {
        tableHead.push(
            new TableHeader("nophone", translate.WithoutPhone, false, false),
            new TableHeader("errorsms", "Error sms", false, false),);
        tableItem = ActionDetailItemSms;
    }
    useEffect(() => {
        const interval = setInterval(() => {
            const notSentH3 = document.getElementById("not-sent-title");
            if (!notSentH3) {
                return;
            }
            const headers = [...notSentH3.parentNode?.querySelectorAll("table th") ?? []].slice(-3).map(x => (x as HTMLTableCellElement));
            if (headers?.length > 0) {
                // const headersSize = headers.map(x => x.offsetWidth).slice(0,-1).reduce((a, b) => a + b, 0) + ([...headers[headers.length - 1].children][0] as HTMLDivElement).offsetWidth;
                const firstChild = [...headers[headers.length - 1].children][0] as HTMLDivElement;
                notSentH3.style.paddingRight = `${headers[headers.length - 1].offsetWidth - firstChild.offsetWidth}px`;
                notSentH3.style.paddingLeft = `calc(${headers[0].offsetLeft}px + 0.5rem)`;
                notSentH3.style.display = "block";
            }
        }, 33);
        return () => clearInterval(interval);
    }, [])
    return (
        <ActionDetailTableProvider id={id}>
            <div className="p-3 small-table">
                <h5 className="mb-3">{translate.DeliveryHistory}</h5>
                {[ActionType.MailSend, ActionType.ActionSendLetter, ActionType.ActionSMSSend].includes(type) && <h3 id="not-sent-title" style={{ display: "none", fontWeight: 600, fontSize: 16, textAlign: "center", color: "var(--intiza-darkGrey)", backgroundColor: "var(--intiza-white)" }}>{TranslationService.translate.NotSent}</h3>}
                <Table headers={tableHead} item={tableItem} />
            </div>
        </ActionDetailTableProvider>)
}

const ActionDetailItem = ({ data, children }: PropsWithChildren<{ data: ActionDetailResponse.ActionDetail }>) => {
    return (
        <tr>
            <td>{formatDateTimeShort(restTimezone(new Date(data.Created)))}</td>
            <td>{data.Manual ? "Manual" : "Auto"}</td>
            <td>{data.Cant}</td>
            {children}
        </tr>
    )
}

const ActionDetailItemExpanded = ({ data }: { data: ActionDetailResponse.ActionDetail }) => {
    return (<ActionDetailItem data={data}>
        <td>{data.NoEmail}</td>
        <td>{data.NoInvoices}</td>
        <td>{data.InternalMail}</td>
    </ActionDetailItem>)
}

const ActionDetailItemSms = ({ data }: { data: ActionDetailResponse.ActionDetail }) => {
    return (<ActionDetailItem data={data}>
        <td>{data.NoPhone}</td>
        <td>{data.SMSError}</td>
    </ActionDetailItem>)
}
