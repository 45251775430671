import { useContext, useEffect, useMemo } from "react";
import CompanyService from "../../../services/CompanyService";
import TaskService from "../../../services/TaskSerivce";
import Collapsable from "../../shared/bootstrap/Collapsable";
import { DefaultModalProps } from "../../shared/bootstrap/DefaultModal";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";
import { TranslationService } from '../../../services/TranslationService';
import { TaskListResponse } from "../entities/TaskListResponse";
import { TaskStatus } from "../entities/TaskStatus";
import TableContext from "../TableContext";
import { TaskTableContextValues } from "../TaskTableProvider";
import TooltipComponent from "../../shared/TooltipComponent";

const TaskMassiveChanges = ({ selectedTasks, clear }: { selectedTasks: { taskIds: Number[], "all": boolean }, clear: () => void }) => {
    const { translate } = TranslationService;
    const { showDefaultModal } = ModalService;
    const { showToast } = useContext(ToastContext);

    const { loading } = useContext(TableContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { clear() }, [loading]);

    const { response, reload, request } = useContext(TableContext) as TaskTableContextValues;
    const selectedCount = selectedTasks.all ? (response as TaskListResponse).itemCount : selectedTasks.taskIds.length;

    const onClickGenerator = (request: () => Promise<unknown | Error>, modalMessage: string, toastMessage: string) => async () => {
        const modalProps: DefaultModalProps = {
            message: modalMessage,
            acceptButtonLabel: translate.Accept,
            onAcceptClick: async () => {
                if (await request() instanceof Error) {
                    showToast(translate.Network)
                }
                else {
                    showToast(toastMessage);
                }
                clear();
                reload();
            }
        }
        showDefaultModal(modalProps);
    }

    const getFulfilledModalText = useMemo(() => {
        if (selectedCount === 1) {
            return TranslationService.translate.AreYouSureMarkAsCompletedSingle;
        }
        else {
            return TranslationService.translate.AreYouSureMarkAsCompleted.replaceAll("{0}", selectedCount.toString());
        };
    }, [selectedCount]);

    const getPendingModalText = useMemo(() => {
        if (selectedCount === 1) {
            return TranslationService.translate.AreYouSureMarkAsPendingSingle;
        }
        else {
            return TranslationService.translate.AreYouSureMarkAsPending.replaceAll("{0}", selectedCount.toString());
        };
    }, [selectedCount]);

    const getDeleteModalText = useMemo(() => {
        if (selectedCount === 1) {
            return TranslationService.translate.AreYouSureDeleteTasksSingle;
        }
        else {
            return TranslationService.translate.AreYouSureDeleteTasks.replaceAll("{0}", selectedCount.toString());
        };
    }, [selectedCount]);

    const MarkAsCompleted = onClickGenerator(() => TaskService.markAs(TaskStatus.Fulfilled, selectedTasks.taskIds, selectedTasks.all, request), getFulfilledModalText, translate.MarkedAsCompleted);
    const MarkAsPending = onClickGenerator(() => TaskService.markAs(TaskStatus.Pending, selectedTasks.taskIds, selectedTasks.all, request), getPendingModalText, translate.MarkedAsPending);
    const Delete = onClickGenerator(() => TaskService.deleteMultiple(selectedTasks.taskIds, selectedTasks.all, request), getDeleteModalText, translate.DeletedTasks);

    const taskPendingNotBlocked = useMemo(() => {
        if (CompanyService.getIsAdmin() || !CompanyService.getSetting("taskdonelock")) {
            return true;
        }
        const invalidTask = response?.list.find(x => (selectedTasks.all || selectedTasks.taskIds.includes(x.TaskID)) &&
            [TaskStatus.Fulfilled, TaskStatus.Validated].includes(x.Status));
        return !invalidTask;
    }, [response?.list, selectedTasks.all, selectedTasks.taskIds]);

    return (
        <Collapsable show={selectedCount > 0}>
            <div data-bs-toggle="collapse" className="selectedCollapse mb-3">
                <div className="quantity me-2">
                    {translate.NumberSelectedTasks.replace("{taskNumber}", selectedCount.toString())}
                </div>
                <div className="actions">
                    {(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) && <>
                        <TooltipComponent title={translate.MarkCompleted}><button onClick={MarkAsCompleted}><i className="far fa-check-circle"></i></button></TooltipComponent>
                        {taskPendingNotBlocked && <TooltipComponent title={translate.MarkPending}><button onClick={MarkAsPending}><i className="far fa-times-circle"></i></button></TooltipComponent>}
                    </>}
                    {(CompanyService.canDo("deletetask")) &&
                        <TooltipComponent title={translate.Delete}><button onClick={Delete}><i className="far fa-trash text-danger"></i></button></TooltipComponent>}
                </div>
            </div>
        </Collapsable>
    );
}

export default TaskMassiveChanges;