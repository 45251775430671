import { CSSProperties, useEffect, useRef, useState } from 'react';
import AuthenticationService from "../../../services/AuthenticationService";
import CompanyService from '../../../services/CompanyService';
import { getCache } from '../../../utils/PersistenceUtils';
import { AUTHENTICATION_DATA_STORAGE_KEY, IAuthenticationData } from '../../shared/AuthenticationContext';
import CashappService from '../../../services/CashappService';
import { isNullOrWhitespace } from '../../../utils/Utils';
import Loading from '../../shared/components/Loading';

let showIframe = () => { };
let loginIframe = () => { };
const CashAppDetail = ({ url, iframeStyle = { width: '100%', height: '300px', border: '1px solid black' } }: { url: string, iframeStyle?: CSSProperties }) => {
	const baseUrl = CashappService.getBaseUrl();
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const [loading, setLoading] = useState(true);
	//url = url.startsWith("/") ? url : ("/" + url)
	useEffect(() => {
		iframeRef.current?.classList.add("d-none");
		setLoading(true);
	}, [url]);
	const cashappid = CompanyService.getCompanyToken()?.cashappid;
	if (isNullOrWhitespace(cashappid)) {
		return <></>;
	}
	const fixedUrl = url.split("://").map(x => x.replaceAll("//", "/")).join("://");

	showIframe = () => {
		if (iframeRef.current === null) {
			return;
		}
		iframeRef.current.classList.remove("d-none");
	}
	loginIframe = async () => {
		if (iframeRef.current === null) {
			return;
		}
		const redirectUrl = baseUrl + "/Account/RedirectCobranzas";
		const result: Error | { key: string } = await CashappService.post("/Account/LoginCobranzas", {});
		if (result instanceof Error) {
			return;
		}
		loginIframe = () => { };
		const origSrc = redirectUrl + "?url=" + iframeRef.current.src.replace(baseUrl, "/es") + "&key=" + result.key;
		iframeRef.current.src = origSrc;
	}
	return (
		<>
			{loading && <Loading></Loading>}
			<div>
				<iframe src={fixedUrl}
					title="Cashapp"
					ref={iframeRef}
					className="d-none"
					onLoad={() => { setLoading(false) }}
					style={iframeStyle}
				/>
			</div>
		</>
	);
}

function refreshToken() {
	const authenticationData = getCache<IAuthenticationData>(AUTHENTICATION_DATA_STORAGE_KEY)!;
	AuthenticationService.refreshToken({ refreshToken: authenticationData });
}

window.addEventListener(
	"message",
	(event) => {
		if (window.origin === event.origin) {
			return;
		}
		if (event.data === "Everythings ok") {
			showIframe();
			refreshToken();

		} else {
			showIframe();
			loginIframe();
		}
	},
);


export default CashAppDetail;