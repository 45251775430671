import React, { useEffect, useRef, useState } from "react";
import DefaultModal, { DefaultModalProps } from "./DefaultModal";
import { Modal } from "bootstrap";
import { TranslationService } from "../../../services/TranslationService";

export class ModalParams {
    size: "modal-sm" | "" | "modal-lg" | "modal-xl" = "";
    title?: string;
    closeClickingOutside: boolean = true;
    children?: React.ReactChild;
}
type ModalParamsWithId = ModalParams & { id: number };
let setModalInternal = (_: ModalParamsWithId) => { };

export class ModalService {
    private static modalId = 1;
    private static previous: React.ReactChild | ModalParams = {} as ModalParams;
    public static showModal(param: React.ReactChild | ModalParams) {
        if (param === ModalService.previous) {
            ModalService.hideModal();
            ModalService.previous = {} as ModalParams;
            return;
        }
        ModalService.previous = param;
        if (!(param instanceof ModalParams)) {
            const modalParams = new ModalParams();
            modalParams.children = param;
            param = modalParams;
        }
        setModalInternal({ ...param, id: ++ModalService.modalId });
    }

    public static showDefaultModal(props: DefaultModalProps) {
        const modal = new ModalParams();
        modal.children = <DefaultModal {...props} />;
        modal.title = props.title;
        modal.size = props.size ?? "";
        modal.closeClickingOutside = true;
        ModalService.showModal(modal);
    }

    public static hideModal() {
        ModalService.previous = {} as ModalParams;
        setModalInternal({ children: undefined, size: "", closeClickingOutside: true, id: ++ModalService.modalId });
    }
}

export const ModalContainer = () => {
    const [modalParams, setModalParams] = useState<ModalParamsWithId>({ ...new ModalParams(), id: 0 });
    const { translate } = TranslationService;
    const bootstrapModal = useRef<Modal | undefined>(undefined);
    const getOptions = (params: ModalParams): Modal.Options => ({
        backdrop: params.closeClickingOutside || 'static',
        keyboard: params.closeClickingOutside,
        focus: true,

    });
    useEffect(() => {
        if (bootstrapModal.current !== undefined) {
            bootstrapModal.current.hide();
            bootstrapModal.current.dispose();
            bootstrapModal.current = undefined;
        }
        if (modalParams.children !== undefined) {
            bootstrapModal.current = new Modal("#modal-service", getOptions(modalParams));
            bootstrapModal.current.show();
        }
    }, [modalParams]);


    useEffect(() => {
        document.getElementById("modal-service")?.addEventListener('hidden.bs.modal', () => {
            ModalService.hideModal();
        }); 
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setModalInternal = setModalParams;
    })
    return (
        <div className="genericModal modal" tabIndex={-1} role="dialog" id="modal-service">
            <div className={"modal-dialog modal-dialog-centered " + modalParams.size} role="document">
                <div className="modal-content">
                    {modalParams.title !== undefined && <div className="modal-header">
                        <h1 className="modal-title fs-5">{modalParams.title}</h1>
                        <button type="button" className="btn-close" onClick={ModalService.hideModal} aria-label={translate.Close}></button>
                    </div>}
                    <div key={modalParams.id}>
                        {modalParams.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalService;

