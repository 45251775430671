import { useState, Dispatch, SetStateAction, useMemo } from "react";
import Dropdown from "../../../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import EditItem from "./EditItem";
import { UpdateModelFunc } from "../ImportTemplateEditBase";

const Frequency = ({ data, updateModel, setModel }: { data?: ImportTemplateListResponse.Item, updateModel: UpdateModelFunc, setModel: Dispatch<SetStateAction<Partial<ImportTemplateListResponse.Item>>> }) => {
	const [period, setPeriod] = useState(data?.AutoPeriod ?? 0);
	const { translate } = TranslationService;
	const updateDay = (index: number) => (selected: boolean) =>
		setModel(x => ({ ...x, AutoFrequency: (x.AutoFrequency ?? 0) ^ (1 << (index)) }));
	const daysOfWeek = useMemo(() => [translate.Sunday, translate.Monday, translate.Tuesday, translate.Wednesday, translate.Thursday, translate.Friday, translate.Saturday,],[translate.Friday, translate.Monday, translate.Saturday, translate.Sunday, translate.Thursday, translate.Tuesday, translate.Wednesday]);
	return (
		<>
			<h5>{translate.Frequency}</h5>
			<EditItem label={translate.Period} >
				<Dropdown onChange={(value: number) => { setPeriod(value); updateModel("AutoPeriod")(value) }}
					defaultValue={period ?? undefined}
					items={[translate.Manually, translate.DailyShort, translate.Weekly, translate.Monthly].map((x,i) => ({text: x, value: i}))} />
			</EditItem>
			{period === 2 /*"weekly"*/ &&
				<div className="row">
					<label className="px-3 w-xl col-form-label font-weight-normal">{translate.WeekDays}</label>
					<div className="px-3">
						<div className="d-flex align-items-center justify-content-between h-100" style={{ gap: 10 }}>
							{daysOfWeek.map((x, i) => <CheckBoxEditor message={x} onChange={updateDay(i)} defaultValue={(Number(data?.AutoFrequency) & (1 << (i))) > 0} />)}
						</div>
					</div>
				</div>
			}
			{period === 3 /*monthly*/ &&
				<EditItem label={translate.Day} ><Dropdown onChange={updateModel("AutoFrequency")} items={Array.from({ length: 31 }, (x, i) => (i + 1)).map(x => ({ text: x.toString(), value: x }))} defaultValue={data?.AutoFrequency} /></EditItem>
			}
		</>)
}

export default Frequency;