import { default as Table } from "../shared/Table";
import { Link, useLocation } from "react-router-dom";
import FilterContainer from "../client/components/FilterContainer";
import { TranslationService } from "../../services/TranslationService";
import moment from "moment";
import ReportHistoryIoListTableProvider from "./providers/ReportHistoryIoListTableProvider";
import ClientService from "../../services/ClientService";
import { InvoiceList } from "../client/invoice/entities/InvoiceListResponse";
import InvoiceService from "../../services/InvoiceService";
import { InvoiceHistoryListRequest } from "../client/invoice/entities/InvoiceHistoryListRequest";
import { TableColumn } from "../client/invoice/InvoiceHistoryTableItem";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import { FilterCollection } from "../../services/FilterService";

const ReportHistoryIoList: React.FC = () => {
    const tableHeaders = getTableHeaders()
    const { search } = useLocation<{ prevRequest?: InvoiceHistoryListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);

    const fromDate = urlParams.get("from")?.replaceAll('-', '')
    const toDate = urlParams.get("to")?.replaceAll('-', '')

    let req = new InvoiceHistoryListRequest();
    req.dateFrom = fromDate || '';
    req.dateTo = toDate || '';
    req.filter = urlParams.get("filter");

    const prevRequest = req;

    const dateFrom = moment(fromDate, 'YYYY-MM-DD').format("DD MMM YYYY")

    return (
        <div className="container-fluid padding">
            <div className="card">
                <h2 className="mb-3">{TranslationService.translate.DueState} {dateFrom}</h2>
                <ReportHistoryIoListTableProvider prevRequest={prevRequest} >
                    <FilterContainer
                        enableImport={false}
                        additionalAction={
                            <Link to={`/${TranslationService.currentLanguage}/report/historyclientlist?${urlParams}`}>
                                {TranslationService.translate.ViewCustomerResume}
                            </Link>} />
                    <AdvancedFilters page={FilterCollection.Invoice} />
                    <Table headers={tableHeaders} item={IoHistoryTableItem}>
                        {/* <ClientHistoryTableTotal /> */}
                    </Table>
                </ReportHistoryIoListTableProvider>
            </div>
        </div>
    )
}

const IoHistoryTableItem = ({ data }: { data: InvoiceList.Item }) => {
    const tableHeaders = getTableHeaders();
    const clientFieldIds = ClientService.getClientHeaders().map(x => x.fieldId);
    return (<tr key={data.IOID}>
        {tableHeaders.map(header => clientFieldIds.includes(header.fieldId)
            ? null//<ClientColumn key={header.fieldId} data={data} header={header} />
            : <TableColumn key={header.fieldId} data={data} header={header} />)}
    </tr>);
}

function getTableHeaders() {
    const tableHeaders = InvoiceService.getTableHeaders();
    const clientHeaders = ClientService.getClientHeaders();
    tableHeaders.unshift(...clientHeaders);
    return tableHeaders;
}


export default ReportHistoryIoList