import { CompanyAuth } from "../../../entities/login/CompanyAuth";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";

type AdditionalDefinition = CompanyAuth.AdditionalDefinition;
type ConfigItem = CompanyAuth.ConfigItem;
export class EntityField {
    id: number = 0;
    title?: string;
    entity: Entities = 0;
    type: DataType = DataType.Text;
    listItems: EntityFieldListItem[] = [];
    Whatsapp: boolean = false;
    ManualEdit: boolean = false;
    isDefault: () => boolean = () => CompanyService.getEntityFieldIsDefault(this.entity, this.id);

    /** @translate se usa para la mayoría de los campos "default" (ConfigItem), que tienen un título fijo dependiente solo del idioma en uso. */
    constructor(field: AdditionalDefinition | ConfigItem) {
        if (instanceOfAdditionalDefinition(field)) {
            this.LoadAdditionalDefinition(field);
        }
        else {
            this.LoadConfigItem(field);
        }
    }

    LoadAdditionalDefinition(field: AdditionalDefinition) {
        this.entity = field.Entity;
        this.id = field.AdditionalDefinitionID;
        this.title = field.Name;
        this.type = field.type;
        this.listItems = field.AdditionalDefinitionItems?.map(x => new EntityFieldListItem(x.AdditionalDefinitionItemID, x.Value, x.Default ?? false)).sort((a: EntityFieldListItem, b: EntityFieldListItem) => (a.value.localeCompare(b.value))) ?? [];
        this.Whatsapp = field.Whatsapp ?? false;
        this.ManualEdit = field.ManualEdit ?? false;
    }

    LoadConfigItem = (field: ConfigItem) => {
        this.entity = field.Entity;
        this.id = field.field;
        this.title = field.name ?? CompanyService.getEntityFieldTitle(this.entity, this.id);
        this.type = CompanyService.getEntityDefaultFieldType(this.entity, this.id);
        this.ManualEdit = field.ManualEdit ?? false;
    }
}

class EntityFieldListItem {
    constructor(
        public id: number,
        public value: string,
        public Default: boolean
    ) { }
}

const instanceOfAdditionalDefinition = (object: {}): object is AdditionalDefinition => 'AdditionalDefinitionID' in object;

export const sortEntityFields = (idList: (string | number)[]) => (field1: EntityField, field2: EntityField) => {
    return idList.indexOf(field1.id.toString()) > idList.indexOf(field2.id.toString()) ? 1 : -1;
}