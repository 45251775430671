import { useEffect, useState } from "react";
import { TranslationService } from '../../services/TranslationService';
import Criteria from "./component/Criteria";
import { CriteriaProvider } from "./providers/CriteriaContext";
import { FormProvider } from "./providers/FormContext";
import ErrorMessage from "../shared/components/Error";
import SegmentService from "../../services/SegmentService";
import Dropdown from "../shared/components/Dropdown";
import Label from "../shared/form/Label";
import CompanyService from "../../services/CompanyService";
import { ToastService } from "../shared/bootstrap/Toast";
import FloatingPanelService from "../shared/FloatingPanel";
import Loading from "../shared/components/Loading";
import { Observable } from "rxjs";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import ModalService from "../shared/bootstrap/Modal";
import { MapIf } from "../../utils/Utils";
import { AxiosError } from "axios";
import { SegmentGetResponse } from "../../entities/segment/SegmentGetResponse";
import { SegmentListResponse } from "../../entities/segment/SegmentListResponse";

export type SegmentModel = Partial<{ [K in keyof SegmentGetResponse.Item]: K extends "SegmentFolderID" ? number | null : SegmentGetResponse.Item[K] }>;
let segmentTemplate: SegmentModel = {
    Name: "",
    AlwaysVisible: true,
    SegmentFolderID: null,
    Priority: null,
    Criteria: [],
}

const EditSegment = ({ id = undefined, folders, reload, onDeleteEvent = undefined }: {
    id?: number,
    folders: SegmentListResponse | Observable<SegmentListResponse | undefined> | undefined,
    reload: () => void,
    onDeleteEvent?: () => void,
}) => {
    const { translate } = TranslationService;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [folderList, setfolderList] = useState(folders instanceof Observable ? undefined : folders);
    const [cloneCount, setCloneCount] = useState(1);

    useEffect(() => {
        if (folders === undefined) {
            const getFolders = async () => {
                const result = await handleErrorWithToast(SegmentService.getList(), () => FloatingPanelService.hidePanel());
                if (!result) {
                    return;
                }
                setfolderList(result);
            }
            getFolders();
            return;
        }
        if (!(folders instanceof Observable)) {
            return;
        }
        const sub = folders.subscribe(x => setfolderList(x));
        return () => sub.unsubscribe();
    }, [folders]);

    const getDefaultData = (id: number | undefined, folders: SegmentListResponse | undefined): SegmentModel => {
        if (!id || folders === undefined) {
            return Object.assign({}, segmentTemplate);
        }
        return folders.list.reduce<SegmentModel | undefined>((prev, x) => prev ?? x.items.find(y => y.SegmentID === id), undefined)!;
    }

    const [data, setData] = useState<SegmentModel>(getDefaultData(id, folderList));
    const shouldDisable = loading;

    useEffect(() => {
        const getData = async () => {
            if (id) {
                setLoading(true);
                setError(false);
                const result = await SegmentService.get(id);
                if (result instanceof Error) {
                    setError(true);
                    setLoading(false);
                    return;
                }
                let criteria: any[] = []
                result.item.Criteria.forEach((criterion) => {
                    if (criterion.ConditionOR) {
                        criterion.or = 2
                    } else {
                        criterion.or = 1
                    }
                    criterion.key = 'def' + Math.random();
                    criteria.push(criterion);
                });
                result.item.Criteria = criteria;
                setData(result.item);
                setLoading(false);
            }
        }
        setData(getDefaultData(id, folderList));
        getData();
    }, [setError, setData, setLoading, id, folderList]);

    const edit = <T extends keyof SegmentModel,>(key: T) => (value: SegmentModel[T]) => setData(x => ({ ...x, [key]: value }));

    const SaveButton = () => {
        const handleEditSegment = async () => {
            let segment = data;
            if (segment.Criteria === undefined || segment.Criteria.length === 0) {
                ToastService.showToast(TranslationService.translate.SelectCriteria);
                return;
            }
            if (!segment.Name || segment.Name.length === 0) {
                ToastService.showToast(TranslationService.translate.EnterSegmentName);
                return;
            }
            if (!segment.SegmentFolderID) {
                segment.SegmentFolderID = null;
            }
            setError(false);
            setLoading(true);
            const result = await SegmentService.set(segment);
            setLoading(false);
            if (result instanceof Error) {
                const axisError = result as AxiosError<{ Message: string }>;
                if (axisError.response?.status === 400 && axisError.response.data.Message === "Segment Name") {
                    ModalService.showDefaultModal({
                        title: TranslationService.translate.Error,
                        message: TranslationService.translate.SegmentNameAlreadyExists,
                    });
                } else {
                    setError(true);
                }
            } else {
                reload();
                FloatingPanelService.hidePanel();
            }
        }
        return (
            <button className="btn btn-primary ms-auto" onClick={handleEditSegment}>{translate.Save}</button>
        );
    }

    const onDelete = () => {
        if (!data.SegmentID) {
            return;
        }
        const deleteSegment = async () => {
            setError(false);
            setLoading(true);
            const result = await SegmentService.delete(data.SegmentID!);
            if (result instanceof Error) {
                setError(true);
                setLoading(false);
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            } else {
                onDeleteEvent && onDeleteEvent();
                reload();
                ToastService.showToast(TranslationService.translate.SegmentDeletedSuccessfully, undefined, "success");
                FloatingPanelService.hidePanel();
            }
        }
        ModalService.showDefaultModal(
            {
                onAcceptClick: deleteSegment,
                acceptButtonLabel: TranslationService.translate.Delete,
                acceptButtonClassName: "btn btn-danger",
                message: TranslationService.translate.SureDeleteSegment,
                title: TranslationService.translate.Delete,
            });
    }

    const cloneModal = () => {
        const count = MapIf(cloneCount, x => !Number.isInteger(x), 1);
        const cloneSegment = async () => {
            setError(false);
            setLoading(true);
            const result = await SegmentService.clone(data.SegmentID!, count);
            if (result instanceof Error) {
                setError(true);
                setLoading(false);
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            } else {
                reload();
                ToastService.showToast(TranslationService.translate.SegmentCloned, undefined, "success");
                FloatingPanelService.hidePanel();
            }
        }
        ModalService.showDefaultModal(
            {
                onAcceptClick: cloneSegment,
                acceptButtonLabel: TranslationService.translate.Clone,
                acceptButtonClassName: "btn btn-primary",
                message: count > 1 ?
                    TranslationService.translate.SureCloneManySegment
                        .replace("{cant}", count.toString()) :
                    TranslationService.translate.SureCloneSegment,
                title: TranslationService.translate.Clone,
            });
    }

    if (loading || folderList === undefined) {
        return <Loading />
    }

    return (
        <>
            <div className="floatingBody p-4">
                <FormProvider data={data} disabled={shouldDisable}>
                    {!error ? <>
                        <Label label={TranslationService.translate.Name}>
                            <input className="form-control" onChange={(e) => edit("Name")(e.target.value)} defaultValue={data.Name} />
                        </Label>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" defaultChecked={data.AlwaysVisible} onChange={(e) => edit("AlwaysVisible")(e.target.checked)} />
                            <label className="form-check-label">{TranslationService.translate.AlwaysVisible}</label>
                        </div>
                        <Label label={TranslationService.translate.Folder}>
                            <Dropdown onChange={edit("SegmentFolderID")} selectClasses="w-100" optionLabel={translate.None} items={folderList.list.map(x => ({ text: x.name ?? "", value: x.id, key: x.id }))} defaultValue={data.SegmentFolderID} />
                        </Label>
                        {CompanyService.getSetting("enablesegmentpriority") &&
                            <Label label={TranslationService.translate.Priority}>
                                <Dropdown onChange={edit("Priority")} selectClasses="w-100" optionLabel={translate.None} items={Array.from({ length: CompanyService.getTotalPriorities() - 1 }).map((x, i) => ({ text: (i + 1).toString(), value: i + 1 }))} defaultValue={data.Priority} />
                            </Label>}
                        <h5 className="h5 mb-0">{translate.Criteria}</h5>
                        <CriteriaProvider data={data} setData={setData}>
                            <Criteria />
                        </CriteriaProvider>
                    </> :
                        <ErrorMessage onRefresh={() => { setError(false) }} message="An error has ocurred" />}
                </FormProvider>
            </div>
            <nav className="floatingFooter">
                <div className="container-fluid px-4">
                    <div className="d-flex justify-content-end w-100">
                        <div className="d-flex flex-row flex-wrap gap-3">
                            {data.SegmentID && <>
                                <button className="btn btn-danger ms-auto" onClick={onDelete}>{translate.Delete}</button>
                                <button className="btn btn-secondary ms-auto show-child-on-hover d-flex"
                                    onClick={cloneModal}>{translate.Clone}
                                    <input
                                        style={{ width: 30, height: 20 }} defaultValue={cloneCount}
                                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                        onChange={(e) => { setCloneCount(parseInt(e.target.value)); }}
                                        className="mx-1 px-1 display-when-hovering-parent" />
                                </button>
                            </>}
                            <SaveButton />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default EditSegment;


