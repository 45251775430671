
import { CheckBoxEditor } from "../../../shared/components/Editors";
import { TranslationService } from '../../../../services/TranslationService';
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import CompanyService from "../../../../services/CompanyService";
import TooltipComponent from "../../../shared/TooltipComponent";


const FixedFieldEdit = ({ data, reload, field: original }: {  data: CompanyAuth.ConfigItem, reload: () => void, field: CompanyAuth.ConfigItem }) => {
    const { translate } = TranslationService;
    const title = data.name ?? CompanyService.getEntityFieldTitle(data.Entity, data.field);
    const fieldName = translate.Name;

    return (
        <>
            <div className="floatingBody p-4">
                <h5 className="mb-3">{title}</h5>
                {title === fieldName ? <section>
                        <CheckBoxEditor onChange={() => { }} message={translate.IncludeInFilters} ></CheckBoxEditor>
                        <CheckBoxEditor onChange={() => { }} message={translate.AllowManualEdit} ></CheckBoxEditor>
                    </section> :
                        <section>
                            <CheckBoxEditor onChange={() => { }} message={translate.IncludeWhenExportingTheCustomersList} ></CheckBoxEditor>
                            <div className="d-flex align-items-center">
                            <CheckBoxEditor onChange={() => { }} message={translate.IncludeInReports} ></CheckBoxEditor>
                            <TooltipComponent title={TranslationService.translate.IncludeInvoicesPaymentsActivitiesTags}>
                                <i className="ms-2 fal fa-question-circle"></i>
                            </TooltipComponent>
                            </div>                         
                            <CheckBoxEditor onChange={() => { }} message={translate.IncludeInFilters} ></CheckBoxEditor>
                            <CheckBoxEditor onChange={() => { }} message={translate.AllowManualEdit} ></CheckBoxEditor>
                            <CheckBoxEditor onChange={() => { }} message={translate.IncludeInCustomerPortal} ></CheckBoxEditor>
                        </section>}
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary">{translate.Save}</button>
            </FloatingPanelFooter>

        </>
    );
}


export default FixedFieldEdit;