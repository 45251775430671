import RequestService from "./RequestService";

export type WhatsappTemplateItemType = {
    waid: string,
    Name: string,
    Structure: string,
    Example: string,
    Parameters: number,
    WATemplateID: number,
    FieldList: string,
}

export type WhatsappTemplateListType = {
    list: WhatsappTemplateItemType[]
}

export type WhatsAppRequestBodySet = {
    WATemplateID: number,
    CompanyID: number,
    FieldList: string,
}
class WhatsappService {

    public static getList(): Promise<Error | WhatsappTemplateListType> {
        return RequestService.post("/watemplate/list", {});
    }

    public static sincroImportTemplates(): Promise<Error | any> {
        return RequestService.post("/watemplate/import", {});
    }

    public static setTemplate(bodyRequest: WhatsAppRequestBodySet): Promise<Error | WhatsappTemplateListType> {
        return RequestService.post("/watemplate/set", { ...bodyRequest });
    }

}


export default WhatsappService;