import { TranslationService } from '../../services/TranslationService';
import TabPosition from "../shared/TabPosition";
import CurrencyConfiguration from "./Configuration/CurrencyConfiguration";
import DashboardConfiguration from "./Configuration/DashboardPanelConfiguration";
import EmailTemplateConfiguration from "./Configuration/EmailTemplateConfiguration";
import OtherConfiguration from "./Configuration/OtherConfiguration";
import VerticalMenu from "../shared/components/VerticalMenu";
import ClientFieldConfiguration from "./Configuration/FieldConfiguration/Fields/ClientFieldConfiguration";
import InvoiceFieldConfiguration from "./Configuration/FieldConfiguration/Fields/InvoiceFieldConfiguration";
import PaymentFieldConfiguration from "./Configuration/FieldConfiguration/Fields/PaymentFieldConfiguration";
import ImportTemplateConfiguration from "./Configuration/ImportTemplate/ImportTemplateConfiguration";
import TransactionType from "./Configuration/TransactionType";
import ActivityType from "./Configuration/ActivityType";
import InvoiceStatus from "./Configuration/InvoiceStatus";
import DataCompanyConfiguration from './Configuration/DataCompanyConfiguration';
import TagsConfiguration from './Configuration/TagsConfiguration';
import SMSConfiguration from './Configuration/SMSConfiguration';
import { ConfigurationProvider } from './ConfigurationContext';
import WhatsappTemplateConfiguration from './Configuration/WhatsappTemplateConfiguration';
import CompanyService from '../../services/CompanyService';

const CompanyConfiguration: React.FC = () => {
    const { translate } = TranslationService;
    let tabs = [
        { title: translate.Company, component: DataCompanyConfiguration },
        { title: translate.ClientFields, component: ClientFieldConfiguration },
        { title: translate.InvoiceFields, component: InvoiceFieldConfiguration },
        { title: translate.PaymentFields, component: PaymentFieldConfiguration },
        { title: translate.Currencies, component: CurrencyConfiguration },
        { title: translate.Tags, component: TagsConfiguration },
        { title: translate.EmailTemplates, component: EmailTemplateConfiguration },
        { title: translate.ImportTemplates, component: ImportTemplateConfiguration },

    ];

    if (CompanyService.canShowWhatsApp()) {
        tabs.push({ title: translate.WhatsAppTemplates, component: WhatsappTemplateConfiguration })
    }

    tabs = [
        ...tabs,
        { title: translate.SMS, component: SMSConfiguration },
        { title: "Dashboard", component: DashboardConfiguration },
        {
            title: translate.Other, component: () =>
                <ConfigurationProvider>
                    <OtherConfiguration />
                </ConfigurationProvider>
        },
        { title: translate.InvoiceStatus, component: InvoiceStatus },
        { title: translate.ActivityTypes, component: ActivityType },
        { title: translate.PaymentTypes, component: TransactionType }
    ]

    return (
        <TabPosition module="Configuration">
            <div className="main-layout-container">
                <div className="main-panel configuration">
                    <VerticalMenu items={tabs} />
                </div>
            </div>
        </TabPosition>
    )
}

export default CompanyConfiguration;