import React, { useCallback, useEffect, useState } from 'react';
import Loading from "../shared/components/Loading";
import { TranslationService } from '../../services/TranslationService';
import DashboardPanel from "./DashboardPanel/DashboardPanel";
import DashboardPanelService from "../../services/DashboardPanelService";
import { DashboardPanelListResponse } from "../../entities/company/DashboardPanel/DashboardPanelListResponse";
import ErrorMessage from "../shared/components/Error";
import { DashboardPanelEntity } from "../../entities/company/DashboardPanel/DashboardPanelEntity";
import Dropdown from '../shared/components/Dropdown';
import { OptionalMap } from '../../utils/Utils';

const DashboardPanelPreview = () => {
    const { translate } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [dashboardPanels, setDashboardPanels] = useState<DashboardPanelListResponse>();
    const [selectedDashboardPanel, setSelectedDashboardPanel] = useState<DashboardPanelEntity>();
    const urlParams = new URLSearchParams(window.location.search);
    const dashboardId = urlParams.get("dashboardId") ?? undefined;
    const defaultId = OptionalMap(dashboardId, parseInt);
    const requestData = async () => {
        const result = await DashboardPanelService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setDashboardPanels(result);
        const urlParams = new URLSearchParams(window.location.search);
        const dashboardId = urlParams.get("dashboardId") ?? undefined;
        const defaultId = OptionalMap(dashboardId, parseInt);
        const dashboardPanel = result?.list.find(x => x.DashboardPanelID === defaultId);
        setSelectedDashboardPanel(dashboardPanel);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const loadDashboards = (id?: number) => {
        const dashboardPanel = dashboardPanels?.list.find(x => x.DashboardPanelID === id);
        setSelectedDashboardPanel(dashboardPanel);
    }

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    return (
        <>
            <div className="dashboard main-layout-container">
                <div className="main-panel mt-4 mx-3">
                    <div className="container-fluid gap-3 pb-3">
                        <div className="row">
                            <div className="col-auto">
                                <label className="font-weight-normal col-form-label pe-3">{TranslationService.translate.SelectDashboard}:</label>
                            </div>
                            <div className="col-2">
                                <Dropdown onChange={loadDashboards} defaultValue={defaultId} optionLabel={translate.Select} items={dashboardPanels?.list.map(x => ({ value: x.DashboardPanelID, text: x.Name })) ?? []} />
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    <DashboardPanel dashboardPanel={selectedDashboardPanel}></DashboardPanel>
                </div>
            </div>
        </>
    )
};

export default DashboardPanelPreview;