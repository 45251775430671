import { default as Table, TableHeaderWithFieldId } from "../shared/Table";
import { useLocation } from "react-router-dom";
import { Cast, Switch } from "../../utils/Utils";

import { ScheduledListRequest } from "./entities/ScheduledListRequest";
import { TranslationService } from "../../services/TranslationService";
import ReportScheduledListTableProvider from "./providers/ReportScheduledListTableProvider";
import { DataType } from "../../services/CompanyService";
import { ScheduledListEntities } from "./entities/ScheduledListResponse";
import { formatDateShort } from "../../utils/FormatUtils";

// REPORTES PROGRAMADOS
const ReportScheduledList: React.FC = () => {
    const tableHeaders = getTableHeaders();
    const { state, search } = useLocation<{ prevRequest?: ScheduledListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);
    const prevRequest = Switch(
        [Boolean(state?.prevRequest), state?.prevRequest],
        [Boolean(urlParams.get("filter")), () => { let req = new ScheduledListRequest(); req.filter = urlParams.get("filter"); return req; }],
    )

    return (
        <div className="container-fluid padding">
            <div className="card">
                <div className="d-flex justify-content-between">
                    <h2 className="mb-3">{TranslationService.translate.ScheduledReports}</h2>
                </div>
                <ReportScheduledListTableProvider prevRequest={prevRequest}>
                    <Table headers={tableHeaders} item={ReportScheduleItem} />
                </ReportScheduledListTableProvider>
            </div>
        </div>
    )
}

const ReportScheduleItem = ({ data }: { data: ScheduledListEntities.ListItem }) => {
    const tableHeaders = getTableHeaders();
    const specialCases = {
        "DestinationEmail": () => Boolean(data.destination) ? <td>FTP: {data.DestinationFTPFolder}</td> : <td>Email: {data.DestinationEmail}</td>,
        "period": () => <td>-</td>
    };

    return (<tr key={data.Name}>
        {tableHeaders.map(header => <ReportScheduleColumn key={header.key} data={data} header={header} specialCases={specialCases} />)}
    </tr>);
}


const ReportScheduleColumn = <T,>({ data, header, specialCases = {} }: { data: ScheduledListEntities.ListItem, header: TableHeaderWithFieldId, specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) } }) => {
    const getValue = () => data[header.key as keyof ScheduledListEntities.ListItem];
    const value = getValue();

    const specialCase = specialCases[header.fieldId];
    if (specialCase) {
        const caseResult = specialCase(Cast<T>(data));
        if (caseResult) {
            return caseResult;
        }
    }

    switch (header.type) {
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
    }

    return <td className="long-text">{value}</td>
}

function getTableHeaders() {
    const tableHeaders = [
        {
            ...new TableHeaderWithFieldId(
                'Naming',
                TranslationService.translate.Reports,
                false,
                true,
                'Naming',
                DataType.Text
            ),
        },
        {
            ...new TableHeaderWithFieldId(
                'Name',
                TranslationService.translate.Name,
                false,
                true,
                'Name',
                DataType.Text
            ),
        },
        {
            ...new TableHeaderWithFieldId(
                'period',
                TranslationService.translate.HeaderWhen,
                false,
                true,
                'period',
                DataType.Date
            ),
        },
        {
            ...new TableHeaderWithFieldId(
                'DestinationEmail',
                TranslationService.translate.Destination,
                false,
                true,
                'DestinationEmail',
                DataType.Text
            ),
        },
    ];

    return tableHeaders;
}



export default ReportScheduledList