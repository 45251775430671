import React, { useCallback, useState } from 'react'
import ButtonPrimary from '../../account/components/ButtonPrimary'
import { TranslationService } from '../../../services/TranslationService'
import CompanyService from '../../../services/CompanyService'

type PaymentMethodsType = {
    paymentMethod: string
}

export const PaymentMethods = ({ paymentMethod }: PaymentMethodsType) => {
    const [saveData, setSaveData] = useState(false)
    const [dataText, setDataText] = useState({ paymentMethod })

    const { translate } = TranslationService;

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ paymentMethod: dataText.paymentMethod })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataText])

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSaveData(true)
        setDataText(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div className="d-flex justify-content-end align-items-end  mx-3">
            <div className='col'>
                <textarea id="paymentMethod" name="paymentMethod" rows={8} cols={50} onInput={handleInputChange}>
                    {dataText.paymentMethod}
                </textarea>
            </div>
            <div>
                {saveData && <ButtonPrimary className='ml-auto p-2' onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
