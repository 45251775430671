import LayoutAlert from "./components/LayoutAlert";
import "./../../pages/mockup/mockupStyles.css";
import LayoutHeader from "./LayoutHeader";

const Layout: React.FC = () => {
    return (<>
        <LayoutAlert />
        <LayoutHeader/>
    </>)
}

export default Layout;