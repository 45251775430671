import { useCallback, useContext, useEffect, useState } from "react";
import { ImportService } from "../../../services/ImportService";
import ImportTemplateService from "../../../services/ImportTemplateService";
import RangedDatepicker from "../../shared/components/DateRangePicker";
import Dropdown from "../../shared/components/Dropdown";
import { ImportLogListResponse } from "../../shared/entities/ImportLogListResponse";
import { ImportTemplateListResponse } from "../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../services/TranslationService';
import Table, { TableHeader } from "../../shared/Table";
import TableContext, { TableContextValues } from "../../task/TableContext";
import ImportLogItem from "./ImportLogItem";
import CompanyService from "../../../services/CompanyService";

const ImportLog = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ImportLogListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<ImportListFilter>({});
    const pagesize = 20;
    const requestData = async () => {
        setLoading(true);
        const result = await ImportService.getList({ ...filters, page: currentPage, pagesize });
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / pagesize);
        setResponse(result);
        setPageCount(totalPages);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [currentPage, filters]);
    useEffect(() => {
        requestDataCallback();
    }, [currentPage, requestDataCallback]);

    const setCurrentPage = (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            _setCurrentPage(page);
        }
    }

    const applyFilters = (filters: ImportListFilter) => {
        setCurrentPage(0);
        setFilters(filters);
    }

    const doExport = async () => {
        const { response } = tableContext as ImportTableContextValues;
        await ImportService.export({ ...filters, page: 1, pagesize: 0 }, response?.itemCount!);
    }

    const tableContext = new ImportTableContextValues(response?.lastcompleteimportid ?? null);
    tableContext.loading = loading;
    tableContext.error = error;
    tableContext.response = response;
    tableContext.currentPage = currentPage;
    tableContext.setCurrentPage = setCurrentPage;
    tableContext.pageCount = pageCount;
    tableContext.reload = requestData;
    tableContext.applyFilters = applyFilters;

    const tableHead: TableHeader[] = [
        new TableHeader("type", translate.Type, false, false, "long-text"),
        new TableHeader("startdate", translate.StartDate, false, false, "long-text"),
        new TableHeader("lastStatus", translate.LastStatus, false, false, "long-text"),
        new TableHeader("status", translate.Status, false, false),
        new TableHeader("result", translate.Result, false, false),
        new TableHeader("user", translate.User, false, false),
        new TableHeader("process", translate.Process, false, false),
        new TableHeader("file", translate.File, false, false),
        new TableHeader("actions", "", false, false),
    ];
    return (
        <div className="container-fluid padding">
            <div className="card">
                <div className="logs">
                    <div className="d-flex main-panel justify-content-between my-4">
                        <h2>{translate.ImportLog}</h2>
                        <div className="d-flex flex-nowrap gap-3">
                            {CompanyService.canDo("export") && <button className="btn btn-underline" onClick={doExport}><i className="far fa-arrow-to-bottom me-2"></i>{translate.ExportToXls}</button>}
                            <button className="btn btn-secondary" onClick={() => { alert("not implemented!") }}>{translate.ViewDictionary}</button>
                            <button className="btn btn-primary" onClick={requestDataCallback}>{translate.Refresh}</button>
                        </div>
                    </div>
                    <TableContext.Provider value={tableContext}>
                        <ImportFilters />
                        <Table headers={tableHead} item={ImportLogItem} />
                    </TableContext.Provider>
                </div>
            </div>
        </div >

    )
}

export class ImportTableContextValues extends TableContextValues<ImportLogListResponse, ImportListFilter>{
    constructor(public lastcompleteimportid: number | null) {
        super();
    }
}

type ImportListFilter = {
    from?: string;
    to?: string;
    importTemplateId?: number;
    type?: string;
}


const ImportFilters = () => {
    const { translate } = TranslationService;
    const tableContext = useContext(TableContext);
    const { applyFilters } = tableContext;

    const [importTemplates, setImportTemplates] = useState<ImportTemplateListResponse>();

    const onDateChange = (start?: Date, end?: Date) => {
        const newFilters = { ...filters, filename, from: start ? start.toJSON() : undefined, to: end ? end.toJSON() : undefined };
        setFilters(newFilters);
        applyFilters(newFilters);
    };

    const [filters, setFilters] = useState<ImportListFilter>({});
    const [filename, setFilename] = useState<string>("");
    const fixedFiltersChange = (propName: string) => (newValue: string | number | undefined) => {
        const newFilters = { ...filters, filename, [propName]: (newValue === "" ? undefined : newValue) };
        setFilters(newFilters);
        applyFilters(newFilters);
    }

    const updateFilename = () => {
        const newFilters = { ...filters, filename };
        setFilters(newFilters);
        applyFilters(newFilters);
    }

    useEffect(() => {
        const getData = async () => {
            const result = await ImportTemplateService.getList();
            if (result instanceof Error) {
                return;
            }
            setImportTemplates(result);
        }
        getData();
    }, []);

    const importTypes = ImportService.getImportTypes().map(x => ({ text: TranslationService.getTranslation(x.text), value: x.value, key: x.value }))

    return (
        <div className="bg-light-gray mb-4">
            <div className="card-body ps-3">
                <div className="row">
                    <div className="col-3">
                        <label>{translate.Date}</label>
                        <RangedDatepicker onChange={onDateChange} defaultValue={null} />
                    </div>
                    <div className="col-3">
                        <label>{translate.Process}</label>
                        <Dropdown optionLabel={translate.FilterDefaultOptionLabel} onChange={fixedFiltersChange('importTemplateId')} items={importTemplates?.list.map(x => ({ key: x.ImportTemplateID, text: x.Name, value: x.ImportTemplateID })) ?? []} />
                    </div>
                    <div className="col-3">
                        <label>{translate.Type}</label>
                        <Dropdown optionLabel={translate.FilterDefaultOptionLabel} onChange={fixedFiltersChange('type')} items={importTypes} />
                    </div>
                    <div className="col-3">
                        <label>{translate.File}</label>
                        <input onKeyPress={event => event.key === "Enter" && updateFilename()} onChange={event => setFilename(event.currentTarget.value)} className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportLog;