import { useCallback, useContext, useEffect, useState } from "react";
import { ReportInvoiceGroupResponse } from "../../entities/reports/reportInvoiceGroup/ReportInvoiceGroupResponse";
import CompanyService from "../../services/CompanyService";
import FilterService, { FilterCollection } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { formatCurrency, formatNumber } from "../../utils/FormatUtils";
import { sumList } from "../../utils/Utils";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import { TranslationService } from '../../services/TranslationService';
import Table, { TableHeader } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import { parseIntOrDefault } from "../../utils/ParseUtils";
import AdvancedFiltersContext from "../shared/components/AdvancedFiltersContext";
import { useHistory } from "react-router-dom";
import { encodeFilters } from "./ReportCollection";

export class ReportInvoiceGroupTableContextValues extends TableContextValues<ReportInvoiceGroupResponse, { extraFilters: string[] }> {
    total: number = 0;
}

// RESUMEN DE FACTURAS
const ReportInvoiceGroup = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportInvoiceGroupResponse>({ list: [] as ReportInvoiceGroupResponse.Item[] } as ReportInvoiceGroupResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [groupBy, setGroupBy] = useState<number>(0);
    const [filter, setFilter] = useState<string>('');
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const filtersContext = useContext(AdvancedFiltersContext);
    const [filtersMap, setFiltersMap] = useState<string[]>([])

    const requestData = async () => {
        setLoading(true);
        if (groupBy === 0) {
            setLoading(false);
            setError(false);
            return;
        }
        const result = await InvoiceService.getInvoiceGroupList(groupBy, filter);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setPageCount(totalPages);
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [filter, groupBy]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters])
        setFilter(filters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&"));

    }

    const tableValues = new ReportInvoiceGroupTableContextValues();
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.total = response?.total ?? 0;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.currentPage = currentPage;
    tableValues.pageCount = pageCount;

    const headers: TableHeader[] = [
        new TableHeader("value", translate.Value, false, false),
        new TableHeader("invoices", translate.Invoices, true, false),
        new TableHeader("pendingAmount", translate.PendingAmount, true, false),
        new TableHeader("percentage", "", true, false),
        new TableHeader("duegraph", "", true, false, ""),
        new TableHeader("", "", false, false, "w-300px"),
    ];

    const onFilterChange = (value?: string) => {
        setGroupBy(parseIntOrDefault(value?.replace("additional-", "")));
    }

    const groupByItems = FilterService.GetFiltersForPage(FilterCollection.Invoice)[0].definitions
        .filter(x => x.Field.startsWith("additional-") || x.Field === "-1015")
        .map(x => ({ text: x.Name, value: x.Field }));
    // groupByItems.find(x => x.value === "-1015")!.value = "-1"; // Falla

    const exportData = () => InvoiceService.exportInvoiceGroupList(groupBy, filter, 100)

    return (
        <TableContext.Provider value={tableValues}>
            <div className="container-fluid padding ">
                <div className="card mh-100">
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-3">{TranslationService.translate.InvoiceSummary}</h2>
                    </div>

                    <div className="genericHeader">
                        <div className="searcherFilterHeader">
                            <button className="btn btn-tertiary" type="button" onClick={() => filtersContext.showFilters()} style={{ height: 40 }}>
                                <i className="far fa-filter" />{TranslationService.translate.Filters}
                            </button>
                            <label className="col-form-label">{translate.GroupInvoicesBy}</label>
                            <Dropdown optionLabel={translate.Select} onChange={onFilterChange} items={groupByItems} />

                        </div>
                        {CompanyService.canDo("export") &&
                            <button type="button" className="btn btn btn-underline" onClick={exportData}>
                                <i className="far fa-arrow-to-bottom me-2"></i> {translate.ExportToXls}
                            </button>
                        }
                    </div>
                    <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportInvoiceGroup} />

                    <Table headers={headers} stickyHeader={true}
                        item={data => <ReportInvoiceGroupItem dataValues={data.data} filter={encodeURIComponent(encodeFilters(filtersMap) || "")} />}>
                        <ReportInvoiceGroupTotal response={response} />
                    </Table>
                </div>
            </div>
        </TableContext.Provider>
    )
}

const ReportInvoiceGroupItem = ({ dataValues, filter }: { dataValues: ReportInvoiceGroupResponse.Item, filter: string }) => {

    const { total } = useContext(TableContext) as ReportInvoiceGroupTableContextValues;
    const { currentLanguage } = TranslationService;
    const history = useHistory()
    const noDueShow = dataValues.amount - dataValues.due
    const dueCalc = ((dataValues.due * 100) / dataValues.max)
    const noDueCalc = ((noDueShow * 100) / dataValues.max)


    const qs = filter;
    const sendToInvoicesWithFilter = () => history.push(`/${currentLanguage}/report/iolist${qs ? '?filter=' + qs : ''}`)

    return (
        <tr style={{ cursor: 'pointer' }} onClick={sendToInvoicesWithFilter}>
            <td>{dataValues.value}</td>
            <td className="text-end">{dataValues.cant}</td>
            <td className="text-end">{formatCurrency(dataValues.amount, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end">{formatNumber(dataValues.amount / total * 100)}%</td>

            <td className='popover__wrapper'>
                <div className="progress progress-amount" style={{ marginBottom: '12px' }}>
                    <div className="progress-bar bg-danger bg-gradient bg-opacity-75" style={{ width: dueCalc + '%' }} />
                    <div className="progress-bar bg-primary bg-gradient progress-bar-stripped bg-opacity-75" style={{ width: noDueCalc + '%' }} />
                </div>
                <div className="popover__content">
                    <p className="popover__message" style={{ color: 'red' }}>{TranslationService.translate.Due}: {formatCurrency(dataValues.due, CompanyService.getDefaultCurrencyId())}</p>
                    <p className="popover__message" style={{ color: 'blue' }}>{TranslationService.translate.NoDue}: {formatCurrency(noDueShow, CompanyService.getDefaultCurrencyId())}</p>
                </div>
            </td>
            <td></td>
        </tr>
    );
}

const ReportInvoiceGroupTotal = ({ response }: { response: ReportInvoiceGroupResponse }) => {
    const { translate } = TranslationService;
    if ((response?.list.length ?? 0) === 0) {
        return <tr><td></td><td></td><td></td><td></td></tr>
    }
    return (
        <tr className="font-weight-bold">
            <td>{translate.Total}</td>
            <td className="text-end">{sumList(response.list, x => x.cant)}</td>
            <td className="text-end">{formatCurrency(response.total, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end">{formatNumber(100)}%</td>
            <td></td>
        </tr>
    );
}


export default ReportInvoiceGroup