import { useContext, useRef } from "react";
import { formatCurrency, formatDateDigits, formatInteger } from "../../../utils/FormatUtils";
import { TranslationService } from '../../../services/TranslationService';
import TableContext from "../../task/TableContext";
import { ClientTableContextValues } from "../providers/ClientTableProvider";
import CompanyService from "../../../services/CompanyService";
import PriorityService from "../../../services/PriorityService";
import TooltipComponent from "../../shared/TooltipComponent";
import ClientTooltip, { TooltipBarClient } from "./ClientTooltip";
import { LinkWithHistory } from "../../shared/BreadcrumbHistory";
import { useHistory } from "react-router-dom";
import { ClientIterator } from "../ClientIterator";
import { endpointRequest } from "../../../utils/RequestUtils";
import ClientService from "../../../services/ClientService";
import Loading from "../../shared/components/Loading";
import { renderToStaticMarkup } from "react-dom/server";

const ClientTableItem = (props: any) => {
    const { translate } = TranslationService;
    const { max, response, request } = useContext(TableContext) as ClientTableContextValues;
    const aging = useRef<string[]>([]);
    const history = useHistory();

    const getContent = () => {
        if (props.data.Activity) {
            return props.data.Activity.Type === 3
                ? props.data.Activity.Subject
                : props.data.Activity.Type === 6 || props.data.Activity.Type === 23
                    ? props.data.Activity.Content
                    : props.data.Activity.Type === 4
                        ? props.data.Activity.Content.replaceAll('<br />', '\n')
                        : '';
        }
        return '';
    };

    const setTooltipBarData = async () => {
        const tooltipElement = document.getElementsByClassName("tooltip-aging-" + props.data.PersonId)[0];

        if (aging.current[props.data.PersonId]) {
            tooltipElement.outerHTML = aging.current[props.data.PersonId];
            return;
        }

        endpointRequest(() => ClientService.getAging(props.data.PersonId), x => {
            //setAgeing(x);

            if (!tooltipElement) {
                return;
            }
            aging.current[props.data.PersonId] = renderToStaticMarkup(<TooltipBarClient ageing={x} amount={props.data.amount ?? 0} overdueAmount={props.data.overdueAmount ?? 0} noDueAmount={props.data.noDueAmount ?? 0}></TooltipBarClient>);
            tooltipElement.outerHTML = aging.current[props.data.PersonId];
        });
    };

    let date = props.data.Activity ? new Date(props.data.Activity.date) : undefined;
    let formattedDate = date ? formatDateDigits(date) : '';
    const rankingIndex = PriorityService.getRankingIndex(props.data.CalculatedRank);
    return (
        <>
            <tr role="button">
                <td className="fw-normal" title={" " /*try removing this*/} >
                    <span className={"w-md" + (props.data.groupCount > 0 ? " fw-bolder" : "")}>
                        <LinkWithHistory to={() =>
                            history.push(`/${TranslationService.currentLanguage}/client/details?id=${props.data.PersonId}`,
                                { clientIterator: new ClientIterator(request, response!, response!.list.findIndex(x => x === props.data)) })}>
                            <TooltipComponent title={<ClientTooltip client={props.data} />}>
                                {props.data.Name}
                            </TooltipComponent>
                        </LinkWithHistory>
                    </span>
                </td>
                <td className="fw-normal text-end w-xl">
                    <span className="w-md">{props.data.count ?? 0}</span>
                </td>
                <td className="fw-normal text-end  w-xl">
                    <span className="w-md">
                        {formatCurrency(props.data.amount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}
                    </span>
                </td>
                {/*
                    <td className="fw-normal text-end  w-xl text-danger">
                        <span className="w-md">
                            {formatCurrency(props.data.overdueAmount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}
                        </span>
                    </td>
                    <td className="fw-normal text-end  w-xl">
                        <span className="w-md">
                            {formatCurrency(props.data.noDueAmount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}
                        </span>
                    </td> 
                */}
                <td className="bar-progress">
                    <TooltipComponent title={<div className={"tooltip-aging-" + props.data.PersonId}><Loading></Loading></div>} onInserted={() => setTooltipBarData()}>
                        <div className="bar">
                            {(props.data.overdueAmount > 0 || props.data.noDueAmount > 0) && <>
                                <div className="expired" style={{ width: ((props.data.overdueAmount ?? 0) * 100 / max) + "%" }}></div>
                                <div className="not-expired" style={{ width: ((props.data.noDueAmount) * 100 / max) + "%" }}></div>
                            </>
                            }
                        </div>
                    </TooltipComponent>
                </td>
                <td className="fw-normal  w-xxl">
                    <TooltipComponent title={formatActivityContent(props.data.Activity?.ClearContent)} html={true}>
                        <div>
                            <span>{formattedDate}</span> - {getContent()}
                        </div>
                    </TooltipComponent>
                </td>
                {!CompanyService.getCompanyAuth()?.HidePriority &&
                    <td className="fw-normal w-l ps-5 text-end" data-bs-toggle="tooltip" data-for={'priority-' + props.data.PersonId} data-tip={translate.Priority + ' ' + props.data.CalculatedRank}>
                        <span className={'badge-priority priority-' + rankingIndex}>{props.data.CalculatedRank}</span>
                    </td>}
            </tr>
        </>
    );
};

export default ClientTableItem;

function formatActivityContent(content: string | undefined) {
    if (!content) {
        return "";
    }
    if (!content.startsWith("<!DOCTYPE html")) {
        return content;
    }
    const html = document.createElement("html");
    html.innerHTML = content;
    const body = html.getElementsByTagName("body")[0];
    const result = [...body.children].map(x => x.tagName === "TABLE" ? x.innerHTML : x.outerHTML).join("<br/>");
    return result;
}
