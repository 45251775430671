import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";

const LayoutAlert: React.FC<any> = () => {
    const { translate } = TranslationService;
    enum AlertType {
        NoAlert = 0, // Default
        BlockingSoon,
        Blocked,
        ExpiringSoon,
        ExpiringToday,
        Expired,
        UserExceeded, // Not implemented
        ContactMe // Deprecated
    }

    const block: string | null | undefined = CompanyService.getBlock();
    const expire: string | null | undefined = CompanyService.getExpire();

    let alertStatus: AlertType = AlertType.NoAlert;

    function getDayDifference(dateInCustomFormat: string | undefined | null): number | undefined {
        if (!dateInCustomFormat || dateInCustomFormat === null) {
            return undefined;
        }

        const parts = dateInCustomFormat.split("-");
        const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));

        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);

        const diff = date.getTime() - dateNow.getTime();
        //GET DAY DIFFERENCE
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    // function daysFromNow(dateInCustomFormat: string | null | undefined): Number {
    //     if (!dateInCustomFormat) {
    //         return 0;
    //     }
    //     const millis = Date.parse(dateInCustomFormat);
    //     const nowMillis = Date.now();
    //     return Math.ceil(Math.abs(millis - nowMillis) / (86400000));
    // }

    function renderClickeableAlert(baseText: string, linkText: string) {
        // FIXME: Show modal contact/_contactprice on click
        const a = baseText.split("{here}", 2);
        return <span className='d-inline-flex justify-content-between align-intems-center'>{a[0]}<button className='btn btn-text text-primary py-0 px-2 border-0'>{linkText}</button>{a[1]}</span>
    }

    const diffBlock = getDayDifference(block);
    const diffExpire = getDayDifference(expire);

    if (diffBlock) {
        if (diffBlock > 0) {
            alertStatus = AlertType.BlockingSoon;
        }
        else {
            alertStatus = AlertType.Blocked;
        }
    }

    if (diffExpire) {
        if (diffExpire === 0) {
            alertStatus = AlertType.ExpiringToday
        }
        else if (diffExpire < 0) {
            alertStatus = AlertType.Expired;
        }
        else if (diffExpire < 7) {
            alertStatus = AlertType.ExpiringSoon;
        }
    }

    return (
        <>
            {alertStatus === AlertType.BlockingSoon ? <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
                <p className='mb-0'>{translate.AccountBlocking.replace("{day}", diffBlock!.toString())}</p>
            </div> : <></>}
            {alertStatus === AlertType.Blocked ? <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
                <p className='mb-0'>{translate.AccountBlocked.replace("{day}", diffBlock!.toString())}</p>
            </div> : <></>}
            {alertStatus === AlertType.ExpiringSoon ? <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
                <p className='mb-0'>
                    {translate.TrialExpiresOn.replace("{days}", diffExpire!.toString()).replaceAll("{plural}", diffExpire! === 1 ? "" : "s")}
                    <span className="ps-2">
                        {renderClickeableAlert(translate.ClickHerePlans, translate.Here)}
                    </span>
                </p>
            </div> : <></>}
            {alertStatus === AlertType.ExpiringToday ? <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
                <p className='mb-0'>{translate.ExpiresToday} {renderClickeableAlert(translate.ClickHerePlans, translate.Here)}</p>
            </div> : <></>}
            {alertStatus === AlertType.Expired ? <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
                <p className='mb-0'>{translate.TrialExpiredOn.replace("{day}", diffExpire!.toString())} {renderClickeableAlert(translate.ClickHerePlans, translate.Here)}</p>
            </div> : <></>}
        </>
    )
}

export default LayoutAlert;