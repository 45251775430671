/* eslint-disable @typescript-eslint/no-unused-vars */

import { AgeingEntity } from "../entities/company/Ageing/AgeingEntity";
import { CompanySettings } from "../entities/company/Company/CompanySettings";
import { CompanyAuth } from "../entities/login/CompanyAuth";
import { CompanyToken } from "../entities/login/CompanyToken";
import { UserPayload } from "../entities/login/UserPayload";
import { parseIntOrDefault } from "../utils/ParseUtils";
import { getCache, setCache } from "../utils/PersistenceUtils";
import { MapIfEqual } from "../utils/Utils";
import { getClientDefaultFieldType, getClientFieldIsDefault, getClientFieldTitle } from "./ClientService";
import { getInvoiceDefaultFieldType, getInvoiceFieldIsDefault, getInvoiceFieldTitle } from "./InvoiceService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

export enum Entities {
    Client = 0,
    Invoice = 1,
    Payment = 2,
    Task = 20,
    Activity,
}

export enum DataType {
    Text = 0,
    List = 1,
    Date = 2,
    Number = 3,
    Currency = 4,
    Phone = 5,
    Link = 6,
    Tags = 20,
    ActivityType,
    Client,
    Priority,
    TextWithOperator,
    ExpirationDate,
    YesNo,
    DaysOld,
    DayCompare,
    Percentage,
    IOStatus,
    Group,
    CurrencyWithCurrencyType,
    Multiselect,
    User,
    Status, /*Transaction/Invoice status*/
    PeriodoGestiones,
    ActivityTypeWithDate,
    Type, /* Activity type, but the type, not the ActivityType */
    Readonly,
    ListNoOperator,
    ClientGroup,
    Download,
}

class CompanyService {
    public static setCompanyAuth(value: CompanyAuth) {
        setCache("company_auth", value);
    }
    public static getCompanyAuth() {
        return getCache<CompanyAuth>("company_auth");
    }
    public static setCompanyToken(value: CompanyToken) {
        setCache("company_token", value);
    }
    public static getCompanyToken() {
        return getCache<CompanyToken>("company_token");
    }
    public static setUserPayload(value: UserPayload) {
        setCache("user_payload", value);
    }
    public static getUserPayload() {
        return getCache<UserPayload>("user_payload");
    }
    public static getUserEmail() {
        return CompanyService.getUserPayload()!.emailaddress;
    }
    public static getUserFullName() {
        return CompanyService.getUserPayload()!.fullname;
    }
    public static getConfigItems() {
        return CompanyService.getCompanyAuth()!.configItems;
    }
    public static getAdditionalDefinitions() {
        return CompanyService.getCompanyAuth()!.additionalDefinitions;
    }
    public static getUsers() {
        return CompanyService.getCompanyAuth()!.users;
    }
    public static getTags() {
        return CompanyService.getCompanyAuth()!.tags;
    }
    public static getEmailTemplates() {
        return CompanyService.getCompanyAuth()!.emailTemplates;
    }
    public static getEmailFrames() {
        return CompanyService.getCompanyAuth()!.emailFrames;
    }
    public static getActivityTypes() {
        return CompanyService.getCompanyAuth()!.activityTypes;
    }
    public static getIOGroup() {
        return CompanyService.getCompanyAuth()!.IOGroup;
    }
    public static getGroupName() {
        return CompanyService.getCompanyAuth()!.groupName;
    }
    public static getIoStatus() {
        return [{ IOStatusDependencies: [], IOStatusID: 0, Value: CompanyService.getNormalIOStatusName(), Exclude: false, HasDependency: false, PublicValue: CompanyService.getNormalIOStatusName(), IOStatusID_ref: null },
        ...CompanyService.getCompanyAuth()!.iostatus];
    }
    public static getSegments() {
        return CompanyService.getCompanyAuth()!.segments?.map(x => ({ Id: x.Id, Value: MapIfEqual(x.Value, "", "\u00A0") }))!;
    }
    public static getCompanyId() {
        return CompanyService.getCompanyAuth()!.companyGuid;
    }
    public static getIoSortedFields() {
        return CompanyService.getCompanyAuth()!.ioSortedFields;
    }
    public static getTransactionTypes() {
        return CompanyService.getCompanyAuth()!.transactionTypes;
    }
    public static getCurrencies() {
        return CompanyService.getCompanyAuth()!.currencies;
    }
    public static getDefaultCurrencyId() {
        return CompanyService.getCompanyAuth()!.defaultCurrencyId;
    }
    public static getReportExports() {
        return CompanyService.getCompanyAuth()!.reportExports;
    }
    public static getCompanyName() {
        return CompanyService.getCompanyAuth()!.companyName;
    }
    public static getPersonSortedFields() {
        return CompanyService.getCompanyAuth()!.personSortedFields;
    }
    public static getEnabledSegmentPriorities() {
        return CompanyService.getCompanyAuth()!.EnabledSegmentPriorities;
    }
    public static getTotalPriorities() {
        return CompanyService.getCompanyAuth()!.TotalPriorities;
    }
    public static getDashboardPanelId() {
        return CompanyService.getCompanyAuth()!.dashBoardPanelId;
    }
    public static getNormalIOStatusName() {
        return CompanyService.getCompanyAuth()!.NormalIOStatusName ?? "Normal";
    }
    public static canShowWhatsApp() {
        return CompanyService.getCompanyAuth()!.Whatsapp;
    }
    public static getBlock() {
        return CompanyService.getCompanyAuth()?.block;
    }
    public static getExpire() {
        return CompanyService.getCompanyAuth()?.expire;
    }
    public static getPersonKey() {
        return CompanyService.getCompanyAuth()!.PersonKey;
    }
    public static setConfigCompany(companySettings: CompanySettings): Promise<unknown | Error> {
        return RequestService.post("/company/setconfig", companySettings)
    }
    public static getConfigsCompany() {
        return RequestService.post("/company/getconfigs", {});
    }
    public static getCashAppId() {
        return CompanyService.getCompanyAuth()?.CashAppGuid;
    }
    /**
     * @returns the timezone offset in minutes
     */
    public static getTimezone() {
        return parseIntOrDefault(CompanyService.getCompanyToken()!.timezone, new Date().getTimezoneOffset());
    }
    public static getWhatsappUrl(phone: string) {
        if (!phone) {
            return undefined;
        }
        const regex = /[+-]/ig;
        return "https://api.whatsapp.com/send?phone=" + phone.replaceAll(regex, '');
        //return "/es/mail?url=" + encodeURIComponent(whatsappUrl);
    }

    public static getPhoneLink(phone: string) {
        if (!phone) {
            return "";
        }
        if (phone[0] !== "+") {
            phone = "+" + phone;
        }
        return phone;
    }

    public static getAdditionalDefinitionsFiltered = (entityType: Entities, right?: boolean,
        manualEdit?: boolean, hidden: boolean = false) => {
        let result = CompanyService.getAdditionalDefinitions().filter(x => x.Entity === entityType);
        if (right !== undefined) {
            result = result.filter(x => (x.Right ?? false) === right);
        }
        if (hidden !== undefined) {
            result = result.filter(x => (x.Hidden ?? false) === hidden);
        }
        if (manualEdit !== undefined) {
            result = result.filter(x => x.ManualEdit === manualEdit);
        }
        return result;
    }

    public static getConfigItemsFiltered = (entityType: Entities, includeInCustomerPortal?: boolean, includeValues?: number[]) => {
        let result = CompanyService.getConfigItems().filter(x => x.Entity === entityType);
        if (includeInCustomerPortal !== undefined) {
            result = result.filter(x => x.IncludeInCustomerPortal === includeInCustomerPortal || includeValues?.includes(x.field));
        }
        return result;
    }

    public static setTags(tags: CompanyAuth.Tag[]) {
        return CompanyService.setCompanyAuth({ ...CompanyService.getCompanyAuth()!, tags });
    }
    public static getEntityDefaultFieldType = (entityType: Entities, id: number) => {
        switch (entityType) {
            case Entities.Client:
                return getClientDefaultFieldType(id);
            case Entities.Invoice:
                return getInvoiceDefaultFieldType(id);
            default:
                return DataType.Text;
        }
    }
    public static getEntityFieldIsDefault = (entityType: Entities, id: number) => {
        switch (entityType) {
            case Entities.Client:
                return getClientFieldIsDefault(id);
            case Entities.Invoice:
                return getInvoiceFieldIsDefault(id);
            default:
                return id < 0;
        }
    }
    public static getEntityFieldTitle = (entityType: Entities, id: number) => {
        switch (entityType) {
            case Entities.Client:
                return getClientFieldTitle(id);
            case Entities.Invoice:
                return getInvoiceFieldTitle(id);
            default:
                return undefined;
        }
    }
    public static getAccountstatus() {
        return CompanyService.getCompanyToken()!.accountstatus;
    }
    public static getAuthorization() {
        return CompanyService.getCompanyToken()!.authorization;
    }
    public static getCompanyAuthorization() {
        return CompanyService.getCompanyToken()!.companyauthorization;
    }
    public static getIsAdmin(): boolean {
        return CompanyService.getCompanyToken()!.isadmin === "1";
    }
    public static getIsReadonly(): boolean {
        return CompanyService.getCompanyToken()!.isreadonly === "1";
    }
    public static getIsInvited(): boolean {
        return !CompanyService.getIsAdmin() && !CompanyService.getIsReadonly();
    }
    public static getUserid() {
        return CompanyService.getCompanyToken()!.userid;
    }
    public static getHasSegments() {
        return CompanyService.getCompanyToken()!.hassegments === "1";
    }
    public static getFilterUserIdPaused() {
        return CompanyService.getCompanyToken()!.filteruseridpaused === "1";
    }
    public static getCompanies() {
        return CompanyService.getCompanyAuth()!.companies;
    }
    public static canDo(action: Action) {
        const needsCompanyAuth = ["newperson", "editperson", "deleteperson", "newtransaction", "edittransaction", "deletetransaction", "newio", "editio", "deleteio", "deletecomment", "newwhatsapp"];
        if (CompanyService.getAccountstatus() !== "0") {
            return false;
        }
        if (CompanyService.getCompanyAuth()?.Production) {
            //ONLY PERMISSION TO CHECK FOR Production IS Report
            if (action === "reporting") {
                return CompanyService.getAuthorization().includes(action);
            }
            return false;
        }
        if (CompanyService.getIsAdmin()) {
            return true;
        }
        if (needsCompanyAuth.includes(action) && !CompanyService.getCompanyAuthorization().includes(action)) {
            return false;
        }
        if (CompanyService.getIsReadonly()) {
            //ONLY PERMISSION TO CHECK FOR A READONLY USER IS EXPORT
            if (action === "export") {
                return CompanyService.getAuthorization().includes(action);
            }
            return false;
        }
        return CompanyService.getAuthorization().includes(action);
    }

    public static GetDefaultCurrencySymbol() {
        const currencyId = CompanyService.getDefaultCurrencyId();
        return CompanyService.getCurrencies().find(x => x.CurrencyId === currencyId)!.Symbol;
    }

    public static GetAgeingValues() {
        const ageing: AgeingEntity[] = [];
        const ageingValues = CompanyService.getCompanyAuth()!.GraphScales;
        let dueVals = ageingValues.filter(x => x < 0).length;

        ageing.push({
            due: true,
            name: TranslationService.getTranslation("Aging-MoreXDaysAgo").replace("{0}", (-ageingValues[0]).toString()),
            maxDays: ageingValues[0],
            filterVal: "due" + dueVals.toString()
        });
        ageingValues.forEach((val, i) => {
            if ((i + 1) >= ageingValues.length) {
                return;
            }
            const nextVal = ageingValues[i + 1];
            const due = val < 0;
            let name = "";
            let filterVal = "";
            if (nextVal < 0) {
                name = TranslationService.getTranslation("Aging-MoreXYDays").replace("{0}", (-(nextVal - 1)).toString()).replace("{1}", (-val).toString());
                filterVal = "due" + (dueVals - 1).toString() + dueVals.toString()
                dueVals--;
            }
            else if (nextVal === 0) {
                name = TranslationService.getTranslation("Aging-LastXDays").replace("{0}", (-val).toString());
                filterVal = "due1";
            }
            else if (val === 0) {
                name = TranslationService.getTranslation("Aging-LessXDays").replace("{0}", (nextVal).toString());
                filterVal = "nodue1";
                dueVals = 1;
            }
            else {
                name = TranslationService.getTranslation("Aging-InXYDays").replace("{0}", (val).toString()).replace("{1}", (nextVal - 1).toString());
                filterVal = "nodue" + dueVals.toString() + (dueVals + 1).toString();
                dueVals++;
            }
            ageing.push({
                due,
                name,
                minDays: val,
                maxDays: nextVal,
                filterVal
            });
        });

        ageing.push({
            due: false,
            name: TranslationService.getTranslation("Aging-MoreXDays").replace("{0}", ageingValues[ageingValues.length - 1].toString()),
            minDays: ageingValues[ageingValues.length - 1],
            filterVal: "nodue" + dueVals.toString()
        });

        return ageing;
    }
    public static changeCompanyName(name: string) {
        return RequestService.post("/company/setname", { name });
    }
    public static changeCompanyLogo(fileId: string) {
        return RequestService.post("/company/setlogo", { fileId });
    }
    public static getSetting(key: keyof CompanySettings) {
        return CompanyService.getCompanyAuthorization().includes(key);
    }
}
export type Action = "allowsegmentfiltertoggle" | "checkotherstask" | "checktask" | "deletecomment" | "deletecompany" | "deleteio" | "deleteperson" | "deletetag" | "deletetask" | "deletetransaction" | "deleteuser" | "editaction" | "editadditionaldata" | "editcomment" | "editcompany" | "editcurrency" | "editgoal" | "editio" | "editiostatus" | "editmail" | "editperson" | "editsegment" | "edittag" | "edittask" | "edittransaction" | "edituser" | "enabled" | "export" | "haspassword" | "importdata" | "newcomment" | "newcompany" | "newio" | "newmail" | "newperson" | "newsms" | "newtag" | "newtask" | "newtransaction" | "newuser" | "reporting" | "sync" | "viewreport" | "hasReceipt" | "newwhatsapp";
export default CompanyService;