import { useContext, useEffect, useState } from "react";
import CompanyService, { DataType } from "../../services/CompanyService";
import { FilterCollection } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { formatCurrency, formatNumber, formatDateShort } from "../../utils/FormatUtils";
import { InvoiceList } from "../client/invoice/entities/InvoiceListResponse";
import { TableColumn } from "../client/invoice/InvoiceTableItem";
import InvoiceTableProvider, { InvoiceTableContextValues } from "../client/invoice/InvoiceTableProvider";
import InvoiceTableTotal from "../client/invoice/InvoiceTableTotal";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import Table, { TableHeaderWithFieldId } from "../shared/Table";
import { Link, useLocation } from "react-router-dom";
import TableContext from "../task/TableContext";
import ClientService, { ClientFields } from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";
import AdvancedFiltersContext from "../shared/components/AdvancedFiltersContext";
import { ActivityContentTableData } from "./ReportPayment";
import InvoiceDetail from "../client/invoice/InvoiceDetail";
import FloatingPanelService from "../shared/FloatingPanel";
import GroupService from "../../services/GroupService";

const ReportInvoice = () => {
    const tableHeaders = getTableHeaders();
    const filtersContext = useContext(AdvancedFiltersContext);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const filterQS = urlParams.get("filter") ? [...urlParams.entries()].map((x, i) => "filter" + i.toString() + "=" + x[1].replace("filter0=", "")).join("&") : "filter0=selStatus%3D0%26selFields%3D-1010";

    return (
        <InvoiceTableProvider pagesize={50} extraFilters={filterQS} quickfilter="2">
            <div className="container-fluid padding">
                <div className="card mh-100">
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-3">{TranslationService.translate.Invoices}</h2>
                    </div>
                    <div className="genericHeader">
                        <div className="searcherFilterHeader">
                            <button className="btn btn-tertiary" type="button" onClick={() => filtersContext.showFilters()}>
                                <i className="far fa-filter" />{TranslationService.translate.Filters}
                            </button>
                        </div>
                        <ExportButton />
                    </div>
                    <AdvancedFilters page={FilterCollection.ReportInvoice} defaultValue={filterQS} />
                    <Table headers={tableHeaders} item={ReportInvoiceItem} stickyHeader={true}>
                        <InvoiceTableTotal tableHeaders={tableHeaders} />
                    </Table>
                </div>
            </div>
        </InvoiceTableProvider>
    );
}

export const ExportButton = ({ ReportTypeId = 1 }: { ReportTypeId?: number }) => {
    const { getFilters, response } = useContext(TableContext) as InvoiceTableContextValues;
    const exportInvoices = (reportId: number | null) => {
        InvoiceService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === ReportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader">
        {CompanyService.canDo("export") && <MenuButton text={TranslationService.translate.ExportToXls} actions={exportItems} extraClassName="select-export" icon="far fa-arrow-to-bottom pe-1" />}
    </div>)
}

const ReportInvoiceItem = ({ data }: { data: InvoiceList.Item }) => {
    const tableHeaders = getTableHeaders();
    const clientFieldIds = ClientService.getClientHeaders().map(x => x.fieldId);
    const specialFields = {
        "last-activity": (x: InvoiceList.Item) => ActivityContentTableData({ Activity: x.LastLogMessage } as Parameters<typeof ActivityContentTableData>[0]),
        "referenceNumber": (x: InvoiceList.Item) => (<td className="is-link">
            <span className="w-md pointer txt-blue" onClick={() => FloatingPanelService.showPanel({
                children: <InvoiceDetail invoiceId={x.IOID} canEdit={false} reload={() => { }} />,
                title: TranslationService.translate.Invoice,
                width: 750,
                height: 800,
            })}>
                {data.referenceNumber}
            </span>
        </td>),
        "status": (item: InvoiceList.Item) => (<td>{CompanyService.getIoStatus().find(x => x.IOStatusID === item.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName()}</td>),
        "group": function GroupFieldImpl(item: InvoiceList.Item) {
            const [name, setName] = useState("");
            useEffect(() => {
                const getGroupName = async () => {
                    if (item.GroupID) {
                        const res = await GroupService.getGroupName(item.GroupID, item.personId);
                        setName(res);
                    }
                };
                getGroupName();
            }, [item.GroupID, item.personId]);
            return (<td>{name ?? ""}</td>);
        },
    }

    return (<tr key={data.IOID}>
        {tableHeaders.map(header => clientFieldIds.includes(header.fieldId) ? <ClientColumn key={header.fieldId} data={data} header={header} /> : <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialFields} />)}
    </tr>);
}

const ClientColumn = ({ data, header }: { data: InvoiceList.Item, header: TableHeaderWithFieldId }) => {
    const { currentLanguage } = TranslationService;
    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data.Person[header.fieldId as keyof InvoiceList.Item["Person"]];
    };
    const value = getValue();
    switch (header.fieldId) {
        case "Name":
            return (<td className="is-link">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.personId}`}>
                        {data.Person.Name}
                    </Link>
                </span>
            </td>)
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end"}>{formatCurrency(parseFloat(value), data.CurrencyID)}</td>;
        case DataType.Number:
            return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
        case DataType.List:
            {
                const additionalId = data.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Id;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId?.toString());
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value)
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
}

function getTableHeaders() {
    const tableHeaders = InvoiceService.getTableHeaders();
    const idxStatus = tableHeaders.findIndex(x => x.fieldId === "status");
    if (idxStatus >= 0) {
        const status = { ...tableHeaders[idxStatus] };
        status.numeric = false;
        tableHeaders.splice(idxStatus, 1, status);
    }
    const clientHeaders = ClientService.getClientHeaders();

    const idMap: Record<string, number> = {
        "Name": ClientFields.Name,
        "Address": ClientFields.Address,
        "Phone": ClientFields.Phone,
    };

    const clientSortedIds = CompanyService.getPersonSortedFields().split(",");
    const sortedClientHeaders = clientSortedIds
        .map(x => clientHeaders.find(y => (idMap[y.fieldId]?.toString() ?? y.fieldId) === x))
        .filterFalsey();

    tableHeaders.unshift(...sortedClientHeaders);
    tableHeaders.push(new TableHeaderWithFieldId(
        "last-activity",
        TranslationService.translate.LastActivity,
        false, false,
        "last-activity",
        DataType.ActivityType,
    ));
    return tableHeaders;
}

export default ReportInvoice;