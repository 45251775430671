import { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";

import AuthenticationContext from "../shared/AuthenticationContext";
import ErrorContext from "../shared/ErrorContext";
import { TranslationService } from "../../services/TranslationService";
import AuthenticationService, {
	ValidLogonResponse,
} from "../../services/AuthenticationService";
import SubmitButton from "./components/SubmitButton";
import Form from "./components/Form";
import { InfoMessage } from "./components/InfoMessage";
import FloatingPanelService from "../shared/FloatingPanel";
import ModalService from "../shared/bootstrap/Modal";
import { StorageService } from "../../services/StorageService";

export const getRememberCacheValue = () => {
	let dataResponse = { rememberUser: false, userEmailCache: '' }
	const keys = Object.keys(localStorage);

	for (const key of keys) {
		if (key.startsWith("company_cache_user_prefs_")) {
			const data = localStorage.getItem(key);

			if (data !== null) {
				const parsedData = JSON.parse(data);
				const rememberUser = parsedData.rememberUser;
				const userEmailCache = parsedData.userEmailCache;

				dataResponse.rememberUser = rememberUser;
				dataResponse.userEmailCache = userEmailCache;
			} else {
				dataResponse.rememberUser = false;
				dataResponse.userEmailCache = "";
			}
		}
	}
	return dataResponse;
}


function Logon(): ReactElement {
	const { rememberUser, userEmailCache } = getRememberCacheValue()

	const defaultValues = {
		email: userEmailCache,
		password: "",
		remember: rememberUser
	};
	const [formData, setFormData] = useState(defaultValues);
	const [loading, setLoading] = useState(false);
	const [visiblePassword, setVisiblePassword] = useState(false);

	const { translate, currentLanguage } = TranslationService;
	const { setCurrentError } = useContext(ErrorContext);
	const { setCurrentAuthentication } = useContext(AuthenticationContext);

	const history = useHistory();
	const location = useLocation();
	const msgParam = new URLSearchParams(location.search).get("msg");

	useEffect(() => {
		ModalService.hideModal();
		FloatingPanelService.hidePanel();
	}, []);

	const formDataSchema = yup.object({
		email: yup
			.string()
			.email(translate.LoginEmailIncorrect)
			.required(translate.LoginEmailMissing),
		password: yup.string().required(translate.LoginPasswordMissing),
	});

	async function handleLogIn(): Promise<void> {
		setCurrentError(undefined);
		if (!formData.email && !formData.password) {
			setCurrentError(translate.LoginIncorrect);
		} else {
			formDataSchema
				.validate(formData)
				.then(async () => {
					setLoading(true);
					const queryParams = new URLSearchParams(location.search);
					const userData = await AuthenticationService.login(
						formData.email,
						formData.password
					);
					if (userData instanceof Error) {
						setCurrentError(translate.Network);
					} else if (userData.response === "LoginIncorrect") {
						setCurrentError(translate.LoginIncorrect);
					} else if (userData.response === "NextBlock") {
						setCurrentError(translate.NextBlock);
					} else if (userData.response === "2AF") {
						queryParams.append("token", userData.twofadata);
						queryParams.append("email", formData.email);
						queryParams.append("remember", formData.remember ? "ok" : "nok");

						history.replace(
							`/${currentLanguage}/completesignin?${queryParams.toString()}`
						);
						return;
					} else if (userData.response === "OK") {
						companyLogin(userData);
						return;
					} else if (userData.response === "Blocked") {
						setCurrentError(translate.Blocked);
					}
					setLoading(false);
				})
				.catch((err) => {
					setCurrentError(err.errors[0]);
					setLoading(false);
				});
		}
	}

	const companyLogin = useCallback(
		async (userData: ValidLogonResponse) => {
			const companyData = await AuthenticationService.companyLogin(
				userData,
				formData.remember
			);
			StorageService.setPreference('rememberUser', formData.remember);
			StorageService.setPreference('userEmailCache', formData.remember ? formData.email : "");

			if (companyData instanceof Error) {
				setCurrentError(translate.Network);
			} else {
				setCurrentAuthentication(companyData);
				const returnUrl = new URLSearchParams(location.search).get("returnUrl");
				history.push(returnUrl ? returnUrl : `/${currentLanguage}/company`);
				return;
			}
		},
		[
			currentLanguage,
			formData.remember,
			formData.email,
			history,
			location.search,
			setCurrentAuthentication,
			setCurrentError,
			translate.Network,
		]
	);

	const handleVisiblePassword = () =>
		setVisiblePassword((prevState) => !prevState);

	const handleInputChange = ({
		target: { name, value, checked, type },
	}: React.ChangeEvent<HTMLInputElement>) => {
		setFormData((prevState) => ({
			...prevState,
			[name]: type === "checkbox" ? checked : value,
		}));
	}

	return (
		<>
			{msgParam && <InfoMessage currentInfoMsg={msgParam} />}

			<Form back={false}>
				<h1>{translate.Login}</h1>
				<div className="input-group">
					<input
						type="email"
						placeholder={translate.Email}
						id="email"
						className="email"
						name="email"
						defaultValue={formData.email}
						onChange={handleInputChange}
					/>
					<i className="fas fa-user icono-input"></i>
				</div>
				<div className="input-group">
					<input
						type={visiblePassword ? "text" : "password"}
						id="password"
						name="password"
						className="contrasena"
						placeholder={translate.Password}
						onKeyPress={(e) => {
							if (e.key === "Enter") handleLogIn();
						}}
						onChange={handleInputChange}
					/>
					<i
						onClick={handleVisiblePassword}
						className={`fas fa-${visiblePassword ? "un" : ""
							}lock-alt icono-input`}
					/>
				</div>
				<div className="pass">
					<Link to={`/${currentLanguage}/home/recoverpassword`}>
						{translate.PasswordForgotten}
					</Link>
				</div>
				<div className="recordar">
					<input
						type="checkbox"
						id="remember"
						name="remember"
						checked={formData.remember}
						onChange={(handleInputChange)}
					/>
					<label htmlFor="remember">{translate.RememberMe}</label>
				</div>
				<div className="login-btn" id="loginbut">
					<SubmitButton
						onClick={handleLogIn}
						text={translate.Login.toUpperCase()}
						loading={loading}
					/>
				</div>
			</Form>
		</>
	);
}

export default Logon;
