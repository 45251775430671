import { AdditionalDefinitionsListResponse } from "../../../../services/FieldConfigurationService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from '../../../../services/TranslationService';
import AdditionalDefinitionsEdit from "./AdditionalDefinitionsEdit";

const InvoiceFieldItem = ({ data, dragEvents, reload }: { data: AdditionalDefinitionsListResponse.Item, dragEvents: Object, reload: () => void }) => {
    const { translate } = TranslationService;
    const showAdditionalDefinitionsEditModal = (AdditionalDefinitions?: AdditionalDefinitionsListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={reload} fieldId={AdditionalDefinitions?.AdditionalDefinitionID} />,
            title: translate.AdditionalDefinitionss,
            width: 600,
            height: 800,
            position: "center",
        })
    }
    return (<tr key={data.AdditionalDefinitionID} className="pointer" onClick={() => showAdditionalDefinitionsEditModal(data)}
        {...dragEvents}
        data-id={data.AdditionalDefinitionID} >
        <td>{data.Name}</td>
        <td>{TranslationService.getTranslation(data.TypeName.replaceAll(" ", ""))}</td>
        <td width={200} style={{textAlign: "center"}}>{data.IsPK && <i className="fas fa-check fa-lg" />}</td>
        <td width={200}><button className="edit"><i className="far fa-pen undefined"></i></button></td>
    </tr>)
}

export default InvoiceFieldItem;