import { useContext, useMemo } from "react";
import { v4 } from "uuid";
import SegmentService from "../../../services/SegmentService";
import { TranslationService } from '../../../services/TranslationService';
import CriteriaContext from "../providers/CriteriaContext";
import Criterion from "./Criterion";

const Criteria = () => {
    const { translate } = TranslationService;
    const { criteria, addCriterion } = useContext(CriteriaContext);
    const definitions = useMemo(() => SegmentService.getCriteria(translate), [translate]);
    const add = () => {
        addCriterion({ key: v4(), highlight: false, Field: -1, Operator: 0, Value: "", Data: 0, ConditionOR: false, ActivityTypes: "" });
    }
    if (criteria.length === 0) {
        add();
    }

    return (
        <>
            {criteria.map((item: any, index: number) => (
                <Criterion key={item.key} first={index === 0} data={item} extraDefinitions={definitions} />
            ))}
            <button type="button" className="btn btn-text pt-3" onClick={add}>{translate.AddNewCriteria}</button>
        </>
    )
}

export default Criteria;
