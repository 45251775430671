import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Entities } from "../../../../../services/CompanyService";
import FieldConfigurationService, { AdditionalDefinitionsListResponse } from "../../../../../services/FieldConfigurationService";
import { ToastService } from "../../../../shared/bootstrap/Toast";
import ErrorMessage from "../../../../shared/components/Error";
import Loading from "../../../../shared/components/Loading";
import FloatingPanelService from "../../../../shared/FloatingPanel";
import { TranslationService } from "../../../../../services/TranslationService";
import AdditionalDefinitionsEdit from "../AdditionalDefinitionsEdit";
import InvoiceFieldItem from "../InvoiceFieldItem";
import OrderableTable from "../OrderableTable";


const FieldConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<{ list: AdditionalDefinitionsListResponse.Item[] }>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
            setLoading(true);
            setError(false);
        let result = await FieldConfigurationService.getList();
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setError(true);
            setLoading(false);
            return;
        }
        setResponse({ list: result.list.filter(x => x.Entity === Entities.Invoice) });
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);


    const showAdditionalDefinitionsEditModal = (AdditionalDefinitions?: AdditionalDefinitionsListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={requestDataCallback} fieldId={AdditionalDefinitions?.AdditionalDefinitionID} />,
            title: translate.AdditionalDefinitionss,
            width: 600,
            height: 800,
            position: "center",
        });
    }

    const onSort = (items: AdditionalDefinitionsListResponse.Item[]) => {

    }

    const headers: { title: string, style?: undefined | CSSProperties }[] = [
        { title: translate.Field },
        { title: translate.Format },
        { title: translate.Identifier, style: { textAlign: "center" } },
        { title: "" }]

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>Campos de la factura</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showAdditionalDefinitionsEditModal(undefined)}><i className="fal fa-plus" /> {translate.CreateNewField}</button>
                </div>
            </div>
            <div className="col-lg-12">
            <OrderableTable data={response!.list} RowComponent={InvoiceFieldItem} headers={headers} getKey={x => x.Name} onSort={onSort} reload={requestDataCallback} />
            </div>
        </div>
    )
}

export default FieldConfiguration;