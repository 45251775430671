import { useEffect, useRef, useState } from "react";
import { ImportService } from "../../../services/ImportService";
import RequestService from "../../../services/RequestService";
import { formatDateTimeDigits } from "../../../utils/FormatUtils";
import { OptionalMap } from "../../../utils/Utils";
import { ImportLogListResponse } from "../../shared/entities/ImportLogListResponse";
import { TranslationService } from '../../../services/TranslationService';
import CompanyService from "../../../services/CompanyService";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";

const ImportLogDetail = ({ data }: { data: ImportLogListResponse.Item }) => {
    
    const { translate } = TranslationService;
    const resultDiv = useRef<HTMLDivElement>(null);
    const importType = ImportService.findImportType(data.Type, data.UpdateStrategy);
    const timezone = CompanyService.getTimezone();
    const lastStatusDate = data.LastStatus && new Date(new Date(data.LastStatus).getTime() - timezone * 60 * 1000);
    const status = (!data.Status && data.Status?.length === 0) ? undefined : TranslationService.getTranslation(data.Status!);
    const statusTitle = status && status + (lastStatusDate ? (" (" + formatDateTimeDigits(lastStatusDate) + ")") : "");
    const calculateDate = (date: string | undefined) => {
        return date ? new Date(new Date(date).getTime() - timezone * 60 * 1000) : undefined;
    }

    const firstRow: MiniTableData[] = [
        { title: translate.Date, value: OptionalMap(calculateDate(data.Started ?? data.Created), x => formatDateTimeDigits(x)) },
        { title: translate.User, value: data.UserName },
        { title: translate.Type, value: importType && TranslationService.getTranslation(importType.text) },
        { title: translate.Template, value: data.ImportTemplate?.Name },
        { title: translate.Files, value: () => <><a className="me-2" href={RequestService.getBaseUrl() + "/import/download?id=" + data.ImportID}>{data.Filename}</a><a title={translate.Template} href={RequestService.getBaseUrl() + "/import/downloadoriginal?id=" + data.ImportID}><i className="fa-regular fa-arrow-down-to-line" /></a></> },
    ]

    const secondRow: MiniTableData[] = [
        { title: translate.Created, value: OptionalMap(calculateDate(data.Created), x => formatDateTimeDigits(x)) },
        { title: translate.Rows, value: data.Rows?.toString() },
        { title: translate.StartDate, value: OptionalMap(calculateDate(data.Started), x => formatDateTimeDigits(x)) ?? "-" },
        { title: translate.Errors, value: () => data.Errors ? <>{data.Errors}<a className="ms-2" href={RequestService.getBaseUrl() + "/import/downloaderrors?id=" + data.ImportID}><i className="fa fa-download" /></a></> : <> </> },
        { title: translate.Status, value: statusTitle },
    ]

    const thirdRow: MiniTableData[] = [
        { title: translate.WithoutChange, value: data.NoChanges?.toString() },
        { title: translate.New + " " + translate.Customers.toLowerCase(), value: data.PersonCount.toString() },
        { title: translate.Updated, value: data.Modified?.toString() },
        { title: translate.NewF + " " + translate.TypeIOList.toLowerCase(), value: data.IOCount.toString() },
        { title: translate.Repeated, value: data.Repeated?.toString() },
        { title: translate.New + " " + translate.Transaction.toLowerCase(), value: data.TransactionCount.toString() },
        { title: translate.Errors, value: data.Errors?.toString() },
        { title: translate.New + " " + translate.Group.toLowerCase(), value: data.TransactionCount.toString() },
    ];

    useEffect(() => {
        const updateHtml = async () => {
            const result = await RequestService.post("/import/getresult", { id: data.ImportID }) as string;
            resultDiv.current!.innerHTML = result;
        }
        updateHtml();
    }, [data.ImportID])
    const [isSaving, ] = useState(false);

    return (
        <>
         <div className="floatingBody p-4">
            <div className="ps-3 d-flex h-100 flex-column">
                <MiniTable data={firstRow} />
                <hr className="w-100" />
                <MiniTable data={secondRow} />
                <hr className="w-100" />
                <MiniTable data={thirdRow} />
                <hr className="w-100" />
                <div ref={resultDiv} className="flex-grow-1" title="result" style={{ width: "100%", border: "1px solid", }} />
            </div>
        </div>
        <FloatingPanelFooter>
        <button className="btn btn-secondary" onClick={() => alert("falta endpoint")}>{translate.RestartImport}</button>
                <button className='btn btn-primary me-3'>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
        </FloatingPanelFooter>
        </>
       );
}

type MiniTableData = { title: string, value?: string | (() => JSX.Element) }

const MiniTable = ({ data }: { data: MiniTableData[] }) => {
    return (

        <div className="row">
            {data.map(x => (
                <div key={x.title} className="col-6 mb-1">
                    <div className="row">
                        <div className="col-4">
                            <label>{x.title}</label>
                        </div>
                        <div className="col-8">
                            {x.value && (typeof x.value === "string" ? x.value : x.value())}
                        </div>
                    </div>
                </div>))
            }
        </div>

    )
}
export default ImportLogDetail;