import { Dropdown as BoostrapDrp } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import { useIsFirstTime } from "../../../utils/ReactUtils";
import { Cast } from "../../../utils/Utils";
import DropdownNest from "./DropdownNest";

export type DropdownProps<T> =
    {
        selectClasses?: string;
        defaultValue?: T;
        value?: T;
        readonly?: boolean;
        items: DropdownItems<T> | OtherItems<T>;
        showSearchFilter?: boolean;
        placeholder?: string;
    }
    & (
        { optionLabel?: undefined; onChange: (value: T) => void; onChildItemChange?: (value: T, parentTitle: string) => void; } |
        { optionLabel: string; onChange: (value: T | undefined) => void; onChildItemChange?: (value: T, parentTitle: string) => void; }
    );

export type DropdownItems<T> = readonly ({ text: string, key?: string | number } & ({ value: T } | { isTitle: boolean } | { childItems: { text: string, value: T }[] }))[];
type OtherItems<T> = T extends (string | number) ? { Value: string, Id: T }[] : never;

type SearchableItems<T> = { title: string, items: DropdownItems<T> }[];

const getDefaultText = <T,>(value: DropdownProps<T>["value"], defaultValue: DropdownProps<T>["defaultValue"], items: DropdownItems<T>, optionLabel: DropdownProps<T>["optionLabel"]) => {
    const selectedValue = value ?? defaultValue;
    let newText = undefined;
    if (selectedValue !== undefined && selectedValue !== null) {
        // eslint-disable-next-line eqeqeq
        newText = getItems(items).find(x => "value" in x && x.value == selectedValue)?.text;
        if (newText === undefined && process.env.NODE_ENV === "development") {
            console.warn("Value not found in dropdown items: " + ((typeof selectedValue === "object") ? JSON.stringify(selectedValue) : selectedValue), items);
        }
    }
    return newText ?? optionLabel ?? items.find(x => "value" in x)?.text ?? "";
};

const getItems = <T,>(items: DropdownProps<T>["items"]): DropdownItems<T> => {
    if (items.length === 0) {
        return []
    };
    if ("Value" in items[0] && "Id" in items[0]) {
        return Cast<readonly { Value: string; Id: string | number; }[]>(items).map(x => ({ text: x.Value, value: x.Id })) as unknown as DropdownItems<T>;
    }
    return items as DropdownItems<T>;
}

const getItemsToSearch = <T,>(newItems: DropdownItems<T>) => {
    let itemsArr = [];
    let itemsElem: typeof newItems[number][] = [];
    let title = "";
    for (let i = 0; i < newItems.length; i++) {
        if ("isTitle" in newItems[i]) {
            title = newItems[i].text;
            itemsElem = [];
            itemsArr.push({ title, items: itemsElem });
        }
        else {
            if (i === 0) {
                itemsArr.push({ title, items: itemsElem });
            }
            itemsElem.push(newItems[i]);
        }
    }
    return itemsArr;
}

const Dropdown = <T,>({ onChange, onChildItemChange, selectClasses, optionLabel, defaultValue, items: orignalItems, value, readonly = false, showSearchFilter = undefined, placeholder = undefined }: DropdownProps<T>) => {
    const [chevronClass, setChevronClass] = useState("fa-chevron-down");
    const prevValue = useRef(value);
    const collapsable = useRef<HTMLButtonElement>(null);
    const filterFields = useRef<HTMLInputElement>(null);
    const [filteredItems, setFilteredItems] = useState(getItems(orignalItems));
    const [itemsToSearch, setItemsToSearch] = useState<SearchableItems<T>>(getItemsToSearch(filteredItems));
    const [currentText, setCurrentText] = useState(getDefaultText(value, defaultValue, getItems(orignalItems), optionLabel));
    const firstTime = useIsFirstTime();
    const showFilter = showSearchFilter ?? orignalItems.length > 6;

    useEffect(() => {
        const onCollapse = () => {
            setChevronClass("fa-chevron-down");
        };
        const onShow = () => {
            setChevronClass("fa-chevron-up");
        };
        const col = collapsable.current;
        col?.addEventListener("hidden.bs.dropdown", onCollapse);
        col?.addEventListener("shown.bs.dropdown", onShow);
        return () => {
            col?.removeEventListener("hidden.bs.dropdown", onCollapse);
            col?.removeEventListener("shown.bs.dropdown", onShow);
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevValue.current !== value) {
            const name = getDefaultText(value, defaultValue, getItems(orignalItems), optionLabel);
            setCurrentText(name);
            prevValue.current = value;
        }
    });

    useEffect(() => {
        if (firstTime()) {
            return;
        }
        const newItems = getItems(orignalItems);
        setFilteredItems(newItems);
        setItemsToSearch(getItemsToSearch(newItems));
    }, [firstTime, orignalItems]);

    const onOptionSelected = (selected: { value: T, text: string } | undefined) => {
        if (value === undefined) {
            setCurrentText(selected?.text ?? optionLabel ?? "");
        }
        onChange(selected?.value as T);
    }

    const onChildOptionClicked = (selected: { value: T, text: string } | undefined, parentTitle: string) => {
        if (value === undefined) {
            setCurrentText(selected?.text ?? optionLabel ?? "");
        }
        if (onChildItemChange) {
            onChildItemChange(selected?.value as T, parentTitle);
        }

        const col = collapsable.current!;
        BoostrapDrp.getInstance(col)?.hide();
    }

    const searchChanged = (value: string) => {
        value = value.toLowerCase();
        const itemsToSearchFiltered = itemsToSearch.flatMap(x => {
            const filtered = x.items.filter(y => y.text?.toLowerCase().indexOf(value) > -1)
            if (filtered.length > 0) {
                if (x.title !== "") {
                    return ([{ isTitle: true, text: x.title }, ...filtered]);
                }
                else {
                    return filtered;
                }
            }
            return [];
        });
        setFilteredItems(itemsToSearchFiltered);
    }

    const dropDownClicked = () => {
        if (!filterFields.current) {
            return;
        }
        filterFields.current.value = "";
        searchChanged("");
        filterFields.current.focus()
    }

    return (
        <div className={"dropdown dropdown-intiza" + (selectClasses ? " " + selectClasses : "")} >
            <button className="btn dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={dropDownClicked} ref={collapsable} disabled={readonly}>
                <div className="select-intiza">
                    <span className={`ellipsis-oneline ${currentText ? '' : 'text-placeholder'}`} title={currentText || placeholder} >{currentText || placeholder}</span>
                    {!readonly && <i className={"fas ms-2 " + chevronClass}></i>}
                </div>
            </button>

            {<ul className="dropdown-menu">
                {showFilter && <li key="filter-search" className="p-2">
                    <input type="text" placeholder={placeholder} onChange={event => searchChanged(event.target.value)} className="form-control" ref={filterFields}></input>
                </li>}
                {filteredItems.length === orignalItems.length && optionLabel && <li onClick={() => onOptionSelected(undefined)}><button type="button" className="dropdown-item">{optionLabel}</button></li>}
                {filteredItems.map(x => (<li key={x.key ?? x.text}>
                    {"isTitle" in x && <h6 className="dropdown-header fw-bolder" style={{ userSelect: "none" }} onClick={e => { e.stopPropagation(); e.preventDefault(); return false; }}>{x.text}</h6>}
                    {"value" in x && <button type="button" onClick={() => onOptionSelected(x)} className="dropdown-item">{x.text}</button>}
                    {"childItems" in x && <DropdownNest title={x.text} onClick={onChildOptionClicked} items={x.childItems} />}
                </li>))}
            </ul>}
        </div>
    );
};

export default Dropdown;