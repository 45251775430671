import { Route, Switch } from "react-router";
import ReportActivity from "../reports/ReportActivity";
import ReportActivitySummary from "../reports/ReportActivitySummary";
import ReportAgingGroup from "../reports/ReportAgingGroup";
import ReportExport from "../reports/ReportExport";
import ReportInvoice from "../reports/ReportInvoice";
import ReportInvoiceGroup from "../reports/ReportInvoiceGroup";
import ReportPayment from "../reports/ReportPayment";
import TabPosition from "./TabPosition";
import ReportClientMap from "../reports/ReportClientMap";
import ReportPaymentAnalysis from "../reports/ReportPaymentAnalysis";
import ReportCollection from "../reports/ReportCollection";
import ReportHistoryClientList from "../reports/ReportHistoryClientList";
import ReportHistoryIoList from "../reports/ReportHistoryIoList";
import ReportIoActivityList from "../reports/ReportIoActivityList";
import ReportScheduledList from "../reports/ReportScheduledList";
import ReportCustomerAnalysis from "../reports/ReportCustomerAnalysis";
import ReportReceivablesSummary from "../reports/ReportReceivablesSummary";

const ReportRoutes = () => {
    return (
        <TabPosition module="Reports">
            <Switch>
                <Route path="/:lang/report/iolist" component={ReportInvoice} />
                <Route path="/:lang/report/historyclientlist" component={ReportHistoryClientList} />
                <Route path="/:lang/report/historyiolist" component={ReportHistoryIoList} />
                <Route path="/:lang/report/ioactivity" component={ReportIoActivityList} />
                <Route path="/:lang/report/scheduled" component={ReportScheduledList} />
                <Route path="/:lang/report/client" component={ReportCustomerAnalysis} />

                <Route path="/:lang/report/payment" component={ReportPayment} />
                <Route path="/:lang/report/reportexport" component={ReportExport} />
                <Route path="/:lang/report/reportactivity" component={ReportActivity} />
                <Route path="/:lang/report/invoicegroup" component={ReportInvoiceGroup} />
                <Route path="/:lang/report/reportActivitySummary" component={ReportActivitySummary} />
                <Route path="/:lang/report/ReportAgingGroup" component={ReportAgingGroup} />
                <Route path="/:lang/report/ReportClientMap" component={ReportClientMap} />
                <Route path="/:lang/report/collection" component={ReportCollection} />
                <Route path="/:lang/report/ReportPaymentAnalysis" component={ReportPaymentAnalysis} />
                <Route path="/:lang/report/ReportReceivablesSummary" component={ReportReceivablesSummary} />
            </Switch>
        </TabPosition>)
}

export default ReportRoutes;