import { SortDirection } from "../../shared/entities/Sort";


export class ScheduledListRequest {
    page: number = 0
    pageSize: number = 20;
    dateFrom: string = '20231201';
    dateTo: string = '20231201';

    filter: any = null;
    sortColumn: string = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    pagesize: number = 20;
}