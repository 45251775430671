import { useCallback, useState } from "react";
import { ActivityListResponse } from "../client/activity/entities/AcitivityListResponse";
import { AdvancedFiltersProvider } from "../shared/components/AdvancedFiltersContext";
import { RrsAdvancedFilters } from "./ReportReceivablesSummary/RrsAdvancedFilters";
import { RrsAveragePayment } from "./ReportReceivablesSummary/RrsAveragePayment";
import { RrsDebtAging } from "./ReportReceivablesSummary/RrsDebtAging";
import { RrsDelicuencyDays } from "./ReportReceivablesSummary/RrsDelicuencyDays";
import { RrsMonthlyDetail } from "./ReportReceivablesSummary/RrsMonthlyDetail";
import { RrsOverdueDebt } from "./ReportReceivablesSummary/RrsOverdueDebt";
import { RrsReceivables } from "./ReportReceivablesSummary/RrsReceivables";
import { ReportReceivablesSummaryService } from "../../services/ReportReceivablesSummaryService";
import { useEndpointData } from "../../utils/TableUtils";
import FilterService from "../../services/FilterService";

const ReportReceivablesSummary = ({ data }: { data: ActivityListResponse.Item }) => {
    const [filter, setFilter] = useState("");
    const onFilterApply = (filterArray: string[] | undefined) => setFilter(FilterService.GetExtraFiltersRequestString(filterArray) ?? "");
    const receivablesEndpoint = useCallback(() => ReportReceivablesSummaryService.getReceivables(filter), [filter]);
    const { loading, response } = useEndpointData(receivablesEndpoint);
    const receivables = loading ? undefined : response
    return (
        <AdvancedFiltersProvider>
            <div className="container-fluid padding">
                <RrsAdvancedFilters onFilterApply={onFilterApply} />
                <RrsReceivables filter={filter} receivables={receivables} />
                <RrsAveragePayment filter={filter} receivables={receivables} />
                <RrsDebtAging filter={filter} receivables={receivables} />
                <RrsOverdueDebt filter={filter} receivables={receivables} />
                <RrsDelicuencyDays filter={filter} receivables={receivables} />
                <RrsMonthlyDetail filter={filter} />
            </div>
        </AdvancedFiltersProvider >
    );
};

export default ReportReceivablesSummary;

