import Translations from '../assets/translations/translations.json';

export type Languages = ["es", "en", "pt"];
const availableLanguages: Languages = ["es", "en", "pt"];
type TupleToUnion<T extends any[]> = T[number]
export type Language = TupleToUnion<typeof availableLanguages>;
export type TranslationsKey = keyof typeof Translations;
export type LangIndex<T> = { [t in Language]:  T};
const translatedLanguages: { [t in Language]:  { [key in  TranslationsKey]: string } } = { es: {}, en: {}, pt: {}} as { [t in Language]:  { [key in  TranslationsKey]: string } };
availableLanguages.forEach(lang => {
    Object.keys(Translations).forEach(key => {
        const entry = (Translations as { [key: string]: { [lang: string]: string | undefined } })[key][lang];
        if (entry !== undefined) {
            translatedLanguages[lang][key as TranslationsKey] = entry;
            return;
        }
        if (process.env.NODE_ENV === 'production') {
            translatedLanguages[lang][key as TranslationsKey] = "";
        } else {
            // console.error('Missing translation! Not suitable for production: ' + key);
            translatedLanguages[lang][key as TranslationsKey] = `Missing '${key}' in '${lang}'`;
        }
    })
});

export class TranslationService {
    public static currentLanguage: Language = "es";
    public static translate: Readonly<typeof translatedLanguages[Language] & {[key: string]: string}> = translatedLanguages["es"];
    public static getTranslation(key: string) {
        return TranslationService.translate[key as "Action"]
    };
    public static setCurrentLanguage(lang: Language) {
        if (lang === TranslationService.currentLanguage){
            return;
        }
        TranslationService.currentLanguage = lang;
        TranslationService.translate = translatedLanguages[lang];
    }
    public static getCurrentLanguage(): Language {
        return TranslationService.currentLanguage;
    }
}