import { useContext } from "react";
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import ModalService from "../shared/bootstrap/Modal";
import ToastContext from "../shared/bootstrap/Toast";
import Dropdown from "../shared/components/Dropdown";
import FloatingPanelService from "../shared/FloatingPanel";
import { TranslationService } from '../../services/TranslationService';
import TabPosition from "../shared/TabPosition";
import { UserListGetResponse } from "./entities/UserListGetResponse";
import { UserEdit } from "./UserEdit";
import Table, { TableHeader } from "../shared/Table";
import UserTableProvider from "./UserTableProvider";
import TableContext from "../task/TableContext";
import { DefaultModalProps } from "../shared/bootstrap/DefaultModal";
import TooltipComponent from "../shared/TooltipComponent";


const btnEditUserClick = async (response: UserListGetResponse | undefined, reload: () => void, title: string, userId?: number) => {
    FloatingPanelService.showPanel({
        title: title,
        children:
            <UserEdit
                userId={userId}
                panelOptions={response?.panels.map(panel => ({ value: panel.DashboardPanelID, text: panel.Name })) ?? []}
                onSubmit={reload}
            />,
        width: 900,
        height: 669,
    });
}

const UserList: React.FC = () => {
    const { translate } = TranslationService;

    const tableHeaders: TableHeader[] = [
        new TableHeader("Name", translate.Name, false, false),
        new TableHeader("Email", translate.Email, false, false),
        new TableHeader("Profile", translate.Profile, false, false),
        new TableHeader("Panel", translate.Panel, false, false),
        new TableHeader("LastLogin", translate.LastLogin, false, false),
        new TableHeader("Actions", "", false, false, "w-80px-fixed")
    ];

    return (
        <TabPosition module="Configuration">
            <UserTableProvider>
                <div className="container-fluid padding">
                    <div className="d-flex gap-3 ">
                        <h2>{translate.Users}</h2>
                        <UserCount></UserCount>
                    </div>
                    <div className="card mt-3">
                        <UserFilters></UserFilters>

                        <Table emptyMessage={translate.NoResults}
                            headers={tableHeaders}
                            item={UserTableItem}
                        />
                    </div>
                </div>
            </UserTableProvider>
        </TabPosition>
    )
}

export const UserCount = () => {
    const context = useContext(TableContext);
    return (
        <div className="align-self-end">
            {`${context.response?.result.users.filter((x: UserListGetResponse.User) => !x.ReadOnly).length ?? 0} ${TranslationService.translate.Users} | ${context.response?.result.users.filter((x: UserListGetResponse.User) => x.ReadOnly).length ?? 0} ${TranslationService.translate.QueryUser}`}
        </div>
    )
}

export const UserFilters = () => {
    const context = useContext(TableContext);
    const profileOptionList = [
        "Guest",
        "Readonly",
        "Administrator",
        "Creator"
    ].map(x => ({ value: x, text: TranslationService.getTranslation("UserRole" + x) }))

    const filterList = (profile?: string) => {
        context.applyFilters(profile);
    }

    return (<div className="genericHeader">
        <div className="searcherFilterHeader">
            <div>
                <p className="pb-1">{TranslationService.translate.Profile}:</p>
                <Dropdown optionLabel={TranslationService.translate.All} onChange={filterList} items={profileOptionList} />
            </div>
        </div>

        <div className="actionButtonsHeader">
            <button type="button" className="btn btn-primary" onClick={() => btnEditUserClick(context.response.result, context.reload, TranslationService.translate.NewUser)}>
                <i className="far fa-plus fa-fw"></i>{TranslationService.translate.NewUser}
            </button>
        </div>
    </div>
    )
}

export const UserTableItem = ({ data }: { data: UserListGetResponse.User }) => {
    const context = useContext(TableContext);
    const { showDefaultModal } = ModalService;
    const { showToast } = useContext(ToastContext);

    const btnDeleteUserClick = async (userId: number) => {

        const modalProps: DefaultModalProps = {
            message: TranslationService.translate.ConfirmDeleteText,
            acceptButtonLabel: TranslationService.translate.Accept,
            onAcceptClick: async () => {
                const result = await UserService.delete(userId);
                if (result instanceof Error) {
                    showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                }
                else {
                    showToast(TranslationService.translate.UserDeleted, undefined, "success");
                    ModalService.hideModal();
                    context.reload();
                }
            }
        }
        showDefaultModal(modalProps);
    }

    const getLastLoginText = (dateLastLoginStr: string | undefined | null) => {
        if (!dateLastLoginStr) { return "-"; }
        const dateLastLogin = new Date(dateLastLoginStr);
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const dateNow = new Date();

        const diffDays = Math.round(Math.abs((dateNow.getTime() - dateLastLogin.getTime()) / oneDay));
        return diffDays === 0 ? TranslationService.translate.Today : TranslationService.translate.NDaysAgo.replace("{days}", diffDays.toString())
    }

    return (
        <tr key={data.UserID} className="show-child-on-hover">
            <td>{data.HasPassword ? data.UserName : <i>Pendiente</i>}</td>
            <td>{data.UserEmail}</td>
            <td className={UserService.getRole(data) === "Creator" ? "fw-bolder" : undefined}>{TranslationService.getTranslation("UserRole" + UserService.getRole(data))}</td>
            <td>
                {data.DashboardPanelID ? context.response!.result.panels.find((y: UserListGetResponse.Panel) => y.DashboardPanelID === data.DashboardPanelID)?.Name : TranslationService.translate.Standard}
            </td>
            <td>{getLastLoginText(data.LastLogin)}</td>
            <td className="text-end">
                <div className={"show-when-hovering-parent"}>
                    <TooltipComponent title={TranslationService.translate.Edit} placement="right" className="align-middle">
                        <i className={"far fa-fw pointer fa-pen mx-2"} onClick={() => btnEditUserClick(context.response.result, context.reload, TranslationService.translate.User + " " + data.UserName, data.UserID)} />
                    </TooltipComponent>
                    <TooltipComponent title={TranslationService.translate.Delete} placement="right" className="align-middle">
                        <i className={(UserService.getRole(data) === "Creator" || data.UserID.toString() === CompanyService.getUserid()) ? "d-none" : "far fa-fw pointer fa-trash text-danger mx-2"} onClick={() => btnDeleteUserClick(data.UserID)} />
                    </TooltipComponent>
                </div>
            </td>
        </tr >
    )
}

export default UserList;