import { useContext } from "react";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import NewTaskForm from "../../task/components/NewTaskForm";
import NewComment from "../activity/components/NewComment";
import ClientDetailContext from "../ClientDetailContext";
import { EmailCompose } from "./EmailCompose";
import { SMSCompose } from "./SMSCompose";
import IconButtonDebts from "./IconButtonDebts";
import { Client } from "../entities/ClientGetResponse";
import TooltipComponent from "../../shared/TooltipComponent";
import { WhatsappCompose } from "./WhatsappCompose";

export const ClientDetailLeftMenuButtons = ({ direction, client, clientEmail, reload }: { direction?: "row" | "column", client: Client, clientEmail: string, reload: () => void }) => {
    const { translate } = TranslationService;
    const clientDetailContextValues = useContext(ClientDetailContext);

    const openNewCommentModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewComment,
            children: <NewComment clientid={client.PersonId.toString()} reload={reload} />,
            width: 800,
            height: 700,
        });
    };

    const openNewTaskModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewTask,
            children: <NewTaskForm personId={client.PersonId.toString()} personName={client.Name} onNewTask={reload} />,
            width: 800,
            height: 751,
        });
    };

    const openNewSMSModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewSMS,
            children: <SMSCompose person={client} onSubmit={reload} />,
            width: 800,
            height: 620,
        });
    };

    const openNewEmailModal = () => {
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children: <EmailCompose personId={client.PersonId.toString()} defaultTo={clientEmail} clientDetailContext={clientDetailContextValues} onSubmit={reload} />,
            width: 1000,
            height: 769,
        });
    };

    const openNewWhatsappModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewWhatsApp,
            children: <WhatsappCompose person={client!} personId={client.PersonId.toString()} onSubmit={reload} />,
            width: 800,
            height: 650,
            position: 'bottom-right'
        });
    };

    return (
        <div className={"actions " + direction}>
            {CompanyService.canDo("newmail") && <IconButtonDebts onClick={openNewEmailModal}>
                <TooltipComponent title={translate.ComposeMail}>
                    <i className="far fa-envelope"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo("newtask") && <IconButtonDebts onClick={openNewTaskModal}>
                <TooltipComponent title={translate.NewTask}>
                    <i className="far fa-square-check"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo("newcomment") && <IconButtonDebts onClick={openNewCommentModal}>
                <TooltipComponent title={translate.NewComment}>
                    <i className="far fa-message-dots"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo("newsms") && <IconButtonDebts onClick={openNewSMSModal}>
                <TooltipComponent title={translate.NewSMS}>
                    <i className="fa-regular fa-phone"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo('newwhatsapp') && <IconButtonDebts onClick={openNewWhatsappModal}>
                <TooltipComponent title={translate.NewWhatsApp}>
                    <i className="fa-brands fa-whatsapp"></i>
                </TooltipComponent>
            </IconButtonDebts>}
        </div>);
};