import React from "react";
import { useState, useCallback, useEffect } from "react";
import { SortDirection } from "../pages/shared/entities/Sort";
import { TableContextValues } from "../pages/task/TableContext";
import { isAbortError, useRequestAborter } from "./RequestUtils";

export function useEndpointData<T extends {}>(endpoint: () => Promise<T | Error>) {
    const [response, setResponse] = useState<T>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const requestAborter = useRequestAborter();

    const requestData = async () => {
        setLoading(true);
        const result = await requestAborter.next(endpoint());
        if (isAbortError(result)) {
            return;
        }
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [endpoint, requestAborter]);

    useEffect(() => {
        requestDataCallback();
    }, [requestAborter, requestDataCallback]);

    const value = new TableContextValues<T>();
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.reload = requestData;
    return value
}

type SetRequest<T> = React.Dispatch<React.SetStateAction<T>>;

export function setCurrentPageHelper<T extends { page: number }>(setRequest: SetRequest<T>, pageCount: number) {
    return (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            setRequest(request => ({ ...request, page }));
        }
    }
}
export function setPageSizeHelper<T extends { pagesize: number }>(setRequest: SetRequest<T>) {
    return (pagesize: number) => {
        setRequest(request => ({ ...request, pagesize }));
    }
}
export function applyFiltersHelper<T extends { filter: string | null, page: number }>(setRequest: SetRequest<T>) {
    return (filters: { extraFilters: string[] }) => {
        setRequest(request => ({ ...request, page: 0, filter: filters.extraFilters.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&") }));
    }
}
export function sortHelper<T extends { descending: number, sortColumn: string }>(request: T, setRequest: SetRequest<T>) {
    const sortDirection = request.descending as SortDirection;
    const sortColumn = request.sortColumn;
    const setSortColumn = (key: string) => {
        const newSort = {
            sortColumn: key,
            sortDirection: sortColumn === key ? Number(!sortDirection) : SortDirection.Descending
        };
        setRequest(request => ({ ...request, sortColumn: newSort.sortColumn, descending: newSort.sortDirection }));
    }
    return {
        sort: { sortDirection, sortColumn },
        setSortColumn
    }
}
export function calculatePageCount(response: undefined | { itemCount: number, pageSize: number }) {
    return response ? Math.ceil(response.itemCount / response.pageSize) : 0;
}
